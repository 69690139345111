import * as React from 'react';
import Paper from '@mui/material/Paper';
import RtcChip from '../input/rtc-chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Cookies from 'universal-cookie';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NotesButton from '../../../core-components/entity-details/header/component-entity-notes';

const updateTrailDetails = (data, type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  let send = {
    id:data.id,
    name: data.name,
    startName:data.startName,
    endName:data.endName,
    startGps: data.startGps,
    endGps: data.endGps,
  }

  if(type === 'Section')
  {
    
  }
  else if(type === 'Region')
  {
    send['abbr'] = data.abbr
    send['rtc'] = data.rtc.id
  }

  const ttl = {
    user: user,
    token: token,
    data: send
  }

  return fetch(cookies.get('apiUrl')+"/trail/update/"+type.toLowerCase() + '/details', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function TralDetails(props) {
  var temp_width = (props.type === 'Region')?8:11
  const [ type, setType ] = React.useState(props.type)
  const [ id, setId ] = React.useState(props.data.id)
  const [ data, setData ] = React.useState(props.data)
  const [ width, setWidth ] = React.useState(temp_width)
  const [rtcEdit, setRtcEdit ]= React.useState((type === 'Region'))
  const [hasEdit, setHasEdit] = React.useState(false)
  const [saveClick, setSaveClick] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const cookies = new Cookies();
  const editPermission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    if(saveClick)
    {
      updateTrailDetails(data, type).then(res => res.json())
        .then((result) => {
          props.refresh()
          setSaveClick(false)
        },
        (error) => {
        });
    }
  },[saveClick, data, type])

  if(props.data !== -1)
  {
    if(props.data.id !== data.id)
    {
      setData(props.data)
      setType(props.type)
      setRtcEdit(props.type === 'Region')
      setWidth(temp_width)
      setHasEdit(false)
      setId(props.data.id)
    }
  }
  
  if(props.data === -1 || data === -1 )
  {
    return <></>
  }

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
    setHasEdit(true)
  };

  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={9} >
          <Stack spacing={1}>
            <Typography>RTC</Typography>
            <RtcChip 
              canEdit={((type==='Region') && editPermission)} 
              data={data} 
              type={type}
              onChange={(rtc) => {
                setData({ ...data, rtc: {
                  name: `${rtc.firstName} ${rtc.lastName}`,
                  id: rtc.id.split('-')[1]
                }});
                setHasEdit(true)
              }}
              /> 
          </Stack>
        </Grid>
        <Grid item xs={1} >
          <NotesButton type={type} focusId={id} />
        </Grid>
        {hasEdit && <Grid item xs={1} >
          <IconButton
            size="small"
            onClick={()=>{setConfirmOpen(true)}}
          >
            <Badge>
              <SaveIcon/>
            </Badge>
          </IconButton>
        </Grid>}
        <Grid item xs={width} >
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <TextField id="outlined-basic" 
              size="small" 
              label={type + " name"} 
              variant="outlined"
              value={data.name}
              onChange={handleChange('name')}
              disabled={!editPermission}
              />
              
            </FormControl>
        </Grid>
        {rtcEdit && <Grid item xs={3}>
          <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
            <TextField id="outlined-basic"
              size="small" 
              label={type + " abbr"} 
              variant="outlined" 
              value={data.abbr} 
              onChange={handleChange('abbr')}
              disabled={!editPermission}
              />
            </FormControl>
        </Grid>}
        <Grid item xs={8} >
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <TextField id="outlined-basic" 
              size="small" 
              label="Start" 
              variant="outlined" 
              value={data.startName} 
              onChange={handleChange('startName')}
              disabled={!editPermission}
              />
            </FormControl>
        </Grid>
        <Grid item xs={3} >
          <FormControl fullWidth sx={{ mt: 1 }}  variant="filled">
            <TextField id="outlined-basic"
              size="small" 
              label={"GPS"} 
              variant="outlined" 
              value={data.startGps} 
              onChange={handleChange('startGps')}
              disabled={!editPermission}
              />
            </FormControl>
        </Grid>
        <Grid item xs={8} >
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <TextField id="outlined-basic" 
              size="small" 
              label="End" 
              variant="outlined" 
              value={data.endName} 
              onChange={handleChange('endName')}
              disabled={!editPermission}
              />
          </FormControl>
        </Grid>
        <Grid item xs={3} >
          <FormControl fullWidth sx={{ mt: 1 }} variant="filled">
            <TextField id="outlined-basic"
              size="small" 
              label={"GPS"} 
              variant="outlined" 
              value={data.endGps} 
              onChange={handleChange('endGps')}
              disabled={!editPermission}
              />
            </FormControl>
        </Grid>
      </Grid>
      <Dialog open={confirmOpen} onClose={()=>{setConfirmOpen(false)}}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setConfirmOpen(false)}}>No</Button>
          <Button onClick={(e) => {
              setSaveClick(true)
              setConfirmOpen(false)
            }}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
