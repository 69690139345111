import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import AddDetailButton from './component-add-detail-button';
import TextField from '@mui/material/TextField';
import BeenhereIcon from '@mui/icons-material/Beenhere';

const InputStyles = makeStyles((theme) => ({
  textField: {
    height: '50px',
    width: '80%',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    margin: 'dense',
    border: 'none'
  },
  input: {
    color: 'white',
    border: 'none'
  }
}));

function CustomTextInput(props) {
  const classes = InputStyles({});
  const { InputProps, ...rest } = props;

  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle1" style={{ paddingTop: "7%" }}>Joined: </Typography>
      <InputBase {...rest} className={classes.textField} ></InputBase>
    </Stack>
  );
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px",
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.25}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

const eventApi = (id, entryTs, action, name) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');
  entryTs = new Date(entryTs);
  entryTs = entryTs.toISOString().split('T')[0];

  const ttl = {
    user: user,
    token: token,
    id: id,
    event: name,
    entryTs: entryTs
  };

  return fetch(cookies.get('apiUrl') + "/events/" + action, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const logTracking = (
  id,
  amount,
  date,
  email,
  eventName) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    amount: amount,
    date: date.toISOString().split('T')[0],
    key: id,
    email: email,
    event: eventName
  };

  return fetch(cookies.get('apiUrl') + "/events/log/tracking", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const checkAttributes = (id, event) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    person: id,
    event: event
  };

  return fetch(cookies.get('apiUrl') + "/events/check/conditions", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function EventChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [size, setSize] = React.useState(1)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [subName, setSubName] = React.useState(props.data.total)
  const oldDate = new Date(props.data.entryDate)
  const [pass, setPass] = React.useState({
    date: oldDate,
    action: '',
    name: props.data.name
  });
  const id = props.data.id;
  const [date, setDate] = React.useState(oldDate);
  const [logAmount, setLogAmount] = React.useState(0);
  const [logDate, setLogDate] = React.useState(new Date());
  const [logDateOpen, setLogDateOpen] = React.useState(false);
  const [logPackage, setLogPackage] = React.useState({
    click: false,
    etId: id,
    date: logDate,
    amount: logAmount,
    email: props.email
  });
  const [dateOpen, setDateOpen] = React.useState(false);
  const cookies = new Cookies();
  var permissiveIcon = <></>;
  if (cookies.get('permission') === 'null') {
    permissiveIcon = <DeleteIcon />;
  }
  const [icon, setIcon] = React.useState(permissiveIcon);
  const [checkRules, setCheckRules] = React.useState(false);

  let smalls = 12;
  var theme = null;
  if (props.data.color !== 'primary') {
    smalls = 8;
    theme = createTheme({
      palette: {
        primary: {
          main: props.data.color,
        },
      },
    });
  }
  const minniMe = smalls;

  React.useEffect(() => {
    if (pass.action !== '')
      eventApi(id, pass.date, pass.action, pass.name)
        .then(res => res.json())
        .then((result) => {
          if (pass.action === 'unregister') {
            props.onClear(id);
          }
          pass.action = '';
        }, (error) => { });
  }, [id, pass]);

  React.useEffect(() => {
    if (logPackage.click)
      logTracking(
        logPackage.etId,
        logPackage.amount,
        logPackage.date,
        logPackage.email,
        props.data.name
      ).then(res => res.json())
        .then((result) => {
          logPackage.click = false
          setLogPackage({ ...logPackage })
          setCheckRules(true);
          props.refresh();
        }, (error) => { });
  }, [logPackage, props]);

  React.useEffect(() => {
    if(checkRules) {
      console.log(props.data.eventID)
      checkAttributes(props.entity.split('-')[1], props.data.eventId)
      .then(res => res.json())
      .then((result) => {
        console.log(result);
        setCheckRules(false);
        props.refresh();
      });
    }
  }, [checkRules]);

  const handleClick = (event) => {
    if (!open) {
      setIcon(<CloseFullscreenIcon />);
      setOpen(!open);
      setSize(1.2);
      setSubName(props.data.id);
    }
  };

  const toggle = (e) => {
    setDateOpen(true);
  };

  const handleDate = (value, e) => {
    pass.action = 'update';
    let temp = new Date(value);
    setDate(value);
    pass.date = temp;
    setPass({ ...pass });
  };

  const handleDelete = (event) => {
    if (open) {
      setSize(1);
      setChipTitle(props.data.name);
      setOpen(!open);
      setIcon(permissiveIcon);
      setSubName(props.data.total);
    }
    else {
      setConfirmOpen(true);
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    pass.action = 'unregister'
    setPass({ ...pass })
    setConfirmOpen(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={minniMe}>
          <CustomChip
            size={size}
            label={(<>
              {!open && <>
                {chipTitle}
              </>}
              {open && <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label='Since'
                    inputFormat="MM/dd/yyyy"
                    value={date}
                    open={dateOpen}
                    onChange={(newValue) => setDate(newValue)}
                    onAccept={(newValue, e) => handleDate(newValue, e)}
                    onClose={() => {
                      setDateOpen(false)
                      setDate(oldDate)
                    }}
                    onClear={() => { setDate(oldDate) }}
                    onOpen={(e) => { toggle(e) }}
                    renderInput={(params) => <CustomTextInput {...params} />}
                  />
                </LocalizationProvider>
              </>}
            </>)}
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
          />
        </Grid>
        {theme !== null && !open &&
          <Grid item xs={2}>
            <AddDetailButton
              icon={<BeenhereIcon size='small' />}
              handleSave={(e) => {
                logPackage.click = true;
                logPackage.date = logDate;
                logPackage.amount = logAmount;
                setLogPackage({ ...logPackage });
                setLogDate(new Date());
                setLogAmount('');
              }}
            >
              <Stack pt={1} spacing={1}>
                <TextField
                  id="outlined-basic"
                  label="Log amount"
                  variant="outlined"
                  value={logAmount}
                  onChange={(e) => { setLogAmount(e.target.value) }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label='Log date'
                    inputFormat="MM/dd/yyyy"
                    value={logDate}
                    open={logDateOpen}
                    onChange={(newValue) => setLogDate(newValue)}
                    onAccept={(newValue, e) => { setLogDateOpen(false) }}
                    onClose={() => {
                      setLogDateOpen(false)
                    }}
                    onClear={() => { setLogDateOpen(false) }}
                    onOpen={(e) => { setLogDateOpen(true) }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </AddDetailButton>
          </Grid>
        }
        <Grid item xs={1}>
          {theme !== null && <ThemeProvider theme={theme}>
            <CustomChip
              size={size}
              label={subName}
              variant="filled"
              deleteIcon={icon}
              color="primary"
            />
          </ThemeProvider>}
        </Grid>
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
