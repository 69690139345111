import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const getMemberTypes = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: 'membership',
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}


const updateMembership = (id,update) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    joinDate: update.joinDate,
    lastPaid: update.lastPaid,
    expDate: update.expDate,
    type: update.type
  }

  return fetch(cookies.get('apiUrl')+"/detail/update/membership", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MembershipDetails(props) {
  const [types, setTypes] = React.useState(<MenuItem value={props.data.type}>{props.data.type}</MenuItem>) 
  const [type, setType] = React.useState(props.data.type) 
  const [data, setData ] = React.useState(props.data)
  const disabled = data.isInherited
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [showSave, setShowSave] = React.useState(false);
  const [update, setUpdate] = React.useState({type:props.data.type, joinDate:new Date(props.data.dateJoined)});
  const [saveClick, setSaveClick] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  
  React.useEffect(()=>{
    getMemberTypes()
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        let list = [];
        result.response.map((t) => {
          list.push(<MenuItem key={t.id} value={t.name}>{t.name}</MenuItem>)
        })

        setTypes(list)
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )},[props])


  React.useEffect(()=>{
    if (saveClick) {
      updateMembership(props.entityId,update)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setSaveClick(false)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
    )
  }},[props, update, saveClick])

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setShowSave(true)
  };

  const handleDateJoined = (event) => {
    let lock = data;
    lock['dateJoined'] = event
    setData({...lock});
    setShowSave(true)
  };

  const handleExpDate = (event) => {
    let lock = data;
    lock['expDate'] = event
    setData({...lock});
    setShowSave(true)
  };

  const handleLastPaid = (event) => {
    let lock = data;
    lock['lastPaid'] = event
    setData({...lock});
    setShowSave(true)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <form>
      <Box sx={{ minWidth: 120 }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Member Type"
                onChange={handleTypeChange}
                disabled={disabled}
              >
                {types}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            { showSave &&
              <IconButton 
                size="large"
                onClick={() => { 
                  update.type = type
                  update.joinDate=data.dateJoined
                  update.lastPaid=data.lastPaid
                  update.expDate=data.expDate
                  setConfirmOpen(true)
                }}>
                <SaveIcon/>
              </IconButton>
            }
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Date Joined"
                  inputFormat="MM/dd/yyyy"
                  value={new Date(data.dateJoined)}
                  onChange={(handleDateJoined)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="status-id"
                label="Status"
                value={data.status}
                disabled
                onChange={handleTypeChange}
              >
              </TextField>
            </FormControl>  
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              { data.expDate === 'Never' &&
                <TextField
                  id="status-id"
                  label="Expiration Date"
                  value={data.expDate}
                  disabled
                />
              }
              { data.expDate !== 'Never' &&
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Exipration Date"
                    inputFormat="MM/dd/yyyy"
                    value={new Date(data.expDate)}
                    onChange={(handleExpDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>
              }
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Last Paid"
                  inputFormat="MM/dd/yyyy"
                  value={new Date(data.lastPaid)}
                  onChange={handleLastPaid}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
              setConfirmOpen(false)
              setSaveClick(true)
              setShowSave(false)
            }}>Save</Button>
        </DialogActions>
      </Dialog>
    </form>        
  );
}
