import * as React from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ReportCheckboxInput(props) {
  const [checked, setChecked] = React.useState(props.default === 'true');
  const [showField, setShowField] = React.useState(props.showVal);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onChange(val)
  };

  const handleShow = (event) => {
    setShowField(event.target.checked);
    props.onShow(event.target.checked)
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {isTemplate && <FormControlLabel
          label="Show field"
          control={
            <Checkbox
              checked={showField}
              indeterminate={showField}
              onChange={handleShow}
            />
          }
        />}
      </Grid>
      <Grid item xs={12}>
       <FormControlLabel
          label={props.label}
          control={
            <Checkbox
              checked={checked}
              indeterminate={checked}
              onChange={handleChange}
            />
          }
        />
      </Grid>
    </Grid>  
  )
}
