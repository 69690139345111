import * as React from 'react';
import Stack from '@mui/material/Stack';
import { DataGridPro } from '@mui/x-data-grid-pro'
import AddDetailButton from '../inputs/component-add-detail-button';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MixedSearchInput from '../inputs/mixed-search-input';

const columns = [
  {
    field: "entity",
    headerName: "Parcel Tax Id",
    width: 150,
  },
  {
    field: "county",
    headerName: "County",
    width: 100,
   },
  {
    field: "town",
    headerName: "Town",
    width: 150,
   },
]

const fetchSearch = (values) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    search: values.search,
    county: values.county,
    town: values.town,
    map: '',
    passback: Date.now()
  }

  return fetch(cookies.get('apiUrl')+"/search/parcels", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addLinkLand = (values, entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:entity,
    package:values
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/parcel", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function LandownerDetails(props) {
  const [ results, setResults ] = React.useState([])
  const [ searchError, setSearchError ] = React.useState(null)
  const [ searchLoading, setSearchLoading ] = React.useState('')
  const [ search, setSearch ] = React.useState('')
  const [ saveTitle, setSaveTitle ] = React.useState('new parcel')
  const [ swis, setSwis ] = React.useState('')
  const [ address, setAddress ] = React.useState('')
  const [ county, setCounty ] = React.useState('')
  const [ town, setTown ] = React.useState('')
  const [ agreement, setAgreement ] = React.useState('')
  const [ section, setSection ] = React.useState()

  const [options,setOptions ] = React.useState([])
  const [protect,setProtect ] = React.useState(false)
  const timestamp = {
    ts:0
  }

  const [ pass, setPass ] = React.useState(
    {
      search:'',
      town:'',
      county:''
    }
  )

  const [ request, setRequest ] = React.useState(
    {
      parcel_tax_id:'',
      town:'',
      county:'',
      swis:'',
      address:''
    }
  )

  const [ requestClick, setRequestClick ] = React.useState(false)
  const cookies = new Cookies();
  var permission = (cookies.get('permission') !== 'null')


  React.useEffect(() => {
    let values = {
      town:pass.town,
      county:pass.county,
      search:pass.search
    }
    setSearchLoading(false);
    setProtect(true)
    fetchSearch(values).then(res => res.json())
      .then(
        (result) => {
          if(timestamp.ts < result.passback)
          {
            setOptions(result.response.options);
            setResults(result.response.table)
            if(result.response.table.length===1 )
            {
              if(result.response.table[0].taxId === search)
              {
                setAddress(result.response.table[0].parcelAddress)
                setSwis(result.response.table[0].parcelSwis)
                setTown(result.response.table[0].parcelTown)
                setCounty(result.response.table[0].parcelCounty)
                setAgreement(result.response.table[0].agreement)
                setSaveTitle('Change and Link')
              }
            }
            setSearchLoading(true);
            setSearchError(null)
            timestamp.ts = result.passback
            setProtect(false)
          }
        },
        (error) => {
          setSearchLoading(true);
          setSearchError(error);
          setProtect(false)
        }
      );
  }, [pass]);

  React.useEffect(() => {
    if(requestClick)
    {
    addLinkLand(request, props.entityId).then(res => res.json())
      .then(
        (result) => {
         props.refresh()
         setRequest(false)
        },
        (error) => {
          props.refresh()
        }
      );
    }
  }, [props,request, requestClick]);

  const handleCountyChange = (event) => {
    if(!protect)
    {
      setCounty(event.target.value)
    }
  }

  const handleTownChange = (event) => {
    if(!protect)
    {
      setTown(event.target.value)
    }
  }

  const handleAddressChange = (event) => {
    if(!protect)
    {
      setAddress(event.target.value)
    }
  }

  const handleSwisChange = (event) => {
    if(!protect)
    {
      setSwis(event.target.value)
    }
  }

  const handleSearch = (value) => {
    pass.search = value;
    pass.town=town
    pass.county=county
    setSearch(value)
    setPass({...pass})
  }

  return (
      <Stack spacing={1}>
        <AddDetailButton 
          title="Change/Add and link parcel" 
          saveTitle={saveTitle}
          handleSave={(e) => {
            request.address = address
            request.swis = swis
            request.parcel_tax_id = search
            request.town = town
            request.county = county
            request.agreement = agreement
            request.section = section
            setRequestClick(true)
          }}>
          <Stack spacing={1}>
            <Autocomplete
              clearOnBlur={false}
              id="combo-box-demo"
              options={options}
              onInputChange={(e, value) => handleSearch(value)}
              renderInput={(params) => <TextField {...params} label="Tax Id" />}
            />
            <MixedSearchInput
              label={'Section'}
              type={'section'}
              value={section}
              onChange={(value) => {setSection(value)}}
            />
            <FormControl fullWidth>
              <InputLabel id="new-section-type-select-label">Agreement</InputLabel>
              <Select
                labelId="new-section-type-select-label"
                id="new-section-type-select"
                value={agreement}
                label="Agreement"
                onChange={(e)=>{
                  setAgreement(e.target.value)
                }}
              >
                <MenuItem value="FLTC Owns">FLTC Owns</MenuItem>
                <MenuItem value="Easement">Easement</MenuItem>
                <MenuItem value="Written Contract">Written Contract</MenuItem>
                <MenuItem value="Verbal Agreement">Verbal Agreement</MenuItem>
                <MenuItem value="Off Trail">Off Trail</MenuItem>
              </Select>
            </FormControl>
            <TextField 
              id="swiss" 
              label="SWIS" 
              variant="outlined" 
              value={swis}
              onChange={handleSwisChange}
            />
            <TextField 
              id="address"
              label="Address" 
              variant="outlined"
              value={address}
              onChange={handleAddressChange}
            />
            <TextField 
              id="county-search-add" 
              label="County" 
              variant="outlined" 
              value={county}
              onChange={handleCountyChange}
            />
            <TextField 
              id="town-search-add"
              label="Town" 
              variant="outlined"
              value={town}
              onChange={handleTownChange}
            />
          </Stack>
        </AddDetailButton>
          <div style={{ display: 'flex', height: '400px' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGridPro
                  rows={props.data}
                  columns={columns}
                  density="compact"
                />
            </div>
          </div>
        </Stack>
  );
}
