import * as React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ReportSearchInput from './report-search-input';
import IncludeListItem from '../input/include-list-item';
import ExcludeListItem from '../input/exclude-list-item';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Cookies from 'universal-cookie';

const fetchAutolist = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: type,
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ReportConditionalInput(props) {
  const [type, setType ]  = React.useState(props.type);
  const [exclusive, setExclusive] = React.useState(props.exclusive);
  const [includeList, setIncludeList ]  = React.useState([]);
  const [excludeList, setExcludeList ]  = React.useState([]);
  const [addExclude, setAddExclude ]  = React.useState(true);
  const [addInclude, setAddInclude ]  = React.useState(true);
  const [idIncludeList, setIdIncludeList ]  = React.useState([]);
  const [idExcludeList, setIdExcludeList ]  = React.useState([]);
  const [newItem, setNewitem ]  = React.useState('');
  const [showField, setShowField] = React.useState(props.showVal);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);
  const [defaultVal, setDefaultVal] = React.useState(null);
  const [trigger, setTrigger] = React.useState(false);
  const [template, setTemplate ] = React.useState(props.template)

  React.useEffect(() => {
    if(trigger)
    {
      fetchAutolist(type).then(res => res.json())
        .then(
          (result) => {
            let response = result.response
            setTrigger(false)
            switch(type)
            {
              case "forms":
                response = response.forms
                break
            }
            let include = [];
            let exclude = [];
            response.map(element => {
              idIncludeList.map((inc) => {
                if(element.id === inc) include.push(element.name)
              })
              idExcludeList.map((exc) => {
                if(element.id === exc) exclude.push(element.name)
              })
            })
            setIncludeList(include)
            setExcludeList(exclude)
          },
          (error) => {
          }
        );
      }
  }, [type, trigger]);

  if(template !== props.template)
  {
    setTemplate(props.template)
    setIdIncludeList([])
    setIdExcludeList([])
    setAddExclude(true)
    setAddInclude(true)
    setTrigger(true)
  }

  if(defaultVal !== props.default && props.default)
  {
    setDefaultVal(props.default)
    setIdIncludeList(props.default.include)
    setIdExcludeList(props.default.exclude)
    setTrigger(true)
  }

  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onShow(val)
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
        <Divider/>
      </Grid>
      <Grid item xs={2}>
        <IncludeListItem 
          disabled={!addInclude}
          title={`Add ${type}`} 
          handleSave={(e) => {
            if (!includeList.includes(newItem.name) 
              && !excludeList.includes(newItem.name)
              && (addInclude)
            ) {
              includeList.push(newItem.name)
              idIncludeList.push(newItem.id)
              props.onChange({
                include: idIncludeList,
                exclude: idExcludeList
              })
              setIdIncludeList([...idIncludeList])
              setIncludeList([...includeList])

              if(exclusive)
              {
                setAddExclude(false)
              }
            }
          }}>
          <Stack spacing={1}>
            <Box sx={{ width: 300 }}> 
              <ReportSearchInput type={type} onChange={(value, name)=>{
                setNewitem({id:value, name:name})
              }} />
            </Box>
          </Stack>
        </IncludeListItem>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={1}>
          {includeList.map(element => (
            <Grid item>
              <div>
                <Chip 
                  label={element} 
                  onDelete={() => {
                    let index = includeList.indexOf(element)
                    if ( index > -1) {
                      includeList.splice(index, 1)
                      idIncludeList.splice(index, 1)
                      props.onChange({
                        include: idIncludeList,
                        exclude: idExcludeList
                      })
                      setIdIncludeList([...idIncludeList])
                      setIncludeList([...includeList])
                      if(includeList.length === 0 && exclusive)
                      {
                        setAddExclude(true)
                      }
                    }
                  }}
                  deleteIcon={(<DeleteIcon/>)}
                ></Chip>
              </div>
            </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <ExcludeListItem
          disabled={!addExclude}
          title={`Add ${type}`} 
          handleSave={(e) => {
            if (!excludeList.includes(newItem.name)
              && !includeList.includes(newItem.name)
              && (addExclude)
            ) {
              excludeList.push(newItem.name)
              idExcludeList.push(newItem.id)
              props.onChange({
                include: idIncludeList,
                exclude: idExcludeList
              })
              setIdExcludeList([...idExcludeList])
              setExcludeList([...excludeList])

              if(exclusive)
              {
                setAddInclude(false)
              }
            }
          }}>
          <Stack spacing={1}>
            <Box sx={{ width: 300 }}> 
              <ReportSearchInput type={type} onChange={(value, name)=>{
                setNewitem({id:value, name:name})
              }} />
            </Box>
          </Stack>
        </ExcludeListItem>
      </Grid>
      <Grid item xs={10}>
       <Grid container spacing={1}>
          {excludeList.map(element => (
            <Grid item>
              <div>
                <Chip 
                  label={element} 
                  onDelete={() => {
                    let index = excludeList.indexOf(element)
                    if ( index > -1) {
                      excludeList.splice(index, 1)
                      idExcludeList.splice(index, 1)
                      props.onChange({
                        include: idIncludeList,
                        exclude: idExcludeList
                      })
                      setIdExcludeList([...idExcludeList])
                      setExcludeList([...excludeList])
                      if(excludeList.length === 0 && exclusive)
                      {
                        setAddInclude(true)
                      }
                    }
                  }}
                  deleteIcon={(<DeleteIcon/>)}
                ></Chip>
              </div>
            </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>  
  )
}
