import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddSettingsButton from './component-add-settings-button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Divider from '@mui/material/Divider';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';

const allConditions = [
  {
    value:'receipt_date',
    type:'date_range',
    label:'the input value of the receipt date',
    when:'transaction'
  },
  {
    value:'account_type',
    type:'select',
    items: ['Donation', 'Purchase', 'Membership'],
    label:'the transaction type',
    when:'transaction'
  },
  {
    value:'payment',
    type:'value_range',
    label:'the input value of payment',
    when:'transaction'
  },
  {
    value:'paid_by',
    type:'select',
    items:['Stripe','PayPal', 'Credit Card','Cash or Check'],
    label:'the input value of payment method',
    when:'transaction'
  },
  {
    value:'num_parcels',
    type:'value_range',
    label:'the number of parcels assigned',
    when:'parcel'
  },
  {
    value:'num_sections',
    type:'value_range',
    label:'the number of sections assigned',
    when:'volunteer'
  },
  {
    value:'type',
    type:'select',
    items:['Administrative','Sponsor','Maintainer'],
    label:'the input value of the volunteer type',
    when:'volunteer'
  },
  {
    value:'assigned_rtc',
    type:'static',
    label:'is assigned as a Regional Trail Coordinator',
    when:'volunteer'
  },
]

const addRule = (rule, action, type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: 'rule',
    package:rule,
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function RuleForm(props) {
  const [conditionList, setConditionList] = React.useState([]) 
  const [type, setType] = React.useState(props.rule.clause)
  const [selectedCondition, setSelectedCondition] = React.useState('')
  const [rule, setRule] = React.useState(props.rule)
  const [checks, setChecks] = React.useState({})
  const [ruleLanguage, setRuleLanguage] = React.useState(`${props.rule.process} the role, ${props.roleName}`);
  const [action, setAction] = React.useState(props.rule.process);
  const [name, setName] = React.useState(props.rule.name);

  if(props.rule.event.hasOwnProperty('conditions'))
  {
    if(conditionList !== props.rule.event.conditions)
    {
      setConditionList(props.rule.event.conditions)
      setRule({...rule})
    }
  }

  const handleChangeGeneral = (prop) => (event) => {
    rule[prop] = event.target.value;
    setRule({...rule, [prop]:event.target.value})
  };


  const handleRemove = (prop) => () => {
    delete rule.event[prop]
    let index = null
    let i = 0
    conditionList.map(element => {
      if(element.value === prop)
      {
        index = i
      }
      i++
    })

    if(index !== null)
    {
      conditionList.splice(index,1)
      setConditionList([...conditionList])
    }
    rule.event['conditions'] = conditionList
    setRule({...rule})
  };

  if(rule !== props.rule)
  {
    setRule(props.rule)
  }

  React.useEffect(() => {
    
    let newLang = `${action} the role, ${props.roleName}`
    if(type !== ''){
      newLang += ', when an entitiy'
      if(type === 'transaction')
        newLang += ' made a transaction'
      
      if(type === 'volunteer')
        newLang += ' has a volunteer link action'

      if(type === 'parcel')
        newLang += ' has a parcel link action'
    }

    let first = true
    Object.entries(rule.event).map(item => {
      allConditions.map(condition => {
        if(condition.value === item[0])
          newLang += ' and ' + condition.label
      })

      if(item[1].hasOwnProperty('gt'))
      { 
        if(item[1].gt && item[1].lt)
          newLang += ' is between ' + item[1].gt + ' and ' + item[1].lt
        if(item[1].gt)
          newLang += ' is greater than ' + item[1].gt
        if(item[1].lt)
          newLang += ' is less than ' + item[1].lt
      }
      else if(item[1].hasOwnProperty('to') && item[1].hasOwnProperty('from'))
      {
        if(item[1].to && item[1].from)
        {
          if(!(item[1].to instanceof Date))
          {
            item[1].to = new Date(item[1].to)
            item[1].from = new Date(item[1].from)
          }

          newLang += ' is between the dates ' + 
            item[1].from.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}) + 
            ' and ' +
            item[1].to.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}) 
        }
      }
      else if(item[0] !== 'description' && item[0] !== 'conditions')
        newLang += ' is set to ' + item[1]
      first = false;
    })

    setRuleLanguage(newLang)
    rule['name'] = name
    if(newLang !== rule.event['description'])
    {
      rule.event['description'] = newLang
      rule['clause'] = type
      rule['process'] = action
      setRule({...rule})
    }
    props.onChange(rule)
  },[rule,action,type, name, props])

  return (
      <Grid container spacing={1}>
        <Grid item xs={12} justifyContent="center">
          <FormControl fullWidth>
            <TextField 
              id="role_name" 
              label="Rule Name" 
              variant="outlined" 
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Stack direction={'row'} spacing={1}>
            <FormControl>
              <InputLabel id="term-select-label">Action</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                value={action}
                label="Term"
                onChange={(e) => {
                  setAction(e.target.value)
                  handleChangeGeneral('process', e)
                }}
              >
                <MenuItem value={'Add'}>Add</MenuItem>
                <MenuItem value={'Remove'}>Remove</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <TextField value={props.roleName} disabled/>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <FormControl fullWidth>
            <InputLabel id="term-select-label">When</InputLabel>
            <Select
              labelId="term-select-label"
              id="term-select"
              value={type}
              label="Term"
              onChange={(e) => {
                setType(e.target.value)
                setConditionList([])
                handleChangeGeneral('clause', e)
              }}
            >
              <MenuItem value={'transaction'}>made a transaction</MenuItem>
              <MenuItem value={'volunteer'}>has a volunteer link action</MenuItem>
              <MenuItem value={'parcel'}>has a parcel link action</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Grid container spacing={1}>
            <Grid item xs={10} justifyContent="center">
              <Typography>If</Typography>
            </Grid>
            <Grid item xs={2} justifyContent="center">
              <AddSettingsButton 
                title={"Select a condition"}
                handleSave={(e) => {
                  conditionList.push(selectedCondition)
                  setConditionList([...conditionList])
                  rule.event['conditions'] = conditionList
                  setRule({...rule})
                }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="condition-select-label"
                      id="condition-select"
                      value={selectedCondition}
                      onChange={(e) => {
                        setSelectedCondition(e.target.value)
                      }}
                    >
                      {allConditions.map(element => {
                        if(element.when === type)
                          return <MenuItem value={element}>{element.label}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
              </AddSettingsButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Stack spacing={1}>
            {conditionList.map(element => {
              switch(element.type)
              {
                case "date_range":
                  if(!rule.event.hasOwnProperty(element.value))
                    rule.event[element.value] = {from:null, to:null}
                  
                  return (
                    <Grid container>
                      <Grid item xs={2}>
                        <IconButton aria-label="delete" onClick={handleRemove(element.value)}>
                          <RemoveIcon fontSize={'small'}/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="From"
                            inputFormat="MM/dd/yyyy"
                            value={rule.event[element.value].from}
                            onChange={(date) => {
                              rule.event[element.value].from = date
                              setRule({...rule})
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="To"
                            inputFormat="MM/dd/yyyy"
                            value={rule.event[element.value].to}
                            onChange={(date) => {
                              rule.event[element.value].to = date
                              setRule({...rule})
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  )
                case "select":
                  return (
                    <Grid containter>
                      <Grid item xs={2}>
                        <IconButton aria-label="delete" onClick={handleRemove(element.value)}>
                          <RemoveIcon fontSize={'small'}/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Box sx={{ maxWidth: 300 }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="condition-select-label"
                              id="condition-select"
                              value={rule.event[element.value]}
                              onChange={(e) => {
                                rule.event[element.value] = e.target.value
                                let temp = rule;
                                setRule({...rule})
                                props.onChange(temp)
                              }}
                            >
                              {element.items.map(name => (<MenuItem value={name}>{name}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                case "value_range":
                  if(!checks.hasOwnProperty(element.value))
                    checks[element.value] = {gt:false, lt:false, ir: false}

                  if(!rule.event.hasOwnProperty(element.value))
                    rule.event[element.value] = {gt:null, lt:null}

                  return (
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton aria-label="delete" onClick={handleRemove(element.value)}>
                          <RemoveIcon fontSize={'small'}/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Stack direction={'row'} spacing={1}> 
                          <FormControlLabel
                            label="Greater Than"
                            control={<Checkbox checked={checks[element.value].gt} onChange={(e) => {
                              checks[element.value] = {gt:e.target.checked, lt:false, ir: false}
                              rule.event[element.value] = {gt:null, lt:null}
                              setRule({...rule})
                              setChecks({...checks})
                            }}/>}
                          />
                          <FormControlLabel
                            label="Less Than"
                            control={<Checkbox checked={checks[element.value].lt} onChange={(e) => {
                              checks[element.value] = {gt:false, lt:e.target.checked, ir: false}
                              rule.event[element.value] = {gt:null, lt:null}
                              setRule({...rule})
                              setChecks({...checks})
                            }} />}
                          />
                          <FormControlLabel
                            label="Between"
                            control={<Checkbox checked={checks[element.value].ir} onChange={(e) => {
                              checks[element.value] = {gt: false, lt: false, ir: e.target.checked}
                              rule.event[element.value] = {gt:null, lt:null}
                              props.onChange(rule)
                              setChecks({...checks})
                            }} />}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        {checks[element.value].gt &&
                          <TextField 
                            label={'Greater than'} 
                            value={rule.event[element.value].gt}
                            onChange={(e) => {
                              rule.event[element.value].gt = e.target.value
                              setRule({...rule})
                            }}
                          />
                        }
                        {checks[element.value].lt &&
                          <TextField 
                            label={'Less than'} 
                            value={rule.event[element.value].lt}
                            onChange={(e) => {
                              rule.event[element.value].lt = e.target.value
                              setRule({...rule})
                            }}
                          />
                        }
                        {checks[element.value].ir &&
                          <Stack direction={'row'} spacing={1}>
                            <TextField 
                              label={'Greater than'} 
                              value={rule[element.value].gt}
                              onChange={(e) => {
                                rule.event[element.value].gt = e.target.value
                                setRule({...rule})
                              }}
                            />
                            <TextField 
                              label={'Less than'} 
                              value={rule.event[element.value].lt}
                              onChange={(e) => {
                                rule.event[element.value].lt = e.target.value
                                setRule({...rule})
                              }}
                            />
                          </Stack>
                        }
                      </Grid>
                    </Grid>
                  )
                case "static":
                  rule.event[element.value] = true
                  setRule({...rule})
                  return (
                    <Grid container>
                      <Grid item xs={2}>
                        <IconButton aria-label="delete"  onClick={handleRemove(element.value)}>
                          <RemoveIcon fontSize={'small'}/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField value={element.label} disabled/>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )
              }
             })}
          </Stack>
          <Grid item xs={12}>
            <Divider/>
            <Typography>{ruleLanguage}</Typography>
            <Divider/>
          </Grid>
        </Grid>
      </Grid>
  )
}