import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';
import FormControl from '@mui/material/FormControl';

const fetchRewards = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    type: type
  }

  return fetch(cookies.get('apiUrl') + "/search/autolist", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  })
}

export default function EventRewardSelect(props) {
  const [options, setOptions] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [reward, setReward] = React.useState('');
  const type = props.type;
  const disabled = props.disabled;
  const label = props.label

  React.useEffect(() => {
    setIsLoaded(false);
    fetchRewards(type)
      .then(res => res.json())
      .then((result) => {
        setIsLoaded(true);
        setOptions(result.response);
      });
  }, [props.refresh]);

  const handleChange = (event) => {
    setReward(event.target.value);

    if (event.target.value === '') {
      props.onChange(false);
    }
    else {
      props.onChange(event.target.value);
    }
  };

  if (error) {
    return <div>Error: There was an issue loading the component</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <FormControl fullWidth variant="filled">
        <TextField
          sx={{width:'250px'}}
          id={'section-event'}
          select
          label={label}
          disabled={disabled}
          value={reward}
          onChange={handleChange}
          className=""
        >
          <MenuItem key={-1} value={''}/>
          {
            options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))
          }
        </TextField>
      </FormControl>
    );
  }
}
