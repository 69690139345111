import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

const approveNotification = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/notification/approve", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ApproveButton(props) {
  const id = props.id
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [open, setOpen] = React.useState(false)

  const [ request, setRequest ] = React.useState({click:false, data:{}})

  React.useEffect(() => {
    if(request.click)
    {
      approveNotification(id).then(res => res.json())
        .then(
          (result) => {
            request.click = false
            props.refresh()
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }}, [request, id, props]);

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = (event) => {
    setOpen(false)
  };

  const handleSave = (event) => {
    request.click = true
    setRequest({...request})
    handleClose(event)
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-label="Volunteers"
        color="inherit"
        onClick={handleClick}
      >
        <Badge color="primary">
          <ThumbUpOffAltIcon/>
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Approve change request? 
      </DialogTitle>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Cancel</Button>
        <Button type="contained" onClick={handleSave}>Confirm</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
