import * as React from 'react';
import Stack from '@mui/material/Stack';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';


export default function ReportTable(props) {
  const filter = (true)?{Toolbar: GridToolbar}:{};
  return (
    <Stack spacing={1}>      
      <div style={{ display: 'flex', width: '800px', height: '500px' }}>
        <div style={{ flexGrow: 3 }}>
          <DataGridPro
            rows={props.data}
            columns={props.columns}
            density="compact"
            autoPageSize
            pagination
            components={filter}
          />
        </div>
      </div>
    </Stack>
  );
}
