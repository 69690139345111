import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import ErrorIcon from '@mui/icons-material/Error';
import Select from '@mui/material/Select';
import AddParcelButton from './input/add-parcel-button';
import ParcelTable from './parcel-table';
import EntitySearchInput from '../../core-components/entity-details/inputs/entity-search-input';
import MixedSearchInput from '../../core-components/entity-details/inputs/mixed-search-input';


const deleteComponent = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    component_name: 'parcel'
  }

  return fetch(cookies.get('apiUrl')+"/user/remove/component", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchSearch = (values) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    search: values.search,
    county: values.county,
    town: values.town,
    map: values.map,
    passback: Date.now()
  }

  return fetch(cookies.get('apiUrl')+"/search/parcels", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addLinkLand = (values, entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:entity,
    package:values
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/parcel", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ParcelSearchComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [options,setOptions ] = React.useState([])
  const menuId = 'report-component-menu-id';
  const [pending, setPending] = React.useState(false)
  const [mapsPending, setMapsPending] = React.useState(false)
  const [mapError, setMapError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [advanced, setAdvanced] = React.useState(false)
  const [ map, setMap] = React.useState(-1)
  const [mapList, setMapList ] = React.useState([])
  const [ county, setCounty ] = React.useState('')
  const [ town, setTown ] = React.useState('')
  const [ search, setSearch ] = React.useState('')
  const [ results, setResults ] = React.useState([])
  const [ searchError, setSearchError ] = React.useState(null)
  const [ searchLoading, setSearchLoading ] = React.useState('')
  const [timestamp, setTimestamp] = React.useState({
    ts:0
  })
  const [ newSwis, setNewSwis ] = React.useState('')
  const [ newAddress, setNewAddress ] = React.useState('')
  const [ newCounty, setNewCounty ] = React.useState('')
  const [ newTown, setNewTown ] = React.useState('')
  const [ newTaxId, setNewTaxId ] = React.useState('')
  const [ newLandonwer, setNewLandowner ] = React.useState('')
  const [refresh, setRefresh] = React.useState(false)
  const [ section, setSection ] = React.useState()
  const [ agreement, setAgreement ] = React.useState('Verbal Agreement')

  React.useEffect(() => {
    const cookies = new Cookies();

    let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
    let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

    const request = { 
      user: user, 
      token: token,
     }

    fetch(cookies.get('apiUrl')+"/trail/maps", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then(
        (result) => {
          setMapsPending(true);
          setMapList(result.response);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setMapsPending(true);
          setMapError(error);
        }
      )
  }, [])

  React.useEffect(() => {
    let temp_map = (map !== -1)?map:''
    let values = {
      town:town,
      county:county,
      map:temp_map,
      search:search
    }
    setSearchLoading(false);
    fetchSearch(values).then(res => res.json())
      .then(
        (result) => {
          if(timestamp.ts < result.passback)
          {
            setOptions(result.response.options);
            setResults(result.response.table)
            setSearchLoading(true);
            setSearchError(null)
            timestamp.ts = result.passback
          }
        },
        (error) => {
          setSearchLoading(true);
          setSearchError(error);
        }
      );
  }, [town,map,county,search, refresh]);

  const [ newLand, setNewLand ] = React.useState(
    {
      parcel_tax_id:'',
      town:'',
      county:'',
      swis:'',
      address:'',
      entity:null
    }
  )

  const [ newLandClick, setNewLandClick ] = React.useState(false)
  const [removeComponent, setRemoveComponent] = React.useState(false)


  React.useEffect(() => {
    if(removeComponent)
    {
      setPending(true);
      deleteComponent().then(res => res.json())
        .then((result) => {
          setPending(true);
          setRemoveComponent(false)
          window.location.reload();
        },
        (error) => {
          setPending(true);
          setError(error);
        });
    }
  },[removeComponent])
  
  React.useEffect(() => {
    if(newLandClick)
    {
    addLinkLand(newLand, newLandonwer.id).then(res => res.json())
      .then(
        (result) => {
         setNewLandClick(false)
         setNewLand(
          {
            parcel_tax_id:'',
            town:'',
            county:'',
            swis:'',
            address:'',
            entity:null
          }
         )
        },
        (error) => {
          props.refresh()
        }
      );
    }
  }, [newLand, newLandonwer, newLandClick]);

  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    setAdvanced(!advanced);
  };

  const handleMapChange = (event) => {
    setMap(event.target.value);
  };

  const handleCountyChange = (event) => {
    setCounty(event.target.value)
  }

  const handleTownChange = (event) => {
    setTown(event.target.value)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
      <Paper className={props.className} direction="columns" style={{ height: '100%'}}>
        <Grid container spacing={0}>
        <Grid item xs={1}>
            <IconButton
                size="large"
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleReportMenuOpen}
                color="inherit">
              <Badge>
                <MenuIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={9} justifyContent="center">
            <div className="component_header">
              <Typography variant="h5" component="h2" >Parcel Search</Typography>
            </div>
          </Grid>
          <Grid item xs={1} justifyContent="center">
           
            <AddParcelButton 
              title="Add Parcel"
              handleSave={(e) => {
                newLand.address = newAddress
                newLand.swis = newSwis
                newLand.parcel_tax_id = newTaxId
                newLand.town = newTown
                newLand.county = newCounty
                newLand.section = section
                newLand.agreement = agreement
                setNewLandClick(true)
              }}>
              <Stack spacing={1}>
                <MixedSearchInput
                  label={'Section'}
                  type={'section'}
                  value={section}
                  onChange={(value) => {setSection(value)}}
                />
                <EntitySearchInput 
                  type="person"
                  label="Landowner"
                  onChange={(value) => {setNewLandowner(value)}}
                />
                <FormControl fullWidth>
                  <InputLabel id="new-section-type-select-label">Agreement</InputLabel>
                  <Select
                    labelId="new-section-type-select-label"
                    id="new-section-type-select"
                    value={agreement}
                    label="Agreement"
                    onChange={(e)=>{
                      setAgreement(e.target.value)
                    }}
                  >
                    <MenuItem value="FLTC Owns">FLTC Owns</MenuItem>
                    <MenuItem value="Easement">Easement</MenuItem>
                    <MenuItem value="Written Contract">Written Contract</MenuItem>
                    <MenuItem value="Verbal Agreement">Verbal Agreement</MenuItem>
                    <MenuItem value="Off Trail">Off Trail</MenuItem>
                  </Select>
                </FormControl>
                <TextField 
                  id="taxId" 
                  label="Tax Id" 
                  variant="outlined" 
                  value={newTaxId}
                  onChange={(e) => {setNewTaxId(e.target.value)}}
                />
                <TextField 
                  id="swiss" 
                  label="SWIS" 
                  variant="outlined" 
                  value={newSwis}
                  onChange={(e) => {setNewSwis(e.target.value)}}
                />
                <TextField 
                  id="address"
                  label="Address" 
                  variant="outlined"
                  value={newAddress}
                  onChange={(e) => {setNewAddress(e.target.value)}}
                />
                <TextField 
                  id="county-search-add" 
                  label="County" 
                  variant="outlined" 
                  value={newCounty}
                  onChange={(e) => {setNewCounty(e.target.value)}}
                />
                <TextField 
                  id="town-search-add"
                  label="Town" 
                  variant="outlined"
                  value={newTown}
                  onChange={(e) => setNewTown(e.target.value)}
                />
              </Stack>
            </AddParcelButton>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              value={search}
              disablePortal
              clearOnBlur={false}
              selectOnFocus
              id="combo-box-demo"
              options={options}
              onInputChange={(e, value) => handleSearch(value)}
              sx={{ p:1, width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Parcel Search" />}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel 
            sx={{p:1}}
            control={<Checkbox
              checked={advanced}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              size={"large"}
            />} label={"More"} />
          </Grid>
          {advanced && 
          <Grid item xs={12}>
            <Stack sx={{p:1}} spacing={1}>
              <TextField 
                id="county-search" 
                label="County" 
                variant="outlined" 
                value={county}
                onChange={handleCountyChange}
              />
              <TextField 
                id="town-search"
                label="Town" 
                variant="outlined"
                value={town}
                onChange={handleTownChange}
              />
              {mapError && <ErrorIcon color="danger"/>}
              {!mapsPending && <CircularProgress color="warning"/>}
              {mapList.length === 0 && <Typography>No Maps to select</Typography>} 
              {mapList.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id="map-select-label">Map</InputLabel>
                  <Select
                    labelId="map-select-label"
                    id="map-select"
                    value={map}
                    label="map"
                    onChange={handleMapChange}
                  >
                     <MenuItem key={'default-map-select'} value={-1}>Select Map</MenuItem>
                    { mapList.map((option) => ( 
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </Grid>
          }
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <ParcelTable 
              error={searchError}
              loading={searchLoading}
              data={results} 
              filter={true} 
              refresh={()=>setRefresh(!refresh)}/>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={()=>setRemoveComponent(true)}>Remove Component</MenuItem>
        </Menu>
      </Paper>
  );
}

