import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GeneralDetails from './body/component-general-details';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import AccountingDetails from './body/component-accounting-details';
import MembershipDetails from './body/component-membership-details';
import MaintainerDetails from './body/component-maintainer-details';
import LandownerDetails from './body/component-landowner-details';
import Divider from '@mui/material/Divider';
import TransactionButton from './footer/component-transaction-button';
import DetailHeader from './header/detail-header';
import EventDetails from './body/component-event-details';
import PublicEntityDetails from './body/component-public-entity-details';

const fetchDetails = (id, index) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    data: index
  };

  return fetch(cookies.get('apiUrl') + "/data/details", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}


export default function ElaborateEntity(props) {
  const entity = props.entity;
  const [id, setId] = React.useState(props.entity.id);
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);

  const [errorsAccounting, setErrorsAccounting] = React.useState(null);
  const [errorsMembership, setErrorsMembership] = React.useState(null);
  const [errorsGeneral, setErrorsGeneral] = React.useState(null);
  const [errorsMaintainer, setErrorsMaintainer] = React.useState(null);
  const [errorsLandowner, setErrorsLandowner] = React.useState(null);
  const [errorsEvent, setErrorsEvent] = React.useState(null);

  const [loadingAccounting, setLoadingAccounting] = React.useState(false);
  const [loadingMembership, setLoadingMembership] = React.useState(false);
  const [loadingGeneral, setLoadingGeneral] = React.useState(false);
  const [loadingMaintainer, setLoadingMaintainer] = React.useState(false);
  const [loadingLandowner, setLoadingLandowner] = React.useState(false);
  const [loadingEvent, setLoadingEvent] = React.useState(false);

  const [dataAccounting, setDataAccounting] = React.useState([]);
  const [dataMembership, setDataMembership] = React.useState([]);
  const [dataGeneral, setDataGeneral] = React.useState([]);
  const [dataMaintainer, setDataMaintainer] = React.useState({ sections: [], regions: [], forms: { current: [], expired: [], missing: [] } });
  const [dataLandowner, setDataLandowner] = React.useState([]);
  const [dataEvent, setDataEvent] = React.useState();

  //background color
  let bgColor = '#9fa8da';
  if (entity.member === 'Current') {
    bgColor = '#d4e157'
  }
  else if (entity.member === 'Expired') {
    bgColor = '#ffa500'
  }
  const dialogColor = bgColor

  //set permissions
  const cookies = new Cookies();
  const permissions = cookies.get('permission');
  var showButton = false;
  if (permissions !== 'null') {
    permissions.forEach(group => {
      group.permission.components.forEach(component => {
        if (component.name === 'details') showButton = true;
      });
    });
  }
  else showButton = true;

  React.useEffect(() => {
    switch (index) {
      case 'general':
        setLoadingGeneral(true);
        break;
      case 'membership':
        setLoadingMembership(true);
        break;
      case 'accounting':
        setLoadingAccounting(true);
        break;
      case 'maintainer':
        setLoadingMaintainer(true);
        break;
      case 'landowner':
        setLoadingLandowner(true);
        break;
      case 'event':
        setLoadingEvent(true);
        break;
      default:
        break;
    }

    fetchDetails(id, index)
      .then(res => res.json())
      .then((result) => {
        switch (index) {
          case 'general':
            setDataGeneral(result.response);
            setLoadingGeneral(false);
            setErrorsGeneral(null);
            break;
          case 'membership':
            setDataMembership(result.response);
            setLoadingMembership(false);
            setErrorsMembership(null);
            break;
          case 'accounting':
            setDataAccounting(result.response);
            setLoadingAccounting(false);
            setErrorsAccounting(null);
            break;
          case 'maintainer':
            setDataMaintainer(result.response);
            setLoadingMaintainer(false);
            setErrorsMaintainer(null);
            break;
          case 'landowner':
            setDataLandowner(result.response);
            setLoadingLandowner(false);
            setErrorsLandowner(null);
            break;
          case 'event':
            setDataEvent(result.response);
            setLoadingEvent(false);
            setErrorsEvent(null);
            break;
          default:
            break;
        }
      }, (error) => {
        switch (index) {
          case 'general':
            setErrorsGeneral(error);
            setLoadingGeneral(true);
            break;
          case 'membership':
            setErrorsMembership(error);
            setLoadingMembership(true);
            break;
          case 'accounting':
            setErrorsAccounting(error);
            setLoadingAccounting(true);
            break;
          case 'maintainer':
            setErrorsMaintainer(error);
            setLoadingMaintainer(true);
            break;
          case 'landowner':
            setErrorsLandowner(error);
            setLoadingLandowner(true);
            break;
          case 'event':
            setErrorsEvent(error);
            setLoadingEvent(true);
            break;
          default:
            break;
        }
      }
      )
  }, [index, id, refresh]);

  const toggleData = (newIndex) => {
    if (index !== newIndex) {
      setIndex(newIndex);
    }
    else {
      setIndex('');
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (!showButton) return <></>;

  return (
    <>
      <Badge
        style={{ "&hover": { backgroundColor: 'rgb(7, 177, 77, 0.6)' } }}
        onClick={handleClickOpen}>
        <MoreVertIcon />
      </Badge>
      <Dialog
        hideBackdrop
        style={{ position: 'top' }}
        open={open}
        fullWidth
        maxWidth="sm"
        scroll={'paper'}
        aria-describedby="details-accordians"
      >
        <DetailHeader entity={entity} handleClose={() => { handleClose() }} />
        <Typography variant="h6" component="h6" mb={2} ml={2}>
          {entity.firstName + " " + entity.lastName}
        </Typography>
        <Divider />
        <DialogContent
          id="details-accordians"
          dividers={true}
          ref={descriptionElementRef}
          tabIndex={-1}
          sx={{ backgroundColor: dialogColor }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('general')}
            >
              <Typography>General Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {errorsGeneral && <ErrorIcon color="danger" />}
              {loadingGeneral && <CircularProgress color="warning" />}
              {(!loadingGeneral && dataGeneral.hasOwnProperty('roles')) &&
                <>
                  < GeneralDetails
                    entity={entity}
                    entityId={id}
                    entityType={entity.type}
                    data={dataGeneral}
                    refresh={() => {
                      setRefresh(!refresh);
                    }}
                    idChange={(newId, newType) => {
                      setId(newId);
                      setRefresh(!refresh);
                      entity.type = newType;
                    }}
                  />
                </>
              }
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('membership')}
            >
              <Typography>Membership</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!loadingMembership && errorsMembership && <ErrorIcon color="danger" />}
              {loadingMembership && <CircularProgress color="warning" />}
              {!loadingMembership && dataMembership.hasOwnProperty('type') &&
                <MembershipDetails
                  refresh={() => {
                    setRefresh(!refresh)
                  }}
                  entityId={id}
                  data={dataMembership}
                />}
              {!loadingMembership && !dataMembership.hasOwnProperty('type') &&
                <Typography>No membership data</Typography>}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('accounting')}
            >
              <Typography>Accounting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {errorsAccounting && <ErrorIcon color="danger" />}
              {loadingAccounting && <CircularProgress color="warning" />}
              {!loadingAccounting &&
                <AccountingDetails
                  entityId={id}
                  family={entity.family}
                  data={dataAccounting}
                />}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('event')}
            >
              <Typography>Event and Awards</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {errorsEvent && <ErrorIcon color="danger" />}
              {loadingEvent && <CircularProgress color="warning" />}
              {(!errorsEvent && !loadingEvent) &&
                <EventDetails
                  refresh={() => {
                    setRefresh(!refresh);
                  }}
                  entityId={id}
                  entity={entity}
                  data={dataEvent} />}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('maintainer')}
            >
              <Typography>Trail Managment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {errorsMaintainer && <ErrorIcon color="danger" />}
              {loadingMaintainer && <CircularProgress color="warning" />}
              {!loadingMaintainer &&
                entity.type !== 'Government' &&
                <MaintainerDetails
                  entityId={id}
                  entityType={entity.type}
                  data={dataMaintainer}
                  refresh={() => {
                    setRefresh(!refresh);
                  }}
                />}
              {!loadingMaintainer &&
                entity.type === 'Government' &&
                <PublicEntityDetails
                  entityId={id}
                  data={dataMaintainer}
                  refresh={() => {
                    setRefresh(!refresh);
                  }}
                />
              }
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('landowner')}
            >
              <Typography>Parcels</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {errorsLandowner && <ErrorIcon color="danger" />}
              {loadingLandowner && <CircularProgress color="warning" />}
              {!loadingLandowner && dataLandowner && <LandownerDetails
                entityId={id}
                data={dataLandowner}
                refresh={() => {
                  setRefresh(!refresh);
                }} />}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <TransactionButton entityId={entity.id} />
        </DialogActions>
      </Dialog>
    </>
  );
}
