import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

export default function AddEmButton(props) {
  const [open, setOpen] = React.useState(false);
  const size = (props.size ? props.size : 'small');
  const [disabled, setDisabled] = React.useState(props.disabled);
  // Error is set to false if not provided
  const [error, setError] = React.useState(props.error === undefined ? false : props.error);

  React.useEffect(() => {
    setDisabled(props.disabled);
  }, [props.refresh]);

  React.useEffect(() => {
    setError(props.error === undefined ? false : props.error);
  }, [props.error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen} disabled={disabled}>
        <AddIcon fontSize={size} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
            // Keep open if an error state is provided and set
            if (!error) {
              props.handleSave(e);
              setOpen(false);
            }
          }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
