import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import {DataGridPro} from '@mui/x-data-grid-pro';
import AddTmButton from '../input/add-tm-button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';

const columns = [
  {
    headerName:'Name',
    field:'name',
    width: 80,
    editable:true
  }
]

const fetchMaps = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token
  }

  return fetch(cookies.get('apiUrl')+"/trail/maps", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const addMap = (name) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity: {
      name:name
    }
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/map", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const deleteMap = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/trail/remove/map", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const editMap = (changed) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    changed:changed,
    table:'map',
  }

  return fetch(cookies.get('apiUrl')+"/table/save", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function ManageMapsButton(props) {
  const [id, setId] = React.useState(props.focusId)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [maps, setMaps] = React.useState({data:[]})
  const [newMap, setNewMap] = React.useState('')
  const [clicked, setClicked] = React.useState(false)
  const [editClicked, setEditClicked] = React.useState(false)
  const [selectionChange, setSelectionChange] = React.useState([])
  const [editList, setEditList] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [refresh, setRefesh] = React.useState(false)
  const cookies = new Cookies()
  const permission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    setIsLoaded(false);
    fetchMaps().then(res => res.json())
      .then(
        (result) => {
          maps['data'] = result.response;
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [maps, refresh]);

  React.useEffect(() => {
    if(clicked)
    {
      addMap(newMap).then(res => res.json())
        .then((result) => {
          setClicked(false)
          setRefesh(!refresh)
        },
        (error) => {
        });
    }
  },[newMap, clicked])

  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  React.useEffect(() => {
    if(deleteList.click)
    {
      deleteList.list.forEach(element => {      
        deleteMap(element, deleteList.region).then(res => res.json())
        .then(
          (result) => {
            deleteList.click = false
            setRefesh(!refresh)
          })
      })
    }
  },[deleteList])

  React.useEffect(() => {
    if(editClicked)
    {   
      editMap(editList).then(res => res.json())
      .then(
        (result) => {
          setEditClicked(false)
        })
    }
  },[editList, editClicked])

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="Contained" onClick={handleClick}>Maps</Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h5" component="h4" >Maps</Typography>
          </Grid>
          {permission && <>
            <Grid item xs={1}>
              <IconButton 
                size="small" 
                color="inherit" 
                onClick={() => {
                  if(editList.length > 0)
                  {
                    setEditOpen(true)
                  }
                }}>
                <EditIcon/>
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <AddTmButton title="New Map" handleSave={(e) => {
                  setClicked(true)
                }}>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"Name"} 
                  variant="outlined" 
                  value={newMap} 
                  onChange={(e) => {setNewMap(e.target.value)}}/>
              </AddTmButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton 
                size="small" 
                color="inherit" 
                onClick={() => {
                  if(selectionChange.length > 0)
                  {
                    setConfirmOpen(true)
                  }
                }}>
                <RemoveIcon/>
              </IconButton>
            </Grid>
          </>}
        </Grid>
        {error && <ErrorIcon color="danger"/>}
        {(!isLoaded && maps.data.length !== 0 ) && <CircularProgress color="warning"/>}
        {maps.data.length > 0 &&
          <div style={{ height: 600,  width: '100%' }}>
            < DataGridPro
              rows={maps.data}
              columns={columns}
              id='search-results'
              density={'compact'}
              filter={true}
              checkboxSelection={permission}
              disableSelectionOnClick
              onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
              onCellEditCommit={(values,event) => {
                editList.push({field:values.field,id:values.id,value:values.value})
              }}
            />
          </div>
        } 
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}}>
        <DialogTitle>Remove Map(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setConfirmOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              region: props.id,
              click:true
            })
            setConfirmOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>      
      <Dialog open={editOpen} onClose={() => {setEditOpen(false)}}>
        <DialogTitle>Edit Map(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setEditOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setEditClicked(true)
            setEditOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

