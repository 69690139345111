import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

export default function EventSelect(props) {
  const [event, setEvent] = React.useState(-1);
  const [options, setOptions] = React.useState([]);
  const [eventType, setEventType] = React.useState('');
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const cookies = new Cookies();
    let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
    let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');
    let type = ((eventType !== -1) ? eventType : '');
    const request = {
      user: user,
      token: token,
      type: type
    };

    fetch(cookies.get('apiUrl') + '/events/get', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then((result) => {
        setOptions(result.response);
        setIsLoaded(true);
      }, (error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [eventType]);

  if (eventType !== props.type) {
    setEventType(props.type);
  }

  if (error) {
    return <div>Error: There was an issue loading the compnent</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const handleChange = (event) => {
      setEvent(event.target.value);
      props.callback(event.target.value);
    };

    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={'section-event'}
            select
            label="Select Event"
            value={event}
            onChange={handleChange}
            className=""
          >
            <MenuItem key="tmt-section" value={-1}>
              Select Event
            </MenuItem>
            {
              options.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))
            }
          </TextField>
        </div>
      </Box>
    );
  }
}
