import * as React from 'react';
import Paper from '@mui/material/Paper';
import ReportSelect from './component-report-select';
import ReportTemplateSelect from './component-report-template-select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import ReportView from './view/report-view';
import TemplateView from './view/template-view';
import ColumnView from './view/column-view';
import ReportInput from './view/report-input';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

const theme = createTheme({
  palette: {
    bkgrnd: { main: '#C0C0C0' },
  },
});

const removeComponent = () => {
  const cookies = new Cookies();
  let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
  let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    component_name: 'report'
  }

  return fetch(cookies.get('apiUrl') + '/user/remove/component', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  })
}

export default function ReportComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'report-component-menu-id';
  const [pending, setPending] = React.useState(false);
  const [clear, setClear] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [deleteClick, setDeleteClick] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [template, setTemplate] = React.useState(null);
  const [reportName, setReportName] = React.useState(null);
  const [reportTitle, setReportTitle] = React.useState(null);
  const [hasTemplate, setHasTemplate] = React.useState(false);
  const [fields, setFields] = React.useState([]);
  const [defaults, setDefaults] = React.useState([]);
  const [inputValues, setInputValues] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    if (deleteClick) {
      setPending(true);
      removeComponent().then(res => res.json())
        .then((result) => {
          setPending(true);
          setDeleteClick(false);
          window.location.reload();
        }, (error) => {
          setPending(true);
          setError(error);
        });
    }
  }, [deleteClick])

  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className={props.className} direction="columns" style={{ height: '100%' }} color="bkgrnd">
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleReportMenuOpen}
              color="inherit"
            >
              <Badge>
                <MenuIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={8} justifyContent="center">
            <div className="component_header">
              <Typography variant="h5" component="h2" >Reports</Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Button variant="Contained" onClick={() => {
              setTemplate(null)
              setInputValues({})
              setReportTitle(null)
              setSelectedReport(null)
              setHasTemplate(false)
              setRefresh(!refresh)
              setFields([])
              setDefaults([])
              setClear(!clear)
            }}>Clear</Button>
          </Grid>
          <Grid item xs={12}>
            <ReportSelect
              refresh={refresh}
              clear={clear}
              callback={(report, name, hasTemplate, fields) => {
                setSelectedReport(report)
                setReportTitle(name)
                setReportName(name)
                setTemplate(null)
                setInputValues({})
                setFields([...fields])
                setHasTemplate(hasTemplate === 'true')
                setDefaults([...fields])
              }} />
          </Grid>
          <Grid item xs={12}>
            <ReportTemplateSelect
              report={selectedReport}
              header={{ m: 1, width: '95%' }}
              hasTemplates={hasTemplate}
              setTemplate={(selected) => {
                setTemplate(selected)
                if (selected !== null) {
                  setReportTitle(reportName + ' - ' + selected.name)
                }
                else {
                  setFields([...defaults])
                }
              }}
            />
          </Grid>
          <Grid item xs={12}><ReportInput
            report={selectedReport}
            fields={fields}
            refresh={refresh}
            template={template}
            hasTemplate={hasTemplate}
            setRequest={(request) => {
              setInputValues(request)
            }}
          />
          </Grid>
          <Grid item xs={5}>
            <TemplateView
              report={selectedReport}
              template={template}
              defaultFields={defaults}
              hasTemplate={hasTemplate}
              fields={fields}
              clear={clear}
              setClear={() => { setClear(false) }}
              refresh={() => {
                setInputValues({})
                setReportTitle(null)
                setSelectedReport(null)
                setHasTemplate(false)
                setFields([])
                setDefaults([])
                setClear(!clear)
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <ColumnView
              report={selectedReport}
              setColumns={(list) => { setColumns(list) }}
            />
          </Grid>
          <Grid item xs={2}>
            <ReportView
              report={selectedReport}
              template={template}
              title={reportTitle}
              columns={columns}
              inputValues={inputValues}
              fields={fields}
            />
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => setDeleteClick(true)}>Remove Component</MenuItem>
        </Menu>
      </Paper>
    </ThemeProvider>
  );
}
