import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Cookies from 'universal-cookie';
import MediationIcon from '@mui/icons-material/Mediation';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import RuleForm from './settings-inputs/rule-form';
import RuleChip from './settings-inputs/rule-chip';

const addSetting = (rule, role) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:rule,
    setting:'rules'
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}



const fetchSetting = (role) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:'rules',
    extra: {
      role_id: role
    }
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function AutomationRules(props) {
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [newClick, setNewClick] = React.useState(false);
  const [rules, setRules] = React.useState([]);
  const [newRule, setNewRule] = React.useState({name:'',event:{},clause:'', process:'Add'})
  const [errors, setErrors] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if(newClick)
    {
      newRule.role_id = props.roleId
      addSetting(newRule)
      .then(
        (result) => {
          setNewClick(false)
          setNewRule({name:'',event:{},clause:'', process:'Add'})
          setRefresh(!refresh)
        })
    }
  },[newRule, newClick, props])

  React.useEffect(() => {
    setIsLoaded(false);
    fetchSetting(props.roleId).then(res => res.json())
      .then(
        (result) => {
          setRules(result.response);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setErrors(error);
        }
      );
  }, [refresh, props]);

  return (
    <>
      <IconButton onClick={()=>setOpen(true)}>
        <MediationIcon fontSize="large"/>
      </IconButton>
      <Dialog key="settings" open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={10}>
              {props.roleName} Rules
            </Grid>
            <Grid item xs={2}>
              <AddSettingsButton
                title="New Rule"
                handleSave={(e) => {
                  setNewClick(true)
                }}
              >
                <RuleForm 
                  roleName={props.roleName}
                  role={props.roleId} 
                  rule={newRule}
                  refresh={() => {setRefresh(!refresh)}}
                  onChange={(values)=>{
                    setNewRule(values)
                  }}/>
              </AddSettingsButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>         
          <Stack spacing={1}>
            {rules.map(element => (
              <RuleChip 
                key={'rl-'+element.id} data={element} 
                roleName={props.roleName}
                refresh={() => {setRefresh(!refresh)}}
                ></RuleChip>))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  </>
  );
}
