import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Cookies from 'universal-cookie';
import AccountingChip from './settings-inputs/accounting-chip';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import ExtraAccountingChip from './settings-inputs/extra-accounting-chip';


const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:setting_package,
    setting:target
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateSeting = (target, id, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:target,
    package:setting_package,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteSeting = (target, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: target,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchSettings = (index) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:index
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function AccountingSettings() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [ugly, setUgly] = React.useState('')
  const [newSolicitation, setNewSolicitation] = React.useState('')
  const [newDonationAccount, setNewDonationAccount] = React.useState('')
  const [newAccountNumber, setNewAccountNumber] = React.useState('')
  const [newPurchaseAccount, setNewPurchaseAccount] = React.useState('')
  const [newDescription, setNewDescription] = React.useState('')
  const [newDiscount, setNewDiscount] = React.useState('')
  const [newDiscountAmount, setNewDiscountAmount] = React.useState('')

  const [toggle, setToggle] = React.useState('')
  const [refresh, setRefresh] = React.useState(false);
  const [deleteTarget, setDeleteTarget] = React.useState({click: false})
  const [updateTarget, setUpdateTarget] = React.useState({click: false})
  const [addTarget, setAddTarget] = React.useState({click: false})


  const [errorDescriptions, setErrorDescriptions] = React.useState(null)
  const [errorAccounts, setErrorAccounts] = React.useState(null)
  const [errorDiscounts, setErrorDiscounts] = React.useState(null)

  const [loadingDescriptions, setLoadingDescriptions] = React.useState(false)
  const [loadingAccounts, setLoadingAccounts] = React.useState(false)
  const [loadingDiscounts, setLoadingDiscounts] = React.useState(false)

  const [dataDescriptions, setDataDescriptions] = React.useState([])
  const [dataAccounts, setDataAccounts] = React.useState([])
  const [dataDiscounts, setDataDiscounts] = React.useState([])

  React.useEffect(() => {
    if(deleteTarget.click)
    {
      deleteSeting(deleteTarget.setting, deleteTarget.id).then(res => res.json())
      .then(
        (result) => {
          setDeleteTarget({click:false})
          setRefresh(!refresh)
          
        })
    }
  },[deleteTarget])

  React.useEffect(() => {
    if(updateTarget.click)
    {
      updateSeting(updateTarget.setting, updateTarget.id, updateTarget.package).then(res => res.json())
      .then(
        (result) => {
          setUpdateTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[updateTarget])

  React.useEffect(() => {
    if(addTarget.click)
    {
      addSetting(addTarget.setting, addTarget.package).then(res => res.json())
      .then(
        (result) => {
          setAddTarget({click:false})
          setRefresh(!refresh)
          setNewSolicitation("")
          setNewDescription("")
          setNewAccountNumber("")
          setNewDiscount("")
          setNewDiscountAmount("")
        })
    }
  },[addTarget, toggle])


  React.useEffect(() => {
    switch(index)
    {
      case 'descriptions':
        setLoadingDescriptions(true)
        break
      case 'accounts':
        setLoadingAccounts(true)
        break
      case 'discounts':
        setLoadingDiscounts(true)
        break
      default:
        break
    }
    fetchSettings(index).then(res => res.json())
    .then(
      (result) => {
        switch(index)
        {
          case 'descriptions':
            setDataDescriptions(result.response)
            setLoadingDescriptions(false)
            setErrorDescriptions(null)
            break
          case 'accounts':
            setDataAccounts(result.response)
            setLoadingAccounts(false)
            setErrorAccounts(null)
            break
          case 'discounts':
            setDataDiscounts(result.response)
            setLoadingDiscounts(false)
            setErrorDiscounts(null)
            break
          default:
            break
        }
      },
      (error) => {
        switch(index)
        {
          case 'descriptions':
            setErrorDescriptions(error)
            setLoadingDescriptions(true)
            break
          case 'accounts':
            setErrorAccounts(error)
            setLoadingAccounts(true)
            break
          case 'discounts':
            setErrorDiscounts(error)
            setLoadingDiscounts(true)
            break
        }
      }
    )    
  }, [index, refresh]);


  const toggleData = (newIndex) => {
    if(index !== newIndex)
    {
      setIndex(newIndex)
    }
    else
    {
      setIndex('');
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
    <List>
      <ListItem key="accounting" disablePadding>
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary={"Accounting Settings"} />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Accounting Settings</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>         
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('descriptions')}
              >
                <Typography>Purchase Descriptions/Solicitations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingDescriptions && errorDescriptions && <ErrorIcon color="danger"/>}
                {loadingDescriptions && <CircularProgress color="warning"/>}
                {!loadingDescriptions && dataDescriptions.length === 0 && 
                  <Grid container>
                    <Grid item xs={12} >
                      <Typography styles={{paddingTop:'2%'}}>
                        No Solicitations or Purchase Descriptions
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Solicitaions
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                          <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                description:newSolicitation,
                                transaction_type:'Donation'
                              },
                              setting:'transaction_selection'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newSolicitation}
                              onChange={(e) => {setNewSolicitation(e.target.value)}}
                              label="Name"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Purchase Descriptions
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                description:newDescription,
                                transaction_type:'Purchase'
                              },
                              setting:'transaction_selection'
                            }

                            setAddTarget(setting_package)
                            setRefresh(!refresh)
                          }}>
                            <TextField
                              value={newDescription}
                              onChange={(e) => {setNewDescription(e.target.value)}}
                              label="Name"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                } 
                {!loadingDescriptions && dataDescriptions.hasOwnProperty('donation') && (
                <>
                  <Grid container>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Solicitaions
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                          <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                description:newSolicitation,
                                transaction_type:'Donation'
                              },
                              setting:'transaction_selection'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newSolicitation}
                              onChange={(e) => {setNewSolicitation(e.target.value)}}
                              label="Name"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Stack spacing={1}>
                        {dataDescriptions.donation.map(element => (
                          <AccountingChip key={element.id} data={element} 
                          onChange={(id, value)=>{
                            setUpdateTarget({
                              click:true,
                              id:id,
                              setting:'TransactionSelection',
                              package: {
                                description:value,
                                transactionType:'Donation'
                              }
                            })
                          }}
                          onDelete={(id)=>{setDeleteTarget({
                            click:true,
                            id:id,
                            setting:'TransactionSelection'
                          })}}></AccountingChip>))}
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Purchase Descriptions
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                description:newDescription,
                                transaction_type:'Purchase'
                              },
                              setting:'transaction_selection'
                            }

                            setAddTarget(setting_package)
                            setRefresh(!refresh)
                          }}>
                            <TextField
                              value={newDescription}
                              onChange={(e) => {setNewDescription(e.target.value)}}
                              label="Name"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Stack spacing={1}>
                        {dataDescriptions.purchase.map(element => (
                          <AccountingChip key={element.id} data={element} 
                          onChange={(id, value)=>{
                            setUpdateTarget({
                              click:true,
                              id:id,
                              setting:'TransactionSelection',
                              package: {
                                description:value,
                                transactionType:'Purchase'
                              }
                            })
                          }}
                          onDelete={(id)=>{setDeleteTarget({
                            click:true,
                            id:id,
                            setting:'TransactionSelection'
                          })}}></AccountingChip>))}
                      </Stack>
                    </Grid>
                  </Grid>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('accounts')}
              >
                <Typography>Accounts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingAccounts && errorAccounts && <ErrorIcon color="danger"/>}
                {loadingAccounts && <CircularProgress color="warning"/>}
                {!loadingAccounts && dataAccounts.length === 0 && 
                  <Grid container>
                    <Grid item xs={12} >
                      <Typography styles={{paddingTop:'2%'}}>
                        No Account Data
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Donations
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                          <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newDonationAccount,
                                qb_account:newAccountNumber,
                                type:'Donation'
                              },
                              setting:'qb_accounts'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newDonationAccount}
                              onChange={(e) => {setNewDonationAccount(e.target.value)}}
                              label="Name"
                            />
                            <TextField
                              value={newAccountNumber}
                              onChange={(e) => {setNewAccountNumber(e.target.value)}}
                              label="Account Number"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Grid container>
                          <Grid item xs={8} >
                            <Typography styles={{paddingTop:'2%'}}>
                              Purchase
                            </Typography>
                          </Grid>
                          <Grid item xs={4} >
                          <AddSettingsButton handleSave={(e) => {
                              let setting_package = {
                                click: true,
                                package: { 
                                  name:newPurchaseAccount,
                                  qb_account:newAccountNumber,
                                  type:'Purchase'
                                },
                                setting:'qb_accounts'
                              }

                              setAddTarget(setting_package)
                            }}>
                              <TextField
                                value={newPurchaseAccount}
                                onChange={(e) => {setNewPurchaseAccount(e.target.value)}}
                                label="Name"
                              />
                              <TextField
                                value={newAccountNumber}
                                onChange={(e) => {setNewAccountNumber(e.target.value)}}
                                label="Account Number"
                              />
                            </AddSettingsButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                } 
                {!loadingAccounts && dataAccounts.hasOwnProperty('donation') && (
                <>
                  <Grid container>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Donations
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                          <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newDonationAccount,
                                qb_account:newAccountNumber,
                                type:'Donation'
                              },
                              setting:'qb_accounts'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newDonationAccount}
                              onChange={(e) => {setNewDonationAccount(e.target.value)}}
                              label="Name"
                            />
                            <TextField
                              value={newAccountNumber}
                              onChange={(e) => {setNewAccountNumber(e.target.value)}}
                              label="Account Number"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Stack spacing={1}>
                        {dataAccounts.donation.map(element => (
                          <ExtraAccountingChip key={element.id} data={element} secondary={element.qbAccount}
                          onChange={(id, value,second,active)=>{
                            setUpdateTarget({
                              click:true,
                              id:id,
                              setting:'QbAccounts',
                              package: {
                                name:value,
                                qbAccount:second,
                                active:active,
                                type:'Donation'
                              }
                            })
                          }}
                          onDelete={(id)=>{setDeleteTarget({
                            click:true,
                            id:id,
                            setting:'QbAccounts'
                          })}}></ExtraAccountingChip>))}
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Purchase
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newPurchaseAccount,
                                qb_account:newAccountNumber,
                                type:'Purchase'
                              },
                              setting:'qb_accounts'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newPurchaseAccount}
                              onChange={(e) => {setNewPurchaseAccount(e.target.value)}}
                              label="Name"
                            />
                            <TextField
                              value={newAccountNumber}
                              onChange={(e) => {setNewAccountNumber(e.target.value)}}
                              label="Account Number"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Stack spacing={1}>
                        {dataAccounts.purchase.map(element => (
                          <ExtraAccountingChip key={element.id} data={element} secondary={element.qbAccount}
                          onChange={(id, value,second, active)=>{
                            setUpdateTarget({
                              click:true,
                              id:id,
                              setting:'QbAccounts',
                              package: {
                                name:value,
                                qbAccount:second,
                                active:active,
                                type:'Purchase'
                              }
                            })
                          }}
                          onDelete={(id)=>{setDeleteTarget({
                            click:true,
                            id:id,
                            setting:'QbAccounts'
                          })}}></ExtraAccountingChip>))}
                      </Stack>
                    </Grid>
                  </Grid>
                  </>
                )}
                
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('discounts')}
              >
                <Typography>Discounts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingDiscounts && errorDiscounts && <ErrorIcon color="danger"/>}
                {loadingDiscounts && <CircularProgress color="warning"/>}
                {!loadingDiscounts && dataDiscounts.length === 0 && 
                  <Grid container>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography>No discounts</Typography>
                        </Grid>
                        <Grid item xs={4} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newDiscount,
                                discount:newDiscountAmount,
                              },
                              setting:'discounts'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newDiscount}
                              onChange={(e) => {setNewDiscount(e.target.value)}}
                              label="Name"
                            />
                            <TextField
                              value={newDiscountAmount}
                              onChange={(e) => {setNewDiscountAmount(e.target.value)}}
                              label="Discount"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                } 
                {!loadingDiscounts && dataDiscounts.length > 0 && (
                <>
                  <Grid container>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        <Grid item xs={8} >
                          <Typography styles={{paddingTop:'2%'}}>
                            Discounts
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newDiscount,
                                discount:newDiscountAmount,
                              },
                              setting:'discounts'
                            }

                            setAddTarget(setting_package)
                          }}>
                            <TextField
                              value={newDiscount}
                              onChange={(e) => {setNewDiscount(e.target.value)}}
                              label="Name"
                            />
                            <TextField
                              value={newDiscountAmount}
                              onChange={(e) => {setNewDiscountAmount(e.target.value)}}
                              label="Discount"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Stack spacing={1}>
                        {dataDiscounts.map(element => (
                          <ExtraAccountingChip key={element.id} data={element} secondary={element.discount}
                          onChange={(id, value,second,active)=>{
                            setUpdateTarget({
                              click:true,
                              id:id,
                              setting:'Discounts',
                              package: {
                                name:value,
                                discount:second,
                              }
                            })
                          }}
                          onDelete={(id)=>{setDeleteTarget({
                            click:true,
                            id:id,
                            setting:'Discounts'
                          })}}></ExtraAccountingChip>))}
                      </Stack>
                    </Grid>
                  </Grid>
                </>
                )}
              </AccordionDetails>
            </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </List>
    {ugly}
  </>
  );
}
