import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import EntitySearchInput from './entity-search-input';

export default function TableGifter(props) {
  const [open, setOpen] = React.useState(false);
  const [targetName, setTargetName] = React.useState(props.val.name)

  const [targetEntity, setTargetEntity] = React.useState("") 

  const changeGifter = () => {
    var id = targetEntity.id.split('-')[1];
    setTargetName(targetEntity.firstName + " " + targetEntity.lastName)
    props.callback({id:props.id, field:props.field, value:id}, null)

    setOpen(false)
  }

  return (
    <>
      <Button onClick={()=>{setOpen(true)}} disabled={props.disabled}>{targetName}</Button>
      <Dialog open={open} onClose={()=>{setOpen(false)}}>
        <DialogTitle>Change Gifter</DialogTitle>
        <DialogContent>
          <EntitySearchInput
            onChange={(value) => (setTargetEntity(value))}
            type="person"
            label="Person Search"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}}>Close</Button>
          <Button onClick={() =>{changeGifter()}}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
