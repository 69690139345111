import * as React from 'react';
import { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import RegionSelect from './input/component-region-select';
import SectionSelect from './input/component-section-select';
import TralViewAccordions from './views/trail-view';
import TralDetails from './views/trail-detail';
import FormView from './views/form-view';
import ManageRegionsButton from './views/manage-regions';
import ManageSectionsButton from './views/manage-sections';
import ManageMapsButton from './views/manage-maps';
import MapView from './views/map-view';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const removeComponent = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    component_name: 'trail'
  }

  return fetch(cookies.get('apiUrl')+"/user/remove/component", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function TrailComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'report-component-menu-id';
  const [region, setRegion] = React.useState(-1)
  const [data, setData] = React.useState(-1)
  const [refresh, setRefesh] = React.useState(false)
  const [type, setType] = React.useState(false)
  const [deleteClick, setDeleteClick] = React.useState(false)

  React.useEffect(() => {
    if(deleteClick)
    {
      removeComponent().then(res => res.json())
        .then((result) => {
          setDeleteClick(false)
          window.location.reload();
        },
        (error) => {
        });
    }
  },[deleteClick])
  
  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
      <Paper className={props.className} direction="columns" style={{ height: '100%'}}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleReportMenuOpen}
              color="inherit"
            >
              <Badge>
                <MenuIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={11} sm={3}>
            <div className="component_header">
              <Typography variant="h5" component="h4">Trail Management</Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Suspense fallback={<div>Loading...</div>}>
              <ManageMapsButton/>
            </Suspense>
          </Grid>
          <Grid item xs={3}>
            <Suspense fallback={<div>Loading...</div>}>
              <ManageRegionsButton/>
            </Suspense>
          </Grid>
          <Grid item xs={3}>
            <Suspense fallback={<div>Loading...</div>}>
              <ManageSectionsButton/>
            </Suspense>
          </Grid>
          <Grid item xs={6}>
            <Suspense fallback={<div>Loading...</div>}>
              <RegionSelect refresh={refresh} callback={(value)=>{
                setData(value)
                setRegion(value)
                setType('Region')
              }}/> 
            </Suspense>
          </Grid>
          <Grid item xs={6}>
            <Suspense fallback={<div>Loading...</div>}>
              <SectionSelect refresh={refresh} region={region} callback={(value)=>{
                setData(value)
                setType('Section')
              }}/> 
            </Suspense>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TralDetails data={data} type={type} refresh={() => {setRefesh(!refresh)}}/>
              </Grid>
              <Grid item xs={12} md={6} >
                <Paper sx={{height:"100%"}}>
                  <Stack spacing={1}>
                    <FormView data={data} type={type} />
                    <MapView data={data} type={type}/>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <TralViewAccordions data={data} type={type} />
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                {type != false &&
                  <Button onClick={()=>{
                    setRegion(-1)
                    setData(-1)
                    setRefesh(!refresh)
                    setType(false)
                  }}>Clear</Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={()=>setDeleteClick(true)}>Remove Component</MenuItem>
        </Menu>
      </Paper>
  );
}
