
import React, { Suspense } from 'react'
import './App.css';
import Core from './component/core'
import Cookies from 'universal-cookie';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@material-ui/core/Grid';
import ReportIcon from '@mui/icons-material/Report';
import LinearProgress from '@mui/material/LinearProgress';
import LoginHeader from './component/login-layout/login-header';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  'e695cebad8e0e9509e98a1d552fad132Tz05MTExMyxFPTE3NDgwMTc2NjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function App() {
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [auth, setAuth] = React.useState([]);
  const [env,setEnv]= React.useState(true);
  const cookies = new Cookies();

  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  React.useEffect(() => {
    if(env)
    {
      setEnv(false)
      if(window.location.hostname === "three.trail-tracker.com"){
        cookies.set('apiUrl', 'https://api.trail-tracker.com')
      }
      else if(window.location.hostname === "testerdev.trail-tracker.com"){
        cookies.set('apiUrl', 'https://devapi.trail-tracker.com')
      }
      else
      {
        cookies.set('apiUrl', process.env.REACT_APP_TT_API_URL)
      }
    }
  },[env])

  const ttl = {
    user: user,
    token: token
  }
  React.useEffect(() => {
    fetch(cookies.get('apiUrl')+"/api/check", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(ttl)
    })
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setAuth(result);
          cookies.set('permission', result.permission)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return (
      <Grid
          container
          spacing={8}
          direction="column"
          alignauth="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
        <Grid item xs={3} ><ReportIcon/></Grid>
        <Grid item xs={6} >
          <Typography>Something went wrong! Please contact Chase!</Typography>
        </Grid>
        <Grid item xs={3}><ReportIcon/></Grid>
        <Grid item xs={12}>
          <ReportIcon className="site-load-fail" />
        </Grid>
      </Grid> )
  } else if (!isLoaded || auth.length === 0) {
    return (
      <div>
        <LoginHeader trail_name="Trail Tracker"/>
        <Grid
            container
            spacing={8}
            direction="column"
            alignauth="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
          <Grid item xs={12}>
            <Stack sx={{ width: '100%', color: 'grey.500'}} spacing={2}>
                <LinearProgress color="warning" />
                <LinearProgress color="warning" />
                <LinearProgress color="warning" />
            </Stack>
          </Grid>
        </Grid> 
      </div>);
  }else {
    return (
      <div>
        <Suspense fallback={(
          <Stack sx={{ width: '100%', color: 'grey.500'}} spacing={2}>
            <LinearProgress color="success" />
            <LinearProgress color="success" />
            <LinearProgress color="success" />
          </Stack>
        )}>
          <Core auth={auth.message} config={auth.config}/>
        </Suspense>
      </div>
    );
  }
}

export default App;
