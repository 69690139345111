import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import Cookies from 'universal-cookie';

export default function AddParcelButton(props) {
  const [open, setOpen] = React.useState(false);
  const size = (props.size)?props.size:'small'
  const cookies = new Cookies();
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(cookies.get('permission') !== 'null')
  {
    return <></>
  }

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <AddIcon fontSize={size}/>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
            props.handleSave(e)
            handleClose()
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
