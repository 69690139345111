import React from 'react';
import { styled, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import ReportComponent from './components/workflow-components/reporting/report-component';
import ParcelSearchComponent from './components/workflow-components/parcel/parcel-search-component';
import TrailComponent from './components/workflow-components/trail-management/trail-component';
import AddNewEntityComponent from './components/workflow-components/new-entity/new-entity-component';
import EventManagementComponent from './components/workflow-components/event-management/event-management-component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import NewEventComponent from './components/workflow-components/new-event-component/event-management-component';

const Body = styled('div')(({ theme }) => ({
  root: {
    flexGrow: 1,
  },
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    top:'20%',
  },
  [theme.breakpoints.up('md')]: {
    top:'15%',
  },
  [theme.breakpoints.up('lg')]: {
    top:'10%',
  },
}));

const useStyles = makeStyles(() => ({
  componentColor: {
    backgroundColor: '#DFEDFF'
  }
}));

const selectComponent = (name, classes) => {
  let component = (<div></div>);
  switch(name) {
    case 'report':
      component = <ReportComponent className={classes.componentColor}/>;
      break;
    case 'parcel':
      component = <ParcelSearchComponent className={classes.componentColor}/>;
      break;
    case 'add-entity':
      component = <AddNewEntityComponent className={classes.componentColor}/>;
      break;
    case 'trail':
      component = <TrailComponent className={classes.componentColor}/>;
      break;
    case 'event':
      component = <EventManagementComponent className={classes.componentColor}/>;
      break;
    case 'new-event':
      component = <NewEventComponent className={classes.componentColor}/>;
      break;
    default:
      component = <SentimentDissatisfiedIcon className={classes.componentColor}/>;
  }
  return component;
}

export default function TtBody(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setOpen(true)
  }

  React.useEffect(() => {
    //window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  return (
    <Body>
      <Grid 
        container 
        spacing={1}
        columns={{ xs: 4, sm: 8, md: 12, lg: 16, xl: 20 }}
        direction="row-reverse"
        justifyContent="flex-end"
        alignItems="baseline"
        >
        {
          Object.entries(props.components).map((t,k) => ( 
            <Grid item 
              xs={t[1].xs}
              sm={t[1].sm}
              md={t[1].md}
              lg={t[1].lg}
              xl={t[1].xl}
              key={k}>
                {selectComponent(t[1].name, classes)}
            </Grid>
          ))
        }
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Did you mean to click on the back button?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => {window.history.back()}}>Go Back!</Button>
        </DialogActions>
      </Dialog>
  </Body>
  );
}
