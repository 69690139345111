import * as React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ReportSearchInput from '../fields/report-search-input';
import ReportSelectInput from '../fields/select-input';
import ReportTextInput from '../fields/text-input';
import ReportDateRange from '../fields/date-range';
import ReportListInput from '../fields/list-input';
import ReportCheckboxInput from '../fields/checkbox-input';
import ReportConditionalInput from '../fields/conditional-input';

export default function TemplateInput(props) {
  const [fields, setFields] = React.useState(props.fields);
  const defaultFields = props.fields
  const [report, setReport] = React.useState(props.report);
  const [request, setRequest] = React.useState({});
  const [showList, setShowList ] = React.useState({})
  const [hasTemplate, setHasTemplate] = React.useState(false);
  const [clear, setClear] = React.useState(false);
  const [template, setTemplate ] = React.useState(props.template)
  const [shouldDefault, setShouldDefault] = React.useState({});
  
  if(props.template && !hasTemplate)
    setHasTemplate(true)

  if(props.clear !== clear && props.clear)
  {
    let list = []
    fields.forEach(element => {
      element.show = 'false'
      delete element.default
      list.push(element)
    });
    setFields(list)
    setClear(props.clear)
  }

  const setRequestValue = (field,value,shouldRefresh) => {
    if(request[field] !== value)
    {
      request[field] = value
      props.setRequest(request)
      if(shouldRefresh)
      {
        setRequest({...request})
      }
    }
  }

  const setShow = (field,value) => {
    if(showList[field] !== value)
    {
      showList[field] = value
      props.setShowList(showList)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={1}>  
          {fields.map(element => {
            if(hasTemplate)
            {
              if(props.template.schema.request.hasOwnProperty(element.requestHeader) && shouldDefault[element.requestHeader])
              {
                if(!request[element.requestHeader])
                  element.default = props.template.schema.request[element.requestHeader]
                else
                  element.default = request[element.requestHeader]
              }
              if(props.template.schema.display.hasOwnProperty(element.requestHeader))
              {
                element.show = (props.template.schema.display[element.requestHeader] === 'true')?'true':'false'
              }
            }
            
            if(element.hasOwnProperty('conditions'))
            {
              let show = true;
              Object.entries(element.conditions).map((option) => {
                if(!option[1].includes(request[option[0]]))
                {
                  show = false
                  element.default = null
                }
              }) 
              if(!show) return <></>;
            }

            switch(element.field.type)
            {
              case 'search':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }

                return <ReportSearchInput 
                        type={element.field.searchType} 
                        label={element.fieldName}
                        default={element.default}
                        onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                        onShow={(show)=> {setShow(element.requestHeader, show)}}
                        isTemplate={true}
                        showVal={(element.show === 'true')}
                      />
              case 'select':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }
                return <ReportSelectInput 
                          label={element.fieldName}
                          list={element.field.list}
                          default={element.default}
                          onChange={(value) => {setRequestValue(element.requestHeader,value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                          />
              case 'text':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }
                return <ReportTextInput 
                          label={element.fieldName}
                          default={element.default}
                          onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                        />
              case 'checkbox':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, (element.default === 'true'))
                }
                return <ReportCheckboxInput 
                          label={element.fieldName}
                          default={(element.default === 'true')}
                          onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                        />
              case 'date':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }
                return <ReportDateRange 
                          label={element.fieldName}
                          default={element.default}
                          onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                        />
              case 'list':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }
                return <ReportListInput 
                          label={element.fieldName} 
                          type={element.field.searchType}
                          default={element.default}
                          onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                        />
              case 'conditional':
                if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                {
                  setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                  setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                }
                return <ReportConditionalInput 
                          label={element.fieldName} 
                          type={element.field.searchType}
                          default={element.default}
                          exclusive={(element.exclusive === 'true')}
                          onChange={(value) => {setRequestValue(element.requestHeader, value, (element.refreshField === 'true'))}}
                          onShow={(show)=> {setShow(element.requestHeader, show)}}
                          isTemplate={true}
                          showVal={(element.show === 'true')}
                        />
              default:
            }
          })}      
        </Stack>
      </Grid>
      <Grid item xs={12}><Divider/></Grid>
    </Grid>  
  )
}