import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge'
import EngineeringIcon from '@mui/icons-material/Engineering';
import EditIcon from '@mui/icons-material/Edit';
import MaintainerView from '../../trail-management/views/maintainer-view';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

const fetchVolunteers = (id) => {
  const cookies = new Cookies()
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id: id,
    view: 'maintainer',
    type: 'section'
  }

  return fetch(cookies.get('apiUrl')+"/trail/view", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addVolunteer = (section,type,entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    entity:entity,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MaintainerButton(props) {
  const [id, setId] = React.useState(props.data.sectionId)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [notes, setNotes] = React.useState({data:[]})
  const [open, setOpen] = React.useState(false)
  const [note, setNote] = React.useState({note:'',click:false})
  const [refresh, setRefresh] = React.useState(false)
  const [type, setType] = React.useState(props.type)
  const [volunteerType, setVolunteerType] = React.useState('Maintainer')
  const [search, setSearch] = React.useState('')

  const [dataMaintainers, setDataMaintainers] = React.useState([])
  const [loadingMaintainers, setLoadingMaintainers] = React.useState(false)
  const [errorMaintainers, setErrorMaintainers] = React.useState(null)

  React.useEffect(() => {
    if(open)
    {
      fetchVolunteers(id).then(res => res.json())
      .then(
        (result) => {
          setDataMaintainers(result.response)
          setLoadingMaintainers(false)
          setErrorMaintainers(null)
        },
        (error) => {
          setErrorMaintainers(error)
          setLoadingMaintainers(true)
        }
      )    
    }
  }, [refresh, id, open]);

  React.useEffect(() => {
    if(note.click)
    {
      addVolunteer(type, id, note.note).then(res => res.json())
        .then(
          (result) => {
            setId(props.focusId)
            setRefresh(!refresh)
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }}, [note, id, type]);

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
          size="small"
          aria-label="Volunteers"
          color="inherit"
          onClick={handleClick}
        >
          <Badge color="primary">
            <EngineeringIcon />
          </Badge>
        </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
            Parcel Volunteers 
          </DialogTitle>
        <DialogContent>
          {!loadingMaintainers && errorMaintainers && <ErrorIcon color="danger"/>}
          {loadingMaintainers && <CircularProgress color="warning"/>}
          {props.data.sectionId === null &&  <Typography>Please assign a section using the <EditIcon/> </Typography>}
          {!loadingMaintainers && dataMaintainers.length === 0 && props.data.sectionId !== null && <Typography>No volunteer data</Typography>} 
          {!loadingMaintainers && dataMaintainers.length > 0 && props.data.sectionId !== null && (
            <MaintainerView 
              data={dataMaintainers}
              type={'Section'} 
              name={props.data.section} 
              entity={props.data.sectionId}
              refresh={() => {setRefresh(!refresh)}}  
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
