import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

export default function EventRewardChip(props) {
  const [chipTitle, setChipTitle] = React.useState(props.data);
  const [size, setSize] = React.useState(1);
  let edit = ((props.canEdit) ? <DeleteIcon /> : <></>);
  const [icon, setIcon] = React.useState(edit);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(props.disabled);

  React.useEffect(() => {
    setDisabled(props.disabled);
  }, [props.refresh]);

  const handleEdit = (event) => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    props.onDelete(props.data);
    setConfirmOpen(false);
  };

  return (
    <div>
      <CustomChip
        size={size}
        label={chipTitle}
        variant="filled"
        onDelete={handleEdit}
        deleteIcon={icon}
        disabled={disabled}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={handleRemove}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
