import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

const getTemplateList = (report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    report:report,
  }

  return fetch(cookies.get('apiUrl')+"/report/get/templates", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function PermissionTemplateSelect(props) {
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  const [raw, setRaw] = React.useState([]);
  const [report, setReport] = React.useState(props.report);
  const [hasTemplates, setHasTemplates] = React.useState(false);

  const handleChange = (event) => {
    setSelectedTemplate(event.target.value);
    Object.entries(raw).map((key) => {
      if(key[1].name === event.target.value)
      {
        props.setTemplate(key[1])
      }
    })
    
  };

  React.useEffect(() => {
    if(report !== null && !hasTemplates)
    {
      getTemplateList(report).then(res => res.json())
        .then(
          (result) => {
            let list = []
            let element = {}
            Object.entries(result.response).map((key) => {
              element = {
                key: key[0],
                label: key[1].name,
                checked: true
              }
              
              list.push(element)
            })

            setHasTemplates(true)
            setRaw(result.response)
            setTemplates(list)
          },
          (error) => {
            
          }
        );
    }
  }, [report,hasTemplates]);

  if(props.report !== report)
  {
    setReport(props.report)
    setHasTemplates(false)
  }

  if(templates.length < 1)
  {
    setTemplates([{label:'no available templates'}])
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '95%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-template-select"
          select
          label="Template"
          value={selectedTemplate}
          onChange={handleChange}
          className="component_select"
        >
          {templates.map((option) => (
            <MenuItem key={'template'+option.key} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Box>
  );
}
