import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';



function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function ReqFormChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<></>)
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  if(props.data === -1)
  {
    return <></>
  }

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon/>)
      setOpen(!open)
      setChipTitle(props.data.entity)
    }
  };

  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setChipTitle(props.data.name)
      setOpen(!open)
      setIcon(<></>)
    }
  };

  return (
    <div>
      <CustomChip
          size={size}
          label={chipTitle}
          variant="filled"
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={icon}
      />
    </div>
  );
}
