import * as React from 'react';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ApproveButton from './approve-button';
import DisapproveButton from './disapprove-button';
import Stack from '@mui/material/Stack';
import {DataGridPro} from '@mui/x-data-grid-pro';
import DeleteButton from './delete-button';
import AddNotification from './add-notification';

const addNotification = (values) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    name:values.name,
    description:values.description
  }

  return fetch(cookies.get('apiUrl')+"/notification/create", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const getNotifications = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    permission: cookies.get('permission') === 'null'
  }

  return fetch(cookies.get('apiUrl')+"/notification/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function Notifications(props) {
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [notificationNum, setNotificationNum] = React.useState(0)
  const handleOpen = () => {
    setRefresh(!refresh)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'
  const [notification, setNotification] = React.useState({
    name: '',
    description: '',
    click: false
  });
  
  const  columns = 
  [
    {
      headerName:'Actions',
      field:'target',
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <ApproveButton 
              id={params.id}
              refresh={()=>{setRefresh(!refresh)}}
            />
            <DisapproveButton 
              id={params.id}
              refresh={()=>{setRefresh(!refresh)}}
            />
          </Stack>
        )
      },
      width: 100,
      filterable:false,
      hide:!permission
    },
    {
      headerName:'Delete',
      field:'id',
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <DeleteButton 
              id={params.id}
              refresh={()=>{setRefresh(!refresh)}}
              />
          </Stack>
        )
      },
      width: 55,
      filterable:false,
      hide:permission
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 470,
    },
    {
      field: "approval",
      headerName: "Approval",
      width: 100,
      hide:permission
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      valueGetter: (params) => new Date(params.row.lastUpdated),
      width: 100,
      type:'date',
    },
  ]
  

  React.useEffect(() => {
    if(notification.click)
    {
      addNotification(notification).then(res => res.json())
        .then(
          (result) => {
            notification.click = false
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [notification, refresh]);

  React.useEffect(() => {
    getNotifications().then(res => res.json())
      .then(
        (result) => {
          
          if(result.response)
          {
            setRows(result.response)
          }
          
          if(permission && result.response)
          {
            setNotificationNum(result.response.length)
          }

          
        },
        (error) => {
        }
      );
  }, [refresh, permission]);

  return (
  <>
    <MenuItem onClick={handleOpen}>
      <IconButton
        size="large"
        aria-label="Help"
        aria-haspopup="true"
        color="inherit"
      >
        <Badge
          badgeContent={notificationNum}
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {props.itemTitle}
    </MenuItem>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <Paper sx={{p:1}}>
        <Grid container spacing={1} >
          <Grid item xs={11}>
            <Typography variant="h4" component="h4" >Notifications</Typography>
          </Grid>
          <Grid item xs={1}>
            <AddNotification 
              title="Change Request"
              handleSave={(e)=>{
                notification.click = true
                setNotification({...notification})
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField 
                      id="name-field"
                      onChange={(event)=>{
                        notification.name = event.target.value
                      }} 
                      label="Name" 
                      variant="standard" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      placeholder="Description"
                      multiline
                      variant="standard"
                      rows={2}
                      maxRows={4}
                      onChange={(event)=>{
                        notification.description = event.target.value
                      }} 
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AddNotification>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 400, width: '99%' }}>
              <DataGridPro
                rows={rows}
                columns={columns}
                density="compact"
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </>)
}
