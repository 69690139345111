import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';

export default function UploadButton(props) {
  const [open, setOpen] = React.useState(false);
  const size = ((props.size) ? props.size : 'small');

  const [uploadText, setUploadText] = React.useState('Upload');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <UploadFileIcon fontSize={size} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
            setUploadText(<CircularProgress />);
            props.handleSave(e);
            setUploadText('Upload');
          }}
          >
            {uploadText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
