import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import { DataGridPro } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddEmButton from '../input/add-em-button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import EventParentSelect from '../input/event-parent-select';
import { Checkbox, FormLabel } from '@mui/material';

// Retrieve event data from db
const fetchEvents = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    type: ''
  };

  return fetch(cookies.get('apiUrl') + '/events/get', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

// Perform delete of event in db using API
const deleteEventCall = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id
  };

  return fetch(cookies.get('apiUrl') + '/events/delete', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

// Add event record to db given data
const addEvent = (data) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    package: data,
    setting: 'event'
  };

  return fetch(cookies.get('apiUrl') + '/settings/add', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function ManageEventsButton(props) {
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [events, setEvents] = React.useState({ data: [] });
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [newEventClick, setNewEventClick] = React.useState(false);
  const [newEvent, setNewEvent] = React.useState({
    name: '',
    type: '',
    parent_event_flag: false,
    parent_event_id: null,
    start_date: new Date(),
    end_date: new Date()
  });
  const [deleteEvent, setDeleteEvent] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [newEventName, setNewEventName] = React.useState('');
  const [type, setType] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [parentEventFlag, setParentEventFlag] = React.useState(false);
  const [parentEventId, setParentEventId] = React.useState(null);

  // Upon refresh, update the event data for table
  React.useEffect(() => {
    setIsLoaded(false);
    fetchEvents()
      .then(res => res.json())
      .then((result) => {
        setEvents({ data: result.response });
        setIsLoaded(true);
      }, (error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [refresh]);

  // Perform deletion of event upon confirmation
  React.useEffect(() => {
    if (deleteEvent !== false && confirmDelete) {
      deleteEventCall(deleteEvent).then(res => res.json())
        .then((result) => {
          // pass back deleted event id to clear input if selected
          props.callback(deleteEvent);
          setDeleteEvent(false);
          setConfirmDelete(false);
          setRefresh(!refresh);
        }, (error) => { });
    }
  }, [deleteEvent, confirmDelete, refresh]);

  // Perform insertion of event upon confirmation
  React.useEffect(() => {
    if (newEventClick !== false) {
      addEvent(newEvent)
        .then(res => res.json())
        .then((result) => {
          // trigger refresh in parent component to update dropdown
          props.callback();
          setNewEventClick(false);
          setNewEvent({
            name: '',
            type: '',
            parent_event_flag: false,
            parent_event_id: null,
            start_date: new Date(),
            end_date: new Date()
          })
          setRefresh(!refresh);
        }, (error) => { });
    }
  }, [newEvent, newEventClick, refresh]);

  const columns = [
    {
      headerName: 'Delete',
      field: 'id',
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            color="inherit"
            onClick={(e) => {
              setDeleteEvent(params.id);
              setConfirmOpen(true);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        )
      },
      width: 70,
      filterable: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
    },
    // Display event type column in event manager
    {
      headerName: 'Type',
      field: 'type',
      width: 200,
    },
  ];

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="Contained" onClick={handleClick}>Manage</Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h5" component="h4" paddingLeft={3}>Manage Events</Typography>
          </Grid>
          <Grid item xs={4}>
            <AddEmButton
              title="Add Event"
              handleSave={() => {
                setNewEventClick(true);
                setNewEvent({
                  type: type,
                  name: newEventName,
                  parent_event_flag: parentEventFlag.toString(),
                  parent_event_id: parentEventId,
                  start_date: Math.floor(new Date(startDate).getTime() / 1000),
                  end_date: Math.floor(new Date(endDate).getTime() / 1000),
                  description: ''
                });
              }}
            >
              <Stack spacing={1} >
                <FormControl fullWidth variant="filled" sx={{ paddingTop: 1 }}>
                  <TextField
                    id={'change-type-select'}
                    select
                    label="Select Type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className=""
                  >
                    <MenuItem key={'basic'} value={'Basic'}>
                      Basic
                    </MenuItem>
                    <MenuItem key={'donation'} value={'Donation'}>
                      Donation
                    </MenuItem>
                    <MenuItem key={'hours'} value={'Hours'}>
                      Hours
                    </MenuItem>
                    <MenuItem key={'mileage'} value={'Mileage'}>
                      Mileage
                    </MenuItem>
                  </TextField>
                </FormControl>
                <FormControl fullWidth variant="filled">
                  <TextField label="Event Name" value={newEventName} onChange={(e) => setNewEventName(e.target.value)} />
                </FormControl>
                <FormControl fullWidth variant="filled">
                  <FormLabel sx={{ width: '95%', fontSize: 12, paddingLeft: 2 }}>Parent Event</FormLabel>
                  <Checkbox
                    id={'change-parent-event-select'}
                    checked={parentEventFlag}
                    onChange={(e) => setParentEventFlag(e.target.checked)}
                    className=""
                    sx={{ alignSelf: 'flex-start', paddingLeft: 4 }}
                  />
                </FormControl>
                {parentEventFlag === false &&
                  <EventParentSelect
                    callback={(value) => {
                      setParentEventId(value);
                      if (value === -1) {
                        setParentEventId(null);
                      }
                    }}
                    onChange={() => { }} />
                }
                {type === 'Mileage' &&
                  <><LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth variant="filled" sx={{ paddingTop: 1 }}>
                      <MobileDatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormControl fullWidth variant="filled" sx={{ paddingTop: 1 }}>
                        <MobileDatePicker
                          label="End Date"
                          value={endDate}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </>
                }
              </Stack>
            </AddEmButton>
          </Grid>
        </Grid>
        {error && <ErrorIcon color="danger" />}
        {(!isLoaded && events.data.length !== 0) && <CircularProgress color="warning" />}
        {events.data.length > 0 &&
          <div style={{ height: 600, width: '100%' }}>
            < DataGridPro
              rows={events.data}
              columns={columns}
              id='search-results'
              density={'compact'}
              filter={true}
              onEditCommit={(values, event) => { }}
            />
          </div>
        }
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => { setConfirmOpen(false) }}>
        <DialogTitle>Delete Event?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => { setConfirmOpen(false) }}>Cancel</Button>
          <Button type="contained" onClick={() => {
            setConfirmDelete(true);
            setConfirmOpen(false);
          }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
