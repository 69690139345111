
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

const editParcelPublicDetail = (values,id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    detail:values,
    landId:id
  }

  return fetch(cookies.get('apiUrl')+"/trail/parcel/edit/public_detail", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function PublicLand(props) {
  const id = props.data.id
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [open, setOpen] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [ request, setRequest ] = React.useState({click:false, data:{}})
  const [data, setData] = React.useState(props.data.publicDetail)

  let init = [];
  if(props.data.publicOptions.options)
  {
    init = props.data.publicOptions.options
  }
  const options = init 
  
  React.useEffect(() => {
    if(request.click)
    {
      editParcelPublicDetail(request.data, id).then(res => res.json())
        .then(
          (result) => {
            request.click = false
            setRefresh(!refresh)
            setOpen(false);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }}, [request, id]);

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleSave = () => {
    setRequest({
      data:data,
      click:true
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-label="Volunteers"
        color="inherit"
        onClick={handleClick}
      >
        <Badge color="primary">
          <AccountBalanceIcon/>
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Public Detail 
        </DialogTitle>
        <Stack spacing={1}>
          {options.map((option)=>{
            let val_id = option.name.replace(' ','_').toLowerCase().replace(' ','_')

            switch(option.type)
            {
              case 'Dropdown':
                return (
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '300px' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        id={val_id}
                        select
                        label={option.name}
                        value={data[val_id]}
                        onChange={(e)=> {
                          data[val_id] = e.target.value
                          setData({...data})
                        }}
                      >
                        {option.options.map((item) => (
                          <MenuItem key={`pl-${item}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                )
            }
          })}
        </Stack>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Close</Button>
        <Button type="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
