import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {DataGrid} from '@mui/x-data-grid';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import AddDetailButton from '../inputs/component-add-detail-button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "fit-content !important;"
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important"
    }
  }
})(DataGrid);

const fetchNotes = (id, table) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    targetType:table,
    noteType:'notes',
    id: id,
  }

  return fetch(cookies.get('apiUrl')+"/data/notifications", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const editNote = (changed) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    changed:changed,
    table:'notes',
  }

  return fetch(cookies.get('apiUrl')+"/table/save", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const addNote = (table, id, note) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    table:table,
    id: id,
    note:note
  }

  return fetch(cookies.get('apiUrl')+"/detail/add/note", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const deleteNotes = (selected) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    selected:selected,
    table:'notes',
  }

  return fetch(cookies.get('apiUrl')+"/table/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function NotesButton(props) {
  const [id, setId] = React.useState(props.focusId)
  const [noteId, setNoteId] = React.useState(null)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [notes, setNotes] = React.useState({data:[]})
  const [editOpen, setEditOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [noteNum, setNoteNum] = React.useState(0)
  const [editNoteValue, setEditNoteValue] = React.useState(false)
  const [removeOpen, setRemoveOpen] = React.useState(false)
  const [note, setNote] = React.useState({note:'',click:false})
  const [refresh, setRefresh] = React.useState(false)
  const [table, setTable] = React.useState(props.type)
  const [selectionChange, setSelectionChange] = React.useState([])
  const [editList, setEditList] = React.useState([])
  const [editClicked, setEditClicked] = React.useState(false)
  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  if(table !== props.type)
  {
    setTable(props.type)
  }

  if(id !== props.focusId)
  {
    setId(props.focusId)
  }

  const columns = [
    {
      headerName:'Edit',
      field:'id',
      width: 50,
      filterable:false,
      renderCell: (params) => {
        return (<IconButton 
          size="small" 
          color="inherit" 
          onClick={() => {
            setEditOpen(true)
            setEditNoteValue(params.row.detail)
            setSelectionChange([params.row.id])
            setNoteId(params.row.id)
          }}>
          <EditIcon/>
        </IconButton>
      )}
    },
    {
      headerName:'Date',
      field:'entryDate',
      width: 100,
      filterable:false,
    },
    {
      field: "detail",
      headerName: "Note",
      width: 350,
      flex:1,
      editable:false
    },
    {
      field: "user",
      headerName: "User Name",
      width: 100,
    },
  ]

  React.useEffect(() => {
    //if(open)
    //{
      setIsLoaded(false);
      fetchNotes(id, table).then(res => res.json())
        .then(
          (result) => {
            notes['data'] = result.response;
            setNoteNum(result.response.length)
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
      //}
  }, [notes,id, table, refresh]);

  React.useEffect(() => {
    if(note.click)
    {
      addNote(table, id, note.note).then(res => res.json())
        .then(
          (result) => {
            setId(props.focusId)
            note.click = false
            setRefresh(!refresh)
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
  }}, [note, table, id, refresh]);

  React.useEffect(() => {
    if(editClicked)
    {   
      editNote(editList).then(res => res.json())
      .then(
        (result) => {
          setEditClicked(false)
          setRefresh(!refresh)
        })
    }
  },[editList, editClicked, refresh])

  React.useEffect(() => {
    if(deleteList.click)
    {   
      deleteNotes(deleteList.list).then(res => res.json())
      .then(
        (result) => {
          deleteList.click = false
          setRefresh(!refresh)
        })
    }
  },[deleteList, refresh])

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
          size="small"
          aria-label="Notes"
          color="inherit"
          onClick={handleClick}
        >
          <Badge 
              badgeContent={noteNum}
              color="primary">
            <StickyNote2Icon />
          </Badge>
        </IconButton>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
            <Grid container>
              <Grid item xs={9}>
                Notes 
              </Grid>
              <Grid item xs={1}>
                <AddDetailButton title="Add Note" handleSave={()=>{
                    note.click = true
                    setNote({...note})
                  }}>
                  <TextField
                    id="note-field"
                    multiline
                    value={note.note}
                    rows={4}
                    variant="filled"
                    onChange={(e) => {
                      note.note = e.target.value
                      setNote({...note})
                    }}
                  />
                </AddDetailButton>
              </Grid>
            </Grid>
          </DialogTitle>
          
          {error && <ErrorIcon color="danger"/>}
          {(!isLoaded && notes.data.length !== 0 ) && <CircularProgress color="warning"/>}
          {notes.data.length > 0 &&
            <div style={{ height: 600,  width: '100%' }}>
              <StyledDataGrid
                rows={notes.data}
                columns={columns}
                id='search-results'
                filter={true}
                rowsPerPageOptions={[10]}
                autoHeight
                onCellEditCommit={(values,event) => {
                  editList.push({field:'note',id:values.id,value:values.value})
                }}
                onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
                getRowHeight={() => 'auto'}
              />
            </div>
          } 
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removeOpen} maxWidth="sm">
        <DialogTitle>
          Remove Note?
        </DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => setRemoveOpen(false)}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              click:true
            })
            setRemoveOpen(false)
            setEditOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} maxWidth="sm">
        <DialogTitle>
          Edit Note
        </DialogTitle>
        <DialogContent>
          <TextField 
            id="note-edit-field" 
            multiline
            fullWidth
            InputProps={{ style: { fontSize: 12 } }}
            maxRows={4}
            value={editNoteValue} 
            onChange={(e) => {setEditNoteValue(e.target.value)}}//onEditCommit(val,e)} 
          />
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={() => {
            setRemoveOpen(true)
          }
          }>Delete</Button>
          <Button type="contained" onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button type="contained" onClick={()=>{
            editList.push({field:'note',id:noteId,value:editNoteValue})
            setEditClicked(true)
            setEditOpen(false)
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}