import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddDetailButton from './component-add-detail-button';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Cookies from 'universal-cookie';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function PublicOptionChip(props) {
  let opts = []
  if(props.data.options !== undefined)
  {
    opts = props.data.options
  }

  const [options,setOptions] = React.useState(opts) 
  const [dialogData,setDialogData] = React.useState({title:'',content:''})

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(props.data.type)
  const [size, setSize] = React.useState(1)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [optionName, setOptionName] = React.useState();

  const cookies = new Cookies();
  var permissiveIcon = <></>
  if(cookies.get('permission') === 'null')
  {
    permissiveIcon = <DeleteIcon/>;
  }
  const [icon, setIcon] = React.useState(permissiveIcon)

  const handleClick = (event) => {
    if(!open)
    {
      setOpen(!open)
      setSize(1.5)
    }
  };


  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setOpen(!open)
      setIcon(permissiveIcon)
    }
    else
    {
      setDeleteOpen(true)
    }
  };

  const deleteOption = (event) => {
    props.deleteClick()
    setDeleteOpen(false)
  }

  const handleChange = (event) => {
    setType(event.target.value)
  };


  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    options.splice(dialogData.target, 1)
    setOptions([...options])
    setConfirmOpen(false)
    props.saveOption({
      name:props.data.name,
      type:type,
      options:options
    })
  };

  const removeOption = (index) => {
    dialogData.content = 'You will need to check each parcel under this affiliation'
    dialogData.title = 'Remove option?'
    dialogData.target = index
    setDialogData({...dialogData})
    setConfirmOpen(true)
  };

  return (<>
    <div>
      <Grid container spacing={1}>
      {!open && 
        <Grid item sx={12}>
          <CustomChip
              label={props.data.name}
              variant="filled"
              onClick={handleClick}
              onDelete={()=>{setDeleteOpen(true)}}
              deleteIcon={icon}
          />
        </Grid>
        }
        {open && <>
          <Grid item xs={6}>
            <CustomChip
              size={size}
              label={
              <>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Type"
                    size='small'
                    onChange={handleChange}
                  >
                    <MenuItem value={"Text"}>Text</MenuItem>
                    <MenuItem value={"Dropdown"}>Dropdown</MenuItem>
                    <MenuItem value={"Checkboxes"}>Checkboxes</MenuItem>
                  </Select>
              </>}
              variant="filled"
              onClick={handleClick}
              onDelete={handleDelete}
              deleteIcon={<CloseFullscreenIcon/>}
            />
          </Grid>
          {type !== 'Text' &&
            <>
              <Grid item sx={2}>
                <AddDetailButton 
                  size='medium' 
                  title="Add Option"
                  handleSave={(e)=>{
                    options.push(optionName)
                    setOptions([...options])
                    props.saveOption({
                      name:props.data.name,
                      type:type,
                      options:options
                    })
                  }}
                >
                  <TextField
                    value={optionName}
                    onChange={(e)=>setOptionName(e.target.value)}
                  />
                </AddDetailButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {options.map((element,index) => (
                    <Grid item>
                      <Chip 
                        key={'co'+index+'-'+element.replace(' ','-').replace("'", '')} 
                        label={element}
                        onDelete={() => {removeOption(index)}}
                        deleteIcon={permissiveIcon}
                        />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          }
        </>
      }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>{dialogData.title}</DialogTitle>
        <DialogContent>{dialogData.content}</DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>This Cannot be undone</DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={deleteOption}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
    {open && <Divider pt={2}/>}
    </>
  );
}
