import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function ExtraAccountingChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [textValue, setTextValue] = React.useState(props.data.name)
  const [secondValue, setSecondValue] = React.useState(props.secondary)
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [active, setActive] = React.useState(props.data.active);

  const handleShowChange = (e) => {
    setActive(e.target.checked);
  };

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon style={{paddingRight:"2%"}}/>)
      setOpen(!open)
      setSize(1.5)
    }
  };

  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      if(chipTitle !== textValue || props.secondary !== secondValue || props.data.active !== active)
      {
        setConfirmChange(true)
        handleDelete(event)
      }
      else
      {
        setOpen(!open)
        setIcon(<DeleteIcon/>)
      }
      
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
    setConfirmChange(false)
    setOpen(false)
    setIcon(<DeleteIcon/>)
  };

  const handleRemove = () => {
    props.onDelete(props.data.id)
    handleClose()
  };

  const handleChange = () => {
    props.onChange(props.data.id,textValue,secondValue,active)
    handleClose()
    setChipTitle(textValue)
    setOpen(!open)
    setIcon(<DeleteIcon/>)
  };

  return (
    <div>
      <Grid container>
        <Grid item >
          <CustomChip
            size={size}
            label={<>
              {!open && chipTitle}
              {open && <>
                <TextField id="outlined-basic" variant="outlined" 
                  value={textValue} 
                  onChange={(e) => {setTextValue(e.target.value)}}
                  size="small"/>      
              </>}
            </>
            }
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
          />
        </Grid>
        {open &&
          <Grid item>
              <CustomChip
                size={size}
                label={
                <Stack direction="row">
                  <TextField id="outlined-basic" variant="outlined" 
                    value={secondValue} 
                    onChange={(e) => {setSecondValue(e.target.value)}}
                    size="small"/>      
                  <FormControlLabel control={<Checkbox
                    checked={active}
                    onChange={handleShowChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="Active" />
                </Stack>
                }
                variant="filled"
                onClick={handleClick}
                deleteIcon={icon}
              />
          </Grid>
        }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmChange} onClose={handleClose}>
        <DialogTitle>Save Changes?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleChange}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
