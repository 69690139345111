import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import AddDonationButton from './component-add-donation-button';
import Cookies from 'universal-cookie';
import MembershipButton from './component-add-membership-button';
import AddPurchaseButton from './component-add-purchase-button';

const fetchTPackage = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    type:'donation',
    id: id,
  }

  return fetch(cookies.get('apiUrl')+"/data/package/transaction", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function TransactionButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = props.entityId;
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);

  const [tPackage, setTPackage] = React.useState({
    contributors:[],
    accounts:[],
    apply: false,
    descriptions: []
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setIsLoaded(false);
    fetchTPackage(id).then(res => res.json())
      .then(
        (result) => {
          setTPackage(result.response)
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [id]);

  return (
    <div>
      <Button onClick={handleClick}>
        Transaction
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <AddDonationButton entityId={id} tPackage={tPackage}/>
        <MembershipButton entityId={id} tPackage={tPackage}/>
        <AddPurchaseButton entityId={id} tPackage={tPackage}/>
      </Menu>
    </div>
  );
}
