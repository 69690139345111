import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const getColumns = (report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    report:report,
  }

  return fetch(cookies.get('apiUrl')+"/report/get/columns", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0d0907",
      contrastText: "#fff" //button text white instead of black
    },
    background: {
      default: "#394764"
    }
  }
});

export default function ColumnView(props) {
  const [open, setOpen] = React.useState(false);
  const [target, setTarget] = React.useState({});
  const [saveClick, setSaveClick] = React.useState(false);
  const [report, setReport] = React.useState(props.report);
  const [columns, setColumns] = React.useState([]);
  const [hasColumns, setHasColumns] = React.useState(false);

  React.useEffect(() => {
    if(report !== null && !hasColumns)
    {
      getColumns(report).then(res => res.json())
        .then(
          (result) => {
            
            let list = []
            let element = {}
            Object.entries(result.response).map((key) => {
              if(typeof key[1] === 'string')
                element = {
                  label: key[0],
                  field: key[1],
                  checked: true
                }
              else
                element = {
                  label: key[0],
                  field: key[1].data,
                  format : key[1].format,
                  checked: true
                }
              
              list.push(element)
            })

            setHasColumns(true)
            props.setColumns(list)
            setColumns(list)
          },
          (error) => {
            
          }
        );
    }
  }, [report, props, hasColumns]);


  if(props.report !== report)
  {
    setReport(props.report)
    setHasColumns(false)
  }

  if(report === null)
  {
    return <></>
  }

  const handleChange = (field) => (event) => {
    let list = []
    columns.map(element => {
      if(field === element.field)
      {
        element.checked = event.target.checked
      }
      list.push(element)
    })

    setColumns(list)
    props.setColumns(list)
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button varient="contained" onClick={()=>{setOpen(true)}}>Columns</Button>
      </ThemeProvider>
      <Dialog open={open} onClose={()=>{setOpen(false)}}>
        <DialogTitle>Report Columns</DialogTitle>
        <DialogContent>
        <FormGroup>
          {columns.map((element, index) => {
            return <FormControlLabel 
              key={"column-"+index}
              control={<Checkbox checked={element.checked} 
              onChange={handleChange(element.field)}/>} 
              label={element.label} 
            />
          })}
        </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}}>Close</Button>
          <Button onClick={(e) => {
            }}>Save</Button>
        </DialogActions>
      </Dialog>
    </> 
  );
}
