import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack'
import ActivityButton from './component-entity-activity';
import NotesButton from './component-entity-notes';
import Switch from '@mui/material/Switch';
import Cookies from 'universal-cookie';
import { styled } from '@material-ui/core/styles';
import EntityMenuItem from '../inputs/component-entity-menu-item';
import DetailDocumentation from './detail-documantation';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 2,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const toggleMoritaryData = (id,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:id,
    setting:type
  }

  return fetch(cookies.get('apiUrl')+"/detail/toggle/moritary", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const getMoritaryData = (id,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:id,
    setting:type
  }

  return fetch(cookies.get('apiUrl')+"/detail/get/moritary", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const toggleMailingOverride = (id,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:id,
    setting:type
  }

  return fetch(cookies.get('apiUrl')+"/detail/toggle/mailing/override", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}



export default function DetailHeader(props) {
  const {entity} = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'entity-settings-menu-id';
  const [toggle, setToggle] = React.useState({type:'',click:false });
  const [deceased, setDeceased] = React.useState(false);
  const [inactive, setInactive] = React.useState(false);
  const [noMail, setNoMail] = React.useState((props.entity.mailingOverride !== null));
  const [noMailToggle, setNoMailToggle] = React.useState((props.entity.deceased !== null));
  const isPerson = props.entity.type === 'Person';

  React.useEffect(() => {
    getMoritaryData(props.entity.id, 'inactive').then(res => res.json())
      .then(
        (result) => {
          setInactive(result.response.inactive !== null)
        },
        (error) => {
        }
      );

      getMoritaryData(props.entity.id, 'deceased').then(res => res.json())
      .then(
        (result) => {
          let deceased = false;
          if(result.response.deceased !== null) {
            deceased = result.response.deceased;
          }

          setDeceased(deceased);
        },
        (error) => {
        }
      );
  }, [props]);

  const handleEntityMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if(toggle.click)
    {
      toggle.click = false
      toggleMoritaryData(props.entity.id, toggle.type).then(res => res.json())
      .then(
        (result) => {
        })
    }
  },[props, toggle])

  React.useEffect(() => {
    if(noMailToggle)
    {
      setNoMailToggle(false)
      toggleMailingOverride(props.entity.id, noMail).then(res => res.json())
      .then(
        (result) => {
        })
    }
  },[props, noMail, noMailToggle])

  return (
    <DialogTitle style={{ cursor: 'move', paddingBottom:"1%" }} id="draggable-dialog-title">
      <Stack direction="row">
        <IconButton
            size="small"
            aria-label="show-more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleEntityMenuOpen}
            color="inherit"
        >
          <Badge>
            <MenuIcon />
          </Badge>
        </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={menuId}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{
              'aria-labelledby': 'show-more',
            }}
          >
            <MenuItem onClick={()=>{
              setNoMailToggle(true)
              setNoMail(!noMail)
            }}>
              <FormGroup>
                <FormControlLabel control={<Switch checked={noMail} />} label="No Contact" />
              </FormGroup>
            </MenuItem>
            {isPerson && 
              <MenuItem onClick={()=>{
                setDeceased(!deceased)
                setInactive(!deceased)
                toggle.click = true
                toggle.type = 'deceased'
                setToggle({...toggle})
              }}>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={deceased} />} label="Deceased" />
                </FormGroup>
              </MenuItem>
            }
            <MenuItem onClick={()=>{
              setInactive(!inactive)
              toggle.click = true
              toggle.type = 'inactive'
              setToggle({...toggle})
            }}>
              <FormGroup>
                <FormControlLabel control={<Switch checked={inactive} />} label="Inactive" />
              </FormGroup>
            </MenuItem>
            <EntityMenuItem itemName="Help" title={'Detail Documentation'}>
              <DetailDocumentation/>
            </EntityMenuItem>
          </Menu>
          <ActivityButton focusId={entity.id} type="entity"/>
          <NotesButton focusId={entity.id} type="entity"/>
          {deceased && <Chip label="Deceased"></Chip>}
          {!deceased && inactive && <Chip label="Inactive"></Chip>}
        </Stack>
        <IconButton
          aria-label="close"
          onClick={()=> {props.handleClose()}}
          sx={{
            position:'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
    </DialogTitle>
  );
}
