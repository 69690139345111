import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';
import AddTmButton from '../input/add-tm-button';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const columns = [
  {
    field: "taxId",
    headerName: "Parcel Tax Id",
    width: 150
  },
  {
    field: "firstName",
    headerName: "Landowner First Name",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Landowner Last Name",
    width: 150,
  },
  {
    field: "emailAddress",
    headerName: "Landowner Email",
    width: 150,
  },
  {
    field: "primaryNumber",
    headerName: "Landowner Phone",
    width: 150,
  },
  {
    field: "address",
    headerName: "Landowner Address",
    width: 150,
  },
  {
    field: "zipCode",
    headerName: "Landowner Zip Code",
    width: 100,
  },
  {
    field: "city",
    headerName: "Landowner City",
    width: 150,
  },
]

const fetchSearch = (values) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    search: values.search,
    county: values.county,
    town: values.town,
    map: values.map,
    passback: Date.now()
  }

  return fetch(cookies.get('apiUrl')+"/search/parcels", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateParcelSection = (section,parcel) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    parcel:parcel
  }

  return fetch(cookies.get('apiUrl')+"/trail/update/parcel/section", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ParcelView(props) {
  const filter = (true)?{Toolbar: GridToolbar}:{};

  let length = props.data.length 
  let calc_height = (length * 50) + 150

  const height = `${calc_height}px`;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [prevSearch, setPrevSearch] = React.useState('')
  const [selectionChange, setSelectionChange] = React.useState([])
  const [options,setOptions ] = React.useState([])
  const timestamp = {
    ts:0
  }

  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    let values = {
      town:'',
      county:'',
      map:'',
      search:search
    }
    if(search !== prevSearch)
    fetchSearch(values).then(res => res.json())
      .then(
        (result) => {
          if(timestamp.ts < result.passback)
          {
            setPrevSearch(search)
            setOptions(result.response.options);
            timestamp.ts = result.passback
          }
        },
        (error) => {
        }
      );
  }, [search, timestamp, prevSearch]);

  const [ newParcel, setNewParcel ] = React.useState({
    click:false
  });

  React.useEffect(() => {
    if(newParcel.click)
    {
      updateParcelSection(newParcel.section, newParcel.parcel).then(res => res.json())
      .then(
        (result) => {
          newParcel.click = false
          props.refresh()
        })
    }
  },[newParcel, props])

  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  React.useEffect(() => {
    if(deleteList.click)
    {
      deleteList.list.forEach(element => {      
        updateParcelSection(null, element).then(res => res.json())
        .then(
          (result) => {
            deleteList.click = false
            props.refresh()
          })
      })
    }
  },[deleteList, props])

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        {props.type === 'Section' && <Grid item xs={1}>
          <AddTmButton title="Add Parcel" handleSave={(e) => {
            setNewParcel({
              section:props.entity,
              parcel: search,
              click: true
            })
          }}>
            <Autocomplete
              clearOnBlur={false}
              id="combo-box-demo"
              options={options}
              sx={{ width: 300 }}
              onInputChange={(e, value) => handleSearch(value)}
              renderInput={(params) => <TextField {...params} label="Parcel Search" />}
            />
          </AddTmButton>
        </Grid>
        }
        <Grid item xs={1}>
          {permission && 
            <IconButton 
              size="small" 
              color="inherit" 
              onClick={() => {
                if(selectionChange.length > 0)
                {
                  setOpen(true)
                }
              }}>
              <RemoveIcon/>
            </IconButton>
          }
        </Grid>
      </Grid>
      <div style={{ display: 'flex', height: height }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.data}
            columns={columns}
            density="compact"
            checkboxSelection={permission}
            onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
            components={filter}
          />
        </div>
      </div>
      <Dialog open={open} onClose={() => {setOpen(false)}}>
        <DialogTitle>Remove Parcels(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              click:true
            })
            setOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>      
    </Stack>
  );
}
