import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SectionChip from '../inputs/component-section-chip';
import RegionChip from '../inputs/component-region-chip';
import FormChip from '../inputs/component-form-chip';
import Divider from '@mui/material/Divider';
import AddDetailButton from '../inputs/component-add-detail-button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import MixedSearchInput from '../inputs/mixed-search-input';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Cookies from 'universal-cookie';

const addMaintainer = (section,type,entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    entity:entity,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const changeType = (entity,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:entity,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/trail/change/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })   
}


const addForm = (entity, form, signed) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    form:form,
    person:entity,
    dateReceived:signed.toISOString().split('T')[0],
  }

  return fetch(cookies.get('apiUrl')+"/trail/form/volunteer/create", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MaintainerDetails(props) {
  const [type, setType] = React.useState(props.data.type);
  
  const {regions,sections} = props.data
  const {entityType} = props
  const [ showEditForm, setShowEditForm ] = React.useState(sections.length > 0);
  const isAffiliation = (entityType !== 'Person')
  const cookies = new Cookies();
  var permission = (cookies.get('permission') !== 'null')

  React.useEffect(() => {
    if(props.data.type !== type)
    {
      changeType(props.entityId, type).then(res => res.json())
      .then(
        (result) => {
          props.refresh()
        })
    }
  },[props, type])


  let size = 12
  if(showEditForm)
  {
    size = 6
  }

  const [ sectionSize, setSectionSize ] = React.useState(size);
  const [current, setCurrent ] = React.useState(props.data.forms.current)
  const [expired, setExpired ] = React.useState(props.data.forms.expired)
  const [missing, setMissing ] = React.useState(props.data.forms.missing)

  if(current !== props.data.forms.current)
  {
    setCurrent(props.data.forms.current)
  }

  if(expired !== props.data.forms.expired)
  {
    setExpired(props.data.forms.expired)
  }

  if(missing !== props.data.forms.missing)
  {
    setMissing(props.data.forms.missing)
  }

  const [hasForms, setHasForms ] = React.useState((
    current.length > 0 || expired.length > 0 ||  missing.length > 0
  ))

  let fSize = ((current.length === 0 && expired.length > 0) || 
  (expired.length === 0 && current.length > 0)) ? 12: 6

  const [formsSize, setFormsSize ] = React.useState(fSize)
  const [ newSection, setNewSection ] = React.useState({
    name:'',
    type:'Maintainer',
    click:false
  });

  const [ newForm, setNewForm ] = React.useState({
    name:'',
    click:false, 
    dateSigned: new Date()
  });

  const [ newFormDate, setNewFormDate ] = React.useState(new Date())

  React.useEffect(() => {
    if(newSection.click)
    {
      addMaintainer(newSection.name, newSection.type,props.entityId).then(res => res.json())
      .then(
        (result) => {
          newSection.click = false
          props.refresh()
        })
    }
  },[props, newSection])

  React.useEffect(() => {
    if(newForm.click)
    {
      addForm(props.entityId,newForm.name, newFormDate).then(res => res.json())
      .then(
        (result) => {
          newForm.click = false
          props.refresh()
        })
    }
  },[props, newForm, newFormDate])

  const handleSignedDate = (date) => {
    setNewFormDate(date)
  };

  const handleAddSection = () =>
  {
    newSection.click = true
    setNewSection({...newSection})
  }

  return (
    <>
    <form>
      <Box sx={{ minWidth: 120 }}>
        <Grid container spacing={1}>
          {showEditForm &&
            <Grid item xs={12} justifyContent="center">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Type"
                  onChange={(e)=>setType(e.target.value)}
                  disabled={permission}
                >
                  <MenuItem value="Maintainer">Maintainer</MenuItem>
                  <MenuItem value="Sponsor">Sponsor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} md={sectionSize}>
            <Grid container>
              <Grid item xs={10} >
                <Typography sx={{paddingTop:'2%'}}>
                  Section
                </Typography>
              </Grid>
              <Grid item xs={2} >
                <AddDetailButton handleSave={(e) => {
                  newSection.click = true
                  setNewSection({...newSection})
                }} title="Add Section">
                  <Stack spacing={1}>
                    <MixedSearchInput
                      label={'Search'}
                      type={'section'}
                      onChange={(value) => {newSection.name = value}}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="new-section-type-select-label">Type</InputLabel>
                      <Select
                        labelId="new-section-type-select-label"
                        id="new-section-type-select"
                        value={newSection.type}
                        label="Type"
                        onChange={(e)=>{
                          newSection.type = e.target.value
                          setNewSection({...newSection})
                        }}
                      >
                        <MenuItem value="Maintainer">Maintainer</MenuItem>
                        <MenuItem value="Sponsor">Sponsor</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider/>
            <Stack spacing={1}>
              {sections.length > 0 && 
                <>
                {sections.map(element => (<SectionChip 
                  key={element.id}
                  entityId={props.entityId}
                  refresh={()=> props.refresh()}
                  data={element}></SectionChip>))}
                </>
              }
            </Stack>
            {sections.length === 0 && <Chip label="No sections assigned"/>}
          </Grid>
          { showEditForm &&
          <Grid item xs={12} md={6}>
            <Typography>
              Region
            </Typography>
            <Divider sx={{paddingTop:'4.5%'}}/>
            <Stack spacing={1}>
              {regions.map(element => (<RegionChip key={element.id} data={element}></RegionChip>))}
            </Stack>
          </Grid>}
          {!isAffiliation && <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10} >
                  <Typography sx={{paddingTop:'2%'}}>
                    Forms
                  </Typography>
                </Grid>
                <Grid item xs={2} >
                  <AddDetailButton handleSave={(e) => {
                    newForm.click = true
                    setNewForm({...newForm})
                  }} title="Add Form">
                    <Stack spacing={1}>
                      <MixedSearchInput
                        label={'Search'}
                        type={'form'}
                        onChange={(value) => {newForm.name = value}}
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="Date Received"
                          inputFormat="MM/dd/yyyy"
                          value={newFormDate}
                          onChange={handleSignedDate}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </AddDetailButton>
                </Grid>
              </Grid>
              <Divider/>
            </Grid>
            {current.length > 0 && 
              <Grid item xs={12} md={formsSize}>
                <Typography>
                  Current
                </Typography>
                <Stack spacing={1}>    
                  {current.map(element => (<FormChip 
                    key={element.id}
                    data={element}
                    entityId={props.entityId}
                    refresh={()=> props.refresh()}
                    ></FormChip>))}
                </Stack>
              </Grid>
            }
            {expired.length > 0 && 
              <Grid item xs={12} md={formsSize}>
                <Typography>
                  Expired
                </Typography>
                <Stack spacing={1}>
                  {expired.map(element => (
                    <FormChip 
                      key={element.id} 
                      data={element} 
                      entityId={props.entityId}
                      refresh={()=> props.refresh()}
                      ></FormChip>))}
                </Stack>
              </Grid> 
            }
            {missing.length > 0 && 
              <Grid item xs={12} md={formsSize}>
                <Typography>
                  Missing
                </Typography>
                <Stack spacing={1}>
                  {missing.map(element => (<Chip key={element.id} label={element.name}></Chip>))}
                </Stack>
              </Grid> 
            }
            {!hasForms && 
            <Stack>
              <Chip label="No forms assigned"/>
            </Stack> 
            }    
          </>}
        </Grid>
      </Box>
    </form>
    </>        
  );
}
