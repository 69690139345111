import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddDetailButton from '../inputs/component-add-detail-button';
import TextField from '@mui/material/TextField';
import MixedSearchInput from '../inputs/mixed-search-input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PublicOptionChip from '../inputs/component-public-option-chip';
import PublicFormChip from '../inputs/component-public-form-chip';
import Cookies from 'universal-cookie';

const addPublicForm = (name, entityId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const ttl = {
    user: user,
    token: token,
    formName: name,
    entityId: entityId
  }

  return fetch(cookies.get('apiUrl')+"/public/form/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deletePublicForm = (id, entityId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const ttl = {
    user: user,
    token: token,
    formId: id,
    entityId: entityId
  }

  return fetch(cookies.get('apiUrl')+"/public/form/remove", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updatePublicOption = (options, entityId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const ttl = {
    user: user,
    token: token,
    options: options,
    entityId: entityId
  }

  return fetch(cookies.get('apiUrl')+"/public/option/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function PublicEntityDetails(props) {
  const entityId = props.entityId
  let defOptions = []
  if (props.data.public_detail !== undefined)
  {
    defOptions = props.data.public_detail
  }
  
  const [options,setOptions] = React.useState(defOptions)
  const [saveOptions,setSaveOptions] = React.useState(false)

  let defForms = []
  if (!props.data.forms.hasOwnProperty('current'))
  {
    defForms = props.data.forms
  }

  const [forms, setForms] = React.useState(defForms)

  const [ newOption, setNewOption ] = React.useState({
    name:'',
    type: 'Text', 
    click:false
  });

  const [ newFormOption, setNewFormOption ] = React.useState({
    name:'',
    click:false,
  });

  const [ deleteFormOption, setDeleteFormOption ] = React.useState({
    id:'',
    click:false,
  });

  React.useEffect(() => {
    if(newFormOption.click)
      addPublicForm(newFormOption.name, entityId).then(res => res.json())
        .then(
          (result) => {
            newFormOption.click = false
            forms.push({
              id:result.response.id,
              name: newFormOption.name
            })
            setForms([...forms])
            
          },
          (error) => {
          }
        );
  }, [entityId, newFormOption, forms]);

  React.useEffect(() => {
    if(deleteFormOption.click)
      deletePublicForm(deleteFormOption.id, entityId).then(res => res.json())
        .then(
          (result) => {
            deleteFormOption.click = false
            let focus = null
            forms.forEach((element,index) => {
              if (element.id === deleteFormOption.id)
              {
                focus = index
              }
            });

            forms.splice(focus,1)
            setForms([...forms])
            
          },
          (error) => {
          }
        );
  }, [entityId, deleteFormOption, forms]);

  React.useEffect(() => {
    if(saveOptions)
      updatePublicOption(options, entityId).then(res => res.json())
        .then(
          (result) => {
            setSaveOptions(false)
          },
          (error) => {
          }
        );
  }, [entityId, options, saveOptions]);

  return (
    <form>
      <Box sx={{ minWidth: 120 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={10} >
                <Typography sx={{paddingTop:'2%'}}>
                  Public parcel options
                </Typography>
              </Grid>
              <Grid item xs={2} >
                <AddDetailButton handleSave={(e) => {
                  options.push({
                    name:newOption.name,
                    type:newOption.type,
                    options:[]
                  })

                  setOptions([...options])
                  setSaveOptions(true)
                }} title="Add Option">
                  <Stack spacing={1}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="type-simple-select"
                      value={newOption.type}
                      label="Type"
                      onChange={(e) => {
                        newOption.type = e.target.value
                        setNewOption({...newOption})
                      }}
                    >
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Dropdown">Dropdown</MenuItem>
                      <MenuItem value="Checks">Checkboxes</MenuItem>
                    </Select>
                    <TextField
                      label={'Name'}
                      onChange={(e) => {newOption.name = e.target.value}}
                    />
                  </Stack>
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider sx={{mb:1}}/>
            <Stack spacing={1}>
              {options.map((element,index) => (
                <PublicOptionChip 
                  key={'po-'+index} 
                  data={element}
                  saveOption={(option) => {
                    options[index] = option
                    setOptions([...options])
                    setSaveOptions(true)
                  }}
                  deleteClick={() => {
                    options.splice(index,1)
                    setOptions([...options])
                    setSaveOptions(true)
                  }}
                  />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={10} >
                <Typography sx={{paddingTop:'2%'}}>
                  Forms
                </Typography>
              </Grid>
              <Grid item xs={2} >
                <AddDetailButton handleSave={(e) => {
                  newFormOption.click = true
                  setNewFormOption({...newFormOption})
                }} title="Assign Form">
                  <Stack spacing={1}>
                    <MixedSearchInput
                      label={'Search'}
                      type={'form'}
                      onChange={(value) => {
                        newFormOption.name = value
                      }}
                    />
                  </Stack>
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider sx={{mb:1}}/>
            <Stack spacing={1}>
              {forms.map(element => (
                <PublicFormChip 
                  key={'form-'+element.id} 
                  label={element.name}
                  entityId={entityId}
                  id={element.id}
                  onClear={(id)=>{
                    setDeleteFormOption({
                      id:id,
                      click:true
                    })
                  }}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>        
  );
}
