import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import PermissionBreakdown from './permission-breakdown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function PermissionChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.label)
  const [data, setData] = React.useState(props.data)

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    //props.onDelete(props.data.id)
    handleClose()
  };

  const handleSave = () => {
    const {id, ...restData} = data
    setChipTitle(restData.name)
    props.onSave(data)
    handleClose()
  };

  const permissionHandleChange = (prop,value) => {
    setData({ ...data, [prop]: value });
  };

  return (
    <div>
      <CustomChip
        size={1}
        label={chipTitle}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Permission</DialogTitle>
        <DialogContent>
          <PermissionBreakdown 
            data={data}
            handleChange={(prop,value)=>{permissionHandleChange(prop,value)}}
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {setConfirmOpen(true)}}>Delete</Button>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>      
      <Dialog 
          open={confirmOpen}
        >
          <DialogTitle>{'Are you sure?'}</DialogTitle>
        <DialogActions>  
          <Button onClick={() => {setConfirmOpen(false)}}>No</Button>
          <Button onClick={() => {
            props.onDelete(data.id)
            setConfirmOpen(false)  
            setOpen(false)
          }}>Yes</Button>    
        </DialogActions>
      </Dialog>
    </div>
  );
}
