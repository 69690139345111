import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import Cookies from 'universal-cookie';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import RuleForm from './rule-form';

const deleteSeting = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: 'Rules',
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateSeting = (rule) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  let id = rule.id
  delete rule['id']
  const ttl = {
    user: user,
    token: token,
    setting:'Rules',
    package:{
      event: rule.event,
      clause: rule.clause,
      process: rule.process,
      name:rule.name,
    },
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function RuleChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [editClick, setEditClick] = React.useState(false);
  const [type, setType] = React.useState(props.data.clause)
  const [size, setSize] = React.useState(1)
  const [delId, setDelId] = React.useState(0)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [editRule, setEditRule] = React.useState(props.data)
  const [deleteClick, setDeleteClick] = React.useState(false)

  React.useEffect(() => {
    if(deleteClick)
    {
      deleteSeting(delId).then(res => res.json())
      .then(
        (result) => {
          setDeleteClick(false)
          props.refresh()
        })
    }
  },[delId,deleteClick ,props])

  React.useEffect(() => {
    if(editClick)
    {
      updateSeting(editRule)
      .then(
        (result) => {
          setEditClick(false)
          props.refresh()
        })
    }
  },[editRule, editClick, props])

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<EditIcon/>)
      setOpen(!open)
    }
    else
    {
      setOpen(!open)
      setIcon(<DeleteIcon/>)
    }
  };

  const handleDelete = (event) => {
    if(open)
    {
      setEditOpen(true)
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setOpen(false)
    setConfirmOpen(false);
    setConfirmChange(false)
  };

  const handleRemove = () => {
    setDelId(props.data.id)
    setDeleteClick(true)
    handleClose()
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
          />
        </Grid>
        {open &&
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{props.data.event.description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>This cannot be undone.</DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Rule</DialogTitle>
        <DialogContent>
          <RuleForm 
            roleName={props.roleName}
            role={props.roleId} 
            rule={props.data}
            refresh={() => props.refresh()}
            onChange={(value)=>{
              setEditRule(value)
              setChipTitle(value.name)
          }}/>
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={()=>setEditOpen(false)}>cancel</Button>
          <Button onClick={()=> {
            setEditClick(true)
            setEditOpen(false)
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
