import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { FormControl, FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import EventOptions from '../input/event-options';
import EventParentSelect from '../input/event-parent-select';

/**
 * This function converts a Date object to a 'mm/dd/yyyy' string which results in data receiving the date of the current day opposed to the previous from using toISOString().split('T')[0] which returns 'yyyy-mm-dd'.
 */
const convertDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Keep month and day 2 digits
  const formattedMonth = String(month).padStart(2, '0');
  const formattedDay = String(day).padStart(2, '0');

  // 'mm/dd/yyyy' string is passed back
  return `${formattedMonth}/${formattedDay}/${year}`;
}

const updateEvent = (id, data) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    data: data,
  };

  return fetch(cookies.get('apiUrl') + '/events/change', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function EventDetails(props) {
  const [data, setData] = React.useState({
    name: '',
    type: '',
    parentEventFlag: false,
    parentEventId: null,
    historicalEventFlag: false,
    click: false,
    start: '',
    end: ''
  });
  const [name, setName] = React.useState(props.name);
  const [type, setType] = React.useState(props.type);
  const [startDate, setStartDate] = React.useState(new Date(props.event.start));
  const [endDate, setEndDate] = React.useState(new Date(props.event.end));
  const [disabled, setDisabled] = React.useState(true);
  const [icon, setIcon] = React.useState(<></>);
  const [event, setEvent] = React.useState();
  const [parentEventFlag, setParentEventFlag] = React.useState(props.event.parent_event_flag);
  const [parentEventId, setParentEventId] = React.useState(props.event.parent_event_id);
  const [historicalEventFlag, setHistoricalEventFlag] = React.useState(props.event.historical_event_flag);
  const [openConfirmHistorical, setOpenConfirmHistorical] = React.useState(false);

  React.useEffect(() => {
    setEvent(props.event);
    setName(props.event.name);
    setType(props.event.type);
    setParentEventFlag(props.event.parent_event_flag);
    setParentEventId(props.event.parent_event_id);
    setHistoricalEventFlag(props.event.historical_event_flag);
    setStartDate(new Date(props.event.start));
    setEndDate(new Date(props.event.end));
  }, [props.refresh]);

  // Event update triggered by change in data
  React.useEffect(() => {
    if (data.click) {
      setIcon(<CircularProgress />);
      setDisabled(true);
      // Update event in db with current data values
      updateEvent(props.event.id, data)
        .then(res => res.json())
        .then((result) => {
          // update event to pass back to parent component
          event.name = name;
          event.type = type;
          event.parent_event_flag = parentEventFlag;
          event.parent_event_id = parentEventId;
          event.historical_event_flag = historicalEventFlag;
          event.start = convertDate(startDate);
          event.end = convertDate(endDate);
          props.callback(event);

          setIcon(<CheckIcon />);
        }, (error) => {
          setIcon(<ErrorIcon />);
        });
    }
  }, [data]);

  if (props.event.type === -1) {
    return <></>;
  }

  // update event fields to match current values
  if (event !== props.event) {
    setEvent(props.event);
    setName(props.event.name);
    setType(props.event.type);
    setParentEventFlag(props.event.parent_event_flag);
    setParentEventId(props.event.parent_event_id);
    setHistoricalEventFlag(props.event.historical_event_flag);
    setStartDate(new Date(props.event.start));
    setEndDate(new Date(props.event.end));
    setIcon(<></>);
    setDisabled(true);
  }

  // Handle changes made in input fields
  const handleChange = (prop) => (event) => {
    if (prop === 'name') {
      setName(event.target.value);
    }

    if (prop === 'type') {
      setType(event.target.value);
    }

    if (prop === 'parent_event_flag') {
      setParentEventFlag(event.target.checked);
      setParentEventId(event.target.checked ? null : -1);
    }

    setDisabled(false);
    setIcon(<SaveIcon />);
  };

  // Opens confirmation box for make historical button
  const handleConfirmOpen = () => {
    setOpenConfirmHistorical(true);
  };

  const handleConfirmClose = (prop) => {
    if (prop === 'confirm') {
      if (endDate > (new Date())) {
        setEndDate(new Date());
      }

      // Setting the data causes api call upon confirming change
      setHistoricalEventFlag(true);
      setData({
        name: name,
        type: type,
        parentEventFlag: parentEventFlag,
        parentEventId: parentEventId,
        historicalEventFlag: true,
        click: true,
        start: convertDate(startDate),
        end: convertDate(endDate),
      });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '95%' },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={0}>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <TextField id="outlined-basic"
              size="small"
              label={type + ' Name'}
              variant="outlined"
              value={name}
              disabled={historicalEventFlag}
              onChange={handleChange('name')}
              InputProps={{
                endAdornment: (
                  <EventOptions
                    title={'Event Options'}
                    event={event}
                  >
                    <Stack spacing={1}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="h5" component="h4" paddingLeft={3}>Event Options</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth variant="filled">
                            <Button
                              type="contained"
                              onClick={handleConfirmOpen}
                              disabled={historicalEventFlag}
                            >
                              Make Historical
                            </Button>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5} paddingTop={1}>
                          <FormControl fullWidth variant="filled">
                            <FormLabel sx={{ width: '95%', fontSize: 12, paddingLeft: 3 }}>Parent Event</FormLabel>
                            <Checkbox
                              id={'change-parent-event-select'}
                              checked={parentEventFlag}
                              onChange={handleChange('parent_event_flag')}
                              className=""
                              disabled={historicalEventFlag}
                              sx={{ alignSelf: 'flex-start', paddingLeft: 6 }}
                            />
                          </FormControl>
                        </Grid>
                        {((parentEventFlag !== true) &&
                          <Grid item xs={6} paddingTop={2}>
                            <FormControl fullWidth variant="filled" >
                              <EventParentSelect
                                event={event}
                                parentEventId={parentEventId}
                                onChange={handleChange('parent_event_id')}
                                callback={(value) => {
                                  setParentEventId(value);
                                  if (value === -1) {
                                    setParentEventId(null);
                                  }
                                }}
                                disabled={historicalEventFlag}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>

                    </Stack>
                  </EventOptions>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <TextField
              id={'change-type-select'}
              size="small"
              select
              label="Select Type"
              value={type}
              onChange={handleChange('type')}
              disabled={historicalEventFlag}
              className=""
            >
              <MenuItem key={'basic'} value={'Basic'}>
                Basic
              </MenuItem>
              <MenuItem key={'donation'} value={'Donation'}>
                Donation
              </MenuItem>
              <MenuItem key={'hours'} value={'Hours'}>
                Hours
              </MenuItem>
              <MenuItem key={'mileage'} value={'Mileage'}>
                Mileage
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth variant="filled">
              <MobileDatePicker
                label="Start Date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e);
                  setDisabled(false);
                  setIcon(<SaveIcon />);
                }}
                disabled={historicalEventFlag}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="End Date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e);
                  setDisabled(false);
                  setIcon(<SaveIcon />);
                }}
                disabled={historicalEventFlag}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={8} >
        </Grid>
        <Grid item xs={4} >
          <IconButton
            size="large"
            disabled={disabled}
            onClick={() => {
              // Parent events cannot be children events too
              if (parentEventFlag) {
                // null out id if event is saved as a parent event
                setParentEventId(null);
              }
              // Set data entry for event to be updated
              setData({
                name: name,
                type: type,
                parentEventFlag: parentEventFlag,
                parentEventId: parentEventId,
                historicalEventFlag: historicalEventFlag,
                click: true,
                start: convertDate(startDate),
                end: convertDate(endDate),
              });
            }}>
            {icon}
          </IconButton>
        </Grid>
      </Grid>
      <Dialog open={openConfirmHistorical} onClose={() => { }}>
        <DialogTitle>Make Historical?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {
            setOpenConfirmHistorical(false);
          }}>
            Cancel
          </Button>
          <Button type="contained" onClick={() => {
            handleConfirmClose('confirm');
            setOpenConfirmHistorical(false);
          }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
