import * as React from 'react';
import { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import EventSelect from './input/event-select';
import ManageEventsButton from './views/manage-events';
import EventViewAccordions from './views/event-view';
import EventDetails from './views/event-detail';
import RoleView from './views/role-view';
import EventTypeSelect from './input/event-type-select';
import HistoricalEventsButton from './views/historical-events';
import RuleView from './views/rule-view';

const removeComponent = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    component_name: 'new-event'
  };

  return fetch(cookies.get('apiUrl') + "/user/remove/component", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function NewEventComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'report-component-menu-id';
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [type, setType] = React.useState(-1);
  const [event, setEvent] = React.useState(-1);
  const [hasSelected, setHasSelected] = React.useState(false);
  const [deleteClick, setDeleteClick] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    if (deleteClick) {
      setPending(true);
      removeComponent()
        .then(res => res.json())
        .then((result) => {
          setPending(true);
          setDeleteClick(false)
          window.location.reload();
        }, (error) => {
          setPending(true);
          setError(error);
        });
    }
  }, [deleteClick])

  // Effect checks for an event to be selected for the event details to appear
  React.useEffect(() => {
    if (event === -1)
      setHasSelected(false);
    else
      setHasSelected(true);
  }, [event])

  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper className={props.className} direction="columns" style={{ height: '100%' }}>
      <Grid container spacing={0}>
        <Grid item xs={1}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleReportMenuOpen}
            color="inherit"
          >
            <Badge>
              <MenuIcon />
            </Badge>
          </IconButton>
        </Grid>
        <Grid item xs={5}>
          <div className="component_header">
            <Typography variant="h5" component="h4" >New Event Component</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <HistoricalEventsButton
            callback={(event) => {
              // Update changes made to event in hist modal to trigger refresh of child components
              setEvent(event);
              setRefresh(!refresh);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <ManageEventsButton
            callback={(value) => {
              // Event needs to be unselected so user can't change input on deleted event
              if (event.id === value) {
                setEvent(-1);
                setHasSelected(false);
              }
              setRefresh(!refresh);
            }} />
        </Grid>
        <Grid item xs={6}>
          <Suspense fallback={<div>Loading...</div>}>
            <EventTypeSelect
              callback={(value) => {
                setType(value);
              }}
            />
          </Suspense>
        </Grid>
        <Grid item xs={6}>
          <Suspense fallback={<div>Loading...</div>}>
            <EventSelect
              event={event}
              type={type}
              refresh={refresh}
              callback={(value) => {
                setEvent(value);
              }}
            />
          </Suspense>
        </Grid>
        {hasSelected === true &&
          // Show component if a event has been selected by user
          (<Grid item xs={12} key={event.id}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <EventDetails
                  event={event}
                  type={type}
                  refresh={refresh}
                  callback={(event) => {
                    // Update changes made to event in hist modal to trigger refresh of child components
                    setEvent(event);
                    setRefresh(!refresh);
                  }}
                />
              </Grid>
              <Grid item xs={12} >
                <RoleView
                  event={event}
                  refresh={refresh}
                  callback={(event) => {
                    setEvent(event);
                    setRefresh(!refresh);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RuleView
                  event={event}
                  refresh={refresh}
                  callback={(event) => {
                    setEvent(event);
                    setRefresh(!refresh);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <EventViewAccordions event={event} />
              </Grid>
            </Grid>
          </Grid>)}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => setDeleteClick(true)}>Remove Component</MenuItem>
      </Menu>
    </Paper>
  );
}
