import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro'
import Cookies from 'universal-cookie';
import TableSelect from '../../core-components/entity-details/inputs/table-select';
import TableCheck from '../../core-components/entity-details/inputs/table-check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

const fetchTransaction = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:type,
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function AccountingDetails(props) {
  const [accounts, setAccounts] = React.useState([])
  const [desc, setDesc] = React.useState([])
  const [discounts, setDiscounts] = React.useState([{id:-1, name:"No Discount"}])
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [changeQueue, setChangeQueue] = React.useState([]);
  const [action, setAction] = React.useState({
    click:false,
    action:'',
    selected: [],
    changed:changeQueue,
  });

  const onEditCommit = (values, event) => {
    
    if(values.field === 'receiptDate')
    {
      let ts = new Date(values.value)
      values.value = ts.getTime()
    }
    
    let append = {id:values.id, field:values.field, value:values.value}
    props.onSave(append)
  }

  React.useEffect(() => {
    fetchTransaction('accounts').then(res => res.json())
      .then(
        (result) => {
          const {donation, purchase} = result.response
          setAccounts(donation.concat(purchase))
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    fetchTransaction('descriptions').then(res => res.json())
      .then(
        (result) => {
          const {donation, purchase} = result.response
          let descriptions = donation.concat(purchase)
          let pass =[]
          descriptions.map((t)=>{
            t.id = t.name 

            pass.push(t)
          })
          setDesc(pass)
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    fetchTransaction('discounts').then(res => res.json())
      .then(
        (result) => {
          setDiscounts(discounts.concat(result.response))
        },
        (error) => {
        }
      );
  }, []);


  const handleRemove = () => {
    action.click = true
    action.action = 'delete'

    props.onDelete(action.selected)
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "receiptDate",
      headerName: "Receipt Date",
      width: 100,
      editable:true,
      type:'date'
    },
    {
      headerName:'Account',
      field:'account',
      renderCell: (params) => {
        return (<TableSelect id={params.id} list={accounts} field="account" val={params.value} callback={(val,e) => onEditCommit(val,e)} />)},
      width: 150,
      filterable:false,
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 80,
      editable:true,
      valueFormatter: (params) => {
        const valueFormatted = params.value;
        return `$${valueFormatted}`;
      },
    },
    {
     field: "paidBy",
     headerName: "Method",
     renderCell: (params) => {
      return (<TableSelect list={[
        {id:'Cash or Check', name:'Cash/Check'},
        {id:'Credit/Debit Card', name:'Credit/Debit Card'},
        {id:'PayPal', name:'PayPal'},
        {id:'In-Kind', name:'In-Kind'},
        {id:'Stripe', name:'Stripe'},
        {id:'Eventbrite', name:'Eventbrite'},
      ]} val={params.value} />)
    },
     width: 150,
     editable:false
    },
    {
     field: "description",
     headerName: "Solicitation/Description",
     width: 150,
     renderCell: (params) => {
      return (
      <TableSelect id={params.id} list={desc} field="description" val={params.value} callback={(val,e) => onEditCommit(val,e)} />
    )},
    },
    {
      field: "discount",
      headerName: "Discount",
      renderCell: (params) => {
        return (<TableSelect id={params.id} list={discounts} field="discount" val={params.value} callback={(val,e) => onEditCommit(val,e)} />
        )},
      width: 150,
      editable:false
     },
    {
      field: "anonymous",
      headerName: "Anon",
      width: 100,
      editable:false,
      renderCell: (params) => {
        return <TableCheck id={params.id} val={params.value} callback={(val,e) => onEditCommit(val,e)} />
      },
     },
  ]

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return ( <>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <IconButton 
          size="large"
          color="inherit"
          onClick={() => {setConfirmOpen(true)}}
          disabled={!show}
          >
          <DeleteForeverIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: 600, width: '100%' }}>
          <DataGridPro
            rows={props.data}
            checkboxSelection={true}
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => {
              action.selected = newSelection
              setShow(newSelection.length > 0)
              
            }} 
            columns={columns}
            density="compact"
            onCellEditCommit={(values, event) => {onEditCommit(values,event)}}
          />
        </div>
      </Grid>
    </Grid>
    <Dialog open={confirmOpen} onClose={handleClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>This cannot be undone</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleRemove}>Yes</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
