import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import MaintainerView from './maintainer-view';
import ParcelView from './parcel-view';
import SectionView from './section-view';

const fetchData = (index, type, entity) => {
  const cookies = new Cookies()
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: entity.id,
    view: index,
    type: type
  }

  return fetch(cookies.get('apiUrl') + '/trail/view', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  })
}

export default function TralViewAccordions(props) {
  const [entity, setEntity] = React.useState(props.data);
  const [type, setType] = React.useState(props.type);
  const [refresh, setRefresh] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [errorParcels, setErrorParcels] = React.useState(null);
  const [errorMaintainers, setErrorMaintainers] = React.useState(null);
  const [errorSections, setErrorSections] = React.useState(null);
  const [loadingParcels, setLoadingParcels] = React.useState(false);
  const [loadingMaintainers, setLoadingMaintainers] = React.useState(false);
  const [loadingSections, setLoadingSections] = React.useState(false);
  const [dataParcels, setDataParcels] = React.useState([]);
  const [dataMaintainers, setDataMaintainers] = React.useState([]);
  const [dataSections, setDataSections] = React.useState([]);

  if (props.data !== -1) {
    if (props.data.id !== entity.id) {
      setEntity(props.data)
      setType(props.type)
      setExpanded(false)
    }
  }

  // Loads data, triggered by dropdowns for volunteers, sections, or parcels
  React.useEffect(() => {
    // Set loading states based on user selection
    switch (index) {
      case 'maintainer':
        setLoadingMaintainers(true);
        break;
      case 'section':
        setLoadingSections(true);
        break;
      case 'parcel':
        setLoadingParcels(true);
        break;
      default:
        break;
    }
    if (index !== '') {
      fetchData(index, type, entity)
        .then(res => res.json())
        .then((result) => {
          // get data and turn off loading states
          switch (index) {
            case 'maintainer':
              setDataMaintainers(result.response);
              setLoadingMaintainers(false);
              setErrorMaintainers(null);
              break;
            case 'section':
              setDataSections(result.response);
              setLoadingSections(false);
              setErrorSections(null);
              break;
            case 'parcel':
              setDataParcels(result.response);
              setLoadingParcels(false);
              setErrorParcels(null);
              break;
            default:
              break;
          }
        }, (error) => {
          switch (index) {
            case 'maintainer':
              setErrorMaintainers(error);
              setLoadingMaintainers(true);
              break;
            case 'section':
              setErrorSections(error);
              setLoadingSections(true);
              break;
            case 'parcel':
              setErrorParcels(error);
              setLoadingParcels(true);
              break;
          }
        });
    }
  }, [index, refresh, type, entity]);

  if (props.data === -1) {
    return <></>;
  }

  // Set index to trigger data loading for maintainer, sections, or parcels
  const toggleData = (newIndex) => {
    if (index !== newIndex) {
      setIndex(newIndex);
    }
    else {
      setIndex('');
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    toggleData(panel);
  };

  return (
    <div>
      <Accordion expanded={index === 'maintainer'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="maintainer-content"
          id="maintainer-header"
          onClick={() => toggleData('maintainer')}
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Volunteers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!loadingMaintainers && errorMaintainers && <ErrorIcon color="danger" />}
          {loadingMaintainers && <CircularProgress color="warning" />}
          {!loadingMaintainers && !errorMaintainers > 0 && (
            <MaintainerView
              data={dataMaintainers}
              type={type}
              name={entity.name}
              entity={entity.id}
              refresh={() => { setRefresh(!refresh) }}
            />
          )}
        </AccordionDetails>
      </Accordion>

      {type === 'Region' &&
        <Accordion expanded={index === 'section'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="section-content"
            id="section-header"
            onClick={() => toggleData('section')}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Sections</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!loadingSections && errorSections && <ErrorIcon color="danger" />}
            {loadingSections && <CircularProgress color="warning" />}
            {!loadingSections && (
              <SectionView
                data={dataSections}
                id={entity.id}
                refresh={() => { setRefresh(!refresh) }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      }
      <Accordion expanded={index === 'parcel'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="parcel-content"
          id="parcel-header"
          onClick={() => toggleData('parcel')}
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Parcels
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!loadingParcels && errorParcels && <ErrorIcon color="danger" />}
          {loadingParcels && <CircularProgress color="warning" />}
          {!loadingParcels && (
            <ParcelView
              data={dataParcels}
              type={type}
              entity={entity.id}
              refresh={() => { setRefresh(!refresh) }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
