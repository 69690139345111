import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ReportTextInput(props) {
  const [value, setValue] = React.useState(props.defalt);
  const [showField, setShowField] = React.useState(props.showVal);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);
  
  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onShow(val)
  };

  return (
    <Grid container>
      <Grid item xs={12}>
      <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ m: 1, width: '99%' }} variant="standard">
          <TextField 
            id="text_field"  
            value={value} onChange={(e) => {
              setValue(e.target.value)
              props.onChange(e.target.value)
            }}/>
        </FormControl>
      </Grid>
    </Grid>  
  )
}
