import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import EntitySearchInput from '../../core-components/entity-details/inputs/entity-search-input';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';

const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function NewMembershipButton(props) {
  const {tPackage } = props;

  const [transStatus, setTransStatus] = React.useState('Sending membership...');

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState({
    id:new Date().getTime(),
    anonymous:false,
    payment: '', 
    account:1, 
    receiptDate: new Date(),
    memo:'',
    paidBy:'',
    contrib:0,
    soft:false,
    description:'Membership Appeal',
    apply:'No',
    recognition:null,
    discount:-1
  });

  const [membership, setMembership] = React.useState({
    status:'No membership',
    expDate: new Date(),
    dateJoined: new Date(),
    type: 'Click Add membership to set membership',
    lastPaid: new Date()
  });

  const [type, setType] = React.useState('');

  if(tPackage)
  {
    transaction.apply = 'Yes'
  }

  const [inputErrors, setInputErrors] = React.useState({
    payment:{error:false, message:'Cannot be empty and must be a number value'},  
    paidBy:{error:false, message:'Please select a payment method!'},
    type:{error:false, message:'Please select a membersip type!!'},
    gift_name:{error:false}
  });

  const [gift, setGift] = React.useState({
    send:'No',
    type:'In Honor',
    target:null
  });

  const [pass, setPass] = React.useState({
    click:false,
    data: {}
  });

  const handleChange = (prop) => (event) => {
    setTransaction({ ...transaction, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDate = (value) => {
    transaction.receiptDate = value;
    membership.dateJoined = value;
    membership.lastPaid = value
    setMembership({...membership})
    setTransaction({...transaction})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGiftClose = () => {
    setConfirmOpen(false);
  };

  const handleGiftOpen = () => {
    let check = true
    if(type === '')
    {
      check = false
      inputErrors.type.error = true
    }
    else inputErrors.type.error = false

    if(transaction.payment === '' || !isInt(transaction.payment)) 
    {
      check = false
      inputErrors.payment.error = true
    }
    else inputErrors.payment.error = false

    if(transaction.paidBy === '')
    {
      check = false
      inputErrors.paidBy.error = true
    }
    else inputErrors.paidBy.error = false

    setInputErrors({...inputErrors})
    if(check) setConfirmOpen(true);
  };

  const handleGiftSave = () => {
    let check = true
    let target = gift.target

    if(gift.target === null)
    {
      check=false
      target = false
      inputErrors.gift_name.error = true
    } 
    else inputErrors.gift_name.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      transaction.gift = gift
      transaction.target = gift.id
      pass.data = transaction
      pass.type = type
      membership.type = type
      membership.status = 'Current'
      setPass({...pass})    
      transaction.target = target
      props.onSave(pass.data, membership)
      setTransaction({
        id:new Date().getTime(),
        anonymous:false,
        payment: '', 
        account:1, 
        receiptDate: new Date(),
        memo:'',
        paidBy:'',
        contrib:0,
        soft:false,
        description:'Membership Appeal',
        apply:'No',
        recognition:null,
        discount:-1
      })
    }
  };

  const handleSave = () => {
    let check = true

    if(type === '')
    {
      check = false
      inputErrors.type.error = true
    }
    else inputErrors.type.error = false

    if(transaction.payment === '' || !isInt(transaction.payment)) 
    {
      check = false
      inputErrors.payment.error = true
    }
    else inputErrors.payment.error = false

    if(transaction.paidBy === '')
    {
      check = false
      inputErrors.paidBy.error = true
    }
    else inputErrors.paidBy.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      transaction.target = false
      pass.data = transaction
      pass.type = type
      
      membership.type = type
      membership.status = 'Current'
      
      setPass({...pass})    
      props.onSave(pass.data, membership)
      setOpen(false)
      setTransaction({
        id:new Date().getTime(),
        anonymous:false,
        payment: '', 
        account:1, 
        receiptDate: new Date(),
        memo:'',
        paidBy:'',
        contrib:0,
        soft:false,
        description:'Membership Appeal',
        apply:'No',
        recognition:null,
        discount:-1
      })
    }
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
       <AddIcon fontSize='small'/>
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle>Membership</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>   
          <form>      
          <Stack spacing={2} >
            {tPackage.contributors.length > 0 &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Contributor</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.contrib}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                  
                >
                  <MenuItem value={0}>None</MenuItem>
                  {tPackage.contributors.map((t) => <MenuItem key={"cccsd"+t.id} value={t.id}>{t.firstName + " " + t.lastName}</MenuItem>)}
                </Select>
              </FormControl>
            }
            {tPackage.apply &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Apply To Family</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.apply}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                >
                  <MenuItem value={0}>Yes</MenuItem>
                  <MenuItem value={1}>No</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl fullWidth>
              <InputLabel id="anon-select-label">Anonymous</InputLabel>
              <Select
                labelId="anon-select-label"
                id="anon-select"
                value={transaction.anonymous}
                label="Anonymous"
                onChange={handleChange('anonymous')}
                required={true}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Type</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={type}
                label="Membership Type"
                onChange={(e) => setType(e.target.value)}
                error={inputErrors.type.error}
              >
                {tPackage.memberTypes.map((t)=> <MenuItem key={"cccss"+t.id} value={t.id}>{t.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Stack direction="row">
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-payment">Payment</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-payment"
                  value={transaction.payment}
                  onChange={handleChange('payment')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Payment"
                  error={inputErrors.payment.error}
                />
              </FormControl>
              <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Payment Method</InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={transaction.paidBy}
                  label="Payment Method"
                  onChange={handleChange('paidBy')}
                  error={inputErrors.paidBy.error}
                >
                  <MenuItem value="Cash or Check">Cash/Check</MenuItem>
                  <MenuItem value="Credit/Debit Card">Credit/Debit Card</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Stripe">Stripe</MenuItem>
                  <MenuItem value="In-Kind">In-Kind</MenuItem>
                  <MenuItem value="Eventbrite">Eventbrite</MenuItem>
                  <MenuItem value="Direct Deposit">Direct Deposit</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Receipt Date"
                  openTo="month"
                  views={['month', 'day', 'year']}
                  value={transaction.receiptDate}
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                  required={true}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Discount</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.discount}
                label="Discount"
                onChange={handleChange('account')}
                required={true}
              >
                {tPackage.discounts.map((t)=> <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          </form>            

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleGiftOpen}>Gift</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleGiftClose}>
        <DialogTitle>Gift</DialogTitle>
        <DialogContent>
          <Stack pt={1} spacing={1}>
            <EntitySearchInput 
              onChange={(value) => (gift.target = value)}
              label="Entity Search"
              error={inputErrors.gift_name.error}
              />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGiftClose}>Close</Button>
          <Button onClick={(e) => {
              handleGiftSave()
            }}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loadingOpen}>
        <DialogTitle>{transStatus}</DialogTitle>
        <DialogActions>
          <Button onClick={(e) => {
              setLoadingOpen(false)
              setConfirmOpen(false)
              setOpen(false)
              setTransaction({
                id:new Date().getTime(),
                anonymous:false,
                payment: '', 
                account:'', 
                receiptDate: new Date(),
                memo:'',
                paidBy:'',
                contrib:0,
                soft:false,
                description:'Membership Appeal',
                apply:'No',
                recognition:null,
                discount:-1
              })
            }}>okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
