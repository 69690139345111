import * as React from 'react';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const InputStyles = makeStyles((theme) => ({
  textField: {
      height: '50px',
      width: '80%',            
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
      margin: 'dense',
      border:'none'
  },
  input: {
      color: 'white',
      border:'none'
  }
}));

function CustomTextInput(props)
{
  const classes = InputStyles({})
  const {InputProps, ...rest} = props
  return ( 
    <Stack direction="row" spacing={2}>
      <Typography variant="subtitle1" style={{paddingTop:"4%"}}>Date about me: </Typography>
      <InputBase {...rest} className={classes.textField} ></InputBase>
    </Stack>
  )
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));



export default function DummyChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState("I'm Interactive!")
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const oldDate = new Date()
  const [date , setDate] = React.useState(oldDate)
  const [dateOpen, setDateOpen] = React.useState(false)

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon style={{paddingRight:"2%"}}/>)
      setOpen(!open)
      setSize(1.5)
    }
  };

  const toggle = (e) =>
  {
    setDateOpen(true)
  }


  const handleDate = (value, e) => {
    setDate(value)
  }


  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setChipTitle("I'm Interactive")
      setOpen(!open)
      setIcon(<DeleteIcon/>)
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    setConfirmOpen(false);
  };

  return (
    <div>
      <CustomChip
          size={size}
          label={(<>
            {!open && <>
              {chipTitle}
            </>}
            {open && <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label='Since'
                  inputFormat="MM/dd/yyyy"
                  value={date}
                  open={dateOpen}
                  onChange={(newValue) => setDate(newValue)}
                  onAccept={(newValue, e) => handleDate(newValue, e)}
                  onClose={() => {
                    setDateOpen(false)
                    setDate(oldDate)
                  }}
                  onClear={() => {setDate(oldDate)}}
                  onOpen={(e) => {toggle(e)}}
                  renderInput={(params) => <CustomTextInput {...params} />}
                />
              </LocalizationProvider>
            </>}
          </>)}
          variant="filled"
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={icon}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
