import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Cookies from 'universal-cookie';

const fetchAutolist = (search, type, ignore = false) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    search: [search],
    passback: Date.now(),
    ignore: ignore
  }

  if(type !== '')
  {
    ttl['type'] = type
  }

  return fetch(cookies.get('apiUrl')+"/search/entities", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EntitySearchInput(props) {
  
  let temp_searchType = ''
  if(props.type !== undefined)
  {
    temp_searchType = props.type
  }

  let tempSize = 'medium'
  if(props.size)
  {
    tempSize = props.size
  }

  let ignore = false
  if(props.ignore)
  {
    ignore = props.ignore
  }

  const [selectValue, setSelectValue] = React.useState(props.value)
  const [size, setSize] = React.useState(tempSize);
  const [searchType, setSearchType] = React.useState(temp_searchType);
  const [list, setList] = React.useState([]);
  const [search, setSearch] = React.useState(props.searchValue);
  const [timestamp, setTimestamp] = React.useState({
    ts:0,
    searching:0
  })

  React.useEffect(() => {
    if(search!=='')
    {
      fetchAutolist(search, searchType, ignore).then(res => res.json())
        .then(
          (result) => {
            if(timestamp.ts < result.passback)
            {
              let newList = []
              Object.entries(result.response).forEach(([id,data]) => {
                if(id !== 'autolist' && id !== 'length') newList.push(data)})
              setList(newList)
              timestamp.ts = result.passback
            }
          },
          (error) => {

          }
        );
    }
  }, [search, timestamp, searchType, ignore]);

  return (
      <Autocomplete
        id="combo-box-demo"
        inputValue={search}
        value={selectValue}
        onChange={(e, value) => {
          setSelectValue(value)
          props.onChange(value)
        }}
        onInputChange={(e, value) => setSearch(value)}
        onBlur={() => {}}
        options={list}
        size={size}
        getOptionLabel={(option) => (option.firstName + ' ' +option.lastName)}
        renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <Grid container>
            <Grid item xs={7}>
              {option.firstName} {option.lastName} 
            </Grid>
            <Grid item sx={1}>-</Grid>
            <Grid item>
              {option.city !== null && <>{option.city}</>}
              {option.city === null && option.emailAddress !== null && <>{option.emailAddress}</>}
            </Grid>
          </Grid>
        </Box>)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.label} error={props.error} />}
      />
  );
}
