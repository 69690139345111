import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

const getReportList = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/report/list", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function PermissionReportSelect(props) {
  let id = 'report-select';
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [report, setReport] = React.useState('');

  React.useEffect(() => {
    getReportList()
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.response);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: There was an issue loading the compnent</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }else if (items.length === 0){
    return <div>Error: There was an issue loading the compnent</div>;
  } else {

    const handleChange = (event) => {
      setReport(event.target.value);
      let value = event.target.value.value
      if(value === 'select')
        value = null
      props.callback(
        value,
        event.target.value.label,
        
      );
    };

    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={id}
            select
            label="Select Report"
            value={report}
            onChange={handleChange}
            className=""
          >
            {
            Object.entries(items).map((option,k) => ( 
              <MenuItem key={option[1].value} value={option[1]}>
                {option[1].label}
              </MenuItem>
            ))
          }
          </TextField>
        </div>
      </Box>
    );
  }
}
