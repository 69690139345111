import * as React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormControl from '@mui/material/FormControl';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Cookies from 'universal-cookie';

const fetchEvents = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    type: ''
  };

  return fetch(cookies.get('apiUrl') + '/events/get', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

// Calculates number of days between two Date type objects given start and end dates
const calculateDays = (start, end) => {
  const msPerDay = 86400000;
  return ((new Date(end)) - (new Date(start))) / msPerDay;
}

/**
 * This function converts a Date object to a 'mm/dd/yyyy' string which results in data receiving the date of the current day opposed to the previous from using toISOString().split('T')[0] which returns 'yyyy-mm-dd'.
 */
const convertDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Keep month and day 2 digits
  const formattedMonth = String(month).padStart(2, '0');
  const formattedDay = String(day).padStart(2, '0');

  // 'mm/dd/yyyy' string is passed back
  return `${formattedMonth}/${formattedDay}/${year}`;
}

const fetchRegistry = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
  };

  return fetch(cookies.get('apiUrl') + '/events/registery', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const updateEvent = (id, data) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    data: data,
  };

  return fetch(cookies.get('apiUrl') + '/events/change', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function HistoricalEventsButton(props) {
  const [data, setData] = React.useState({
    name: '',
    type: '',
    parentEventFlag: false,
    parentEventId: null,
    historicalEventFlag: false,
    click: false,
    start: '',
    end: ''
  });
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [events, setEvents] = React.useState({ data: [] });
  const [refresh, setRefresh] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmActivate, setConfirmActivate] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [activateEvent, setActivateEvent] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [newEndDate, setNewEndDate] = React.useState(new Date());

  // Upon refresh, update the event data for table
  React.useEffect(() => {
    setIsLoaded(false);
    fetchEvents()
      .then(res => res.json())
      .then((result) => {
        result.response = result.response.filter(item => item.historical_event_flag);
        // We're only looking at the historical events
        result.response.map((event) => {
          fetchRegistry(event.id)
            .then(res => res.json())
            .then((result) => {
              setIsLoaded(false);
              event.total_participants = result.response.length;
              // Causes rerender in datagrid for tot participants to appear
              setIsLoaded(true);
            });

          event.event_duration = calculateDays(event.start, event.end);
        });
        setEvents({ data: result.response });
        setIsLoaded(true);
      }, (error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [props.refresh, refresh]);

  // Perform activation of event upon confirmation
  React.useEffect(() => {
    if (activateEvent !== false && confirmActivate) {
      data.historicalEventFlag = false;
      data.end = convertDate(newEndDate);
      updateEvent(activateEvent, data)
        .then(res => res.json())
        .then((result) => {
          setActivateEvent(false);
          setConfirmActivate(false);
          setRefresh(!refresh);
          // Update event details for callback
          selectedEvent.historical_event_flag = false;
          selectedEvent.end = convertDate(newEndDate);
          props.callback(selectedEvent);
        }, (error) => { });
    }
  }, [activateEvent, confirmActivate, refresh]);

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
    },
    // Display event type column in event manager
    {
      headerName: 'Type',
      field: 'type',
      width: 100,
    },
    {
      headerName: 'Total Participants',
      field: 'total_participants',
      width: 150,
    },
    {
      headerName: 'Event Duration',
      field: 'event_duration',
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.value} days
          </div>
        )
      }
    },
    {
      headerName: 'Activate',
      field: 'id',
      renderCell: (params) => {
        return (
          <Button
            type="contained"
            onClick={(e) => {
              // Set data from event needed for api call
              setActivateEvent(params.id);
              setSelectedEvent(params.row);
              // historical flag unset in data to re-activate
              setData({
                name: params.row.name,
                type: params.row.type,
                parentEventFlag: params.row.parent_event_flag,
                parentEventId: params.row.parent_event_id,
                click: true,
                start: params.row.start,
              });
              setConfirmOpen(true);
            }}
          >
            Activate
          </Button>
        )
      },
      width: 80,
      filterable: false,
    },
  ];

  const handleClick = (event) => {
    // We need current data upon opening the modal
    setRefresh(!refresh);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="Contained" onClick={handleClick}>Historical</Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h5" component="h4" paddingLeft={3}>Historical Events</Typography>
          </Grid>
        </Grid>
        {error && <ErrorIcon color="danger" />}
        {(!isLoaded && events.data.length !== 0) && <CircularProgress color="warning" />}
        {isLoaded &&
          <div style={{ height: 600, width: '100%' }}>
            < DataGridPro
              rows={events.data}
              columns={columns}
              id='search-results'
              density={'compact'}
              filter={true}
              onEditCommit={(values, event) => { }}
            />
          </div>
        }
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => { setConfirmOpen(false) }}>
        <DialogTitle>Activate Event?</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Grid container paddingTop={1}>
              <FormControl fullWidth variant="filled">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="New End Date"
                    value={newEndDate}
                    onChange={(e) => {
                      // New end date should be greater than current date
                      if (e > new Date()) {
                        setNewEndDate(e);
                      }
                      else {
                        // revert date to old end date otherwise
                        setNewEndDate(new Date(selectedEvent.end));
                      };
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={() => { setConfirmOpen(false) }}>Cancel</Button>
          <Button type="contained" onClick={() => {
            // update parent component with most recent historical value
            selectedEvent.historical_event_flag = false;
            props.callback(selectedEvent);
            setConfirmActivate(true);
            setConfirmOpen(false);
          }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}