import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';

export default function AddSettingsButton(props) {
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState(false)

  let disable = false
  if(props.disableSave === true)
  {
    disable = true
  }

  if(props.width && props.width !== width)
  {
    setWidth(props.width)
  }

  const [disableSave, setDisableSave] = React.useState(disable);
  const size = (props.size)?props.size:'small'

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        {!props.icon && <AddIcon fontSize={size}/>}
        {props.icon && props.icon}
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={width}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button 
            disable={disableSave}
            onClick={(e) => {
              props.handleSave(e)
              handleClose()
            }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
