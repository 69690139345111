import * as React from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';
import PasswordComponent from '../../../login-layout/components/sub-components/component-password';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

const changePassword = (oldPw, newPw) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    oldPw:oldPw,
    newPw:newPw,
  }

  return fetch(cookies.get('apiUrl')+"/user/change/password", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function PasswordReset(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [ changeClick, setChangeClick] = React.useState(false)

  React.useEffect(() => {
    if(changeClick)
    {
      changePassword(oldPassword,newPassword).then(res => res.json())
        .then(
          (result) => {
            setOldPassword('')
            setNewPassword('')
            setConfirmPassword('')
            setChangeClick(false)
            setOpen(false)
          },
          (error) => {
          }
        );
      }
  }, [oldPassword, newPassword, changeClick]);

  const handleButtonClick = (e) => {
    setChangeClick(true)
  }

  const handlePassword = (type,value) => {
    if(type === 'new')
    {
      setNewPassword(value)
    }
    else 
    {
      setConfirmPassword(value)
    }

    
  }

  return (<>
    <MenuItem onClick={handleOpen}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
      >
        <Badge>
          <AccountCircle />
        </Badge>
      </IconButton>
      {props.itemTitle}
    </MenuItem>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Grid 
          container    
          justify="center"
        >
          <Grid item xs={12} align="center">
            <PasswordComponent
              password={oldPassword}
              label={'Old Password'}
              onChange={(e) => setOldPassword(e.target.value)}
              type={'new-password'}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <PasswordComponent
              label={'New Password'}
              password={newPassword}
              onChange={(e) => handlePassword('new',e.target.value)}
              type={'new-password'}
              />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="contained" onClick={handleButtonClick}>Save</Button>
        <Button type="contained" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
    </>)
}
