import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Badge from '@mui/material/Badge';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import ComponentSelectInput from './core-components/component-select-input';
import Grid from '@mui/material/Grid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

const components = {
  report: {
    name: 'report',
    proper: 'Report Component',
    xs: 12,
    sm: 10,
    md: 8,
    lg: 4,
    xl: 4
    },
  event: {
    name: 'event',
    proper: 'Event Management',
    xs: 12,
    sm: 10,
    md: 8,
    lg: 4,
    xl: 4
  },
  new_event: {
    name: 'new-event',
    proper: 'New Event Component',
    xs: 12,
    sm: 10,
    md: 8,
    lg: 4,
    xl: 4
  },
  add_entity: {
    name: 'add-entity',
    proper: 'Add Entity Component',
    xs: 12,
    sm: 12,
    md: 4,
    lg: 2,
    xl: 2
  },
  parcel: {
    name: 'parcel',
    proper: 'Parcel Search Component',
    xs: 12,
    sm: 10,
    md: 8,
    lg: 4,
    xl: 4
    },
  trailManagment: {
    name: 'trail',
    proper: 'Trail Management Component',
    xs: 12,
    sm: 12,
    md: 10,
    lg: 6,
    xl: 6
  }
}


export default function AddComponentButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [componentList, setComponentList] = React.useState({})
  const [selectedComponent, setSelectedComponent] = React.useState([])
  const [pending, setPending] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [load, setLoad] = React.useState(false)

  const cookies = new Cookies();
  const handleButtonClick = (e) => {
    e.preventDefault();
    const cookies = new Cookies();

    let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
    let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
    
    const request = { 
      user: user, 
      token: token,
      component: selectedComponent
     }

    setPending(true);

    fetch(cookies.get('apiUrl')+"/user/add/component", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then((result) => {
        setPending(true);
        window.location.reload();
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setPending(true);
        setError(error);
      });
  }

  React.useEffect(() => {
    if(open)
    {
      if(cookies.get('permission') === 'null' && componentList !== components)
      {
        setComponentList(components)
      }
      else if(cookies.get('permission') !== 'null' && !load)
      {
        var permission = cookies.get('permission')
        permission.forEach(element => {
          element.permission.components.forEach(component => {
            for (var key of Object.keys(components))
            {
              if(components[key].name === component.name)
              {
                componentList[key]= components[key]
              }
            }
          })
        });
        setLoad(true)
        setComponentList({...componentList})
      }
    }
  },[open, load, componentList, components])

  return ( <>
    <MenuItem onClick={handleOpen}>
      <IconButton size="large" color="inherit">
        <Badge>
          <AddBoxIcon />
        </Badge>
      </IconButton>
      {props.itemTitle}
    </MenuItem>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Component</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ComponentSelectInput 
              components={componentList}
              onChange={(e) => setSelectedComponent(e.target.value)}  
            />  
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton 
          size="large" 
          color="inherit"
          onClick={handleButtonClick}
          >
          {!pending && <IconButton size="large" color="inherit" type="submit">
            <Badge>
              {error ? <DoNotDisturbAltIcon/> : <SaveAltIcon />}
            </Badge>
          </IconButton>}
          {pending && 
            <Badge>
              {error ? <DoNotDisturbAltIcon/> : <CircularProgress />}
            </Badge>
          }
        </IconButton>
      </DialogActions>
    </Dialog>
    </>
  )
}

