import * as React from 'react';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Cookies from 'universal-cookie';
import Stack from '@mui/material/Stack';
import {DataGridPro} from '@mui/x-data-grid-pro';
import BugReportIcon from '@mui/icons-material/BugReport';
import DeveloperTaskForm from './developer-task-form';
import AddDevTask from './add-dev-task';
import EditDeveloperTaskForm from './edit-developer-task-form';
import EditDevTask from './edit-dev-task';

const addDevTask = (task) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    summary:task.name,
    description:task.description,
    type:task.type
  }

  return fetch(cookies.get('apiUrl')+"/jira/add/task", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const editDevTask = (task) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    summary:task.name,
    description:task.description,
    type:task.type
  }

  return fetch(cookies.get('apiUrl')+"/jira/update/task/"+task.id, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteDevTask = (task) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/jira/delete/task/"+task.id, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const getTasks = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/jira/task/list", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function DeveloperTasks(props) {
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const handleOpen = () => {
    setRefresh(!refresh)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const [newTask, setNewTask] = React.useState({
    name: '',
    description: '',
    type: '',
    click: false
  });
  
  const [existingTask, setExistingTask] = React.useState({
    name: '',
    description: '',
    type: '',
    click: false
  });

  const [deleteTask, setDeleteTask] = React.useState({
    id: '',
    click: false
  });

  const  columns = 
  [
    {
      headerName:'Actions',
      field:'target',
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <EditDevTask 
              title={params.id}
              handleSave={()=>{
                existingTask.id = params.id
                existingTask.click = true
                setExistingTask({...existingTask})
              }}
              handleDelete={()=>{
                setDeleteTask({
                  id:params.id,
                  click:true
                })
              }}
            >
              <EditDeveloperTaskForm
                data={params.row}
                onChange={(type, name, description) =>{
                  existingTask.type = type
                  existingTask.name = name
                  existingTask.description = description
                }}
              />  
            </EditDevTask>
          </Stack>
        )
      },
      width: 70,
      filterable:false,
    },
    {
      field: "id",
      headerName: "Task Id",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 280,
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
    },
    {
      field: "dateSubmitted",
      headerName: "Date Submitted",
      valueGetter: (params) => new Date(params.row.dateSubmitted),
      width: 120,
      type:'date',
    },
    {
      field: "dateUpdated",
      headerName: "Date Updated",
      valueGetter: (params) => new Date(params.row.dateUpdated),
      width: 110,
      type:'date',
    },
  ]
  

  React.useEffect(() => {
    if(newTask.click)
    {
      addDevTask(newTask).then(res => res.json())
        .then(
          (result) => {
            newTask.click = false
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [newTask, refresh]);

  React.useEffect(() => {
    if(existingTask.click)
    {
      editDevTask(existingTask).then(res => res.json())
        .then(
          (result) => {
            existingTask.click = false
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [existingTask, refresh]);

  React.useEffect(() => {
    if(deleteTask.click)
    {
      deleteDevTask(deleteTask).then(res => res.json())
        .then(
          (result) => {
            deleteTask.click = false
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [deleteTask, refresh]);

  React.useEffect(() => {
    getTasks().then(res => res.json())
      .then(
        (result) => {
          setRows(result.response)
        },
        (error) => {
        }
      );
  }, [refresh]);

  return (
  <>
    <MenuItem onClick={handleOpen}>
      <IconButton
        size="large"
        aria-label="Help"
        aria-haspopup="true"
        color="inherit"
      >
        <Badge
          color="secondary"
        >
          <BugReportIcon />
        </Badge>
      </IconButton>
      {props.itemTitle}
    </MenuItem>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <Paper sx={{p:1}}>
        <Grid container spacing={1} >
          <Grid item xs={11}>
            <Typography variant="h4" component="h4" >Developer Tasks</Typography>
          </Grid>
          <Grid item xs={1}>
            <AddDevTask
              title="New Developer Task"
              handleSave={(e)=>{
                newTask.click = true
                setNewTask({...newTask})
              }}
            >
              <DeveloperTaskForm onChange={(type, name, description) => {
                newTask.name = name
                newTask.type = type
                newTask.description = description
              }}/>
            </AddDevTask>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 400, width: '99%' }}>
              <DataGridPro
                rows={rows}
                columns={columns}
                density="compact"
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </>)
}
