import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider,createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@material-ui/core/Container';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PasswordComponent from './sub-components/component-password';
import CircularProgress from '@mui/material/CircularProgress';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Cookies from 'universal-cookie';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import SecurePasswordReset from './secure-password-reset';

const addCopmonentModalRoot =makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 4),
  },
}));

const addComponentModalStyle = {
  borderRadius: 5,
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs:'50%',md:'25%'},
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

const login = (username, password) => {
  const cookies = new Cookies();
  const creds = { username: username, password: password }
  return fetch(cookies.get('apiUrl')+"/api/login", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(creds)
  })
}

export default function LoginModal(props) {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [pending, setPending] = React.useState(false)
  const [loginClick, setLoginClick] = React.useState(false)
  const [error, setError] = React.useState(false)
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const cookies = new Cookies();
    if(loginClick)
    {
      setPending(true);
      login(username, password)
      .then(res => res.json())
      .then((result) => {
        if(!result.hasOwnProperty('error'))
        {
          setLoginClick(false)
          setPending(false);
          cookies.set('user', result.user)
          cookies.set('credToken', result.token)
          window.location.reload();
        }
        else
        {
          setLoginClick(false)
          setPending(false);
          setError(result.error)
        }
      },
      (error) => {
        setPending(true);
        setError(error);
        setLoginClick(false)
      });
    }
  },[username, password, loginClick])


  const handleButtonClick = (e) => {
    e.preventDefault();
    setLoginClick(true)
    
  }

  const modal_view = ( 
    <div>
      <ThemeProvider theme={theme}>
        <Button color="neutral" variant="contained" onClick={handleOpen}>
          Login
        </Button>
        </ThemeProvider>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-component-modal-title"
        aria-describedby="add-component-modal-description"
      >
        <Box sx={addComponentModalStyle}>
          <form onSubmit={handleButtonClick}>
            <Container component="section" maxWidth="sm">
              <Stack direction="column" justifyContent="center"  alignItems="center" padding="2%">
                <div align-content="center">
                  <Typography id="add-component-modal-title" variant="h5" component="h2">
                    Login
                  </Typography>
                </div>
                <div align-content="center">
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <TextField 
                      required
                      id="username" 
                      label="Username"
                      variant="standard"
                      value={username}
                      onChange={(e) => {
                        setError(null)
                        setUsername(e.target.value)}
                      } />
                    </FormControl>
                </div>
                <div align-content="center">
                  <PasswordComponent
                    value={password}
                    label="Password"
                    onChange={(e) => {
                      setError(null)
                      setPassword(e.target.value)
                    }}/>
                </div>
                {error &&
                  <div align-content="center">
                    <Alert severity="error">{error}</Alert>
                  </div>
                }
              </Stack>
              <Grid container>
                <Grid item sm={7} xs={1}></Grid>
                <Grid item sm={5} xs={11}>
                  <SecurePasswordReset/>
                </Grid>
                <Grid item sm={7} xs={1}></Grid>
                <Grid item sm={5} xs={11}>
                  {!pending && 
                    <Button size="large" color="inherit" type="submit">
                      {error ? 
                        <Badge>
                          <DoNotDisturbAltIcon/> 
                        </Badge> : 
                        <Typography>Login</Typography>
                      }
                    </Button>
                  }
                  {pending && 
                    <Badge>
                      {error ? <DoNotDisturbAltIcon/> : <CircularProgress />}
                    </Badge>
                  }
                </Grid>
              </Grid>
            </Container>          
          </form>
        </Box>
      </Modal>
    </div>
  )
    

  if(props.view == 'Mobile')
  {
    return (
      <MenuItem>
        {modal_view}
      </MenuItem>
    )
  }
  return modal_view
}
