import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import EntitySearchInput from '../../core-components/entity-details/inputs/entity-search-input';

const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function AddDonationButton(props) {
  const id = props.entityId;
  const {tPackage, type } = props;

  const [isSoft, setIsSoft] = React.useState(false);
  const [transStatus, setTransStatus] = React.useState('Sending donation...');

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState({
    id:new Date().getTime(),
    anonymous:false,
    payment: '', 
    account:'', 
    receiptDate: new Date(),
    memo:'',
    paidBy:'',
    contrib:0,
    soft:'',
    description:'',
    apply:'No',
    recognition:null,
    discount:-1,
    type: 'donation'
  });

  if(tPackage)
  {
    transaction.apply = 'Yes'
  }

  const [inputErrors, setInputErrors] = React.useState({
    payment:{error:false, message:'Cannot be empty and must be a number value'},  
    paidBy:{error:false, message:'Please select a payment method!'},
    description:{error:false, message:'Please select a solicitation!'},
    account:{error:false, message:'Please select an account!'},
    recognition_name:{error:false}
  });

  const [recognition, setRecognition] = React.useState({
    send:'No',
    type:'In Honor',
    target:null
  });

  const [pass, setPass] = React.useState({
    click:false,
    data: {}
  });

  
  const handleChange = (prop) => (event) => {
    setTransaction({ ...transaction, [prop]: event.target.value });
  };

  const handleRecognitionChange = (prop) => (event) => {
    setRecognition({ ...recognition, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDate = (value) => {
    transaction.receiptDate = value;
    setTransaction({...transaction})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRecognitionClose = () => {
    setConfirmOpen(false);
  };

  const handleRecognitionOpen = () => {
    let check = true
    if(transaction.account === '')
    {
      check = false
      inputErrors.account.error = true
    }
    else inputErrors.account.error = false

    if(transaction.description === '') 
    {
      check = false
      inputErrors.description.error = true
    }
    else inputErrors.description.error = false

    if(transaction.payment === '' || !isInt(transaction.payment)) 
    {
      check = false
      inputErrors.payment.error = true
    }
    else inputErrors.payment.error = false

    if(transaction.paidBy === '')
    {
      check = false
      inputErrors.paidBy.error = true
    }
    else inputErrors.paidBy.error = false

    setInputErrors({...inputErrors})
    if(check) setConfirmOpen(true);
  };

  const handleRecognitionSave = () => {
    let check = true

    if(recognition.target === null)
    {
      check=false
      inputErrors.recognition_name.error = true
    } 
    else inputErrors.recognition_name.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      transaction.recognition = recognition
      pass.data = transaction
      setPass({...pass})
      props.onSave(pass.data)    
      setOpen(false);
      setTransaction({
        id:new Date().getTime(),
        anonymous:false,
        payment: '', 
        account:'', 
        receiptDate: new Date(),
        memo:'',
        paidBy:'',
        contrib:0,
        soft:false,
        description:'',
        apply:'No',
        recognition:null,
        discount:-1,
        type: 'donation'
      })
    }
  };

  const handleSoft = (event) => {
    setIsSoft(event.target.value);
  };

  const handleSave = () => {
    let check = true

    if(transaction.account === '')
    {
      check = false
      inputErrors.account.error = true
    }
    else inputErrors.account.error = false

    if(transaction.description === '') 
    {
      check = false
      inputErrors.description.error = true
    }
    else inputErrors.description.error = false

    if(transaction.payment === '' || !isInt(transaction.payment)) 
    {
      check = false
      inputErrors.payment.error = true
    }
    else inputErrors.payment.error = false

    if(transaction.paidBy === '')
    {
      check = false
      inputErrors.paidBy.error = true
    }
    else inputErrors.paidBy.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      pass.data = transaction
      setPass({...pass})    
      props.onSave(pass.data)
      setOpen(false);
      setTransaction({
        id:new Date().getTime(),
        anonymous:false,
        payment: '', 
        account:'', 
        receiptDate: new Date(),
        memo:'',
        paidBy:'',
        contrib:0,
        soft:false,
        description:'',
        apply:'No',
        recognition:null,
        discount:-1,
        type: 'donation'
      })
    }
    
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Add Donation</MenuItem>
      <Dialog open={open} fullWidth>
        <DialogTitle>Donation</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>         
          <Stack spacing={2} >
            { tPackage.contributors.length > 0 &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Contributor</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.contrib}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                  
                >
                  <MenuItem value={0}>None</MenuItem>
                  {tPackage.contributors.map((t) => <MenuItem key={"cccsd"+t.id} value={t.id}>{t.firstName + " " + t.lastName}</MenuItem>)}
                </Select>
              </FormControl>
            }
            {  tPackage.apply &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Apply To Family</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.apply}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                >
                  <MenuItem value={0}>Yes</MenuItem>
                  <MenuItem value={1}>No</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl fullWidth>
              <InputLabel id="anon-select-label">Anonymous</InputLabel>
              <Select
                labelId="anon-select-label"
                id="anon-select"
                value={transaction.anonymous}
                label="Anonymous"
                onChange={handleChange('anonymous')}
                required={true}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Account</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.account}
                label="Account"
                onChange={handleChange('account')}
                error={inputErrors.account.error}
              >
               {tPackage.accounts.map((t)=> {
                  if(t.type === 'Donation')
                    return <MenuItem key={"cccsw"+t.id} value={t.id}>{t.name}</MenuItem>})}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Solicitation</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.description}
                label="description"
                onChange={handleChange('description')}
                error={inputErrors.description.error}
              >
              {tPackage.descriptions.map((t)=> {
                  if(t.type === 'Donation')
                    return <MenuItem key={"cccsw"+t.id} value={t.name}>{t.name}</MenuItem>})}
              </Select>
            </FormControl>
            <Stack direction="row">
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-payment">Payment</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-payment"
                  value={transaction.payment}
                  onChange={handleChange('payment')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Payment"
                  error={inputErrors.payment.error}
                />
              </FormControl>
              <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Payment Method</InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={transaction.paidBy}
                  label="Payment Method"
                  onChange={handleChange('paidBy')}
                  error={inputErrors.paidBy.error}
                >
                  <MenuItem value="Cash or Check">Cash/Check</MenuItem>
                  <MenuItem value="Credit/Debit Card">Credit/Debit Card</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Stripe">Stripe</MenuItem>
                  <MenuItem value="In-Kind">In-Kind</MenuItem>
                  <MenuItem value="Eventbright">Eventbrite</MenuItem>
                  <MenuItem value="Direct Deposit">Direct Deposit</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Receipt Date"
                  openTo="month"
                  views={['month', 'day', 'year']}
                  value={transaction.receiptDate}
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                  required={true}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Memo"
                multiline
                maxRows={4}
                value={transaction.memo}
                onChange={handleChange('memo')}
              />
            </FormControl>
            { props.type !== 'Person' &&
              <Stack direction="row">
                <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                  <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Soft Credit</InputLabel>
                  <Select
                    labelId="method-select-label"
                    id="method-select"
                    value={isSoft}
                    label="Soft Credit"
                    onChange={handleSoft}
                    required
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
                <EntitySearchInput 
                  type='entity'
                  onChange={(value) => (transaction.soft = value)}
                  label="Search"
                  />
              </Stack>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleRecognitionOpen}>Recognition</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleRecognitionClose}>
        <DialogTitle>Recognition</DialogTitle>
        <DialogContent>
          <Stack pt={1} spacing={1}>
            <Select
              labelId="method-select-label"
              id="method-select"
              value={recognition.send}
              label="Send Response"
              onChange={handleRecognitionChange('send')}
              required={true}
            >
              <MenuItem value='Yes'>Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
            <Select
              labelId="method-select-label"
              id="method-select"
              value={recognition.type}
              onChange={handleRecognitionChange('type')}
              required={true}
            >
              <MenuItem value='In Honor'>In Honor</MenuItem>
              <MenuItem value="In Memory">In Memory</MenuItem>
            </Select>
            <EntitySearchInput 
              type='person'
              onChange={(value) => (recognition.target = value)}
              label="Entity Search"
              error={inputErrors.recognition_name.error}
              />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRecognitionClose}>Close</Button>
          <Button onClick={(e) => {
              handleRecognitionSave()
            }}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loadingOpen}>
        <DialogTitle>{transStatus}</DialogTitle>
        <DialogActions>
          <Button onClick={(e) => {
              setLoadingOpen(false)
              setConfirmOpen(false)
              setOpen(false)
              setTransaction({
                id:new Date().getTime(),
                anonymous:false,
                payment: '', 
                account:'', 
                receiptDate: new Date(),
                memo:'',
                paidBy:'',
                contrib:0,
                soft:false,
                description:'',
                apply:'No',
                recognition:null,
                discount:-1
              })
            }}>okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
