import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

export default function SectionSelect(props) {
  const [region, setRegion] = React.useState('');
  const [sections, setSections] = React.useState({})
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [sectionId, setSectionId] = React.useState(-1);
  const [refresh, setRefresh] = React.useState(props.refresh)

  React.useEffect(() => {
    const cookies = new Cookies();

    let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
    let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
    let id = (region !== -1)?region.id:'';
    const request = { 
      user: user, 
      token: token,
      id: id
     }

    fetch(cookies.get('apiUrl')+"/trail/sections", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then((result) => {
        setSections(result.response)
        setIsLoaded(true);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      });
    }, [region, refresh])

  
  if(region.id !== props.region.id)
  {
    setRegion(props.region) 
    setSectionId(-1)
  }

  if(refresh !== props.refresh)
  {
    setRefresh(!refresh)
  }
  
  if (error) {
    return <div>Error: There was an issue loading the compnent</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

    const handleChange = (event) => {
      setSectionId(event.target.value);
      props.callback(event.target.value);
    };

    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={'section-select'}
            select
            label="Select Section"
            value={sectionId}
            onChange={handleChange}
            className=""
          >
            <MenuItem key="tmt-section" value={-1}>
              Select Section
            </MenuItem>
            {
            sections.map((option) => ( 
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))
          }
          </TextField>
        </div>
      </Box>
    );
  }
}
