import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AttributeChip from '../inputs/component-attribute-chip';
import Divider from '@mui/material/Divider';
import AddDetailButton from '../inputs/component-add-detail-button';
import EventChip from '../inputs/component-event-chip';
import MixedSearchInput from '../inputs/mixed-search-input';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Cookies from 'universal-cookie';

const addAward = (id, name) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    attribute: name,
    type: 'awards'
  };

  return fetch(cookies.get('apiUrl') + "/detail/attribute/add", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const registerForEvent = (id, reg) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    attribute: reg.date.toISOString().split('T')[0],
    name: reg.name
  };

  return fetch(cookies.get('apiUrl') + "/events/register", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function EventDetails(props) {
  let setEvents = [];
  let setAwards = [];
  if (props.data !== undefined) {
    setEvents = (props.data.events === undefined) ? [] : props.data.events;
    setAwards = (props.data.awards === undefined) ? [] : props.data.awards;
  }

  const handleDateJoined = (date) => {
    newEvent.date = date
  };

  const [events, setEvent] = React.useState(setEvents);
  const [awards, setAward] = React.useState(setAwards);
  const [newEvent, setNewEvent] = React.useState({
    name: '',
    date: new Date(),
    click: false
  });
  const [newAward, setNewAward] = React.useState({
    name: '',
    click: false
  });

  React.useEffect(() => {
    if (newAward.click) {
      addAward(props.entityId, newAward.name)
        .then(res => res.json())
        .then((result) => {
          props.refresh();
        });
    }
  }, [props, newAward]);

  React.useEffect(() => {
    if (newEvent.click) {
      registerForEvent(props.entityId, newEvent)
        .then(res => res.json())
        .then((result) => {
          props.refresh()
        });
    }
  }, [props, newEvent]);

  const cleanAttrib = (id) => {
    props.refresh();
  }

  return (
    <form>
      <Box sx={{ minWidth: 120 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={8} >
                <Typography styles={{ paddingTop: '2%' }}>
                  Events
                </Typography>
              </Grid>
              <Grid item xs={4} >
                <AddDetailButton
                  title="Register for event"
                  handleSave={(e) => {
                    newEvent.click = !newEvent.click;
                    setNewEvent({ ...newEvent });
                  }}>
                  <Stack spacing={1}>
                    <MixedSearchInput
                      label={'Search'}
                      type={'event'}
                      onChange={(value) => { newEvent.name = value }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Date Registered"
                        inputFormat="MM/dd/yyyy"
                        value={newEvent.date}
                        onChange={(handleDateJoined)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider />
            <Stack spacing={1}>
              {events.map(element => (<EventChip
                key={'e-' + element.id}
                data={element}
                entity={props.entityId}
                email={props.entity.emailAddress}
                onClear={(id) => cleanAttrib(id)}
                refresh={() => { props.refresh() }}
              ></EventChip>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={8} >
                <Typography sx={{ paddingTop: '2%' }}>
                  Awards
                </Typography>
              </Grid>
              <Grid item xs={4} >
                <AddDetailButton
                  title="Add award"
                  handleSave={(e) => {
                    newAward.click = !newAward.click;
                    setNewAward({ ...newAward });
                  }}>
                  <MixedSearchInput
                    label={'Search'}
                    type={'award'}
                    onChange={(value) => { newAward.name = value }}
                  />
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider />
            <Stack spacing={1}>
              {awards.map(element => (<AttributeChip
                key={'a-' + element.id}
                data={element}
                type='award'
                entity={props.entityId}
                onClear={(id) => cleanAttrib(id)}
                onChange={(data) => {
                  element = data;
                }}
              />))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
