import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton  from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import PermissionReportSelect from './permission-report-select';
import ReportChip from './report-chip';

const components = [
  {
    name: 'report',
    proper: 'Report Component',
  },
  {
    name: 'event',
    proper: 'Event Management',
  },
  {
    name: 'add-entity',
    proper: 'Add Entity Component',
  },
  {
    name: 'parcel',
    proper: 'Parcel Search Component',
    },
  {
    name: 'trail',
    proper: 'Trail Management Component',
  },
  {
    name: 'settings',
    proper: 'Administration',
  },
  {
    name: 'search',
    proper: 'Search Bar',
  },
  {
    name: 'details',
    proper: 'Details Component',
  }
]


export default function PermissionBreakdown(props) {
  const [newComponent, setNewComponent] = React.useState('')
  const [newReport, setNewReport] = React.useState('')
  const [permissionData, setPermissionData] = React.useState(props.data);

  let list = [];
  let temp_hasReports = false
  let r_list = []

  const handleChange = (prop) => (event) => {
    setPermissionData({ ...permissionData, [prop]: event.target.value });
    props.handleChange(prop,event.target.value)
  };

  if(props.data.hasOwnProperty('permission'))
  {
    if(props.data.permission.hasOwnProperty('components'))
    {
      list = props.data.permission.components
      list.forEach((element) => {
        if(!temp_hasReports)
        {
          temp_hasReports = (element.name === 'report' )
        }
      })
    }
    if(props.data.permission.hasOwnProperty('reports'))
    {
      r_list = props.data.permission.reports
    }
    if(props.data.permission.hasOwnProperty('restriction') &&  permissionData.restriction !== props.data.permission.restriction)
    {
      permissionData.restriction = props.data.permission.restriction
    }
  }

  const [hasReportComponent, setHasReportComponent] = React.useState(temp_hasReports)
  const [componentList, setComponentList] = React.useState(list);
  const [reportList, setReportList] = React.useState(r_list);

  React.useEffect(() => {
    if(componentList.length > 0 && permissionData.components !== componentList)
    {
      setPermissionData({ ...permissionData, ['components']: componentList});
      props.handleChange('components',componentList)
    }
  },[props, componentList, permissionData])

  React.useEffect(() => {
    if(reportList.length > 0 && permissionData.reports !== reportList)
    {
      setPermissionData({ ...permissionData, ['reports']: reportList});
      props.handleChange('reports',reportList)
    }
  },[props, reportList, permissionData])

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={1}>
        <TextField id="name" label="Permission Name" value={permissionData.name} onChange={handleChange('name')}/>
        <TextField
          id="outlined-select-currency"
          select
          label="User Type"
          value={permissionData.token_ttl}
          onChange={handleChange('token_ttl')}
        >
          <MenuItem key={'standard'} value={86400}>
            Human User
          </MenuItem>
          <MenuItem key={'data-api'} value={10}>
            Data API User
          </MenuItem>
        </TextField>
        <TextField
            id="outlined-select-restriction"
            select
            defaultValue='none'
            label="Data restirctions"
            value={permissionData.restriction}
            onChange={handleChange('restriction')}
        >
          <MenuItem key={'none'} value={'none'}>
            None
          </MenuItem>
          <MenuItem key={'region'} value={'region'}>
            Regions
          </MenuItem>
          <MenuItem key={'events'} value={'events'}>
            Events
          </MenuItem>
        </TextField>
        <Grid container>
          <Grid item xs={9}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Box
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '95%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Component"
                      value={newComponent}
                      onChange={(e) => setNewComponent(e.target.value)}
                    >
                      {components.map((option) => (
                        <MenuItem key={option.name} value={option}>
                          {option.proper}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item={3}>
            <IconButton  sx={{ml:2, mt:2}} onClick={() => {
              let contains = false
              let hasReports = false
              componentList.forEach(element =>{
                if(element.name === newComponent.name)
                {
                  contains = true
                }
              })

              if(newComponent.name === 'report')
              {
                hasReports = true
              }

              setHasReportComponent(hasReports)

              if(!contains)
              {
                setComponentList([...componentList,newComponent])
              }
            }}><AddIcon/></IconButton >    
          </Grid>
          <Grid item={12}>
            <Grid container spacing={1}>
              {componentList.map((option) => (
                <Grid item>
                  <Chip 
                    key={'item-'+option.name}
                    label={option.proper}
                    onDelete={()=>{
                      let i = 0
                      let target = -1;
                      let hasReports = false
                      componentList.forEach(element => {
                        if(!hasReports)
                        {
                          if(element.name === 'report')
                          {
                            hasReports = true
                          }
                        }
                        
                        if (element.name === option.name)
                        {
                          target = i
                          if(element.name === 'report')
                          {
                            hasReports = false
                          }
                        }
                        i++
                      });
                      setHasReportComponent(hasReports)
                      componentList.splice(target, 1)
                      setComponentList([...componentList])
                    }}  
                  />    
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {hasReportComponent && <Grid container>
          <Grid item xs={9}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <PermissionReportSelect
                  callback= {(value, label)=>
                  {
                    setNewReport({value: value, label:label})
                  }}/>
              </div>
            </Box>
          </Grid>
          <Grid item={3}>
            <IconButton  sx={{ml:2, mt:2}} onClick={() => {
              let contains = false
              reportList.forEach(element =>{
                if(element.value === newReport.value)
                {
                  contains = true
                }
              })

              if(!contains)
              {
                setReportList([...reportList,newReport])
              }
            }}><AddIcon/></IconButton >    
          </Grid>
          <Grid item={12}>
            <Grid container spacing={1}>
              {reportList.map((report, iter) => { return (
                <Grid item>
                  <ReportChip 
                    label={report.label}
                    value={report.value}
                    templates={report.templates}
                    saveTemplates={(list)=>{
                      reportList[iter] = {...reportList[iter],['templates']:list}
                      setReportList([...reportList])
                    }}
                    onDelete={()=>{
                      let i = 0
                      let target = 0;
                      reportList.forEach(element => {
                        if (element.value === report.value)
                        {
                          target = i
                        }
                        i++
                      });

                      reportList.splice(target, 1)
                      setReportList([...reportList])
                    }}  
                  />    
                </Grid>
              )})}
            </Grid>
          </Grid>
        </Grid>}
      </Stack>
    </Box>
  )
}
