import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

export default function EventOptions(props) {
  const [open, setOpen] = React.useState(false);
  
  // Opens the modal and updates current values if necessary
  const handleClick = () => {
    setOpen(true);
  };

  // Closes modal and ensures most recent state values are being passed back
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="Contained" onClick={handleClick}>
        <MenuIcon fontSize='small'/>
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
