import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountingSettings from './accounting-settings';
import RoleSettings from './role-settings';
import MemberSettings from './member-settings';
import FormsSettings from './forms-settings';
import UserSettings from './user-settings';
import Cookies from 'universal-cookie';

export default function SettinsDrawer(props) {  
  const cookies = new Cookies();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  if( cookies.get('permission') !== 'null') return <div></div> 

  const search_bar = (
      <React.Fragment key={'left'}>  
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2 }}
          onClick={toggleDrawer('left',true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          <Box
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          role="presentation"
          >
            <AccountingSettings/>
            <Divider />
            <FormsSettings/>
            <Divider />
            <MemberSettings/>
            <Divider />
            <RoleSettings/>
            <Divider />
            <UserSettings/>
            <Divider />
        </Box>
      </Drawer>
    </React.Fragment>
  ) 

  

  return search_bar
  
}
