import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Stack from '@mui/material/Stack'
import MailIcon from '@mui/icons-material/Mail';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import Switch from '@mui/material/Switch';
import Cookies from 'universal-cookie';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 2,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


const toggleMailing = (entityId, mailingType, method, toggle) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity: entityId,
    mailingType: mailingType,
    method:method,
    toggle:toggle
  }

  return fetch(cookies.get('apiUrl')+"/detail/toggle/mailing/individual", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const getMailingToggle = (entityId, mailingType) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity: entityId,
    mailingType: mailingType
  }

  return fetch(cookies.get('apiUrl')+"/detail/get/mailing/individual", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MailingTypeChip(props) {
  const [open, setOpen] = React.useState(false);
  const chipTitle = props.data.name
  const entityId = props.entity
  const data = props.data
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<></>)
  const [optMail, setOptMail]   = React.useState(props.data.outMail)
  const [optEmail, setOptEmail] = React.useState(props.data.outEmail)
  const [emailClick, setEmailClick] = React.useState(false)
  const [mailClick, setMailClick] = React.useState(false)
  const cookies = new Cookies()
  const permission = cookies.get('permission') === 'null'
  const handleMail = (event) => {
    if(permission)
    {
      setOptMail(!optMail);
      setMailClick(true)
    }
  };

  const handleEmail = (event) => {
    if(permission)
    {
      setOptEmail(!optEmail);
      setEmailClick(true)
    }
  };

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon style={{paddingRight:"2%"}}/>)
      setOpen(!open)
      setSize(1.5)
    }
  };

  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setOpen(!open)
      setIcon(<></>)
    }
  };

  React.useEffect(() => {
    if(open)
      getMailingToggle(entityId, data.id).then(res => res.json())
        .then(
          (result) => { 
            setOptMail(result.response.mail)
            setOptEmail(result.response.email)
          },
          (error) => {
          }
        );
  }, [open, chipTitle, data]);

  React.useEffect(() => {
    if(emailClick)
      toggleMailing(entityId, data.id, 'email', !optEmail).then(res => res.json())
        .then(
          (result) => { 
            setEmailClick(false)
          },
          (error) => {
          }
        );
  }, [optEmail,emailClick, entityId, data]);

  React.useEffect(() => {
    if(mailClick)
      toggleMailing(entityId, data.id, 'mail', !optMail).then(res => res.json())
        .then(
          (result) => { ''
            setMailClick(false)
          },
          (error) => {
          }
        );
  }, [optMail,mailClick, entityId, data]);


  return (
    <div>
      <CustomChip
          size={size}
          label={(<>
            {!open && <>
              {chipTitle}
            </>}
            {open && <>
              <Stack direction="row" spacing={1} style={{width:'150px'}}>
                <MailIcon/>
                <AntSwitch checked={optEmail} onChange={handleEmail}/>
                <MarkunreadMailboxIcon />
                <AntSwitch checked={optMail} onChange={handleMail}/>
              </Stack>
            </>}
          </>)}
          variant="filled"
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={icon}
      />
    </div>
  );
}
