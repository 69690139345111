import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

import FormControl from '@mui/material/FormControl';

// Input component returns selection bar for parent events
export default function EventParentSelect(props) {
  const [options, setOptions] = React.useState([]);
  const [event, setEvent] = React.useState(props.event);
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasSelected, setHasSelected] = React.useState(false);
  const [parentEventId, setParentEventId] = React.useState(props.parentEventId);
  const disabled = props.disabled;

  // Flag indicates if event property is provided to check for pre existing event
  const hasEvent = (props.event != null);

  React.useEffect(() => {
    const cookies = new Cookies();
    let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
    let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');
    let type = '';
    const request = {
      user: user,
      token: token,
      type: type
    };

    // Fetch events from db and store in options to be selected from
    fetch(cookies.get('apiUrl') + '/events/get', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then((result) => {
        setOptions(result.response);
        setIsLoaded(true);
      }, (error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, []);

  const handleChange = (event) => {
    // State var indicates selection has been made to decide which value is displayed in text field
    setHasSelected(true);

    // Update event to currently selected event object to be displayed in text field
    setEvent(event.target.value);

    // -1 indicates null so return in callback null id
    if (event.target.value === -1) {
      setParentEventId(null);
      props.callback(null);
    }
    // return in callback selected event id otherwise
    else {
      setParentEventId(event.target.value.id);
      props.callback(event.target.value.id);
    }

    // Trigger chang in component
    props.onChange();
  };

  if (error) {
    return <div>Error: There was an issue loading the component</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <FormControl fullWidth variant="filled">
        <TextField
          size="small"
          id={'section-event'}
          select
          label="Select Parent Event"
          disabled={disabled}
          value={
            // Display the current parent event (if available) if a new selection has not been made. Display new selection otherwise
            (!hasSelected &&
              hasEvent &&
              (parentEventId === -1 ? -1 : options.find(option => option.id == parentEventId))) ||
            event
          }
          onChange={handleChange}
          className=""
        >
          <MenuItem key={-1} value={-1}>
            None
          </MenuItem>
          {
            options.map((option) => (
              // Cheeky query for parent events - only include menu item if it's parent flag is set
              option.parent_event_flag === true &&
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))
          }
        </TextField>
      </FormControl>
    );
  }
}
