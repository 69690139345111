import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro'
import AddTmButton from '../input/add-tm-button';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'universal-cookie';
import MixedSearchInput from '../../../core-components/entity-details/inputs/mixed-search-input';

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 80,
  },
  {
    field: "startName",
    headerName: "Start Name",
    width: 200,
   },
  {
   field: "endName",
   headerName: "End Name",
   width: 200,
  },
  {
   field: "startGps",
   headerName: "Start GPS",
   width: 100,
  },
  {
   field: "endGps",
   headerName: "End GPS",
   width: 100,
  },
]

const addSection = (section,region) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    region:region,
    action:'add'
  }

  return fetch(cookies.get('apiUrl')+"/trail/section/region", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const removeSection = (section,region) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    region:region,
    action: 'remove'
  }

  return fetch(cookies.get('apiUrl')+"/trail/section/region", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function SectionView(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [selectionChange, setSelectionChange] = React.useState([])
  const [ newSection, setNewSection ] = React.useState({
    name:props.name,
    click:false
  });

  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    if(newSection.click)
    {
      addSection(newSection.name, newSection.region).then(res => res.json())
      .then(
        (result) => {
          newSection.click = false
          setConfirmOpen(true)
        })
    }
  },[newSection, props])

  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  React.useEffect(() => {
    if(deleteList.click)
    {
      deleteList.list.forEach(element => {      
        removeSection(element, deleteList.region).then(res => res.json())
        .then(
          (result) => {
            deleteList.click = false
            props.refresh()
          })
      })
    }
  },[deleteList, props])

  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <AddTmButton title="Add Section" handleSave={(e) => {
            setNewSection({
              name:newSection.name,
              region:props.id,
              click:true
            })
          }}>
            <Stack spacing={1}>
              <MixedSearchInput
                label={'Search'}
                type={'section'}
                onChange={(value) => {newSection.name = value}}
              />
            </Stack>
          </AddTmButton>
        </Grid>
        <Grid item xs={1}>
          {permission && 
            <IconButton 
              size="small" 
              color="inherit" 
              onClick={() => {
                if(selectionChange.length > 0)
                {
                  setOpen(true)
                }
              }}>
              <RemoveIcon/>
            </IconButton>
          }
        </Grid>
      </Grid>
      <div style={{ display: 'flex', height: '400px' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.data}
            columns={columns}
            density="compact"
            checkboxSelection={permission}
            onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
          />
        </div>
      </div>
      <Dialog open={open} onClose={() => {setOpen(false)}}>
        <DialogTitle>Remove Section(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              region: props.id,
              click:true
            })
            setOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}}>
        <DialogTitle>Section Added!</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {
            setConfirmOpen(false)
            props.refresh()
          }}>Okay</Button>
        </DialogActions>
      </Dialog>            
    </Stack>
  );
}
