import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';


export default function ComponentSelectInput(props) {
  const [component, setComponent] = React.useState('select');
  
  var components = [
    {
      value: 'select',
      label: 'Select',
    },
  ];
  
  for (var key of Object.keys(props.components)) {
    components.push({
      label:props.components[key].proper,
      value:props.components[key]
    })  
  }

  const [componentList, setComponentList] = React.useState(components);

  const handleChange = (event) => {
    props.onChange(event);
    setComponent(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '300px' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-select-currency"
          select
          label="Component"
          value={component}
          onChange={handleChange}
        >
          {componentList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Box>
  );
}