import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Cookies from 'universal-cookie';

export default function AddDetailButton(props) {
  const [open, setOpen] = React.useState(false);
  
  const [saveTitle, setSaveTitle] = React.useState('save');
  if(props.saveTitle !== undefined && props.saveTitle!==saveTitle)
  {
    setSaveTitle(props.saveTitle)
  }


  const size = (props.size !== undefined)?props.size:'small';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const icon = (props.icon !== undefined) ?props.icon:<AddIcon fontSize={size}/>;

  const cookies = new Cookies();
  if(cookies.get('permission') !== 'null')
  {
    return <></>
  }

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        {icon}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box>
            {props.children}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
              props.handleSave(e)
              setOpen(false)
            }}>{saveTitle}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
