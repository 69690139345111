import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import EventRuleChip from '../input/event-rule-chip';
import EventRewardChip from '../input/event-reward-chip';
import AddEmButton from '../input/add-em-button';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import Cookies from 'universal-cookie';
import EventRewardSelect from '../input/event-reward-select';

const addRule = (eventId, condition) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    event: eventId,
    condition: condition.id,
    name: condition.name,
    description: condition.description,
    constraint: condition.constraint,
    roles: condition.roles,
    awards: condition.awards
  };

  return fetch(cookies.get('apiUrl') + '/events/add/condition', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const updateRule = (eventId, condition) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    event: eventId,
    condition: condition.id,
    name: condition.name,
    description: condition.description,
    constraint: condition.constraint,
    roles: condition.roles,
    awards: condition.awards,
    active: condition.active
  };

  return fetch(cookies.get('apiUrl') + '/events/update/condition', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const removeRule = (condition, eventId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    event: eventId,
    condition: condition
  };

  return fetch(cookies.get('apiUrl') + '/events/remove/condition', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const fetchConditions = (eventId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    event: eventId
  };

  return fetch(cookies.get('apiUrl') + '/events/get/conditions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

/**
 * Checks if input is an integer
 * 
 * @param {*} value 
 * @returns true if integer, false otherwise
 */
const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function RuleView(props) {
  const [data, setData] = React.useState([]);
  const [deleteRule, setDeleteRule] = React.useState(false);
  const [changeRule, setChangeRule] = React.useState(false);
  const [newRule, setNewRule] = React.useState(false);
  const [ruleName, setRuleName] = React.useState('');
  const [conditionAmount, setConditionAmount] = React.useState();
  const [description, setDescription] = React.useState('');
  const [tempRule, setTempRule] = React.useState({
    name: '',
    description: '',
    constraint: null,
    roles: [],
    awards: []
  });
  const [refresh, setRefresh] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [awards, setAwards] = React.useState([]);
  const [newRole, setNewRole] = React.useState(false);
  const [tempRole, setTempRole] = React.useState(false);
  const [deleteRole, setDeleteRole] = React.useState(false);
  const [newAward, setNewAward] = React.useState(false);
  const [tempAward, setTempAward] = React.useState(false);
  const [deleteAward, setDeleteAward] = React.useState(false);
  const [inputErrors, setInputErrors] = React.useState({
    amount: { error: false, message: 'Cannot be empty and must be a number value' },
    name: { error: false, message: 'Please enter a name for the rule!' }
  });
  const [error, setError] = React.useState(false);

  /**
   * Reset state to reflect initial values.
   */
  const reset = () => {
    setRuleName('');
    setConditionAmount();
    setDescription('');
    setTempRule({
      name: '',
      description: '',
      constraint: null,
      roles: [],
      awards: []
    });
    setInputErrors({
      amount: { error: false, message: 'Cannot be empty and must be a number value' },
      name: { error: false, message: 'Please enter a name for the rule!' }
    });
  }
  // Update condition list
  React.useEffect(() => {
    fetchConditions(props.event.id)
      .then(res => res.json())
      .then((result) => {
        setData(result.response);
        setRoles(data.roles !== undefined ? result.response.roles.split(',') : []);
        setAwards(data.awards !== undefined ? result.response.awards.split(',') : []);
      });
  }, [props.refresh, props.event, refresh])

  // Create new rule
  React.useEffect(() => {
    if (newRule !== false) {
      addRule(props.event.id, tempRule)
        .then(res => res.json())
        .then((result) => {
          // State should be wiped after new rule for clean slate on input modal
          reset();
          setNewRule(false);
          setRefresh(!refresh);
        });
    }
  }, [tempRule, newRule]);

  // Update selected rule
  React.useEffect(() => {
    if (changeRule !== false) {
      updateRule(props.event.id, tempRule)
        .then(res => res.json())
        .then((result) => {
          // State should be wiped to prevent data leak from update
          reset();
          setChangeRule(false);
          setRefresh(!refresh);
        });
    }
  }, [tempRule, changeRule]);

  // Delete selected rule
  React.useEffect(() => {
    if (deleteRule !== false) {
      removeRule(deleteRule, props.event.id)
        .then(res => res.json())
        .then((result) => {
          setDeleteRule(false);
          setRefresh(!refresh);
        }, (error) => { });
    }
  }, [tempRule, deleteRule]);

  React.useEffect(() => {
    if (newRole !== false) {
      // Add new role to roles if not already in there to prevent duplicates
      if (roles.indexOf(newRole) === -1) {
        roles.push(newRole);
      }
      setNewRole(false);
    }
  }, [data, newRole]);

  React.useEffect(() => {
    if (deleteRole !== false) {
      // Check if target role is in roles to be deleted
      if (roles.indexOf(deleteRole) !== -1) {
        roles.splice(roles.indexOf(deleteRole));
      }
      setDeleteRole(false);
    }
  }, [data, deleteRole]);

  React.useEffect(() => {
    if (newAward !== false) {
      // Add new award to awards if not already in there to prevent duplicates
      if (awards.indexOf(newAward) === -1) {
        awards.push(newAward);
      }
      setNewAward(false);
    }
  }, [data, newAward]);

  React.useEffect(() => {
    if (deleteAward !== false) {
      // Check if target award is in awards to be deleted
      if (awards.indexOf(deleteAward) !== -1) {
        awards.splice(awards.indexOf(deleteAward));
      }
      setDeleteAward(false);
    }
  }, [data, deleteAward]);

  React.useEffect(() => {
    let check = true;

    // Check for input error fails if either input errors are set
    if (inputErrors.name.error || inputErrors.amount.error) {
      check = false;
    }

    setError(!check);
  }, [inputErrors.name.error, inputErrors.amount.error])


  if (props.event === -1) {
    return <></>;
  }

  return (
    <Paper sx={{ height: "100%" }}>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography>Event Rules</Typography>
          <AddEmButton
            handleSave={(e) => {
              // Prevents error from hitting save on empty input modal
              if (ruleName !== '' && conditionAmount !== undefined) {
                setTempRule({
                  name: ruleName,
                  description: description,
                  constraint: conditionAmount,
                  roles: roles.join(','),
                  awards: awards.join(',')
                });
                setNewRule(true);
              }
            }}
            disabled={props.event.historical_event_flag}
            refresh={props.refresh}
            error={error}
            title='Add Rule'
          >
            {<Stack spacing={1}>
              <FormControl fullWidth variant="filled">
                <InputLabel>Rule Name</InputLabel>
                <OutlinedInput
                  value={ruleName}
                  onChange={(e) => {
                    // Rule name cannot be empty
                    if (e.target.value === '') {
                      inputErrors.name.error = true;
                    }
                    else inputErrors.name.error = false;

                    setRuleName(e.target.value);
                  }}
                  label="Rule Name"
                  error={inputErrors.name.error}
                />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <InputLabel>Amount</InputLabel>
                <OutlinedInput
                  value={conditionAmount}
                  onChange={(e) => {
                    // Amount must not be empty and must be an integer value
                    if (e.target.value === '' || !isInt(e.target.value)) {
                      inputErrors.amount.error = true;
                      setError(true);
                    }
                    else inputErrors.amount.error = false;

                    setConditionAmount(e.target.value);
                  }}
                  label="Amount"
                  error={inputErrors.amount.error}
                />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <TextField
                  id="description-field"
                  label='Description'
                  multiline
                  value={description}
                  rows={4}
                  variant="filled"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <Paper sx={{ maxHeight: '85px', maxWidth: '250px', overflow: 'auto' }}>
                  <Stack spacing={1}>
                    <Stack direction="row">
                      <Typography>Roles</Typography>
                      <AddEmButton
                        handleSave={(e) => {
                          setNewRole(tempRole);
                          setTempRole(false);
                        }}
                        disabled={props.event.historical_event_flag}
                        refresh={props.refresh}
                        title='Add Role'
                      >
                        <EventRewardSelect
                          label={'Search'}
                          type={'role'}
                          onChange={(value) => {
                            setTempRole(value);
                          }}
                        />
                      </AddEmButton>
                    </Stack>
                    <Stack spacing={1}>
                      {roles.map((option) => (
                        <Grid item xs={7} key={"gcr-" + option.id}>
                          <EventRewardChip
                            key={"cr-" + option.id}
                            data={option}
                            canEdit={true}
                            onDelete={(name) => {
                              setDeleteRole(name);
                            }}
                            disabled={props.event.historical_event_flag}
                            refresh={props.refresh}
                          />
                        </Grid>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </FormControl>
              <FormControl fullWidth variant="filled">
                <Paper sx={{ maxHeight: '85px', maxWidth: '250px', overflow: 'auto' }}>
                  <Stack spacing={1}>
                    <Stack direction="row">
                      <Typography>Awards</Typography>
                      <AddEmButton
                        handleSave={(e) => {
                          setNewAward(tempAward);
                          setTempAward(false);
                        }}
                        disabled={props.event.historical_event_flag}
                        refresh={props.refresh}
                        title='Add Award'
                      >
                        <EventRewardSelect
                          label={'Search'}
                          type={'award'}
                          onChange={(value) => {
                            setTempAward(value);
                          }}
                        />
                      </AddEmButton>
                    </Stack>
                    <Stack spacing={1}>
                      {awards.map((option) => (
                        <Grid item xs={7} key={"gca-" + option.id}>
                          <EventRewardChip
                            key={"ca-" + option.id}
                            data={option}
                            canEdit={true}
                            onDelete={(name) => {
                              setDeleteAward(name);
                            }}
                            disabled={props.event.historical_event_flag}
                            refresh={props.refresh}
                          />
                        </Grid>
                      ))}
                    </Stack>
                  </Stack>
                </Paper>
              </FormControl>
            </Stack>
            }
          </AddEmButton>
        </Stack>
        <Grid container spacing={1}>
          {data.length >= 0 &&
            data.map((option) => (
              <Grid item xs={4} key={"gec-" + option.id}>
                <EventRuleChip
                  key={"ec-" + option.id}
                  data={option}
                  canEdit={true}
                  onDelete={(name) => {
                    setDeleteRule(name);
                  }}
                  disabled={props.event.historical_event_flag}
                  refresh={() => {
                    setRefresh(!refresh);
                  }}
                  onChange={(value) => {
                    setTempRule(value);
                    setChangeRule(true);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Stack>
    </Paper>
  );
}
