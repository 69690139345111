import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import PasswordComponent from '../../../../../login-layout/components/sub-components/component-password';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import UserPermissionChip from './user-permission-chip';

const addCopmonentModalRoot =makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 4),
  },
}));

const addComponentModalStyle = {
  borderRadius: 5,
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const addComponentCard = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid black',
  borderRadius: '5px',
  textAlign: 'center',
};


const getPermissions = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/user/permissions", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function UserBreakdown(props) {
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [passwordMatch, setPasswordMatch] = React.useState(true)
  const [userData, setUserData] = React.useState(props.data);
  const [permissionList, setPermissionList] = React.useState([]);
  const [newPermission, setNewPermission] = React.useState([]);

  React.useEffect(() => {
    getPermissions(props.data.id).then(res => res.json())
    .then(
      (result) => {
        result.response.unshift({id:0, name: "Admin"})
        setPermissionList(result.response)
      })
  },[])

  let list = [];
  if (Object.keys(props.data).length) {
    list = [{name:'Admin'}];
    if(props.data.hasOwnProperty('permission'))
    {
      list = props.data.permission
    }
  }

  const [userPermissions, setUserPermissions] = React.useState(list);

  React.useEffect(() => {
    if (userPermissions.length > 0 && userPermissions[0].name !== 'Admin')
    {
      props.handleChange('permission',userPermissions)
    }
  },[userPermissions])

  const handleChange = (prop) => (event) => {
    setUserData({ ...userData, [prop]: event.target.value });
    props.handleChange(prop,event.target.value)
  };

  const handlePasswordChange = (prop,value) => {
    setUserData({ ...userData, [prop]: value });
    props.handleChange(prop,value)
  };

  const handlePermission = (prop, data) => {
    setUserData({ ...[userPermissions], [prop]: data });
  };

  const handlePassword = (type,value) => {
    if(type === 'password')
    {
      setNewPassword(value)
    }
    else 
    {
      setConfirmPassword(value)
    }
    
    handlePasswordChange(type,value)
  }

  return (
    <form autocomplete="off" data-lpignore="true">
    <Stack 
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}  
    >
      <FormControl sx={{ m: 1, width: '25ch' }} variant="standard" >
        <TextField 
          id="username" 
          label="Username" 
          variant="standard" 
          value={userData.username} 
          onChange={handleChange('username')}
          inputProps={{
            autocomplete: 'off',
            form: {
              autocomplete: 'off',
            },
          }}
          />
      </FormControl>
      <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
        <TextField id="email" label="Email" variant="standard" value={userData.email_address} onChange={handleChange('email_address')}/>
      </FormControl>
      <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
        <TextField id="firstName" label="First Name" variant="standard" value={userData.first_name} onChange={handleChange('first_name')}/>
      </FormControl>
      <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
        <TextField id="lastName" label="Last Name" variant="standard" value={userData.last_name} onChange={handleChange('last_name')}/>
      </FormControl>
      
      <PasswordComponent
        label="New Password"
        value={userData.password}
        type='new-password'
        onChange={(e) => handlePassword('password',e.target.value)}/>
      <PasswordComponent
        label="Confirm Password"
        type='new-password'
        value={userData.confirm}
        onChange={(e) => handlePassword('confirm',e.target.value)}/>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item={4}>  
            <FormControl sx={{ width: '19ch' }} variant="standard">
              <TextField
                id="permission-select"
                select
                label="Permissions"
                variant="standard"
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value)}
              >
                {permissionList.map((option) => (
                  <MenuItem key={option.name} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item={3}>
            <Button sx={{mt:3}} onClick={() => {
              let contains = false
              let admin = false
              userPermissions.forEach(element =>{
                if(element.name === newPermission.name)
                {
                  contains = true
                }
                if(element.name === 'Admin')
                {
                  admin = true
                }
              })

              if(admin)
              {
                setUserPermissions([newPermission])
                handlePermission('permission',userPermissions)
              }
              else if(!contains)
              {
                setUserPermissions([...userPermissions,newPermission])
                handlePermission('permission',userPermissions)
              }
            }}>+</Button>    
          </Grid>
        </Grid>
      </Box>
      <Stack spacing={1} direction="row">
        {userPermissions.map((option) => {
          if(option.name !== 'Admin')
          {
            return (
              <UserPermissionChip 
                key={'item-'+option.name}
                data={option}
                changeFilter={(id, filter)=>{
                  let i = 0
                  let target = 0
                  userPermissions.forEach(element => {
                    if(element.id === id)
                    {
                      target = i
                    }
                    i++
                  })
                  userPermissions[target]['filter'] =  filter
                  setUserPermissions([...userPermissions])
                  handlePermission('permission',userPermissions)
                }}
                onChange={(id, value)=> {
                  let i = 0
                  let target = 0
                  userPermissions.forEach(element => {
                    if(element.id === id)
                    {
                      target = i
                    }
                    i++
                  })
                  userPermissions[target] = value

                }}
                onDelete={()=>{
                  let i = 0
                  let target = 0;
                  userPermissions.forEach(element => {
                    if (element.name === option.name)
                    {
                      target = i
                    }
                    i++
                  });

                  userPermissions.splice(target, 1)

                  if(userPermissions.length === 0)
                  {
                    userPermissions.push({name:'Admin'})
                  }

                  setUserPermissions([...userPermissions])
                }} 
              />
            )
          }
          else
          {
            return (<Chip 
              key={'item-'+option.name}
              label={option.name} 
            />)
          }
        })}
      </Stack>
    {!passwordMatch &&
      <div align-content="center">
        <Typography variant="h6" component="h2" color="danger">
          Passwords must match!
        </Typography>
      </div>
    }
    </Stack>
    </form>
  )
}
