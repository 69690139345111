import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px",
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.25}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

export default function SettingAttributeChip(props) {
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  var theme = null

  if(props.data.color !== 'primary' && props.data.color !== undefined)
  {
    theme = createTheme({
      palette: {
        primary: {
          // Purple and green play nicely together.
          main: props.data.color,
        },
      },
    });
  }

  const handleDelete = (event) => {
    setConfirmOpen(true)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    props.onDelete(props.data.refId)
    handleClose()
  };

  return (
    <div>
      {theme !== null && <ThemeProvider theme={theme}>
        <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onDelete={handleDelete}
            deleteIcon={icon}
            color="primary"
        />
      </ThemeProvider>}
      {theme === null && 
        <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onDelete={handleDelete}
            deleteIcon={icon}
            color="primary"
        />}
        <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Remove Setting?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
