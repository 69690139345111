import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ExcludeListItem(props) {
  const [open, setOpen] = React.useState(false);
  const size = (props.size)?props.size:'small'

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
          disabled={props.disabled}
          onClick={handleClickOpen}>
        Exclude
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
            props.handleSave(e)
            setOpen(false)
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
