import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ReqFormChip from '../input/req-form-chip';
import Typography from '@mui/material/Typography';

export default function FormView(props) {
  const [ data, setData ] = React.useState(props.data)

  if(props.data !== data)
  {
    setData(props.data)
  }

  if(data === -1 )
  {
    return <></>
  }

  return (
    <Paper sx={{height:"100%"}}>
      <Stack spacing={1}>
        <Typography>Required Forms</Typography>
        <Grid container spacing={1}>
          {data.forms.map((option) => {
            let length = 9
            if(option.name.length > 11)
              length = 7
            else if(option.name.length > 19)
              length = 9
            else if(option.name.length < 11)
              length = 4
            return(
            <Grid item xs={length} key={"f-"+option.id}>  
              <ReqFormChip data={option}/>
            </Grid>
          )})}      
        </Grid>
      </Stack>  
    </Paper>
  );
}
