import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import {DataGridPro} from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';

const columns = [
  {
    headerName:'Date',
    field:'entryDate',
    width: 100,
    filterable:false,
  },
  {
    field: "detail",
    headerName: "Details",
    width: 350,
    flex:1
   },
  {
   field: "user",
   headerName: "User Name",
   width: 100,
  },
]


const fetchActivity = (id, table) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    targetType:table,
    noteType:'activity',
    id: id,
  }

  return fetch(cookies.get('apiUrl')+"/data/notifications", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}


export default function ActivityButton(props) {
  const [id, setId] = React.useState(props.focusId)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [activity, setActivity] = React.useState({data:[]})
  const [open, setOpen] = React.useState(false)
  const [table, setTable] = React.useState(props.type)

  React.useEffect(() => {
    if(open)
    {
      setIsLoaded(false);
      fetchActivity(id, table).then(res => res.json())
        .then(
          (result) => {
            activity['data'] = result.response;
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [activity, id, table, open]);


  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
          size="small"
          aria-label="Activity"
          color="inherit"
          onClick={handleClick}
        >
          <Badge 
              
              color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Activity</DialogTitle>
          {error && <ErrorIcon color="danger"/>}
          {(!isLoaded && activity.data.length !== 0 ) && <CircularProgress color="warning"/>}
          {activity.data.length > 0 &&
            <div style={{ height: 600,  width: '100%' }}>
              < DataGridPro
                rows={activity.data}
                columns={columns}
                id='search-results'
                filter={true}
                pagination
                pageSize={10}
                onEditCommit={(values,event) => {
                  //console.log(values)
                }}
                getRowHeight={() => 'auto'}
                autoHeight
              />
            </div>
          } 
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
