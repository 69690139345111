import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

export default function RegionSelect(props) {
  let id = 'report-select';
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [regions, setRegions] = React.useState({})
  const [refresh, setRefresh] = React.useState(props.refresh)
  const cookies = new Cookies();
  const [regionId, setRegionId] = React.useState(-1);
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const request = { 
    user: user, 
    token: token,
    permission: ''
   }

  React.useEffect(() => {
    fetch(cookies.get('apiUrl')+"/trail/regions", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(request)
    }).then(res => res.json())
      .then((result) => {
        setRegions(result.response)
        setIsLoaded(true);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [refresh])

  
  if(refresh !== props.refresh)
  {
    setRefresh(!refresh)
  }

  if (error) {
    return <div>Error: There was an issue loading the compnent</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

    const handleChange = (event) => {
      setRegionId(event.target.value);
      props.callback(event.target.value);
    };

    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={id}
            select
            label="Select Region"
            value={regionId}
            onChange={handleChange}
            className=""
          >
            <MenuItem key="tmt-section" value={-1}>
              Select Region
            </MenuItem>
            {
            regions.map((option) => ( 
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))
          }
          </TextField>
        </div>
      </Box>
    );
  }
}
