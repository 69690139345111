import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';

const disapproveNotification = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/notification/disapprove", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EditDevCommentButton(props) {
  const id = props.id

  const [open, setOpen] = React.useState(false)
  const [comment, setComment] = React.useState(props.value)

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = (event) => {
    setOpen(false)
  };

  const handleSave = (event) => {
    props.save(id, comment)
    handleClose(event)
  };

  const handleDelete = (event) => {
    props.delete(id)
    handleClose(event)
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-label="Volunteers"
        color="inherit"
        onClick={handleClick}
      >
        <Badge color="primary">
          <EditIcon/>
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Edit Comment
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            placeholder="comment"
            multiline
            variant="standard"
            rows={2}
            maxRows={4}
            value={comment}
            onChange={(event)=>{
              setComment(event.target.value)
            }} 
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Cancel</Button>
        <Button type="contained" onClick={handleDelete}>Delete</Button>
        <Button type="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
