import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Cookies from 'universal-cookie';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const fetchAutolist = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: type,
  }

  return fetch(cookies.get('apiUrl')+"/report/get/autolist", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ReportSearchInput(props) {
  const [report, setReport] = React.useState(props.report)
  const [selectValue, setSelectValue] = React.useState()//props.default)
  const [searchType, setSearchType] = React.useState(props.type);
  const [list, setList] = React.useState([]);
  const [raw, setRaw] = React.useState([]);
  const [start, setStart] = React.useState()//props.default);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);
  const [showField, setShowField] = React.useState(props.showVal);
  const [id, setId] = React.useState();

  if(props.report !== report)
  {
    setReport(props.report)
    setStart(null)
    setSelectValue(null)
    setSearchType(null)
  }

  if(searchType !== props.type)
  {
    setSearchType(props.type)
    setList([])
  }

  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onShow(val)
  };
  
  React.useEffect(() => {
    fetchAutolist(searchType).then(res => res.json())
      .then(
        (result) => {
          let response = result.response
          switch(searchType)
          {
            case "forms":
              let test = response.forms
              if(test === undefined)
              {
                test = response[0]
              }
              response = test
              break
            /**case "roles":
              response = response.forms
              break**/
          }

          setRaw(response)
          let newList = []
          response.map(element => newList.push(element.name))
          setList(newList)

          
        },
        (error) => {
        }
      );
  }, [searchType]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-box-demo"
          onChange={(e, value) => {
            let id
            raw.map(element => {
              if(element.name === value)
              {
                id = element.id
              }
            })
            props.onChange(id, value)
            setId(id)
            setSelectValue(value)
          }}
          value={selectValue}
          inputValue={start}
          onInputChange={(event, value) => {
            setStart(value);
          }}
          options={list}
          sx={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
    </Grid>  
  );
}
