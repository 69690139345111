import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EventRoleChip from '../input/event-role-chip';
import AddEmButton from '../input/add-em-button';
import MixedSearchInput from '../../../core-components/entity-details/inputs/mixed-search-input';
import Cookies from 'universal-cookie';

const addRole = (role, eventId) => {
  const cookies = new Cookies();
  let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
  let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: eventId,
    role: role
  };

  return fetch(cookies.get('apiUrl') + '/events/add/role', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
};

const removeRole = (role, eventId) => {
  const cookies = new Cookies();
  let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
  let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: eventId,
    role: role
  };

  return fetch(cookies.get('apiUrl') + '/events/remove/role', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function RoleView(props) {
  const [data, setData] = React.useState(props.event);
  const [newRole, setNewRole] = React.useState(false);
  const [tempRole, setTempRole] = React.useState(false);
  const [deleteRole, setDeleteRole] = React.useState(false);

  React.useEffect(() => {
    if (newRole !== false) {
      addRole(newRole, data.id)
        .then(res => res.json())
        .then((result) => {
          if (result.response !== false) {
            data.roles.push(result.response);
            setData({ ...data });
            setNewRole(false);
          }
        }, (error) => { });
    }
  }, [data, newRole]);

  React.useEffect(() => {
    if (deleteRole !== false) {
      removeRole(deleteRole, data.id)
        .then(res => res.json())
        .then((result) => {
          setDeleteRole(false);
          let i = 0;
          let delIndex = 0;
          data.roles.map((element) => {
            if (element.id === data.id) {
              delIndex = i;
            }
            i++;
          });
          data.roles.splice(delIndex, 1);
          setData({ ...data });
        }, (error) => { });
    }
  }, [data, deleteRole]);

  if (props.event === -1) {
    return <></>;
  }

  if (data.id !== props.event.id) {
    setData(props.event);
  }

  return (
    <Paper sx={{ height: "100%" }}>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography>Roles</Typography>
          <AddEmButton
            handleSave={(e) => {
              setNewRole(tempRole);
              setTempRole(false);
            }}
          >
            <MixedSearchInput
              label={'Search'}
              type={'role'}
              onChange={(value) => {
                setTempRole(value);
              }}
            />
          </AddEmButton>
        </Stack>
        <Grid container spacing={1}>
          {data.roles.map((option) => (
            <Grid item xs={3} key={"ger-" + option.id}>
              <EventRoleChip
                key={"er-" + option.id}
                data={option}
                canEdit={true}
                onDelete={(name) => {
                  setDeleteRole(name);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Paper>
  );
}
