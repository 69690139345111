import * as React from 'react';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';


export default function PublicFormChip(props) {
  const id = props.id
  const entityId = props.entityId
  const [deleteClick, setDeleteClick ] = React.useState(false)

  const cookies = new Cookies();
  var permissiveIcon = <></>
  if(cookies.get('permission') === 'null')
  {
    permissiveIcon = <DeleteIcon/>;
  }
  const icon = permissiveIcon
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  React.useEffect(() => {
    if(deleteClick)
        props.onClear(id)
        setConfirmOpen(false);
  }, [id, deleteClick]);

  const handleDelete = (event) => {
    setDeleteClick(true)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const openDelete = () => {
    setConfirmOpen(true);
  };

  return (
    <div>
      <Chip
        label={props.label}
        variant="filled"
        onDelete={openDelete}
        deleteIcon={icon}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
