import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Cookies from 'universal-cookie';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddDevTask from './add-dev-task';
import {DataGridPro} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import EditDevTask from './edit-dev-task';
import EditDevCommentButton from './edit-dev-comment-button';

const getTaskTypes = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/jira/task/types", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const getTaskComments = (task) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/jira/task/item/"+task, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addTaskComment = (task, comment) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    comment: comment
  }

  return fetch(cookies.get('apiUrl')+"/jira/add/comment/"+task, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const editTaskComment = (comment, task,  comment_id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    comment: comment
  }

  return fetch(cookies.get('apiUrl')+"/jira/update/comment/"+task + '/'+comment_id, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteTaskComment = (task, comment) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token
  }

  return fetch(cookies.get('apiUrl')+"/jira/delete/comment/"+task + '/'+comment, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EditDeveloperTaskForm(props) {
  const [name, setName] = React.useState(props.data.name);
  const [description, setDescription] = React.useState(props.data.description);
  const [taskType, setTaskType] = React.useState(props.data.type);
  
  const [taskTypes, setTaskTypes] = React.useState([]);

  const [deleteComment, setDeleteComment] = React.useState(0)
  const [deleteCommentClick, setDeleteCommentClick] = React.useState(false)
  const [editComment, setEditComment] = React.useState('')
  const [editCommentClick, setEditCommentClick] = React.useState(false)
  const [newComment, setNewComment] = React.useState('')
  const [commentClick, setCommentClick] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)

  const  columns = 
  [
    {
      headerName:'Edit',
      field:'target',
      renderCell: (params) => {

        return (
          <EditDevCommentButton 
            value={params.row.comment}
            save={(id, comment)=>{
              setEditComment(comment)
              setDeleteComment(params.id)
              setEditCommentClick(true)
            }}
            delete={(id)=> {
              setDeleteComment(params.id)
              setDeleteCommentClick(true)
            }}
          />
        )
      },
      width: 70,
      filterable:false,
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 280,
    },
    {
      field: "created",
      headerName: "Date Created",
      valueGetter: (params) => new Date(params.row.created),
      width: 110,
      type:'date',
    },
  ]

  React.useEffect(() => {
    getTaskTypes().then(res => res.json())
      .then(
        (result) => {
          setTaskTypes(result.response)
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    if(commentClick)
    {
      addTaskComment(props.data.id, newComment).then(res => res.json())
        .then(
          (result) => {
            setCommentClick(false)
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [newComment, props, commentClick, refresh]);

  React.useEffect(() => {
    if(editCommentClick)
    {
      editTaskComment(editComment, props.data.id, deleteComment).then(res => res.json())
        .then(
          (result) => {
            setEditCommentClick(false)
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [editComment, props, editCommentClick, deleteComment, refresh]);

  React.useEffect(() => {
    if(deleteCommentClick)
    {
      deleteTaskComment(props.data.id, deleteComment).then(res => res.json())
        .then(
          (result) => {
            setDeleteCommentClick(false)
            setRefresh(!refresh)
          },
          (error) => {
          }
        );
    }
  }, [editComment, props, deleteCommentClick, refresh]);

  React.useEffect(() => {
    getTaskComments(props.data.id).then(res => res.json())
      .then(
        (result) => {
          setComments(result.response.comments)
        },
        (error) => {
        }
      );
  }, [refresh, props]);

  React.useEffect(() => {
    props.onChange(taskType, name, description)
  }, [taskType, description, name]);

  return (
    <Grid container spacing={1} >
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="contrib-select-label">Task Type</InputLabel>
          <Select
            labelId="task-type-select-label"
            id="task-type-select"
            label="Task Type"
            value={taskType}
            onChange={(event)=>{
              setTaskType(event.target.value)
            }} 
            
          >
            {taskTypes.map((t) => <MenuItem key={t.name} value={t.name}>{t.name} - {t.description}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth>
          <TextField 
            id="name-field"
            value={name}
            onChange={(event)=>{
              setName(event.target.value)
            }} 
            label="Name" 
            variant="standard" />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            placeholder="Description"
            multiline
            variant="standard"
            rows={2}
            maxRows={4}
            value={description}
            onChange={(event)=>{
              setDescription(event.target.value)
            }} 
          />
        </FormControl>
      </Grid>
      <Divider/>
      <Grid item xs={10}>
        <Typography>Comments</Typography>
      </Grid>
      <Grid item xs={2}>
        <AddDevTask 
          title="New Comment"
          handleSave={()=>{
            setCommentClick(true)
          }}>
            <FormControl fullWidth>
              <TextField
                placeholder="comment"
                multiline
                variant="standard"
                rows={2}
                maxRows={4}
                value={newComment}
                onChange={(event)=>{
                  setNewComment(event.target.value)
                }} 
              />
            </FormControl>
        </AddDevTask>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: 250, width: '99%' }}>
          <DataGridPro
            rows={comments}
            columns={columns}
            density="compact"
          />
        </div>
      </Grid>
    </Grid>
    )
}
