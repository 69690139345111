import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import EventRegistery from './event-registery';

const fetchRegistry = (id) => {
  const cookies = new Cookies();
  let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
  let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
  };

  return fetch(cookies.get('apiUrl') + '/events/registery', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function EventViewAccordions(props) {
  const cookies = new Cookies();
  const [entity, setEntity] = React.useState(props.event);
  const [ugly, setUgly] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [register, setRegister] = React.useState([]);
  const [index, setIndex] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    setIsLoaded(false);
    setRegister([]);
    fetchRegistry(entity.id)
      .then(res => res.json())
      .then((result) => {
        setIsLoaded(true);
        let register = [];
        let temp = {};
        result.response.map((e) => {
          temp = e;
          temp.entity = e;
          register.push(temp);
        });
        setRegister(register);
      },
        (error) => {
          setIsLoaded(true);
          setErrors(error);
        });
  }, [entity, refresh]);

  if (props.event === -1) {
    return <></>;
  }

  if (entity !== props.event) {
    setEntity(props.event);
  }

  const toggleData = (newIndex) => {
    if (index !== newIndex) {
      setIndex(newIndex);
    }
    else {
      setIndex('');
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    toggleData(panel);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'registery'} onChange={handleChange('registery')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="maintainer-content"
          id="maintainer-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Registery
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errors && <ErrorIcon color="danger" />}
          {!isLoaded && <CircularProgress color="warning" />}
          {isLoaded && (
            <EventRegistery data={register} event={entity} refresh={() => {
              setRefresh(!refresh);
            }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
