import * as React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ReportSearchInput from '../fields/report-search-input';
import ReportSelectInput from '../fields/select-input';
import ReportTextInput from '../fields/text-input';
import ReportDateRange from '../fields/date-range';
import ReportListInput from '../fields/list-input';
import ReportCheckboxInput from '../fields/checkbox-input';
import ReportConditionalInput from '../fields/conditional-input';
import Cookies from 'universal-cookie';

export default function ReportInput(props) {
  const [fields, setFields] = React.useState(props.fields);
  const [report, setReport] = React.useState(props.report);
  const [request, setRequest] = React.useState({});
  const [hasTemplate, setHasTemplate] = React.useState(props.hasTemplate);
  const [refresh, setRefresh] = React.useState(false);
  const [template, setTemplate] = React.useState(null);
  const [shouldDefault, setShouldDefault] = React.useState({});
  const cookies = new Cookies();
  var showOverride = (cookies.get('permission') === 'null')?'true':'false'

  if(template !== props.template)
  {
    //clear the request
    Object.entries(request).map((item) => {
      delete request[item[0]]
    })
    //set the new one
    if(props.template)
      Object.entries(props.template.schema.request).map((item)=>{
          request[item[0]] = item[1]
      })
    props.setRequest(request)
    setTemplate(props.template)
  }

  if(props.hasTemplate !== hasTemplate)
  {
    setHasTemplate(props.hasTemplate)
  }
  
  if(refresh !== props.refresh)
  {
    setRefresh(props.refresh)
    setFields(props.fields)
    setTemplate(null)
  }

  const setRequestValue = (field,value) => {
    if(request[field] !== value)
    {
      request[field] = value
      props.setRequest(request)
      setFields([...fields])
    }
  }

  if(report !== props.report)
  {
    setReport(props.report)
    setFields({...props.fields});
  }

  if(props.fields.length < 1 ) 
  {
    if(fields !== props.fields)
    {
      setFields(props.fields)
    }
    return <></>;
  }
  else if(fields !== props.fields)
  {
    setFields(props.fields);
  }

  if(!Array.isArray(fields))
  {
    setFields(Object.values(fields))
    return <></>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ m: 1, width: '95%' }}
        >
          <Stack spacing={1}>
            {fields.map((element, index) => {
              if(element.permissive === 'true')
              {
                element.show = showOverride
              }
              if(hasTemplate && template)
              {
                if(template.schema.request.hasOwnProperty(element.requestHeader))
                {
                  element.default = template.schema.request[element.requestHeader]
                }
                if(template.schema.display.hasOwnProperty(element.requestHeader))
                {
                  element.show = (template.schema.display[element.requestHeader] === 'true')?'true':'false'
                }
                else
                {
                  element.show = "false"
                }
              }
              if(hasTemplate && template === null)
              {
                return false;
              }

              if(element.hasOwnProperty('conditions'))
              {
                let show = true;
                Object.entries(element.conditions).map((option) => {
                  if(!option[1].includes(request[option[0]]))
                  {
                    show = false
                    element.default = null
                  }
                }) 
                if(!show) return false;//return <div key={"input-"+index} display="none"></div>;
              }

              switch(element.field.type)
              {
                case 'search':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportSearchInput
                    key={"input-"+index}
                    report={report}
                    type={element.field.searchType} 
                    label={element.fieldName}
                    default={element.default}
                    onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                    onShow={(show)=> {}}
                    isTemplate={false}
                    showVal={false}
                  />
                case 'select':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportSelectInput 
                            key={"input-"+index}
                            label={element.fieldName}
                            list={element.field.list}
                            default={element.default}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            showVal={false}
                            />
                case 'text':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportTextInput
                            key={"input-"+index}
                            label={element.fieldName}
                            default={element.default}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            showVal={false}
                          />
                case 'checkbox':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, (element.default === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportCheckboxInput 
                            key={"input-"+index}
                            label={element.fieldName}
                            default={(element.default === 'true')}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            showVal={false}
                          />
                case 'date':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportDateRange 
                            key={"input-"+index}
                            label={element.fieldName}
                            default={element.default}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            showVal={false}
                          />
                case 'list':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportListInput 
                            key={"input-"+index}
                            label={element.fieldName} 
                            type={element.field.searchType}
                            default={element.default}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            showVal={false}
                            template={template}
                          />
                case 'conditional':
                  if(element.default && !hasTemplate && !shouldDefault[element.requestHeader])
                  {
                    setShouldDefault({...shouldDefault,[element.requestHeader]:true})
                    setRequestValue(element.requestHeader, element.default, (element.refreshField === 'true'))
                  }
                  if(element.show === 'false' ) return <></>
                  return <ReportConditionalInput
                            key={"input-"+index}
                            label={element.fieldName} 
                            type={element.field.searchType}
                            default={element.default}
                            exclusive={element.exclusive}
                            onChange={(value) => {setRequestValue(element.requestHeader, value)}}
                            onShow={(show)=> {}}
                            isTemplate={false}
                            template={template}
                            showVal={false}
                          />
                default:
              }
            })}      
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}><Divider/></Grid>
    </Grid>  
  )
}
