import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MapChip from '../input/map-chip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import MixedSearchInput from '../../../core-components/entity-details/inputs/mixed-search-input';
import Cookies from 'universal-cookie';

const updateMaps = (data, action) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    action:action,
    data:data
  }

  return fetch(cookies.get('apiUrl')+"/trail/update/section/maps", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MapView(props) {
  const [ data, setData ] = React.useState(props.data)
  const [open, setOpen] = React.useState(false);
  const [newMap, setNewMap] = React.useState({});
  const [target, setTarget] = React.useState({});
  const [mapList, setMapList] = React.useState(props.data.maps);
  const [saveClick, setSaveClick] = React.useState(false);
  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    if(saveClick)
    {
      updateMaps(target.data,target.action).then(res => res.json())
        .then(
          (result) => {
            setSaveClick(false)
          },
          (error) => {
            
          }
        );
    }
  }, [target, saveClick]);

  if(props.data === -1)
  {
    return <></>
  }

  if(props.data.id !== data.id)
  {
    setData(props.data)
    setMapList(props.data.maps)
  }

  if(data === -1)
  {
    return <></>
  }

  return (
    <Stack spacing={1}>
      <Typography>Maps</Typography>
      <Grid container spacing={.5}>
        {props.type === 'Section' && permission && <Grid item xs={1}>
          <IconButton
            size="small"
            onClick={()=>{setOpen(true)}}
            disabled={mapList.length !== 0}
          >
            <Badge>
              <AddIcon/>
            </Badge>
          </IconButton>
        </Grid>}
        {mapList.map((option) => {
          let length = 3
          if(option.name.length > 5)
          {
            length = 4
          }
          if(option.name.length > 7)
          {
            length = 5
          }

          return (
            <Grid item xs={length} key={"m-"+option.id}>
              <MapChip 
                data={option} 
                canEdit={permission} 
                onDelete={(id) => {                
                  let index = 0
                  let i = 0
                  mapList.map((element)=>{
                    if(element.id === id)
                    {
                      index = i
                    }
                    i++
                  })

                  let tempArr = mapList
                  tempArr.splice(index, 1)
                  
                  setTarget({
                    data:{
                      id: data.id,
                      map:id
                    },
                    action:'remove'
                  })
                  setSaveClick(true)
                  setMapList([...tempArr])
              }}/>
            </Grid>
          )})}
      </Grid>
      <Dialog open={open} onClose={()=>{setOpen(false)}}>
        <DialogTitle>Add Map</DialogTitle>
        <DialogContent>
          <MixedSearchInput
            label={'Search'}
            type={'map'}
            objects={true}
            onChange={(value) => {setNewMap({id:value.id,name:value.label})}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}}>Close</Button>
          <Button onClick={(e) => {
              setOpen(false)
              let tempArr = mapList
              let hasMap = false
              tempArr.map((element)=>{
                if(element.id === newMap.id)
                {
                  hasMap = true
                }
              })

              if(!hasMap)
              {
                tempArr.push(newMap)
                setMapList(tempArr)
                setTarget({
                  data:{
                    id: data.id,
                    map:newMap.id
                  },
                  action:'add'
                })
                setSaveClick(true)
              }
            }}>Add Map</Button>
        </DialogActions>
      </Dialog>
    </Stack>  
  );
}
