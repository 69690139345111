import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import AddEmButton from '../input/add-em-button';
import UploadButton from '../input/upload-button';
import EntitySearchInput from '../../../core-components/entity-details/inputs/entity-search-input';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Cookies from 'universal-cookie';
import ElaborateEntity from '../../../core-components/entity-details/component-elaborate-entity';
import Papa from 'papaparse';


const registerForEvent = (name, reg) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: reg.person.id,
    attribute: reg.dateJoined.toISOString().split('T')[0],
    name: name
  };

  return fetch(cookies.get('apiUrl') + '/events/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const logTracking = (data) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    amount: data.amount,
    date: data.date,
    key: data.key,
    email: data.email,
    event: data.event
  };

  return fetch(cookies.get('apiUrl') + '/events/log/tracking', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const columns = [
  {
    headerName: 'Details',
    field: 'entity',
    renderCell: (params) => {
      return <ElaborateEntity entity={params.value} />;
    },
    width: 70,
    filterable: false,
  },
  {
    field: "etId",
    headerName: "Id",
    width: 50,
    editable: true
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 100,
    editable: true
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 100,
    editable: true
  },
  {
    field: "emailAddress",
    headerName: "Email Address",
    width: 225,
    editable: true
  },
  {
    field: "primaryNumber",
    headerName: "Phone Number",
    width: 125,
    editable: true
  },
  {
    field: "address",
    headerName: "Address",
    width: 200,
    editable: true
  },
  {
    field: "city",
    headerName: "City",
    width: 125,
    editable: true
  },
  {
    field: "state",
    headerName: "State",
    width: 75,
    editable: true
  },
  {
    field: "zipCode",
    headerName: "Zip Code",
    width: 100,
    editable: true
  },
];

export default function EventRegistery(props) {
  const onEditCommit = (values, event) => { };
  const filter = ((true) ? { Toolbar: GridToolbar } : {});
  const [fileType, setFileType] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);
  const [tempLink, setTempLink] = React.useState(null);
  const [dateJoined, setDateJoined] = React.useState(new Date());
  const [requestData, setRequestData] = React.useState({
    click: false,
    dateJoined: '',
    person: ''
  });
  const [trackingData, setTrackingData] = React.useState({
    click: false,
    amount: '',
    date: new Date(),
    key: '',
    email: '',
    event: props.event.name
  });
  const [fileInfo, setFileInfo] = React.useState('');

  const startDate = new Date(props.event.start);
  const endDate = new Date(props.event.end);

  // Time needs to be unset for current date so that time of day doesn't impede day comparisons
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const handleDateJoined = (date) => {
    setDateJoined(date);
  };

  const handleFileData = () => {
    fileData.map(results => {
      if (fileType === 'Mileage') {
        logTracking({
          amount: results['Total Miles Completed'],
          date: results['Entry Date'],
          key: results['Particpant ID'],
          email: results['Email'],
          event: props.event.name,
        });
      }
      else {
        //use email here
        setRequestData({
          click: true,
          dateJoined: dateJoined,
          person: tempLink
        });
      }
    })
    setTrackingData({
      click: false,
      amount: '',
      date: new Date(),
      key: '',
      email: '',
      event: props.event.name
    });
  };

  const handleCapture = (type) => ({ target }) => {
    setFileInfo(`${type} File: ${target.files[0].name}`);
    setFileType(type);
    Papa.parse(target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFileData(results.data);
      },
    });
  };

  React.useEffect(() => {
    if (requestData.click) {
      registerForEvent(props.event.name, requestData)
        .then(res => res.json())
        .then((result) => {
          setRequestData({
            click: false,
            dateJoined: '',
            person: ''
          });
          props.refresh();
        });
    }
  }, [props, requestData]);

  React.useEffect(() => {
    if (trackingData.click) {
      logTracking(trackingData)
        .then(res => res.json())
        .then((result) => {
          setTrackingData({
            amount: '',
            date: new Date(),
            key: '',
            email: '',
            event: props.event.name
          });
        });
    }
  }, [props, trackingData]);

  React.useEffect(() => {
    if (requestData.click) {
      logTracking(requestData).then(res => res.json())
        .then((result) => {
          setRequestData({
            click: false,
            dateJoined: '',
            person: ''
          });
          props.refresh();
        });
    }
  }, [props, requestData]);

  return (
    <Stack spacing={1}>
      <Grid container>
        <Grid item xs={10}>
          {(currentDate <= endDate) &&
            <AddEmButton title="Add Person" handleSave={(e) => {
              setRequestData({
                click: true,
                dateJoined: dateJoined,
                person: tempLink
              });
            }}>
              <Stack>
                <EntitySearchInput
                  label={'Search'}
                  type='person'
                  onChange={(value) => { setTempLink(value) }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Date Registered"
                    inputFormat="MM/dd/yyyy"
                    value={dateJoined}
                    onChange={(handleDateJoined)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </AddEmButton>
          }
        </Grid>
        <Grid item xs={2}>
          <UploadButton title="Upload File" handleSave={(e) => {
            handleFileData();
          }}>
            <Grid container>
              <Grid item xs={6}>
                <input
                  style={{ display: 'none' }}
                  id="registration-file"
                  onChange={handleCapture('Registry')}
                  type="file"
                  accept=".csv"
                />
                <label htmlFor="registration-file">
                  <Button variant="raised" component="span">
                    Registery
                  </Button>
                </label>
              </Grid>
              {props.event.type === 'Mileage' &&
                <Grid item={3}>
                  <input
                    style={{ display: 'none' }}
                    id="tracking-file"
                    onChange={handleCapture('Mileage')}
                    type="file"
                    accept=".csv"
                  />
                  <label htmlFor="tracking-file">
                    <Button variant="raised" component="span">
                      Tracking
                    </Button>
                  </label>
                </Grid>
              }
              <Grid item xs={12}>
                {fileInfo}
              </Grid>
            </Grid>
          </UploadButton>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', height: '400px' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.data}
            columns={columns}
            density="compact"
            onCellEditCommit={(values, event) => {
              onEditCommit(values, event);
            }}
            components={filter}
          />
        </div>
      </div>
    </Stack>
  );
}
