import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SettingAttributeChip from './setting-attribute-chip';
import AddSettingsButton from './component-add-settings-button';
import DialogContent from '@mui/material/DialogContent';
import MixedSearchInput from '../../entity-details/inputs/mixed-search-input';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 10}px`,
    width: (props) => `${props.size * 10}px`,
    color: "primary"
  }
}));


export default function UserPermissionChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [filter, setFilter] = React.useState(props.data.filter)
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState(props.data.name)
  const [permissionFitler, setPermissionFilter] = React.useState()

  if(!(filter))
  {
    setFilter([])
  }

  const handleClick = (event) => {
    if(!open && props.data.permission.restriction !== 'none')
    {
      setIcon(<CloseFullscreenIcon/>)
      setOpen(!open)
      setSize(1.5)
    }
  };

  const handleDelete = (event) => {
    if(open && props.data.permission.restriction !== 'none')
    {
      setSize(1)
      if(props.data.name !== textValue)
      {
        setConfirmChange(true)
        props.onDelete()
      }
      else
      {
        setChipTitle(props.data.name)
        setOpen(!open)
        setIcon(<DeleteIcon/>)
      }
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setOpen(!open)
    setConfirmOpen(false);
    setConfirmChange(false)
  };

  const handleRemove = () => {
    props.onDelete(props.data.id)
    handleClose()
  };

  const handleChange = () => {
    props.onChange(props.data.id,textValue)
    handleClose()
    setChipTitle(textValue)
    setOpen(!open)
    setIcon(<DeleteIcon/>)
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
          />
        </Grid>
        {open &&
          <>
            <Grid item xs={2}/>
            <Grid item xs={1}>
              <AddSettingsButton size='large' title={`Add ${props.data.permission.restriction} filter`} handleSave={(e) => {
                let add = true
                
                filter.map(e => {if(e.id === permissionFitler.id) add = false})
                if(add)
                {
                  filter.push(permissionFitler)
                  setFilter([...filter])
                  props.changeFilter(props.data.id,filter)
                }
              }}>
                <MixedSearchInput 
                  type="region"
                  onChange={(value) => {
                    setPermissionFilter(value)
                  }}
                  objects={true}
                />
              </AddSettingsButton>
            </Grid>
          </>
        }
        {open &&
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {filter.map(element => (
                  <Grid key={'fil'+element.id} item>
                    <Chip
                      key={'f-'+element.id} 
                      label={element.label}
                      onDelete={() => {
                        let i = 0;
                        let index;
                        filter.map(e => {
                          if(e.id = element.id)
                          {
                            index = i
                          }
                          i++;
                        })

                        filter.splice(index, 1)
                        setFilter([...filter])
                      }}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>This cannot be undone.</DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmChange} onClose={handleClose}>
        <DialogTitle>Save Changes?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleChange}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
