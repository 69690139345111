import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';

const InputStyles = makeStyles((theme) => ({
  textField: {
      height: '50px',
      width: '80%',            
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
      margin: 'dense',
      border:'none'
  },
  input: {
      color: 'white',
      border:'none'
  }
}));

function CustomTextInput(props)
{
  const classes = InputStyles({})
  const {inputLabel, InputProps, ...rest} = props
  
  return ( 
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle1" style={{paddingTop:"4%"}}>{inputLabel}</Typography>
      <InputBase {...rest} className={classes.textField} ></InputBase>
    </Stack>
  )
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px",
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.25}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


const deleteForm = (id, name) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const ttl = {
    user: user,
    token: token,
    person: id,
    form:name,
  }

  return fetch(cookies.get('apiUrl')+"/trail/form/volunteer/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateForm = (id, name, dateSent, dateReceived) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  
  const ttl = {
    user: user,
    token: token,
    person: id,
    form:name,
    dateSent: dateSent,
    dateReceived:dateReceived
  }

  return fetch(cookies.get('apiUrl')+"/trail/form/volunteer/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EventChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [size, setSize] = React.useState(1)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const oldDate = new Date(props.data.dateSigned)
  
  const cookies = new Cookies();
  var permissiveIcon = <></>
  if(cookies.get('permission') === 'null')
  {
    permissiveIcon = <DeleteIcon/>;
  }
  const [icon, setIcon] = React.useState(permissiveIcon)

  let tempSentdate = new Date()
  let tempNoSentDate = true
  if(
    props.data.dateSent !== undefined && 
    props.data.dateSent !== '' && 
    props.data.dateSent !== null
  )
  {
    tempSentdate = new Date(props.data.dateSent)
    tempNoSentDate = false
  }
  
  const [noSentdate, setNoSentDate] = React.useState(tempNoSentDate)
  
  const oldSentDate = tempSentdate
  const expDate = new Date(props.data.expDate)
  
  const [pass, setPass] = React.useState(
    {
      signedDate:oldDate,
      sentDate:oldSentDate,
      action:'',
      name:props.data.name,
      click:false
    })

  const [deleteClick, setDeleteClick] = React.useState(false)
  const [date , setDate] = React.useState(oldDate)
  const [dateOpen, setDateOpen] = React.useState(false)
  const [sentDate, setSentDate] = React.useState(oldSentDate)
  const [dateSentOpen, setDateSentOpen] = React.useState(false)

  let smalls = 12
  let theme = null;
  let dateChipTitle='Expires'
  if(props.data.color !== 'primary' && props.data.color !== undefined)
  {
    if (props.data.color === '#ff4444')
    {
      dateChipTitle="Expired"
    }
    smalls = 6
    theme = createTheme({
      palette: {
        primary: {
          main: props.data.color,
        },
      },
    });
  }

  const tempScale = smalls

  const [scale, setScale] = React.useState(tempScale)

  React.useEffect(() => {
    if(deleteClick)
      deleteForm(props.entityId,props.data.name ).then(res => res.json())
        .then(
          (result) => {
            props.refresh()
            
          },
          (error) => {
          }
        );
  }, [props,deleteClick]);


  React.useEffect(() => {
    if(pass.click)
      updateForm(props.entityId,props.data.name, pass.sentDate,pass.signedDate ).then(res => res.json())
        .then(
          (result) => {
            props.refresh()
          },
          (error) => {
          }
        );
  }, [props,pass]);

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon/>)
      setOpen(!open)
      setSize(1.2)
      setScale(12)
    }
  };

  const toggle = (e,index) =>
  {
    if(index === 'sent')
      setDateSentOpen(true)
    else
      setDateOpen(true)
    
  }

  const handleDate = (value,index,e) => {
    pass.action='update'
    let temp = new Date(value)
    if(index == 'sent')
    {
      setSentDate(value)
      pass.sentDate=temp.toISOString().split('T')[0]
      pass.signedDate = null
      pass.click=true
    }
    else
    {
      setDate(value)
      pass.signedDate=temp.toISOString().split('T')[0]
      pass.sentDate = null
      pass.click=true
    }

    setPass({...pass})
  }

  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setChipTitle(props.data.name)
      setOpen(!open)
      setIcon(permissiveIcon)
      setScale(tempScale)
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    setDeleteClick(true)
  };


  return (
    <div>
      <Grid container>
        <Grid item >
          <CustomChip
              size={size}
              label={(<>
                {!open && <>
                  {chipTitle}
                </>}
                {open && <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label='Since'
                      inputFormat="MM/dd/yyyy"
                      value={date}
                      open={dateOpen}
                      onChange={(newValue) => setDate(newValue)}
                      onAccept={(newValue, e) => handleDate(newValue,'signed', e)}
                      onClose={() => {
                        setDateOpen(false)
                        setDate(oldDate)
                      }}
                      onClear={() => {setDate(oldDate)}}
                      onOpen={(e) => {toggle(e,'signed')}}
                      renderInput={(params) => <CustomTextInput inputLabel={"Received"} {...params} />}
                    />
                  </LocalizationProvider>
                </>}
              </>)}
              variant="filled"
              onClick={handleClick}
              onDelete={handleDelete}
              deleteIcon={icon}
          />
        </Grid>
        {!open &&
        <Grid item xs={4}>
          {theme !== null && <ThemeProvider theme={theme}>
            <CustomChip
                size={size}
                label={<>{dateChipTitle}: {expDate.toLocaleDateString("en-US")}</>}
                variant="filled"
                deleteIcon={icon}
                color="primary"
            />
          </ThemeProvider>}
        </Grid>}
        {open &&
          <Grid item xs={12}>
            <CustomChip
              size={size}
              label={(<>
                {!noSentdate && <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label='Since'
                      inputFormat="MM/dd/yyyy"
                      value={sentDate}
                      open={dateSentOpen}
                      onChange={(newValue) => setSentDate(newValue)}
                      onAccept={(newValue, e) => {
                        tempNoSentDate = false
                        setNoSentDate(tempNoSentDate)
                        handleDate(newValue,'sent', e)}
                      
                      }
                      onClose={() => {
                        setNoSentDate(tempNoSentDate)
                        setDateSentOpen(false)
                        setSentDate(oldSentDate)
                      }}
                      onClear={() => {setSentDate(oldSentDate)}}
                      onOpen={(e) => {toggle(e, 'sent')}}
                      renderInput={(params) => <CustomTextInput inputLabel={"Sent"} {...params} />}
                    />
                  </LocalizationProvider> </>
                }
                {noSentdate && <Button onClick={() => {
                    setNoSentDate(false)
                    setDateSentOpen(true)
                  }}>Click to set sent to agency date </Button>}
              </>)}
            variant="filled"
            deleteIcon={icon}
            />
          </Grid>
        }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
