import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import ErrorIcon from '@mui/icons-material/Error';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';
import AddTmButton from '../input/add-tm-button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';

const columns = [
  {
    headerName:'Name',
    field:'name',
    width: 80,
    filterable:false,
  },
  {
    field: "startName",
    headerName: "Start Name",
    width: 350,
   },
  {
   field: "endName",
   headerName: "endName",
   width: 100,
  },
]


const fetchSection = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id: '',
  }

  return fetch(cookies.get('apiUrl')+"/trail/sections", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addSection = (data) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity: data
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/section", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const deleteSection = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/trail/remove/section", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function ManageSectionsButton(props) {
  const filter = (true)?{Toolbar: GridToolbar}:{};
  const [id, setId] = React.useState(props.focusId)
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [section, setSection] = React.useState({data:[]})
  const [open, setOpen] = React.useState(false)
  const [selectionChange, setSelectionChange] = React.useState([])
  const [clicked, setClicked] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false)
  const [newSection, setNewSection] = React.useState({
    name:'',
    startName:'',
    endName:'',
    startGps:'',
    endGps:''
  })
  const cookies = new Cookies();

  React.useEffect(() => {
    setIsLoaded(false);
    fetchSection(id).then(res => res.json())
      .then(
        (result) => {
          section['data'] = result.response;
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [section,id, refresh, open]);

  React.useEffect(() => {
    if(clicked)
    {
      addSection(newSection).then(res => res.json())
        .then((result) => {
          setClicked(false)
          setRefresh(!refresh)
        },
        (error) => {
        });
    }
  },[newSection, clicked])

  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  React.useEffect(() => {
    if(deleteList.click)
    {
      deleteList.list.forEach(element => {      
        deleteSection(element).then(res => res.json())
        .then(
          (result) => {
            deleteList.click = false
            setRefresh(!refresh)
          })
      })
    }
  },[deleteList])

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeGeneral = (prop) => (event) => {
    newSection[prop] = event.target.value;
    setNewSection({...newSection})
  };

  if(cookies.get('permission') !== 'null')
  {
    return <></>
  }

  return (
    <div>
      <Button variant="Contained" onClick={handleClick}>Sections</Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h5" component="h4" >Sections</Typography>
          </Grid>
          <Grid item xs={1}>
            <AddTmButton title="New Section" handleSave={(e) => {setClicked(true)}}>
              <Stack spacing={1}>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"Name"} 
                  variant="outlined" 
                  value={newSection.name} 
                  onChange={handleChangeGeneral('name')}/>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"End Location Name"} 
                  variant="outlined" 
                  value={newSection.startName} 
                  onChange={handleChangeGeneral('startName')}/>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"Starting Location Name"} 
                  variant="outlined" 
                  value={newSection.endName} 
                  onChange={handleChangeGeneral('endName')}/>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"Start GPS"} 
                  variant="outlined" 
                  value={newSection.startLocGps} 
                  onChange={handleChangeGeneral('startGps')}/>
                <TextField id="outlined-basic"
                  size="small" 
                  label={"End GPS"} 
                  variant="outlined" 
                  value={newSection.endLocGps} 
                  onChange={handleChangeGeneral('endGps')}/>
              </Stack>
            </AddTmButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton 
              size="small" 
              color="inherit" 
              onClick={() => {
                if(selectionChange.length > 0)
                {
                  setConfirmOpen(true)
                }
              }}>
              <RemoveIcon/>
            </IconButton>
          </Grid>
        </Grid>
        {error && <ErrorIcon color="danger"/>}
        {(!isLoaded && section.data.length !== 0 ) && <CircularProgress color="warning"/>}
        {(isLoaded && section.data.length > 0) &&
          <div style={{ height: 600,  width: '100%' }}>
            < DataGridPro
              rows={section.data}
              columns={columns}
              id='search-results'
              density={'compact'}
              filter={true}
              checkboxSelection={true}
              onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
              onEditCommit={(values,event) => {
              }}
              components={filter}
            />
          </div>
        } 
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}}>
        <DialogTitle>Remove Section(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setConfirmOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              region: props.id,
              click:true
            })
            setConfirmOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>  
    </div>
  );
}
