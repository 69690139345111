import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TemplateInput from './template-input';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const makeModifyTemplate = (template, report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  let display = {}
  if(Array.isArray(template['display']))
  {
    for(const property in template.display)
    {
      display[property] = template.display[property]
    }
  }

  template['display'] = display

  const ttl = {
    user: user,
    token: token,
    report:report,
    template:template
  }

  return fetch(cookies.get('apiUrl')+"/report/template", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteTemplate = (name, report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    report:report,
    name:name
  }

  return fetch(cookies.get('apiUrl')+"/report/template", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0d0907",
      contrastText: "#fff" //button text white instead of black
    },
    background: {
      default: "#394764"
    }
  }
});

export default function TemplateView(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [deleteClick, setDeleteClick] = React.useState(false);
  const [saveClick, setSaveClick] = React.useState(false);
  const [typing, setTyping] = React.useState(false);
  const [clear, setClear] = React.useState(props.clear);
  const [templateName, setTemplateName] = React.useState('');
  const [newName, setNewName] = React.useState('');

  let fields = props.fields
  let report = props.report;
  let selectedTemplate = props.template;
  let name = ''
  if(selectedTemplate)
  {
    name = selectedTemplate.name
  }

  if(name !== templateName && !typing && name !== '')
  {
    setTemplateName(name)
  }

  if(clear !== props.clear)
  {
    selectedTemplate = null
    setClear(props.clear)
    setTemplateName('')
  }

  const [currTemplate, setCurrTemplate] = React.useState({
    request:{},
    display:{},
    name:'',
  })

  if(name !== '')
  {
    currTemplate.oldName =  name

  }

  const cookies = new Cookies();
  React.useEffect(() => {
    if(saveClick)
    {
      if(newName !== '')
      {
        currTemplate.name = newName
      }
      makeModifyTemplate(currTemplate,report).then(res => res.json())
        .then(
          (result) => {
            setSaveClick(false)
            props.refresh()
          },
          (error) => {
            
          }
        );
    }
  }, [currTemplate, report, saveClick, newName ,props]);

  React.useEffect(() => {
    if(deleteClick)
    {
      deleteTemplate(currTemplate['name'],report).then(res => res.json())
        .then(
          (result) => {
            setDeleteClick(false)
            props.refresh()
          },
          (error) => {
            
          }
        );
    }
  }, [currTemplate, report, deleteClick, props]);

  if(selectedTemplate)
  {
    currTemplate['name'] = selectedTemplate.name

    let show = 'false'
    let list = []
    fields.forEach(element => {
      show = element.show
      if(selectedTemplate.schema.display.hasOwnProperty(element.requestHeader))
      {
        show = (selectedTemplate.schema.display[element.requestHeader] === 'true')?'true':'false'
      }
      element.show = show
      list.push(element)
    });

    fields = list
    if(clear)
      props.setClear(false)
    currTemplate['display'] = selectedTemplate.schema.display
  }

  if(cookies.get('permission') !== 'null')
  {
    return <></>
  }


  return (
    <>
      {props.hasTemplate && 
        <ThemeProvider theme={theme}>
          <Button varient="contained" onClick={()=>{setOpen(true)}}>Template</Button>
        </ThemeProvider>
      }
      <Dialog open={open} onClose={()=>{setOpen(false)}} fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11}>
              <FormControl sx={{ m: 1, width: '95%' }} variant="standard">
                <TextField 
                  id="text_field" 
                  label="Template Name" 
                  value={templateName} 
                  onChange={(e) => {
                    setNewName(e.target.value)
                    setTyping(true)
                    setTemplateName(e.target.value)
                    currTemplate.name = e.target.value
                  }}/>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
            <IconButton onClick={()=>setConfirmOpen(true)}>
              <DeleteIcon />
            </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TemplateInput 
                report={report}
                fields={fields} 
                template={selectedTemplate}
                clear={clear}
                saveClick={saveClick}
                hasTemplate={props.hasTemplate}
                setRequest={(request) => {
                  currTemplate['request'] = request
                }}
                setShowList={(list) => {
                  Object.entries(list).map((item) => {
                    currTemplate.display[item[0]] = item[1]  
                  })
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}}>Close</Button>
          <Button onClick={(e) => {
              selectedTemplate = null
              fields = []
              setClear(true)
              setSaveClick(true)
              setOpen(false)
            }}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={()=>{setConfirmOpen(false)}}>
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogActions>
          <Button onClick={()=>{setConfirmOpen(false)}}>No</Button>
          <Button onClick={(e) => {
              setDeleteClick(true)
              setConfirmOpen(false)
              setOpen(false)
            }}>Yes</Button>
        </DialogActions>
      </Dialog>
    </> 
  );
}

