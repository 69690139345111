import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SettingAttributeChip from './setting-attribute-chip';
import AddSettingsButton from './component-add-settings-button';
import DialogContent from '@mui/material/DialogContent';
import MixedSearchInput from '../../entity-details/inputs/mixed-search-input';
import Cookies from 'universal-cookie';
import AutomationRules from '../automation-rules';

const deleteSeting = (target, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: target,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:setting_package,
    setting:target
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function RoleChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [types, setTypes] = React.useState(props.data.types)
  const [size, setSize] = React.useState(1)
  const [delId, setDelId] = React.useState(0)
  const [addId, setAddId] = React.useState(0)
  const [icon, setIcon] = React.useState(<DeleteIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState(props.data.name)
  const [deleteTarget, setDeleteTarget] = React.useState({click: false})
  const [addTarget, setAddTarget] = React.useState({click: false})

  React.useEffect(() => {
    if(deleteTarget.click)
    {
      deleteSeting('MailingRole', delId).then(res => res.json())
      .then(
        (result) => {
          setDeleteTarget({click:false})
          props.refresh()
        })
    }
  },[delId,deleteTarget.click ,props])

  React.useEffect(() => {
    if(addTarget.click)
    {
      addSetting('mailing_role', {role_id:props.data.id, mt_id:addId}).then(res => res.json())
      .then(
        (result) => {
          setAddTarget({click:false})
          props.refresh()
        })
    }
  },[addId,addTarget, props])

  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon style={{paddingRight:"2%"}}/>)
      setOpen(!open)
      setSize(1.5)
      setChipTitle((
        <TextField id="outlined-basic" variant="outlined" value={chipTitle} size="small"/>
      ))
    }
  };

  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      if(props.data.name !== textValue)
      {
        setConfirmChange(true)
        handleDelete(event)
      }
      else
      {
        setChipTitle(props.data.name)
        setOpen(!open)
        setIcon(<DeleteIcon/>)
      }
      
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setOpen(!open)
    setConfirmOpen(false);
    setConfirmChange(false)
  };

  const handleRemove = () => {
    props.onDelete(props.data.id)
    handleClose()
  };

  const handleChange = () => {
    props.onChange(props.data.id,textValue)
    handleClose()
    setChipTitle(textValue)
    setOpen(!open)
    setIcon(<DeleteIcon/>)
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <CustomChip
            size={size}
            label={<>
              {!open && chipTitle}
              {open && <>
                <TextField id="outlined-basic" variant="outlined" 
                  value={textValue} 
                  onChange={(e) => {setTextValue(e.target.value)}}
                  size="small"/>      
              </>}
            </>
            }
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
          />
        </Grid>
        {open &&
          <>
            <Grid item xs={1}/>
            <Grid item xs={2}>
              <AddSettingsButton size='large' title="Link mailing type" handleSave={(e) => {
                setAddTarget({click:true})
              }}>
                <MixedSearchInput 
                  type="mailing_type"
                  onChange={(value) => {
                    setAddId(value)
                  }}
                />
              </AddSettingsButton>
            </Grid>
            <Grid item xs={1}>
              <AutomationRules 
                roleId={props.data.id} 
                roleName={props.data.name}
                />
            </Grid>
          </>
        }
        {open &&
        <Grid item xs={2}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {types.map(element => (
                  <Grid key={'mt'+element.refId} item>
                    <SettingAttributeChip
                      key={'r-'+element.refId} 
                      data={element} 
                      onDelete={(id) => {
                        setDelId(id)
                        setDeleteTarget({click:true})
                      }}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      </Grid>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>This cannot be undone.</DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmChange} onClose={handleClose}>
        <DialogTitle>Save Changes?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleChange}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
