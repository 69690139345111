import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

export default function EntityMenuItem(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleClick = (event) => {
    setConfirmOpen(!confirmOpen)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

//<Button sx={{ '&:hover': {backgroundColor:'#ff5252'}, textColor:'white'}} type="contained" onClick={handleClose}>Close</Button>

  return (
    <div>
      <MenuItem onClick={handleClick}>{props.itemName}</MenuItem>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
          {props.children}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
