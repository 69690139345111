import * as React from 'react';
import { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import EventSelect from './input/event-select';
import ManageMapsButton from './views/manage-events';
import EventViewAccordions from './views/event-view';
import EventDetails from './views/event-detail';
import RoleView from './views/role-view';
import EventTypeSelect from './input/event-type-select';

const removeComponent = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    component_name: 'event'
  }

  return fetch(cookies.get('apiUrl')+"/user/remove/component", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EventManagementComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const menuId = 'report-component-menu-id';
  const [pending, setPending] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [type, setType] = React.useState(-1)
  const [event, setEvent] = React.useState(-1)
  const [hasSelected, setHasSelected] = React.useState(false)

  const [deleteClick, setDeleteClick] = React.useState(false)
  React.useEffect(() => {
    if(deleteClick)
    {
      setPending(true);
      removeComponent().then(res => res.json())
        .then((result) => {
          setPending(true);
          setDeleteClick(false)
          window.location.reload();
        },
        (error) => {
          setPending(true);
          setError(error);
        });
    }
  },[deleteClick])

  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
      <Paper className={props.className} direction="columns" style={{ height: '100%'}}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <IconButton
                size="large"
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleReportMenuOpen}
                color="inherit"
            >
              <Badge>
                <MenuIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <div className="component_header">
              <Typography variant="h5" component="h4" >Event Management </Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            <ManageMapsButton/>
          </Grid>
          <Grid item xs={6}>
            <Suspense fallback={<div>Loading...</div>}>
              <EventTypeSelect callback={(value)=>{
                setType(value)
              }}/> 
            </Suspense>
          </Grid>
          <Grid item xs={6}>
            <Suspense fallback={<div>Loading...</div>}>
              <EventSelect type={type} callback={(value)=>{
                setEvent(value)
                if(type === -1 && value === -1)
                  setHasSelected(false)
                else setHasSelected(true)
              }}/> 
            </Suspense>
          </Grid>
          {hasSelected && (<Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <EventDetails event={event} type={type}/>
              </Grid>
              <Grid item xs={12} >
                <RoleView event={event} />
              </Grid>
              <Grid item xs={12}>
                <EventViewAccordions event={event}/>
              </Grid>
            </Grid>
          </Grid>)}
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={()=>setDeleteClick(true)}>Remove Component</MenuItem>
        </Menu>
      </Paper>
  );
}
