import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import ReportTable from './report-table';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

// Build report through API
const buildReport = (report, request) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    request: request,
    report: report
  };

  return fetch(cookies.get('apiUrl') + '/report/build', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

// Retrieve aggregates from db
const getAggregates = (report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    report: report
  };

  return fetch(cookies.get('apiUrl') + '/report/get/aggregate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

// Set color palette for report view component
const theme = createTheme({
  palette: {
    primary: {
      main: "#0d0907",
      contrastText: "#fff" //button text white instead of black
    },
    background: {
      default: "#394764"
    }
  }
});

// Build report table columns from given fields
const buildColumns = (list) => {
  let columns = [];

  list.map(value => {
    if (value.checked) {
      // set prefined column width if available
      let width = 120
      if (value.hasOwnProperty('width')) {
        width = value.width;
      }

      // Give checkbox to non-null boolean fields
      if (value.field.split('_')[0] === 'award') {
        columns.push({
          field: value.field,
          headerName: value.label,
          width: width,
          renderCell: (params) => (
            <>
              {params.value !== undefined &&
                <Checkbox
                  checked={params.value}
                  pointer-events='none'
                />}
            </>
          ),
        });
      }
      else {
        // Add specified field to array as a column
        columns.push({
          field: value.field,
          headerName: value.label,
          width: width
        });
      }
    }
  });

  return columns;
}

export default function ReportView(props) {
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState(props.report);
  const fields = props.fields;
  const [reportData, setReportData] = React.useState([]);
  const [aggregates, setAggregates] = React.useState([]);
  const [loadingReportData, setLoadingReportData] = React.useState(false);
  const [errorReportData, setErrorReportData] = React.useState(null);
  const [errorReportInput, setErrorReportInput] = React.useState(false);
  const [inputValues, setInputValues] = React.useState(props.inputValues);
  var template = props.template;

  React.useEffect(() => {
    if (open) {
      const cookies = new Cookies();
      let permission = (cookies.get('permission') !== 'null' ? cookies.get('permission') : true);
      let build = true;

      //precheck to see if the value is hidden with a template
      if (permission !== true) {
        for (let i = 0; i < permission.length; i++) {
          let restriction_name = permission[i].permission.restriction;
          for (let f = 0; f < fields.length; f++) {
            if (fields[f].requestHeader === restriction_name && !inputValues.hasOwnProperty(restriction_name)) {
              build = false;
            }
            else if (fields[f].requestHeader === restriction_name && inputValues.hasOwnProperty(restriction_name)) {
              build = (inputValues[restriction_name] !== 'All' && inputValues[restriction_name] !== undefined);
            }
            //check to see if the field is defaulted from template
            if (template) {
              let test = JSON.stringify(template.schema.display)
              if (test === '{}' || test === '[]') {
                build = true;
              }
              if (template.schema.display[restriction_name] === 'false') {
                build = true;
              }
            }
          }
        }
      }

      if (build) {
        setLoadingReportData(true);
        buildReport(report, inputValues)
          .then(res => res.json())
          .then((result) => {
            setReportData(result.response);
            setLoadingReportData(false);
            setErrorReportData(null);
          }, (error) => {
            setReportData([]);
            setLoadingReportData(false);
            setErrorReportData(error);
          });
      }
      else setReportData([]);

      setErrorReportInput(build);
    }
  }, [report, inputValues, open, fields, template]);

  React.useEffect(() => {
    if (open) {
      getAggregates(report)
        .then(res => res.json())
        .then((result) => {
          let sum = 0;
          let newAgg = [];
          let name = '';
          result.response.forEach(aggregate => {
            sum = 0;
            reportData.forEach(record => {
              sum += record[aggregate];
            });
            name = aggregate.charAt(0).toUpperCase() + aggregate.slice(1);
            newAgg.push((<Typography>{name} Aggregate: ${sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>));
          });
          setAggregates(newAgg);
        }, (error) => { });
    }
  }, [reportData, open, report]);

  if (props.report !== report) {
    setReport(props.report);
  }

  if (props.inputValues !== inputValues) {
    setInputValues(props.inputValues);
  }

  if (report === null) {
    return <></>;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          varient="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Build
        </Button>
      </ThemeProvider>
      <Dialog open={open} onClose={() => { setOpen(false) }} maxWidth="lg">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {!loadingReportData && errorReportData &&
            (
              <Grid container>
                <Grid item xs={2}>
                  <ErrorIcon color="danger" />
                </Grid>
                <Grid item xs={8}>
                  <Typography>There was an error processing the report</Typography>
                </Grid>
                <Grid item xs={2}>
                  <ErrorIcon color="danger" />
                </Grid>
              </Grid>
            )}
          {loadingReportData &&
            <CircularProgress color="warning" />}
          {!loadingReportData && !errorReportInput &&
            <Typography>You must select a region</Typography>}
          {!loadingReportData && errorReportInput && !errorReportData && reportData.length === 0 &&
            <Typography>No Report Data</Typography>}
          {!loadingReportData && reportData.length > 0 && (
            <Stack spacing={2}>
              <ReportTable
                data={reportData}
                columns={buildColumns(props.columns)}
              />
              <Stack spacing={1}>
                {aggregates}
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (props.template === null) {
              window.location.reload();
            }
            setOpen(false);

          }}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
