import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

export default function TableSelect(props) {
  const [val, setVal] = React.useState(props.val);
  const list = props.list

  return (
    <FormControl varient="standard" fullWidth>
      <Select
        labelId="method-select-label"
        id="method-select"
        value={val}
        disabled={props.disabled}
        onChange={(e) => {
          setVal(e.target.value)
          props.callback({id:props.id, field:props.field, value:e.target.value}, null)
        }}
      >
        {list.map((t)=> {
          return <MenuItem dense key={props.field+'-'+t.id} value={t.id}>{t.name}</MenuItem>})}
      </Select>
    </FormControl>
  );
}
