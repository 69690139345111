import React, { Component } from 'react';
import TtBody from './core-layout/tt-body'
import TtHeader from './core-layout/tt-header'
import LoginBody from './login-layout/login-body';
import LoginHeader from './login-layout/login-header';
import Box from '@mui/material/Box';

export default function Core(props) {
    const { auth, config } = props;    
    const ref = React.useRef(null);
    if(auth === 'login')
    {
      return (
        <div>
          <LoginHeader trail_name="Trail Tracker"/>
          <Box>
            <LoginBody/>
          </Box>
        </div>
      );  
    } 
    else
    {
      return (
        <div>
          <TtHeader trail_name="Finger Lakes Trail"/>
          <Box sx={{ pb: 7 }} ref={ref}>
            <TtBody components={config.components}/>
          </Box>
        </div>
      );
    }
}

