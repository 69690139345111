import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import EntitySearchInput from '../../../core-components/entity-details/inputs/entity-search-input';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function RtcChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.rtc.name)
  const [type, setType ] = React.useState(props.type)
  const [data, setData] = React.useState(props.data)
  const [edit,setEdit] = React.useState(props.canEdit) 
  const [search,setSearch] = React.useState('') 
  const size = 1

  const [icon, setIcon] = React.useState((<></>))
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  if(props.data !== -1)
  {
    if(props.data.id !== data.id)
    {
      setData(props.data)
      setType(props.type)
      setEdit(props.canEdit)
      setChipTitle(props.data.rtc.name)
      setOpen(false)
      setIcon(<></>)
    }
    if(props.data.rtc.name !== chipTitle)
    {
      setChipTitle(props.data.rtc.name)
    }
  }
  
  if(props.data === -1 || data === -1 )
  {
    return <></>
  }

  const handleEdit = (event) => {
    if(open && type !== 'Region'){
      setOpen(!open)
      setIcon((<></>))
      setChipTitle(data.rtc.name)
    }
  };

  const handleClick = (event) => {
    if(edit)
    {
      setConfirmOpen(true)
    }
    else if(!open)
    {
      setIcon(<CloseFullscreenIcon/>)
      setOpen(!open)
      setChipTitle(data.rtc.region)
    }
    
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };


  const handleSearch = (value) => {
    setSearch(value)
  };

  return (
    <div sx={{p:1}}>
      <CustomChip
          size={size}
          label={chipTitle}
          variant="filled"
          onDelete={handleEdit}
          onClick={handleClick}
          deleteIcon={icon}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Change RTC</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{pt:1}}>
            <EntitySearchInput 
              type="person"
              label="Search"
              onChange={(value) => {handleSearch(value)}}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={() => {
            handleClose()
            props.onChange(search)
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
