import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AttributeChip from '../inputs/component-attribute-chip';
import MailingTypeChip from '../inputs/component-mailing-type-chip';
import Divider from '@mui/material/Divider';
import AddDetailButton from '../inputs/component-add-detail-button';
import MixedSearchInput from '../inputs/mixed-search-input';
import Cookies from 'universal-cookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

const addRole = (id,name) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    attribute: name,
    type:'role'
  }

  return fetch(cookies.get('apiUrl')+"/detail/attribute/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const changeType = (id,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/detail/update/type", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function GeneralDetails(props) {
  const [type, setType] = React.useState({new:props.entity.type,old:props.entity.type});
  const mailing = props.data.mailing
  const [roles, setRoles] = React.useState(props.data.roles);
  const [eventData, setEventData] = React.useState({click: false, roleSelect:''})
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const cookies = new Cookies();
  var permission = (cookies.get('permission') !== 'null')

  React.useEffect(() => {
    if(eventData.click)
    {
      addRole(props.entityId, eventData.roleSelect).then(res => res.json())
      .then(
        (result) => {
          props.refresh()
        })
    }
  },[props, eventData])

  React.useEffect(() => {
    if(type.old !== type.new)
    {
      changeType(props.entityId, type.new).then(res => res.json())
      .then(
        (result) => {
          props.idChange(result.response, type.new)
          
        })
    }
  },[props, type])

  const handleChange = (event) => {
    setConfirmOpen(true)
    type.old = type.new
    type.new = event.target.value
  };

  const cleanAttrib = (id) => {
    props.refresh()
  } 

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <form>
      <Box sx={{ minWidth: 120 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} justifyContent="center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type.new}
                label="Type"
                onChange={handleChange}
                disabled={permission}
              >
                <MenuItem value={"Person"}>Person</MenuItem>
                <MenuItem value={"Family"}>Family</MenuItem>
                <MenuItem value={"Club"}>Club</MenuItem>
                <MenuItem value={"Business"}>Business</MenuItem>
                <MenuItem value={"Government"}>Government</MenuItem>
                <MenuItem value={"Association"}>Association</MenuItem>
                <MenuItem value={"Foundation"}>Foundation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={8} >
                <Typography styles={{paddingTop:'2%'}}>
                  Roles
                </Typography>
              </Grid>
              <Grid item xs={4} >
                <AddDetailButton 
                  title="Add role"
                  handleSave={(e) => {
                      eventData.click = !eventData.click
                      setEventData({...eventData})
                    }}>
                  <MixedSearchInput
                    label={'Search'}
                    type={'role'}
                    onChange={(value) => {eventData.roleSelect = value}}
                  />
                </AddDetailButton>
              </Grid>
            </Grid>
            <Divider/>
            <Stack spacing={1}>
              {roles.map(element => (
              <AttributeChip 
                key={element.id} 
                data={element}
                onClear={(id) => cleanAttrib(id)}
                >
              </AttributeChip>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography >
              Mailing
            </Typography>
            <Divider sx={{paddingTop:'4.5%'}}/>
            <Stack spacing={1}>
              {mailing.map(element => (<MailingTypeChip key={element.id} entity={props.entityId} data={element}></MailingTypeChip>))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Stack spacking={1}>
            <p>If you are changing to and from the Person type, this cannot be undone. </p>
            <p>This record may loose all form data, affiliate/affiliation data, public entity data, maintainer data, deceased data,
               event data, and the name formatting may process incorrectly.</p>
            <p>Please click with care.</p>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={(e) => {
              setType({...type})
              setConfirmOpen(false)
            }}>Save</Button>
        </DialogActions>
      </Dialog>
    </form>        
  );
}
