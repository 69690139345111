import * as React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddListItem from '../input/add-list-item';
import ReportSearchInput from './report-search-input';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';

const fetchAutolist = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: type,
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ReportListInput(props) {
  const [type, setType ]  = React.useState(props.type);
  const [list, setList ]  = React.useState([]);
  const [idList, setIdList ]  = React.useState([]);
  const [newItem, setNewitem ]  = React.useState('');
  const [showField, setShowField] = React.useState(props.showVal);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);
  const [defaultVal, setDefaultVal] = React.useState(null);
  const [trigger, setTrigger] = React.useState(false);
  const [template, setTemplate ] = React.useState(props.template)

  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onShow(val)
  };

  React.useEffect(() => {
    if(trigger)
    {
      fetchAutolist(type).then(res => res.json())
        .then(
          (result) => {
            let response = result.response
            setTrigger(false)
            switch(type)
            {
              case "forms":
                response = response.forms
                break
            }
            let currList = [];
            response.map(element => {
              idList.map((exc) => {
                if(element.id === exc) currList.push(element.name)
              })
            })
            setList(currList)
          },
          (error) => {
          }
        );
      }
  }, [type, trigger]);

  if(defaultVal !== props.default && props.default)
  {
    setDefaultVal(props.default)
    setIdList(props.default)
    setTrigger(true)
  }

  if(template !== props.template)
  {
    setTemplate(props.template)
    setIdList([])
    setTrigger(true)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
        <Divider/>
      </Grid>
      <Grid item xs={1}>
        <AddListItem 
          title={`Add ${type}`} 
          handleSave={(e) => {
            if (!list.includes(newItem.name)) {
              list.push(newItem.name)
              idList.push(newItem.id)
              props.onChange(idList)
              setIdList([...idList])
              setList([...list])
            }
          }}>
          <Stack spacing={2}>
            <Box sx={{ width: 300 }}> 
              <ReportSearchInput type={type} onChange={(value, name)=>{
                setNewitem({id:value, name:name})
              }} />
            </Box>
          </Stack>
        </AddListItem>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          {list.map(element => (
            <Grid item >
              <div>
                <Chip 
                  label={element} 
                  onDelete={() => {
                    let index = list.indexOf(element)
                    if ( index > -1) {
                      list.splice(index, 1)
                      idList.splice(index, 1)
                      props.onChange(idList)
                      setIdList([...idList])
                      setList([...list])
                    }
                  }}
                  deleteIcon={(<DeleteIcon/>)}
                ></Chip>
              </div>
            </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>  
  )
}
