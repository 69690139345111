import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import MoreIcon from '@mui/icons-material/MoreVert';
import AddComponentButton from './components/add-compnent';
import Cookies from 'universal-cookie';
import SearchBar from './components/search-bar-component';
import SettinsDrawer from './components/core-components/settings/settings-drawer-component';
import { useMediaQuery } from 'react-responsive';
import PasswordReset from './components/header-components/password-reset';
import LogoutIcon from '@mui/icons-material/Logout';
import Documentation from './components/header-components/documentation';
import Notifications from './components/header-components/notifications';
import DeveloperTasks from './components/header-components/developer-tasks';


export default function TtHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [hideMenu,setHideMenu] = React.useState(false);


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const isMobile = useMediaQuery({ query: `(max-width: 750px)` });  

  const handleLogout = (e) => {
    e.preventDefault();
    const cookies = new Cookies();

    fetch(cookies.get('apiUrl')+"/api/terminate", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        user: cookies.get('user')
      })
    }).then(res => res.json())
      .then((result) => {
        cookies.set('user', '')
        cookies.set('credToken', '')
        window.location.reload();
      })
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <AddComponentButton itemTitle={<p>Add Component</p>}/>
      <PasswordReset itemTitle={<p>Change Password</p>}/>
      <Documentation itemTitle={<p>Documentation</p>}/>
      <DeveloperTasks itemTitle={<p>Report Issue</p>}/>
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="large"
          color="inherit"
        >
          <Badge>
            <LogoutIcon/>
          </Badge>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <AddComponentButton itemTitle={<p>Add Component</p>}/>
      <Notifications itemTitle={<p>Notifications</p>}/>
      <PasswordReset itemTitle={<p>Change Password</p>}/>
      <Documentation itemTitle={<p>Documentation</p>}/>
      <DeveloperTasks itemTitle={<p>Report Issue</p>}/>
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="large"
          color="inherit"
        >
          <Badge>
            <LogoutIcon/>
          </Badge>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <SettinsDrawer/>
          {!isMobile && <>
            <div style={{
              width:{
                md:'200px'
              },
              paddingRight:'1%'
            }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { sm: 'none', md: 'block' }}}
              >
                {props.trail_name}
              </Typography>
            </div>
          </>}
          <Box sx={{flexGrow:1}}>
            <Stack direction="row">
              <SearchBar onChange={(hide)=>{
                setHideMenu(hide)
              }}/>
            </Stack>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Notifications/>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          {!hideMenu &&
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          }
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}