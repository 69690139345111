import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import Cookies from 'universal-cookie';


const recordTransaction = (id,data,gift = null) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:id,
    type:'Purchase',
    transaction:{
      contrib: data.contrib,
      anonymous:data.anon,
      payment:data.amount,
      paid_by:data.method,
      description:data.desc,
      account_id:data.account,
      receipt_date:data.receiptDate.toISOString().split('T')[0],
      memo:data.memo,
      recognition:null,
      gifter:gift,
    }
  }

  return fetch(cookies.get('apiUrl')+"/transaction/record", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function AddPurchaseButton(props) {
  const id = props.entityId;
  const {tPackage, type } = props;

  const [isSoft, setIsSoft] = React.useState(false);
  const [transStatus, setTransStatus] = React.useState('Sending donation...');

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState({
    anon:'No',
    amount: '', 
    account:'', 
    receiptDate: new Date(),
    memo:'',
    method:'',
    contrib:0,
    soft:false,
    desc:'',
    apply:'Yes',
    recognition:null
  });

  if(!tPackage.apply) {
    transaction.apply = "No"
  }

  const [inputErrors, setInputErrors] = React.useState({
    amount:{error:false, message:'Cannot be empty and must be a number value'},  
    method:{error:false, message:'Please select a payment method!'},
    desc:{error:false, message:'Please select a solicitation!'},
    account:{error:false, message:'Please select an account!'},
    recognition_name:{error:false}
  });

  const [recognition, setRecognition] = React.useState({
    send:'No',
    type:'In Honor',
    target:null
  });

  const [pass, setPass] = React.useState({
    click:false,
    data: {}
  });

  React.useEffect(() => {
    if(pass.click)
    {
      pass.click = false
      setLoadingOpen(true)
      recordTransaction(id, pass.data).then(res => res.json())
      .then(
        (result) => {
          setTransStatus('Success!');
        },(error) =>{
          setTransStatus('There was an issue processing your request');
        })
    }
  },[id, pass])

  const handleChange = (prop) => (event) => {
    setTransaction({ ...transaction, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDate = (value) => {
    transaction.receiptDate = value;
    setTransaction({...transaction})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSoft = (event) => {
    setIsSoft(event.target.value);
  };

  const handleSave = () => {
    let check = true

    if(transaction.account === '')
    {
      check = false
      inputErrors.account.error = true
    }
    else inputErrors.account.error = false

    if(transaction.desc === '') 
    {
      check = false
      inputErrors.desc.error = true
    }
    else inputErrors.desc.error = false

    if(transaction.amount === '' || !isInt(transaction.amount)) 
    {
      check = false
      inputErrors.amount.error = true
    }
    else inputErrors.amount.error = false

    if(transaction.method === '')
    {
      check = false
      inputErrors.method.error = true
    }
    else inputErrors.method.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      pass.data = transaction
      setPass({...pass})    
    }
    
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Purchase</MenuItem>
      <Dialog open={open} fullWidth>
        <DialogTitle>Purchase</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>         
          <Stack spacing={2} >
            { tPackage.contributors.length > 0 &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Contributor</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.contrib}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                  
                >
                  <MenuItem value={0}>None</MenuItem>
                  {tPackage.contributors.map((t) => <MenuItem key={"cccsd"+t.id} value={t.id}>{t.firstName + " " + t.lastName}</MenuItem>)}
                </Select>
              </FormControl>
            }
            {  tPackage.apply &&
              <FormControl fullWidth>
                <InputLabel id="apply-select-label">Apply To Family</InputLabel>
                <Select
                  labelId="apply-select-label"
                  id="apply-select"
                  value={transaction.apply}
                  label="Apply to Family"
                  onChange={handleChange('apply')}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl fullWidth>
              <InputLabel id="anon-select-label">Anonymous</InputLabel>
              <Select
                labelId="anon-select-label"
                id="anon-select"
                value={transaction.anon}
                label="Anonymous"
                onChange={handleChange('anon')}
                required={true}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Account</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.account}
                label="Account"
                onChange={handleChange('account')}
                error={inputErrors.account.error}
              >
                {tPackage.accounts.map((t)=> {
                  if(t.type === 'Purchase')
                    return <MenuItem key={"cccsw"+t.id} value={t.id}>{t.name}</MenuItem>})}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Description</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.desc}
                label="description"
                onChange={handleChange('desc')}
                error={inputErrors.desc.error}
              >
                {tPackage.descriptions.map((t)=> {
                  if(t.type === 'Purchase')
                    return <MenuItem key={"cccsw"+t.id} value={t.id}>{t.name}</MenuItem>})}
              </Select>
            </FormControl>
            <Stack direction="row">
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={transaction.amount}
                  onChange={handleChange('amount')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Amount"
                  error={inputErrors.amount.error}
                />
              </FormControl>
              <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Payment Method</InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={transaction.method}
                  label="Payment Method"
                  onChange={handleChange('method')}
                  error={inputErrors.method.error}
                >
                  <MenuItem value="Cash or Check">Cash/Check</MenuItem>
                  <MenuItem value="Credit/Debit Card">Credit/Debit Card</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Stripe">Stripe</MenuItem>
                  <MenuItem value="In-Kind">In-Kind</MenuItem>
                  <MenuItem value="Eventbrite">Eventbrite</MenuItem>
                  <MenuItem value="Direct Deposit">Direct Deposit</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Receipt Date"
                  openTo="month"
                  views={['month', 'day', 'year']}
                  value={transaction.receiptDate}
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                  required={true}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Memo"
                multiline
                maxRows={4}
                value={transaction.memo}
                onChange={handleChange('memo')}
              />
            </FormControl>
            { id.split('-')[0] === 'a' &&
              <Stack direction="row">
                <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                  <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Soft Credit</InputLabel>
                  <Select
                    labelId="method-select-label"
                    id="method-select"
                    value={isSoft}
                    label="Soft Credit"
                    onChange={handleSoft}
                    required
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={transaction.amount}
                    onChange={handleChange('soft')}
                    label="soft"
                    disabled={isSoft}
                    required={isSoft}
                  />
                </FormControl>
              </Stack>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loadingOpen}>
        <DialogTitle>{transStatus}</DialogTitle>
        <DialogActions>
          <Button onClick={(e) => {
              setLoadingOpen(false)
              setConfirmOpen(false)
              setOpen(false)
              setTransaction({
                anon:'No',
                amount: '', 
                account:'', 
                receiptDate: new Date(),
                memo:'',
                method:'',
                contrib:0,
                soft:false,
                desc:'',
                apply:'No',
                recognition:null
              })
            }}>okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
