import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';

const updateEvent = (id, data) => {
  const cookies = new Cookies();
  let user = ((cookies.get('user') !== undefined) ? cookies.get('user') : '');
  let token = ((cookies.get('credToken') !== undefined) ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    data: data,
  };

  return fetch(cookies.get('apiUrl') + '/events/change', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function EventDetails(props) {
  const [data, setData] = React.useState({
    name: '',
    type: '',
    parentEventFlag: false,
    parentEventId: null,
    click: false,
    start: '',
    end: ''
  });
  const [name, setName] = React.useState(props.name);
  const [type, setType] = React.useState(props.type);
  const [startDate, setStartDate] = React.useState(new Date(props.event.start));
  const [endDate, setEndDate] = React.useState(new Date(props.event.end));
  const [disabled, setDisabled] = React.useState(true);
  const [icon, setIcon] = React.useState(<></>);
  const [event, setEvent] = React.useState();
  const [parentEventFlag, setParentEventFlag] = React.useState(new Date(props.event.parent_event_flag));
  const [parentEventId, setParentEventId] = React.useState(props.event.parent_event_id);

  React.useEffect(() => {
    if (data.click) {
      setIcon(<CircularProgress />)
      setDisabled(true)
      updateEvent(props.event.id, data)
        .then(res => res.json())
        .then((result) => {
          setIcon(<CheckIcon />);
        }, (error) => {
          setIcon(<ErrorIcon />);
        });
    }
  }, [props, data])

  if (props.event.type === -1) {
    return <></>;
  }

  if (event !== props.event) {
    setEvent(props.event);
    setName(props.event.name);
    setType(props.event.type);
    setStartDate(new Date(props.event.start));
    setEndDate(new Date(props.event.end));
    setParentEventFlag(props.event.parent_event_flag);
    setParentEventId(props.event.parent_event_id);
    setIcon(<></>);
    setDisabled(true);
  }


  const handleChange = (prop) => (event) => {
    if (prop === 'name') {
      setName(event.target.value);
    }

    if (prop === 'type') {
      setType(event.target.value);
    }

    setDisabled(false)
    setIcon(<SaveIcon />);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '95%' },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={0}>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <TextField id="outlined-basic"
              size="small"
              label={type + " name"}
              variant="outlined"
              value={name}
              onChange={handleChange('name')} />
          </FormControl>
        </Grid>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <TextField
              id={'change-type-select'}
              size="small"
              select
              label="Select Type"
              value={type}
              onChange={handleChange('type')}
              className=""
            >
              <MenuItem key={'Tracking'} value={'Tracking'}>
                Tracking
              </MenuItem>
              <MenuItem key={'Basic'} value={'Basic'}>
                Series
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth variant="filled">
              <MobileDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  setDisabled(false);
                  setIcon(<SaveIcon />);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} >
          <FormControl fullWidth variant="filled">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  setDisabled(false);
                  setIcon(<SaveIcon />);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={8} >
        </Grid>
        <Grid item xs={4} >
          <IconButton
            size="large"
            disabled={disabled}
            onClick={() => {
              setData({
                name: name,
                type: type,
                click: true,
                parentEventFlag: parentEventFlag,
                parentEventId: parentEventId,
                start: startDate.toISOString().split('T')[0],
                end: endDate.toISOString().split('T')[0]
              });
            }}
          >
            {icon}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
