import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AddEmButton from '../input/add-em-button';
import EventRewardChip from '../input/event-reward-chip';
import EventRewardSelect from './event-reward-select';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

/**
 * Checks if input is an integer
 * 
 * @param {*} value 
 * @returns true if integer, false otherwise
 */
const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function EventRuleChip(props) {
  const [open, setOpen] = React.useState(false);
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name);
  const [size, setSize] = React.useState(1);
  let edit = ((props.canEdit) ? <EditIcon /> : <></>);
  const [icon, setIcon] = React.useState(edit);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [disabled, setDisabled] = React.useState(props.disabled);
  const [ruleName, setRuleName] = React.useState(props.data.name);
  const [conditionAmount, setConditionAmount] = React.useState(props.data.constraint);
  const [description, setDescription] = React.useState(props.data.description);
  const [roles, setRoles] = React.useState(props.data.roles !== undefined ? props.data.roles.split(',') : []);
  const [awards, setAwards] = React.useState(props.data.awards !== undefined ? props.data.awards.split(',') : []);
  const [active, setActive] = React.useState(props.data.active);
  const [newRole, setNewRole] = React.useState(false);
  const [tempRole, setTempRole] = React.useState(false);
  const [deleteRole, setDeleteRole] = React.useState(false);
  const [newAward, setNewAward] = React.useState(false);
  const [tempAward, setTempAward] = React.useState(false);
  const [deleteAward, setDeleteAward] = React.useState(false);
  const [inputErrors, setInputErrors] = React.useState({
    amount: { error: false, message: 'Cannot be empty and must be a number value' },
    name: { error: false, message: 'Please enter a name for the rule!' }
  });
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (newRole !== false) {
      // Add new role to roles if not already in there to prevent duplicates
      if (roles.indexOf(newRole) === -1) {
        roles.push(newRole);
      }
      setNewRole(false);
    }
  }, [newRole]);

  React.useEffect(() => {
    if (deleteRole !== false) {
      // Check if target role is in roles to be deleted
      if (roles.indexOf(deleteRole) !== -1) {
        roles.splice(roles.indexOf(deleteRole));
      }
      setDeleteRole(false);
    }
  }, [deleteRole]);

  React.useEffect(() => {
    if (newAward !== false) {
      // Add new award to awards if not already in there to prevent duplicates
      if (awards.indexOf(newAward) === -1) {
        awards.push(newAward);
      }
      setNewAward(false);
    }
  }, [newAward]);

  React.useEffect(() => {
    if (deleteAward !== false) {
      // Check if target award is in awards to be deleted
      if (awards.indexOf(deleteAward) !== -1) {
        awards.splice(awards.indexOf(deleteAward));
      }
      setDeleteAward(false);
    }
  }, [deleteAward]);

  // Update data on refresh and check if component is supposed to be accessible
  React.useEffect(() => {
    setRuleName(props.data.name);
    setConditionAmount(props.data.constraint);
    setDescription(props.data.description);
    setRoles(props.data.roles != '' ? props.data.roles.split(',') : []);
    setAwards(props.data.awards != '' ? props.data.awards.split(',') : []);
    setActive(props.data.active);
    setDisabled(props.disabled);
  }, [props.refresh]);

  React.useEffect(() => {
    let check = true;

    // Check for input error fails if either input errors are set
    if (inputErrors.name.error || inputErrors.amount.error) {
      check = false;
    }

    setError(!check);
  }, [inputErrors.name.error, inputErrors.amount.error]);

  const handleEdit = (event) => {
    setConfirmChange(true);
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const handleCancel = () => {
    setConfirmDelete(false);
  };

  const handleClose = () => {
    setRuleName(props.data.name);
    setConfirmDelete(false);
    setConfirmChange(false);
    props.refresh();
    setOpen(false);
  };

  const handleRemove = () => {
    handleClose();
    props.onDelete(props.data.name);
  };

  const handleCheck = (event) => {
    setActive(event.target.checked);
  };

  const handleChange = (event) => {
    // Only apply changes if no error
    if (!error) {
      handleClose();
      props.data.name = ruleName;
      props.data.description = description;
      props.data.constraint = conditionAmount;
      props.data.awards = awards.join(',');
      props.data.roles = roles.join(',');
      props.data.active = active;
      props.onChange(props.data);
      setChipTitle(ruleName);
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={7}>
          <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onDelete={handleEdit}
            deleteIcon={icon}
            disabled={disabled}
            onClick={handleEdit}
          />
        </Grid>
      </Grid>
      <Dialog open={confirmDelete} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleCancel}>Cancel</Button>
          <Button type="contained" onClick={handleRemove}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmChange} onClose={handleClose}>
        <DialogTitle>
          <Stack direction='row'>
            Edit Rule
            <Checkbox
              checked={active}
              onChange={handleCheck}
              className=""
              disabled={disabled}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {<Stack spacing={1}>
            <InputLabel>Rule Name</InputLabel>
            <OutlinedInput
              value={ruleName}
              onChange={(e) => {
                // Rule name cannot be empty
                if (e.target.value === '') {
                  inputErrors.name.error = true;
                }
                else inputErrors.name.error = false;

                setRuleName(e.target.value);
              }}
              label="Rule Name"
              error={inputErrors.name.error}
            />
            <FormControl fullWidth variant="filled">
              <InputLabel>Amount</InputLabel>
              <OutlinedInput
                value={conditionAmount}
                onChange={(e) => {
                  // Amount must not be empty and must be an integer value
                  if (e.target.value === '' || !isInt(e.target.value)) {
                    inputErrors.amount.error = true;
                    setError(true);
                  }
                  else inputErrors.amount.error = false;

                  setConditionAmount(e.target.value);
                }}
                label="Amount"
                error={inputErrors.amount.error}
              />
            </FormControl>
            <FormControl fullWidth variant="filled">
              <TextField
                id="description-field"
                label='Description'
                multiline
                value={description}
                rows={4}
                variant="filled"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </FormControl>
            <FormControl fullWidth variant="filled">
              <Paper sx={{ maxHeight: '85px', maxWidth: '250px', overflow: 'auto' }}>
                <Stack spacing={1}>
                  <Stack direction="row">
                    <Typography>Roles</Typography>
                    <AddEmButton
                      handleSave={(e) => {
                        setNewRole(tempRole);
                        setTempRole(false);
                      }}
                      refresh={props.refresh}
                      title='Add Role'
                    >
                      <EventRewardSelect
                        label={'Search'}
                        type={'role'}
                        onChange={(value) => {
                          setTempRole(value);
                        }}
                      />
                    </AddEmButton>
                  </Stack>
                  <Stack spacing={1}>
                    {roles.map((option) => (
                      <Grid item xs={7} key={"gcr-" + option.id}>
                        <EventRewardChip
                          key={"cr-" + option.id}
                          data={option}
                          canEdit={true}
                          onDelete={(name) => {
                            setDeleteRole(name);
                          }}
                          refresh={props.refresh}
                        />
                      </Grid>
                    ))}
                  </Stack>
                </Stack>
              </Paper>
            </FormControl>
            <FormControl fullWidth variant="filled">
              <Paper sx={{ maxHeight: '85px', maxWidth: '250px', overflow: 'auto' }}>
                <Stack spacing={1}>
                  <Stack direction="row">
                    <Typography>Awards</Typography>
                    <AddEmButton
                      handleSave={(e) => {
                        setNewAward(tempAward);
                        setTempAward(false);
                      }}
                      refresh={props.refresh}
                      title='Add Award'
                    >
                      <EventRewardSelect
                        label={'Search'}
                        type={'award'}
                        onChange={(value) => {
                          setTempAward(value);
                        }}
                      />
                    </AddEmButton>
                  </Stack>
                  <Stack spacing={1}>
                    {awards.map((option) => (
                      <Grid item xs={7} key={"gca-" + option.id}>
                        <EventRewardChip
                          key={"ca-" + option.id}
                          data={option}
                          canEdit={true}
                          onDelete={(name) => {
                            setDeleteAward(name);
                          }}
                          refresh={props.refresh}
                        />
                      </Grid>
                    ))}
                  </Stack>
                </Stack>
              </Paper>
            </FormControl>
          </Stack>
          }
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleDelete}>Delete</Button>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={handleChange}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
