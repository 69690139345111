import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ReportSelectInput(props) {
  const [value, setValue] = React.useState(props.default);
  
  const [list, setList ]  = React.useState(props.list);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);
  const [showField, setShowField] = React.useState(props.showVal);
  
  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false"
    props.onShow(val)
  };

  let i = 0

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl varient="standard" fullWidth>
          <Select
            labelId="select"
            id="method-select"
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              props.onChange(e.target.value)
            }}
          >
            {list.map((t)=> {
              i++
              return <MenuItem dense key={props.fieldName+'-'+i} value={t.value}>{t.label}</MenuItem>})}
          </Select>
        </FormControl>
      </Grid>
    </Grid>  
  )
}
