import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Cookies from 'universal-cookie';

const fetchAutolist = (type, objects) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    type: type
  }

  if(objects)
  {
    ttl['objects'] = objects
  }

  return fetch(cookies.get('apiUrl')+"/search/autolist", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MixedSearchInput(props) {
  const [list, setList] = React.useState([]);
  const [type, setType] = React.useState(props.type);  
  const [objects, setObjects] = React.useState(props.objects)
  const [start, setStart] = React.useState(props.value)

  const [selectValue, setSelectValue] = React.useState(props.value)

  React.useEffect(() => {
    fetchAutolist(type, objects).then(res => res.json())
      .then(
        (result) => {
          setList(result.response)
        },
        (error) => {
        }
      );
  }, [type, objects, start]);

  return (
      <Autocomplete
        clearOnBlur={false}
        selectOnFocus
        id="combo-box-demo"
        onChange={(e, value) => {
          props.onChange(value)
        }}
        value={start}
        options={list}
        inputValue={start}
        onInputChange={(event, value) => {
          setStart(value);
        }}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
  );
}
