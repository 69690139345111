import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@material-ui/core/Container';
import Cookies from 'universal-cookie';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import PasswordComponent from './sub-components/component-password';

const addCopmonentModalRoot =makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 4),
  },
}));

const addComponentModalStyle = {
  borderRadius: 5,
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs:'50%',md:'25%'},
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};



const checkUser = (username, email) => {
  const cookies = new Cookies();

  const ttl = {
    user: username,
    email:email
  }

  return fetch(cookies.get('apiUrl')+"/api/validate/user", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const sendCode = (username, email, code) => {
  const cookies = new Cookies();
  const ttl = {
    user: username,
    email:email,
    code:code,
  }

  return fetch(cookies.get('apiUrl')+"/email/send/code", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const secureResetPassword = (username, email,password, code, token) => {
  const cookies = new Cookies();
  const ttl = {
    user: username,
    email:email,
    code:code,
    token:token,
    password:password
  }

  return fetch(cookies.get('apiUrl')+"/user/change/password/code", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const destroyTokens = (username) => {
  const cookies = new Cookies();
  const ttl = {
    user: username
  }

  return fetch(cookies.get('apiUrl')+"/destroy/reset/tokens", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function SecurePasswordReset(props) {
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState(1)
  const [emailCode, setEmailCode] = React.useState('');
  const [secureCode, setSecureCode] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [ passwordMatch, setPasswordMatch] = React.useState(true)
  const [ continueClick, setContinueClick] = React.useState(false)
  const [ saveClick, setSaveClick] = React.useState(false)
  const [ sendEmail, setSendEmail] = React.useState('pending')
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [tryCounter,setTryCounter] = React.useState(0);


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setStep(1)
  }

  React.useEffect(() => {
    if(continueClick)
    {
      checkUser(username,email).then(res => res.json())
        .then(
          (result) => {
            if(result.response !== false)
            {
              setSendEmail('send')
              setSecureCode(result.response)
              setContinueClick(false)
              setStep(2)
            }
            else
            {
              setSendEmail('halt')
              setContinueClick(false)
            }
          },
          (error) => {
          }
        );
      }
  }, [username, email, continueClick]);

  React.useEffect(() => {
    if(sendEmail === 'send')
    {
      sendCode(username,email,secureCode).then(res => res.json())
        .then(
          (result) => {
            setSendEmail('pending')
          },
          (error) => {
          }
        );
      }
  }, [username, email, secureCode, sendEmail]);

  React.useEffect(() => {
    if(passwordMatch && saveClick )
    {
      secureResetPassword(username,email,newPassword,emailCode,secureCode).then(res => res.json())
        .then(
          (result) => {
            if(result.response === true)
            {
              setStep(3)
            }
            else
            {
              setSaveClick(false)
              setTryCounter(tryCounter + 1)
            }

            if(tryCounter === 3)
            {
              destroyTokens(username)
              setStep(4)
            }
          },
          (error) => {
          }
        );
      }
  }, [
    username, 
    email, 
    secureCode, 
    emailCode, 
    passwordMatch,
    saveClick,
    tryCounter,
    newPassword
  ]);

  return (<>
    <Button size="large" color="inherit" onClick={handleOpen}>
      <Typography>Forgot Password</Typography>
    </Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-component-modal-title"
      aria-describedby="add-component-modal-description"
    >
      <Box sx={addComponentModalStyle}>
        <Container component="section" maxWidth="lg" className={addCopmonentModalRoot}>
          <Stack direction="column" justifyContent="center"  alignItems="center" spacing={2}>
            {step === 1 && <>
              <div align-content="center">
                <Typography id="add-component-modal-title" variant="h5" component="h2">
                  User and email
                </Typography>
              </div>
              <div align-content="center">
                <FormControl sx={{ m: 1, width: '25ch' }}>
                  <TextField 
                    required
                    id="username" 
                    label="Username"
                    variant="standard"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value)}
                    } />
                </FormControl>
              </div>
              <div align-content="center">
                <FormControl sx={{ m: 1, width: '25ch' }}>
                  <TextField 
                    required
                    id="email" 
                    label="Email"
                    variant="standard"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)}
                    } />
                </FormControl>
              </div>
              <Grid container>
                <Grid item xs={4}>
                  <Button size="large" color="inherit" onClick={handleClose}>
                    <Typography>Cancel</Typography>
                  </Button>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button size="large" color="inherit" onClick={()=>{
                    setContinueClick(true)
                  }}>
                    <Typography>Continue</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>}
            {step === 2 && <>
              <div align-content="center">
                <Typography id="add-component-modal-title" variant="h5" component="h2">
                  Change Password
                </Typography>
              </div>
              <div align-content="center">
                <FormControl sx={{ m: 1, width: '25ch' }}>
                  <TextField 
                    required
                    id="email" 
                    label="Secure Code"
                    variant="standard"
                    value={emailCode}
                    onChange={(e) => {
                      setEmailCode(e.target.value)}
                    } />
                </FormControl>
              </div>
              <div align-content="center">
                <PasswordComponent
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value)
                  }}/>
              </div>
              <div align-content="center">
                <PasswordComponent
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                  }}/>
              </div>
              <Grid container>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button size="large" color="inherit" onClick={()=>{
                    setSaveClick(true)
                    if(newPassword !== confirmPassword)
                    {
                      setPasswordMatch(false)
                    }
                    else setPasswordMatch(true)
                  }}>
                    <Typography>Save</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>}
            {step === 3 && <>
              <div align-content="center">
                <Typography id="add-component-modal-title" variant="h5" component="h2">
                  Success!
                </Typography>
              </div>
              <Grid container>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button size="large" color="inherit" onClick={handleClose}>
                    <Typography>Continue</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>}
            {step === 4 && <>
              <div align-content="center">
                <Typography id="add-component-modal-title" variant="h5" component="h2">
                  Please contact support.
                </Typography>
              </div>
              <Grid container>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Button size="large" color="inherit" onClick={handleClose}>
                    <Typography>Continue</Typography>
                  </Button>
                </Grid>
              </Grid>
            </>}
          </Stack>
        </Container>          
      </Box>
    </Modal>
  </>)
}
