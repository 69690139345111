import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';
import ListSubheader from '@mui/material/ListSubheader';

const getReportList = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/report/list", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ReportSelect(props) {
  let id = 'report-select';
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [report, setReport] = React.useState('');
  const [reloadData, setReloaData] = React.useState({});

  const [refresh, setRefresh] = React.useState(false);
  const [clear, setClear] = React.useState(false);

  if(props.refresh !== refresh)
  {
    setRefresh(props.refresh)
    setReport('')
    props.callback(
      '',
      '',
      false,
      []
    );
  }

  if(props.clear !== clear)
  {
    setClear(props.clear)
    setReport('')
  }

  React.useEffect(() => {
    getReportList()
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          
          setItems(result.response);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: There was an issue loading the compnent</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }else if (items.length === 0){
    return <div>Error: There was an issue loading the compnent</div>;
  } else {

    const handleChange = (event) => {
      setReport(event.target.value);
      let value = event.target.value.value
      if(value === 'select')
        value = null

      setReloaData({
        value:value,
        name:event.target.value.label,
        hasTemplates:event.target.value.hasTemplates,
        fields:event.target.value.fields
      })
      
      props.callback(
        value,
        event.target.value.label,
        event.target.value.hasTemplates,
        event.target.value.fields
      );
    };

    let menu_list = {};
    let report_list = []
    Object.entries(items).map((option) => {
      if (!menu_list.hasOwnProperty(option[1].group)){
        menu_list[option[1].group] = [];
      }
      menu_list[option[1].group].push(option[1]);
      menu_list[option[1].group].sort();
      
    })
    
    Object.entries(menu_list).map((option, k) => {
      report_list.push((
        <ListSubheader key={option[0]}>
          {option[0]}
        </ListSubheader>
      ))
    
      option[1].forEach((report)=>{
        report_list.push((
          <MenuItem key={report.value} value={report}>
            {report.label}
          </MenuItem>
        ))
      })
    })


    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id={id}
            select
            label="Select Report"
            value={report}
            onChange={handleChange}
            className=""
          >
            {report_list}
          </TextField>
        </div>
      </Box>
    );
  }
}
