import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MenuItem from '@mui/material/MenuItem';
import HelpIcon from '@mui/icons-material/Help';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export default function Documentation(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
  <>
    <MenuItem onClick={handleOpen}>
      <IconButton
        size="large"
        aria-label="Help"
        aria-haspopup="true"
        color="inherit"
      >
        <Badge>
          <HelpIcon />
        </Badge>
      </IconButton>
      {props.itemTitle}
    </MenuItem>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h4" >Welcome to Trail Tracker v2!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h4" >
              This is a prototype, and connected to the test database! 
            </Typography>
            <Typography variant="body1" component="h4" >Please do not be alerted if you see incorrect data, or if inputs are not working.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="h4" >
              Welcome Back! This time a lot of the office functionality has been completed. You can edit entity data, change entity attributes, and so much more listed below                
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Adding Components</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5">
                  Adding componets is simple click on the <AddBoxIcon/> icon, select the component then click on the <SaveAltIcon/> icon to add the component to your dashboard.
                  Documentation for components will be added as I complete the inputs. Temporarily it will be not be able to change, once the migration to this new interface is finished
                  I will be able to implement a system where you will be able to change the workflow documentation. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Changing Basic Data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5">
                  Using the search feature, once you search a record you can edit the record as if it were a spreadsheet. To save the changes click the <SaveAltIcon/> to commit the 
                  changes. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Merging Records</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5">
                  Select the two records that you would like to merge using the checkboxes in the search datatable. Then click the <CallMergeIcon/> to comit the merge. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Deleting Records</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5">
                  Select the records that you would like to delete using the checkboxes in the search datatable. Then click the <DeleteIcon/> to comit the deletion. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Task List</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={4}>Name</Grid><Grid item xs={4}>UI Complete</Grid><Grid item xs={4}>Inputs Complete</Grid>
                  <Grid item xs={4}>Improved Search Feature</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Improved Datatables</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Entity Details: General</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Partial</Grid>
                  <Grid item xs={4}>Entity Details: Accounting</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Entity Details: Membership</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Entity Details: Events and Awards</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Entity Details: Trail Management</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Entity Details: Parcels</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Entity Details: Activity</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Entity Details: Documentation</Grid><Grid item xs={4}>Partial</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Entity Details: Notes</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Entity Details: Transaction Forms</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Entity Details: Affiliates/Affiliations</Grid><Grid item xs={4}>No</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Land Details: General</Grid><Grid item xs={4}>No</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Settings: Accounting</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Add/Remove Component</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>Yes</Grid>
                  <Grid item xs={4}>Settings: Roles and Mailing</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Settings: Membership</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Settings: Forms and Awards</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Settings: User setings</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Settings: User permissions</Grid><Grid item xs={4}>No</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: Trail Management</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: Reports</Grid><Grid item xs={4}>Partial</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: Parcel Management</Grid><Grid item xs={4}>Yes</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: Add Entity</Grid><Grid item xs={4}>Partial</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: Events</Grid><Grid item xs={4}>No</Grid><Grid item xs={4}>No</Grid>
                  <Grid item xs={4}>Component: RTC</Grid><Grid item xs={4}>No</Grid><Grid item xs={4}>No</Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      <DialogActions>
        <Button type="contained" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </>)
}
