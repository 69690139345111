import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import background from "./../../img/login-image.jpg";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function LoginBody() {
  const { height, width } = useWindowDimensions();
  const divStyle = {
    height: (height-75),
    width:(width),
    objectFit: 'cover',
  };

  return (
    <div
      style={divStyle} >
      <img src={background} alt="Logo" className="login-image"/>
    </div>
  )
}
