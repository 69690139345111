import * as React from 'react';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';

const fetchTransaction = (id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    data:"accounting"
  }

  return fetch(cookies.get('apiUrl')+"/data/details", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}



export default function FamilyTransactuibChip(props) {
  const columns = [
    {
      field: "receiptDate",
      headerName: "Receipt Date",
      valueGetter: (params) => new Date(params.row.receiptDate),
      width: 100,
      type:'date',
    },
    {
      headerName:'Account',
      field:'account',
      width: 150,
      renderCell: (params) => { 
        let account = props.accounts.find(element => element.id === params.value)
        return account.name 
      }
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 80,
      renderCell: (params) => { return `$${params.value}`}
    },
    {
     field: "paidBy",
     headerName: "Method",
     width: 150
    },
    {
     field: "description",
     headerName: "Solicitation",
     width: 150
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 150,
      renderCell: (params) => { return (params.value === -1 ? "No Discount" : params.value) }
    },
    {
      field: "gifter",
      headerName: "Gifter",
      width: 150,
      renderCell: (params) => { return (params.value.name ? params.value.name : "") }
    },
    {
      field: "anonymous",
      headerName: "Anon",
      width: 100
    },
  ]

  const id = props.id;
  const filter = (true)?{Toolbar: GridToolbar}:{};
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);

  const toggleOpen = (toggle) => {
    setOpen(toggle)
  }

  React.useEffect(() => {
    if(open && id !== false)
    fetchTransaction(id).then(res => res.json())
      .then(
        (result) => {
          setData(result.response)
        },
        (error) => {
        }
      );
  }, [id, open]);


  if (id === false) {
    return <></>
  }

  return (
    <>
      <Chip
        label="Family Transactons"
        onClick={() => {toggleOpen(true)}}
        onDelete={() => {toggleOpen(true)}}
        deleteIcon={<PeopleIcon />}
      />
      <Dialog key="settings" open={open} onClose={() => {toggleOpen(false)}} maxWidth="lg">
        <DialogTitle>Family Transactons</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>
        <Stack spacing={1}>      
          <div style={{ display: 'flex', width: '800px', height: '500px' }}>
            <div style={{ flexGrow: 3 }}>
              <DataGridPro
                rows={data}
                columns={columns}
                density="compact"
                autoPageSize
                pagination
                components={filter}
              />
            </div>
          </div>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {toggleOpen(false)}}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

