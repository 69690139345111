import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function TableCheck(props) {

  const [annon, setAnnon] = React.useState(props.val)
  return <Checkbox 
    disabled={props.disabled}
    checked={annon} onChange={(e)=>{
      setAnnon(e.target.checked)
      props.callback({id:props.id, field:'anonymous', value:annon}, null)
  }}/>
}
