import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import Cookies from 'universal-cookie';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import EditFormChip from './settings-inputs/edit-form-chip';
import AccountingChip from './settings-inputs/accounting-chip';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    package: setting_package,
    setting: target
  };

  return fetch(cookies.get('apiUrl') + "/settings/add", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const updateSetting = (target, id, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    setting: target,
    package: setting_package,
    id: id
  };

  return fetch(cookies.get('apiUrl') + "/settings/update", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const deleteSetting = (target, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    setting: target,
    id: id
  };

  return fetch(cookies.get('apiUrl') + "/settings/delete", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const fetchSettings = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    setting: 'forms'
  };

  return fetch(cookies.get('apiUrl') + "/settings/get", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function FormsSettings() {
  const [refresh, setRefresh] = React.useState(false);
  const [deleteTarget, setDeleteTarget] = React.useState({ click: false });
  const [updateTarget, setUpdateTarget] = React.useState({ click: false });
  const [addTarget, setAddTarget] = React.useState({ click: false });
  const [newAward, setNewAward] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [newForm, setNewForm] = React.useState({
    name: '',
    duration: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (prop) => (event) => {
    setNewForm({ ...newForm, [prop]: event.target.value });
  };

  const handleSelect = (value) => {
    newForm['duration'] = value;
    setNewForm({ ...newForm })
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (deleteTarget.click) {
      deleteSetting(deleteTarget.setting, deleteTarget.id)
        .then(res => res.json())
        .then((result) => {
          setDeleteTarget({ click: false })
          setRefresh(!refresh)

        });
    }
  }, [deleteTarget]);

  React.useEffect(() => {
    if (updateTarget.click) {
      updateSetting(updateTarget.setting, updateTarget.id, updateTarget.package)
        .then(res => res.json())
        .then((result) => {
          setUpdateTarget({ click: false });
          setRefresh(!refresh);
        })
    }
  }, [updateTarget]);

  React.useEffect(() => {
    if (addTarget.click) {
      addSetting(addTarget.setting, addTarget.package)
        .then(res => res.json())
        .then((result) => {
          setAddTarget({ click: false })
          setRefresh(!refresh)
        });
    }
  }, [addTarget]);

  React.useEffect(() => {
    setIsLoaded(false);
    fetchSettings()
      .then(res => res.json())
      .then((result) => {
        setData(result.response);
        setIsLoaded(true);
      }, (error) => {
        setIsLoaded(true);
        setErrors(error);
      }
      );
  }, [refresh]);

  return (
    <>
      <List>
        <ListItem key="forms" disablePadding>
          <ListItemButton onClick={handleClickOpen}>
            <ListItemIcon>
              <DynamicFormIcon />
            </ListItemIcon>
            <ListItemText primary={"Forms and Awards"} />
          </ListItemButton>
        </ListItem>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>Forms and Awards</DialogTitle>
          <Divider sx={{ pb: "2%" }} />
          <DialogContent>
            {errors && <ErrorIcon color="danger" />}
            {!isLoaded && <CircularProgress color="warning" />}
            {data.length === 0 && <Typography>No forms</Typography>}
            {data.hasOwnProperty('forms') > 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={10} >
                        Forms
                      </Grid>
                      <Grid item xs={2} >
                        <AddSettingsButton title="Add form" handleSave={(e) => {
                          setAddTarget({
                            click: true,
                            package: newForm,
                            setting: 'form'
                          });
                        }}>
                          <Stack spacing={1} sx={{ pt: 1 }}>
                            <TextField id="outlined-basic" label="name" variant="outlined" value={newForm.name} onChange={handleChange('name')} />
                            <FormControl fullWidth>
                              <InputLabel id="term-select-label">Duration</InputLabel>
                              <Select
                                labelId="term-select-label"
                                id="term-select"
                                value={newForm.duration}
                                label="Duration"
                                onChange={(e) => (handleSelect(e.target.value))}
                              >
                                <MenuItem value="-1">Calandar Year</MenuItem>
                                <MenuItem value="0">Does Not Expire</MenuItem>
                                <MenuItem value="1">1 Year</MenuItem>
                                <MenuItem value="2">2 Years</MenuItem>
                                <MenuItem value="3">3 Years</MenuItem>
                                <MenuItem value="4">4 Years</MenuItem>
                                <MenuItem value="5">5 Years</MenuItem>
                              </Select>
                            </FormControl>
                          </Stack>
                        </AddSettingsButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          {data.forms.map(element => (
                            <EditFormChip
                              key={'f-' + element.id}
                              data={element}
                              onChange={(id, newData) => {
                                setUpdateTarget({
                                  click: true,
                                  id: id,
                                  package: newData,
                                  setting: 'Form'
                                });
                              }}
                              onDelete={(id) => {
                                setDeleteTarget({
                                  id: id,
                                  click: true,
                                  setting: 'Form'
                                });
                              }}
                            >
                            </EditFormChip>))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={10}>
                        Awards
                      </Grid>
                      <Grid item xs={2} >
                        <AddSettingsButton handleSave={(e) => {
                          let setting_package = {
                            click: true,
                            package: {
                              name: newAward,
                              description: newAward
                            },
                            setting: 'awards'
                          };

                          setAddTarget(setting_package);
                        }}>
                          <TextField
                            value={newAward}
                            onChange={(e) => { setNewAward(e.target.value) }}
                            label="Name"
                          />
                        </AddSettingsButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          {data.awards.map(element => <AccountingChip key={element.id} data={element}
                            onChange={(id, value) => {
                              setUpdateTarget({
                                click: true,
                                id: id,
                                setting: 'Awards',
                                package: {
                                  name: value,
                                  description: value
                                }
                              });
                            }}
                            onDelete={(id) => {
                              setDeleteTarget({
                                click: true,
                                id: id,
                                setting: 'Awards'
                              });
                            }}></AccountingChip>)}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </List>
    </>
  );
}

