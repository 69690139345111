import * as React from 'react';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';

const InputStyles = makeStyles((theme) => ({
  textField: {
    height: '50px',
    width: '80%',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    margin: 'dense',
    border: 'none'
  },
  input: {
    color: 'white',
    border: 'none'
  }
}));

function CustomTextInput(props) {
  const classes = InputStyles({});
  const { InputProps, ...rest } = props;
  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="subtitle1" style={{ paddingTop: "6%" }}>Entry Date</Typography>
      <InputBase {...rest} className={classes.textField} ></InputBase>
    </Stack>
  );
}

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

const apiAttributes = (id, entryTs, action) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');
  entryTs = new Date(entryTs);
  entryTs = entryTs.toISOString().split('T')[0];

  const ttl = {
    user: user,
    token: token,
    id: id,
    entryTs: entryTs
  };

  return fetch(cookies.get('apiUrl') + "/detail/attribute/" + action, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

const toggleCertificate = (id, entity, certificate) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined ? cookies.get('user') : '');
  let token = (cookies.get('credToken') !== undefined ? cookies.get('credToken') : '');

  const ttl = {
    user: user,
    token: token,
    id: id,
    entity: entity,
    certificate: certificate
  };

  return fetch(cookies.get('apiUrl') + "/detail/award/certificate", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ttl)
  });
}

export default function AttributeChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name);
  const [size, setSize] = React.useState(1);
  const cookies = new Cookies();
  var permissiveIcon = <></>
  if (cookies.get('permission') === 'null') {
    permissiveIcon = <DeleteIcon />;
  }
  const [icon, setIcon] = React.useState(permissiveIcon);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const oldDate = new Date(props.data.entryDate);
  const [pass, setPass] = React.useState({ date: oldDate, action: '' });
  const id = props.data.id;
  const [date, setDate] = React.useState(oldDate);
  const [dateOpen, setDateOpen] = React.useState(false);
  const type = (props.type !== undefined ? props.type : null);
  const [certificate, setCertificate] = React.useState(props.data.certificate === undefined ? false : props.data.certificate);

  React.useEffect(() => {
    if (pass.action !== '')
      apiAttributes(id, pass.date, pass.action)
        .then(res => res.json())
        .then((result) => {
          if (pass.action === 'delete') {
            props.onClear(id);
          }
          pass.action = '';
        }, (error) => { });
  }, [id, pass, props]);

  const handleClick = (event) => {
    if (!open) {
      setIcon(<CloseFullscreenIcon style={{ paddingRight: "2%" }} />);
      setOpen(!open);
      setSize(1.5);
    }
  };

  const toggle = (e) => {
    setDateOpen(true);
  }

  const handleDate = (value, e) => {
    pass.action = 'update';
    let temp = new Date(value);
    setDate(value);
    pass.date = temp;
    setPass({ ...pass });
  }

  const handleDelete = (event) => {
    if (open) {
      setSize(1);
      setChipTitle(props.data.name);
      setOpen(!open);
      setIcon(permissiveIcon);
    }
    else {
      setConfirmOpen(true);
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    pass.action = 'delete';
    setPass({ ...pass });
    setConfirmOpen(false);
  };

  const handleCertificate = (event) => {
    setCertificate(event.target.checked);
    toggleCertificate(id, props.entity, event.target.checked);
    props.data.certificate = event.target.checked;
    props.onChange(props.data);
  };

  return (
    <div>
      <CustomChip
        size={size}
        label={(<>
          {!open && <>
            {chipTitle}
          </>}
          {open && <>
            <Stack direction="row">
              {type === 'award' &&
                <Checkbox
                  checked={certificate}
                  onChange={handleCertificate}
                  sx={{ paddingTop: 1.4 }}
                />
              }
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label='Since'
                  inputFormat="MM/dd/yyyy"
                  value={date}
                  open={dateOpen}
                  onChange={(newValue) => setDate(newValue)}
                  onAccept={(newValue, e) => handleDate(newValue, e)}
                  onClose={() => {
                    setDateOpen(false);
                    setDate(oldDate);
                  }}
                  onClear={() => { setDate(oldDate) }}
                  onOpen={(e) => { toggle(e) }}
                  renderInput={(params) => <CustomTextInput {...params} />}
                  sx={{ width: 120 }}
                />
              </LocalizationProvider>
            </Stack>
          </>}
        </>)}
        variant="filled"
        onClick={handleClick}
        onDelete={handleDelete}
        deleteIcon={icon}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
