import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Cookies from 'universal-cookie';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const getTaskTypes = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  return fetch(cookies.get('apiUrl')+"/jira/task/types", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function DeveloperTaskForm(props) {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    props.onChange('Task', name, description)
  }, [description, name]);

  return (
    <Grid container spacing={1} >
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField 
            id="name-field"
            value={name}
            onChange={(event)=>{
              setName(event.target.value)
            }} 
            label="Name" 
            variant="standard" />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            placeholder="Description"
            multiline
            variant="standard"
            rows={2}
            value={description}
            onChange={(event)=>{
              setDescription(event.target.value)
            }} 
          />
        </FormControl>
      </Grid>
    </Grid>
    )
}
