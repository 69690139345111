import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PermissionTemplateSelect from './permission-report-template-select';
import Grid from '@mui/material/Grid';
import IconButton  from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function ReportChip(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [newTemplate, setNewTemplate] = React.useState({});
  const [templateList, setTemplateList] = React.useState(props.templates);
  if(templateList === undefined)
  {
    setTemplateList([])
    return <></>
  }


  const handleEdit = (event) => {
    setConfirmOpen(true)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    handleClose()
    props.onDelete()
  };

  const handleSave = () => {
    handleClose()
    props.saveTemplates(templateList)
  };

  return (
    <div>
      <CustomChip
          size={1}
          label={props.label}
          variant="filled"
          onDelete={handleEdit}
          deleteIcon={<EditIcon/>}
      />
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Select Report Templates </DialogTitle>
        <DialogContent>
          <Stack>
            <Typography>No selected templates defaults to all templates for this report</Typography>
            <Grid container>
              <Grid item xs={9}>
                <PermissionTemplateSelect 
                  report={props.value}
                  setTemplate={(template)=>{
                    setNewTemplate(template)
                  }}
                />
              </Grid>
              <Grid item={2}>
                <IconButton  sx={{ml:2, mt:2}} onClick={() => {
                  let contains = false
                  templateList.forEach(element =>{
                    if(element.value === newTemplate.name)
                    {
                      contains = true
                    }
                  })
                  
                  if(!contains)
                  {
                    setTemplateList([...templateList,newTemplate])
                  }
                }}><AddIcon/></IconButton >    
              </Grid>
              <Grid item={12}>
                <Grid container spacing={1}>
                  {templateList.map((option) => (
                    <Grid item>
                      <Chip 
                        key={'item-'+option.name}
                        label={option.name}
                        onDelete={()=>{
                          let i = 0
                          let target = 0;
                          templateList.forEach(element => {
                            if (element.value === option.value)
                            {
                              target = i
                            }
                            i++
                          });

                          templateList.splice(target, 1)
                          setTemplateList([...templateList])
                        }}  
                      />    
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={handleRemove}>Delete</Button>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
