import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import EntitySearchInput from '../inputs/entity-search-input';
import Cookies from 'universal-cookie';

const getTypeCost = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/transaction/membership/type/amount", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const recordTransaction = (id,data,type, target) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:id,
    type:'membership',
    membership:type,
    membershipTarget: target.id,
    transaction:{
      contrib: data.contrib,
      anonymous:data.anon,
      payment:data.amount,
      paid_by:data.method,
      description:data.desc,
      account_id:1,
      receipt_date:data.receiptDate.toISOString().split('T')[0],
      memo:data.memo,
      discount:data.discount,
      gifter:null,
    }
  }


  return fetch(cookies.get('apiUrl')+"/transaction/record", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const isInt = (value) => {
  var x;
  return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
}

export default function MembershipButton(props) {
  const id = props.entityId;
  const {tPackage } = props;

  const [transStatus, setTransStatus] = React.useState('Sending membership...');
  const [defaultVal, setDefaultVal] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [fillTypeAmount, setFillTypeAmount] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [transaction, setTransaction] = React.useState({
    anon:'No',
    amount: '', 
    account:'', 
    receiptDate: new Date(),
    memo:'',
    method:'',
    contrib:0,
    soft:false,
    desc:'',
    apply:'Yes',
    recognition:null
  });

  if(!tPackage.apply) {
    transaction.apply = "No"
  }

  const [type, setType] = React.useState('');

  const [inputErrors, setInputErrors] = React.useState({
    amount:{error:false, message:'Cannot be empty and must be a number value'},  
    method:{error:false, message:'Please select a payment method!'},
    type:{error:false, message:'Please select a membersip type!!'},
    gift_name:{error:false}
  });

  const [gift, setGift] = React.useState({
    send:'No',
    type:'In Honor',
    target:null
  });

  const [pass, setPass] = React.useState({
    click:false,
    data: {}
  });

  React.useEffect(() => {
    if(pass.click)
    {
      pass.click = false
      setLoadingOpen(true)
      recordTransaction(id, pass.data, pass.type, gift.target).then(res => res.json())
      .then(
        (result) => {
          setTransStatus('Success!');
        },(error) =>{
          setTransStatus('There was an issue processing your request');
        })
    }
  },[id, pass, gift])
  

  React.useEffect(() => {
    if(fillTypeAmount)
    {
      getTypeCost(type).then(res => res.json())
      .then(
        (result) => {
          transaction['amount'] = result.response.amount
          setTransaction({...transaction})
          setFillTypeAmount(false)
        },(error) =>{
          setTransStatus('There was an issue processing your request');
        })
    }
  },[type, fillTypeAmount, transaction])

  const handleChange = (prop) => (event) => {
    setTransaction({ ...transaction, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDate = (value) => {
    transaction.receiptDate = value;
    setTransaction({...transaction})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGiftClose = () => {
    setConfirmOpen(false);
  };

  const handleGiftOpen = () => {
    let check = true
    if(type === '')
    {
      check = false
      inputErrors.type.error = true
    }
    else inputErrors.type.error = false

    if(transaction.amount === '' || !isInt(transaction.amount)) 
    {
      check = false
      inputErrors.amount.error = true
    }
    else inputErrors.amount.error = false

    if(transaction.method === '')
    {
      check = false
      inputErrors.method.error = true
    }
    else inputErrors.method.error = false

    setInputErrors({...inputErrors})
    if(check) setConfirmOpen(true);
  };

  const handleGiftSave = () => {
    let check = true

    if(gift.target === null)
    {
      check=false
      inputErrors.gift_name.error = true
    } 
    else inputErrors.gift_name.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      pass.click = true
      transaction.gift = gift
      pass.data = transaction
      pass.type = type
      setPass({...pass})    
    }
  };

  const handleSave = () => {
    let check = true

    if(type === '')
    {
      check = false
      inputErrors.type.error = true
    }
    else inputErrors.type.error = false

    if(transaction.amount === '' || !isInt(transaction.amount)) 
    {
      check = false
      inputErrors.amount.error = true
    }
    else inputErrors.amount.error = false

    if(transaction.method === '')
    {
      check = false
      inputErrors.method.error = true
    }
    else inputErrors.method.error = false

    setInputErrors({...inputErrors})
    if(check)
    {
      gift.target = {id:id}
      pass.click = true
      pass.data = transaction
      pass.type = type
      setPass({...pass})    
    }
    
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Membership</MenuItem>
      <Dialog open={open} fullWidth>
        <DialogTitle>Membership</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>   
          <form>      
          <Stack spacing={2} >
            { tPackage.contributors.length > 0 &&
              <FormControl fullWidth>
                <InputLabel id="contrib-select-label">Contributor</InputLabel>
                <Select
                  labelId="contrib-select-label"
                  id="contrib-select"
                  value={transaction.contrib}
                  label="Contributor"
                  onChange={handleChange('contrib')}
                  
                >
                  <MenuItem value={0}>None</MenuItem>
                  {tPackage.contributors.map((t) => <MenuItem key={"cccsd"+t.id} value={t.id}>{t.firstName + " " + t.lastName}</MenuItem>)}
                </Select>
              </FormControl>
            }
            {  tPackage.apply &&
              <FormControl fullWidth>
                <InputLabel id="apply-select-label">Apply To Family</InputLabel>
                <Select
                  labelId="apply-select-label"
                  id="apply-select"
                  value={transaction.apply}
                  label="Apply To Family"
                  onChange={handleChange('apply')}
                >
                  <MenuItem key={"applyYes"} value={"Yes"}>Yes</MenuItem>
                  <MenuItem key={"applyNo"} value={"No"}>No</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl fullWidth>
              <InputLabel id="anon-select-label">Anonymous</InputLabel>
              <Select
                labelId="anon-select-label"
                id="anon-select"
                value={transaction.anon}
                label="Anonymous"
                onChange={handleChange('anon')}
                required={true}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Type</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={type}
                label="Membership Type"
                onChange={(e) => {
                  setType(e.target.value)
                  setFillTypeAmount(true)
                }}
                error={inputErrors.type.error}
              >
                {tPackage.memberTypes.map((t)=> <MenuItem key={"cccss"+t.id} value={t.id}>{t.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Stack direction="row">
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={transaction.amount}
                  onChange={handleChange('amount')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Amount"
                  error={inputErrors.amount.error}
                />
              </FormControl>
              <FormControl fullWidth  sx={{paddingTop:'1.25%'}}> 
                <InputLabel id="method-select-label" sx={{paddingTop:'1.5%'}}>Payment Method</InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={transaction.method}
                  label="Payment Method"
                  onChange={handleChange('method')}
                  error={inputErrors.method.error}
                >
                  <MenuItem value="Cash or Check">Cash/Check</MenuItem>
                  <MenuItem value="Credit/Debit Card">Credit/Debit Card</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Stripe">Stripe</MenuItem>
                  <MenuItem value="In-Kind">In-Kind</MenuItem>
                  <MenuItem value="Eventbrite">Eventbrite</MenuItem>
                  <MenuItem value="Direct Deposit">Direct Deposit</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Receipt Date"
                  openTo="month"
                  views={['month', 'day', 'year']}
                  value={transaction.receiptDate}
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                  required={true}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="account-select-label">Discount</InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={transaction.discount}
                label="Discount"
                onChange={handleChange('discount')}
                required={true}
              >
                {tPackage.discounts.map((t)=> <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          </form>            

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleGiftOpen}>Gift</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleGiftClose}>
        <DialogTitle>Gift</DialogTitle>
        <DialogContent>
          <Stack pt={1} spacing={1}>
            <EntitySearchInput 
              onChange={(value) => (gift.target = value)}
              label="Entity Search"
              error={inputErrors.gift_name.error}
              />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGiftClose}>Close</Button>
          <Button onClick={(e) => {
              handleGiftSave()
            }}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loadingOpen}>
        <DialogTitle>{transStatus}</DialogTitle>
        <DialogActions>
          <Button onClick={(e) => {
              setLoadingOpen(false)
              setConfirmOpen(false)
              setOpen(false)
              setTransaction({
                anon:'No',
                amount: '', 
                account:'', 
                receiptDate: new Date(),
                memo:'',
                method:'',
                contrib:0,
                soft:false,
                desc:'',
                apply:'No',
                recognition:null
              })
            }}>okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
