import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import EditMemberTypeChip from './settings-inputs/edit-member-type-chip';
import Cookies from 'universal-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  setting_package.code = (Math.random() + 1).toString(36).substring(7)

  const ttl = {
    user: user,
    token: token,
    package:setting_package,
    setting:target
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateSeting = (target, id, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:target,
    package:setting_package,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteSeting = (target, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: target,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchSetting = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:'membership'
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MemberSettings() {
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [deleteTarget, setDeleteTarget] = React.useState({click: false})
  const [updateTarget, setUpdateTarget] = React.useState({click: false})
  const [addTarget, setAddTarget] = React.useState({click: false})
  const [refresh, setRefresh] = React.useState(false);

  const [data,setData]= React.useState([])

  const [newType,setNewType]= React.useState({
    name:'',
    payment:'',
    term:'',
  })

  React.useEffect(() => {
    if(deleteTarget.click)
    {
      deleteSeting(deleteTarget.setting, deleteTarget.id).then(res => res.json())
      .then(
        (result) => {
          setDeleteTarget({click:false})
          setRefresh(!refresh)
          
        })
    }
  },[deleteTarget])

  React.useEffect(() => {
    if(updateTarget.click)
    {
      updateSeting(updateTarget.setting, updateTarget.id, updateTarget.package).then(res => res.json())
      .then(
        (result) => {
          setUpdateTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[updateTarget])

  React.useEffect(() => {
    if(addTarget.click)
    {
      addSetting(addTarget.setting, addTarget.package).then(res => res.json())
      .then(
        (result) => {
          setAddTarget({click:false})
          setRefresh(!refresh)
          setNewType({
            name:'',
            payment:'',
            term:'',
          })
        })
    }
  },[addTarget])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (prop) => (event) => {
    newType[prop] = event.target.value;
    setNewType({...newType})
  };

  const handleSelect = (value) => {
    newType['term'] = value;
    setNewType({...newType})
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setIsLoaded(false);
    fetchSetting().then(res => res.json())
      .then(
        (result) => {
          setData(result.response);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setErrors(error);
        }
      );
  }, [refresh]);

  return (
    <>
    <List>
      <ListItem key="membership" disablePadding>
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <LoyaltyIcon />
          </ListItemIcon>
          <ListItemText primary={"Membership Settings"} />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Membership Settings</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}><Typography>Membership Types</Typography></Grid>
                <Grid item xs={2} >
                  <AddSettingsButton title="Add membership type" handleSave={(e) => {
                    setAddTarget({
                      click:true,
                      package:newType,
                      setting:'membership_type'
                    })
                  }}>
                    <Stack spacing={1} sx={{pt:1}}>
                      <TextField id="outlined-basic" label="name" variant="outlined" value={newType.name} onChange={handleChange('name')}/>
                      <FormControl fullWidth>
                        <InputLabel id="term-select-label">Term</InputLabel>
                        <Select
                          labelId="term-select-label"
                          id="term-select"
                          value={newType.term}
                          label="Term"
                          onChange={(e) => (handleSelect(e.target.value))}
                        >
                          <MenuItem value={-1}>Does Not Expire</MenuItem>
                          <MenuItem value={1}>1 Year</MenuItem>
                          <MenuItem value={2}>2 Years</MenuItem>
                          <MenuItem value={3}>3 Years</MenuItem>
                          <MenuItem value={4}>4 Years</MenuItem>
                          <MenuItem value={5}>5 Years</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <OutlinedInput
                          id="filled-adornment-cost"
                          value={newType.payment}
                          onChange={handleChange('payment')}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                      </FormControl>
                    </Stack>
                  </AddSettingsButton>
                </Grid>
              </Grid>
              <Divider />
              {errors && <ErrorIcon color="danger"/>}
              {!isLoaded && <CircularProgress color="warning"/>}
              {data.length === 0 && isLoaded && <Typography>No membership types</Typography>} 
              {data.length > 0 && (
                <Grid container spacing={1}>
                  {data.map(element => (
                    <Grid
                      key={`grid-${element.id}`}
                      item 
                      xs={6}>
                      <EditMemberTypeChip     
                        data={element}
                        onChange={(id,newData)=>{
                          setUpdateTarget({
                            click:true,
                            id:id,
                            package:newData,
                            setting:'MembershipType'
                          })
                        }}
                      onDelete={(id)=>{
                        setDeleteTarget({
                          id:id,
                          click:true,
                          setting:'MembershipType'
                        })
                      }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </List>
  </>
  );
}

