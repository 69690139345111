import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ReportDateRange(props) 
{
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [defaults, setDefaults] = React.useState(null);
  const [showField, setShowField] = React.useState(props.showVal);
  const [isTemplate, setIsTemplate] = React.useState(props.isTemplate);

  React.useEffect(() => {
    if(toDate && fromDate) {
      props.onChange({
        to_date: toDate,
        from_date: fromDate
      });
    }
  }, [fromDate, toDate]);

  if(props.default) {
    if(props.default !== defaults) {
      setDefaults(props.default);
      let from = new Date(props.default.from_date);
      let to = new Date(props.default.to_date);

      if(props.default.to_date === '') { 
        to = null;
        from = null;
      }
      
      if(from !== fromDate || toDate !== to) {
        setFromDate(from);
        setToDate(to);
      }
    }
  }

  const handleDateChange = (dateType) => (date) => {
    if(dateType === 'from') {
      setFromDate(date)
    }
    else {
      setToDate(date)
    }

/**    if(toDate && fromDate) {
      props.onChange({
        to_date: toDate,
        from_date: fromDate
      })
    } 
**/
  };

  const handleShow = (event) => {
    setShowField(event.target.checked);
    let val = (event.target.checked)?"true":"false";
    props.onShow(val);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
      <Grid container>
          <Grid item xs={9}>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item xs={3}>
            {isTemplate && <FormControlLabel
              label="Show field"
              control={
                <Checkbox
                  checked={showField}
                  indeterminate={showField}
                  onChange={handleShow}
                />
              }
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="From"
            inputFormat="MM/dd/yyyy"
            value={fromDate}
            onChange={(handleDateChange('from'))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="To"
            inputFormat="MM/dd/yyyy"
            value={toDate}
            onChange={(handleDateChange('to'))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>  
  )
}
