import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px"
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.75}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));


export default function EditMemberTypeChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [data, setData] = React.useState(props.data)
  const [size, setSize] = React.useState(1)
  const [icon, setIcon] = React.useState(<EditIcon/>)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);


  const handleEdit = (event) => {
    setConfirmOpen(true)
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };


  const handleRemove = () => {
    props.onDelete(props.data.id)
    setConfirmDelete(false)
    handleClose()
  };

  const handleSave = () => {
    const {id, ...restData} = data
    props.onChange(id, restData)
    setChipTitle(restData.name)
    handleClose()
  };

  return (
    <div>
      <CustomChip
          size={size}
          label={chipTitle}
          variant="filled"
          onDelete={handleEdit}
          deleteIcon={icon}
      />
      <Dialog open={confirmOpen}>
        <DialogTitle>Edit form</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{pt:1}}>
            <TextField id="outlined-basic" label="name" variant="outlined" value={data.name} onChange={handleChange('name')}/>
            <FormControl fullWidth>
              <InputLabel id="term-select-label">Duration</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                value={data.duration}
                label="Term"
                onChange={(e) => {
                  data.duration = e.target.value
                  setData({...data})
                }}
              >
                <MenuItem value="-1">Calandar Year</MenuItem>
                <MenuItem value="0">Does Not Expire</MenuItem>
                <MenuItem value="1">1 Year</MenuItem>
                <MenuItem value="2">2 Years</MenuItem>
                <MenuItem value="3">3 Years</MenuItem>
                <MenuItem value="4">4 Years</MenuItem>
                <MenuItem value="5">5 Years</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="contained" onClick={()=>{setConfirmDelete(true)}}>Delete</Button>
          <Button type="contained" onClick={handleClose}>Cancel</Button>
          <Button type="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete} onClose={handleClose}>
        <DialogTitle>Are you sure, this cannot be undone?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={()=>{setConfirmDelete(false)}}>No</Button>
          <Button type="contained" onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
