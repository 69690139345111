import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Cookies from 'universal-cookie';
import AccountingChip from './settings-inputs/accounting-chip';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import RoleChip from './settings-inputs/role-chip';
import BeenhereIcon from '@mui/icons-material/Beenhere';

const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:setting_package,
    setting:target
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateSeting = (target, id, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:target,
    package:setting_package,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const deleteSeting = (target, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting: target,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchSettings = (index) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:index
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

export default function RoleSettings() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [ugly, setUgly] = React.useState('')

  const [newMailingType, setNewMailingType] = React.useState('')
  const [newRole, setNewRole] = React.useState('')

  const [toggle, setToggle] = React.useState('')
  const [refresh, setRefresh] = React.useState(false);
  const [deleteTarget, setDeleteTarget] = React.useState({click: false})
  const [updateTarget, setUpdateTarget] = React.useState({click: false})
  const [addTarget, setAddTarget] = React.useState({click: false})

  const [errorRoles, setErrorRoles] = React.useState(null)
  const [errorMailingType, setErrorMailingType] = React.useState(null)

  const [loadingRoles, setLoadingRoles] = React.useState(false)
  const [loadingMailingType, setLoadingMailingType] = React.useState(false)

  const [dataRoles, setDataRoles] = React.useState([])
  const [dataMailingType, setDataMailingType] = React.useState([])


  React.useEffect(() => {
    if(deleteTarget.click)
    {
      deleteSeting(deleteTarget.setting, deleteTarget.id).then(res => res.json())
      .then(
        (result) => {
          setDeleteTarget({click:false})
          setRefresh(!refresh)
          
        })
    }
  },[deleteTarget])

  React.useEffect(() => {
    if(updateTarget.click)
    {
      updateSeting(updateTarget.setting, updateTarget.id, updateTarget.package).then(res => res.json())
      .then(
        (result) => {
          setUpdateTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[updateTarget])

  React.useEffect(() => {
    if(addTarget.click)
    {
      addSetting(addTarget.setting, addTarget.package).then(res => res.json())
      .then(
        (result) => {
          setAddTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[addTarget, toggle])


  React.useEffect(() => {
    switch(index)
    {
      case 'roles':
        setLoadingRoles(true)
        break
      case 'mailingTypes':
        setLoadingMailingType(true)
        break
      default:
        break
    }
    fetchSettings(index).then(res => res.json())
    .then(
      (result) => {
        switch(index)
        {
          case 'roles':
            setDataRoles(result.response)
            setLoadingRoles(false)
            setErrorRoles(null)
            break
          case 'mailingTypes':
            setDataMailingType(result.response)
            setLoadingMailingType(false)
            setErrorMailingType(null)
            break
          default:
            break
        }
      },
      (error) => {
        switch(index)
        {
          case 'roles':
            setErrorRoles(error)
            setLoadingRoles(true)
            break
          case 'mailingTypes':
            setErrorMailingType(error)
            setLoadingMailingType(true)
            break
        }
      }
    )    
  }, [index, refresh]);

  const toggleData = (newIndex) => {
    if(index !== newIndex)
    {
      setIndex(newIndex)
    }
    else
    {
      setIndex('');
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
    <List>
      <ListItem key="roles" disablePadding>
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <BeenhereIcon />
          </ListItemIcon>
          <ListItemText primary={"Role and Mailing Types"} />
        </ListItemButton>
      </ListItem>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Roles and mailing types</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>         
        <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('roles')}
              >
                <Typography>Roles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingRoles && errorRoles && <ErrorIcon color="danger"/>}
                {loadingRoles && <CircularProgress color="warning"/>}
                {!loadingRoles && dataRoles.length === 0 && <Typography>No roles</Typography>} 
                {!loadingRoles && dataRoles.length > 0 && (
                <>
                  <Grid container>
                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item xs={10} >
                          <Typography styles={{paddingTop:'2%'}}>
                            
                          </Typography>
                        </Grid>
                        <Grid item xs={2} >
                          <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                name:newRole,
                                details:newRole
                              },
                              setting:'role'
                            }

                            setAddTarget(setting_package)
                            setRefresh(!refresh)
                          }}>
                            <TextField
                              value={newRole}
                              onChange={(e) => {setNewRole(e.target.value)}}
                              label="Name"
                            />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Grid container spacing={1}>
                        {dataRoles.map(element => (
                          <Grid key={'r-'+element.id} item xs={6}>
                            <RoleChip data={element} 
                              refresh={() => setRefresh(!refresh)}
                              onChange={(id, value)=>{
                                setUpdateTarget({
                                  click:true,
                                  id:id,
                                  setting:'Role',
                                  package: {
                                    name:value,
                                    details:value
                                  }
                                })
                              }}
                              onDelete={(id)=>{setDeleteTarget({
                                click:true,
                                id:id,
                                setting:'Role'
                              })}}
                              ></RoleChip>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('mailingTypes')}
              >
                <Typography>Mailing Types</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingMailingType && errorMailingType && <ErrorIcon color="danger"/>}
                {loadingMailingType && <CircularProgress color="warning"/>}
                {!loadingMailingType && dataMailingType.length === 0 && <Typography>No roles</Typography>} 
                {!loadingMailingType && dataMailingType.length > 0 && (
                  <>
                  <Grid container>
                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item xs={10} >
                          <Typography styles={{paddingTop:'2%'}}>
                          </Typography>
                        </Grid>
                        <Grid item xs={2} >
                        <AddSettingsButton handleSave={(e) => {
                            let setting_package = {
                              click: true,
                              package: { 
                                code:newMailingType,
                                details:newMailingType
                              },
                              setting:'mailing_type'
                            }

                            setAddTarget(setting_package)
                            setRefresh(!refresh)
                          }}>
                            <TextField
                                value={newMailingType}
                                onChange={(e) => {setNewMailingType(e.target.value)}}
                                label="Name"
                              />
                          </AddSettingsButton>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Grid container spacing={1}>
                        {dataMailingType.map(element => (
                          <Grid key={element.id} item xs={6}>
                            <AccountingChip data={element} 
                            onChange={(id, value)=>{
                              setUpdateTarget({
                                click:true,
                                id:id,
                                setting:'MailingType',
                                package: {
                                  code:value,
                                  details:value
                                }
                              })
                            }}
                            onDelete={(id)=>{setDeleteTarget({
                              click:true,
                              id:id,
                              setting:'MailingType'
                            })}}/>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </List>
    {ugly}
  </>
  );
}
