import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DataTable from './core-components/component-data-grid';
import ElaborateEntity from './core-components/entity-details/component-elaborate-entity';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const buttonTheme = createTheme({
  palette: {
    primary: {main:'#C0C0C0'},
  },
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#BEBEBE',
  '&:hover': {
    backgroundColor: '#EBECF0',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '200px',
  [theme.breakpoints.up('sm')]: {
    width: '400px',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
    width: '700px',
  },
}));


const ResultList = (props) => {
  const results = props.results

  const columns = [
    {
      headerName:'Details',
      field:'entity',
      renderCell: (params) => {
        return <ElaborateEntity entity={params.value}/>
      },
      width: 70,
      filterable:false,
    },
    {
      field: "type",
      headerName: "Type",
      width: 90,
     },
    {
     field: "firstName",
     headerName: "First Name",
     width: 150,
     editable:true
    },
    {
     field: "lastName",
     headerName: "Last Name",
     width: 150,
     editable:true
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 220,
      editable:true
    },
    {
     field: "primaryNumber",
     headerName: "Phone Number",
     width: 130,
     editable:true
    },
    {
     field: "address",
     headerName: "Address",
     width: 200,
     editable:true
    },
    {
      field: "city",
      headerName: "City",
      width: 120,
      editable:true
     },
    {
      field: "state",
      headerName: "State",
      width: 90,
      editable:true
     },
    {
      field: "zipCode",
      headerName: "Zip Code",
      width: 110,
      editable:true
     },
     {
      field: "county",
      headerName: "County",
      width: 110,
      editable:true
     },
     {
      field: "username",
      headerName: "Username",
      width: 110,
      editable:true
     },
     {
      field: "altEmailAddress",
      headerName: "Alt Email",
      width: 220,
      editable:true
     },
     {
      field: "secondaryNumber",
      headerName: "Alt Phone Number",
      width: 130,
      editable:true
     },
     {
      field: "inCareOf",
      headerName: "In Care Of",
      width: 220,
      editable:true
     },
  ]

  //turn to material UI datatable
  let data = []
  Object.entries(results).forEach((t,k) => { 
    if(t[0] !== 'length' && t[0] !== 'autolist' && t[0] !== 'passback')
    {
      t[1]['entity'] = t[1];
      data.push(t[1])
    }
    
  })
  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'

  var list = (
  < DataTable
    title = "Search Results"
    canEdit={permission}
    data={data}
    columns={columns}
    id='search-results'
    size={'small'}
    filter={true}
    selectionChange={(value)=>{props.selectionChange(value)}}
    onEditCommit={(values,event) => {
      props.changeCommit(values)
    }}
    groupingColDef={true}
  />)

  let result_box = (
    <Box
      sx={{ width: '100%' }}
      sm={{ width: '100%' }}
      md={{ width: '100%' }}
      lg={{ width: '100%' }}
      xl={{ width: '100%' }}
      role="presentation"
    >
      <Grid container>
        <Grid item xs={10}>
          {permission &&
            <Stack direction={"row"}>
              <IconButton 
                size="large" 
                color="inherit" 
                onClick={() => {props.onSave()}}
                disabled={props.showSave}
                >
                <SaveIcon/>
              </IconButton>
              <IconButton 
                size="large" 
                color="inherit" 
                onClick={() => {props.onCancel()}}
                disabled={props.showCancel}
                >
                <CancelIcon/>
              </IconButton>
              <IconButton 
                size="large" 
                color="inherit" 
                onClick={() => {props.onJoin()}}
                disabled={props.showJoin}
                >
                <GroupAddIcon/>
              </IconButton>
              <IconButton 
                size="large" 
                color="inherit" 
                onClick={() => {props.onLeave()}}
                disabled={props.showLeave}
                >
                <PersonRemoveIcon/>
              </IconButton>
              <IconButton 
                size="large"
                color="inherit" 
                onClick={() => {props.onMerge()}}
                disabled={props.showMerge}
                >
                <CallMergeIcon/>
              </IconButton>
              <IconButton 
                size="large"
                color="inherit"
                onClick={() => {props.onDelete()}}
                disabled={props.showDelete}
                >
                <DeleteForeverIcon/>
              </IconButton>
            </Stack>
          }
        </Grid>
        <Grid item xs={2}>
          <Stack justifyContent="flex-end">
            <IconButton size="large" color="inherit" onClick={() => {props.onClose()}}>
              <CloseIcon/>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      {list}
    </Box>
    )

    if(results.length === 0)
      result_box = <></>

    return result_box
}

const fetchSearch = (values,keyboard) => {
    const cookies = new Cookies();
    let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
    let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
    let send = Object.keys(values).map((key) => values[key])

    if(keyboard.length > 1)
    {
      send = [keyboard, ...send]
    }

    const ttl = {
      user: user,
      token: token,
      search: send,
      passback: Date.now()
    }

    return fetch(cookies.get('apiUrl')+"/search/entities", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(ttl)
    })
     
}

const doTableAction = (action,selected,changed,typeMeta, nameMeta) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    selected:selected,
    changed:changed,
    table:'entity',
    type:typeMeta,
    name:nameMeta
  }

  return fetch(cookies.get('apiUrl')+"/table/"+action, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function SearchBar(props) {  
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [actionError, setActionError] = React.useState(null);
  const [actionLoaded, setActionLoaded] = React.useState(true);
  const [popTitle, setPopTitle] = React.useState('');
  const [popBody, setPopBody] = React.useState('');
  const [search, setSearch] = React.useState([])
  const [autolist, setAutoList] = React.useState([])
  const [resultAmount, setResultAmount] = React.useState(0)
  const [state, setState] = React.useState({top: false});
  const [results, setResults] = React.useState([])
  const [timestamp, setTimestamp] = React.useState({
    ts:0,
    searching:0
  })
  const [index, setIndex] = React.useState(0)
  const [keyboard,setKeyboard] = React.useState('')

  const [showMerge, setShowMerge] = React.useState(true)
  const [showDelete, setShowDelete] = React.useState(true)
  const [showJoin, setShowJoin] = React.useState(true)
  const [showLeave, setShowLeave] = React.useState(true)
  const [showForm, setShowForm] = React.useState(false)
  const [showSc, setShowSc] = React.useState({save: false, cancel:false})
  const [changeValues, setChangeValues] = React.useState({changed:[],selected:[]})
  const [open, setOpen] = React.useState(false);
  const [actionType, setActionType] = React.useState('');
  const [oldSearch, setOldSearch] = React.useState('')
  const [joinMetaName, setJoinMetaName] = React.useState('')
  const [joinMetaType, setJoinMetaType] = React.useState('')

  const cookies = new Cookies();
  const permissions = cookies.get('permission')
  const [showBar, setShowBar] = React.useState(false);

  if(permissions !== 'null' && showBar !== true)
  {
    permissions.forEach(group => {
      group.permission.components.forEach(component => {
        if(component.name === 'search') setShowBar(true)
      })
    });
  }
  else if (showBar !== true) setShowBar(true)

  const toggleDrawer = (anchor, open, bypass = false) => (event) => {
    if (
      !bypass &&
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  React.useEffect(() => {
    setError(null);
    setIsLoaded(false);
    fetchSearch(search,keyboard).then(res => res.json())
      .then(
        (result) => {
          if(timestamp.ts < result.passback)
          {
            setResultAmount(result.response.length)
            setResults(result.response);
            setAutoList(result.response.autolist)
            setIsLoaded(true);
            setError(null)
            timestamp.ts = result.passback
            props.onChange(result.response.length)
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          setResultAmount(0);
        }
      );
  }, [search, keyboard, timestamp, props]);

  const handleKeystroke = (value) => {
    setKeyboard(value)
  };

  const handleGroup = (value) => {
      setSearch(value);
      setIndex(value.length)
  }

  const filterOptions = createFilterOptions({
    stringify: ({ search }) => {
      return search
    }
  });

  const handleTableAction = (action) => {
    setShowForm(false)
    let pass = (action !== 'save')?true:showSc.save
    let messageHeader = 'Are you sure?'
    let messageBody = 'This cannot be undone'
    if(action === 'join')
    {
      let countA = 0;
      changeValues.selected.map((selection) => {
        countA += (selection.split('-')[0] === 'a')?1:0;
      });

      if(countA === 0)
      {
        messageHeader = "Please provide some info"
        messageBody = (<></> )
        setShowForm(true)
      }
    }

    if(action === 'delete')
    {
      messageHeader = "You are about to delete " + changeValues.selected.length + " records!"

      messageBody = "Are you sure? This cannot be undone.";
    }
    if(action === 'cancel' && showSc.cancel)
    {
      setChangeValues({
        selected:[],
        changed:[]
      })
      setResults({...results})
      showSc.save = false
      showSc.cancel = false
    }
    else if(pass)
    {
      setActionType(action)
      setOpen(true)
      setPopBody(messageBody)
      setPopTitle(messageHeader)
    }
    
  }

  const handleJoinMetaName = (e) =>
  {
    setJoinMetaName(e.target.value)
  }

  const handleJoinMetaType = (e) =>
  {
    setJoinMetaType(e.target.value)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const commit = () => {
    let should_commit = (actionType !== 'Okay')
    if(should_commit)
    {
      doTableAction(actionType,changeValues.selected,changeValues.changed, joinMetaType, joinMetaName).then(res => res.json())
        .then(
          (result) => {
            setActionLoaded(true);
            setActionError(null);
            setPopTitle('Change log')
            setActionType('Okay')
            setOldSearch(search)
            setShowForm(false)
            setPopBody((
              <>
                <Stack spacing={1}>
                  {
                    result.response.map((log) => {
                    return <Typography key={log.split('|')[0]}>{log}</Typography>
                  })}
                </Stack>
              </>
            ))

            setSearch('')
          },
          (error) => {
            setActionLoaded(true);
            setActionError(error);
          }
        );
    }
    else
    {
      setOpen(false)
      setChangeValues({
        selected:changeValues.selected,
        changed:[]
      })
      setSearch(oldSearch)
      showSc.save = false
      showSc.cancel = false
      setJoinMetaName('')
      setJoinMetaType('')
    }

  };

  if(!showBar) return <></>

  const search_bar = (
    <>
      <Search>
        <Autocomplete
          multiple
          clearOnBlur={false}
          id="tt-search"
          fullWidth
          options={Object.entries(autolist).map((k) => k[1].name)}
          onKeyDown={e => {
            if (e.code === 'Enter' && timestamp.searching === 0) {
              e.preventDefault();
              timestamp.searching = 0
              if(resultAmount > 0)
              {
                toggleDrawer('top', true,true)(e)
              }
            }
            else if (e.code === 'ArrowDown' && e.target.value) {
              if(resultAmount > 0)
              {
                timestamp.searching++
              }
              else
              {
                timestamp.searching = 0
              }
            }
            else if (e.code === 'ArrowUp' && e.target.value) {
              timestamp.searching--
              timestamp.searching = (timestamp.searching <0)?0:timestamp.searching
            }
            else
            {
              timestamp.searching = 0
            }


          }}
          onInputChange={(e, value) => handleKeystroke(value)}
          onChange={(e, value) => handleGroup(value)}
          /**filterOptions={filterOptions}**/
          renderInput={ (params) => <TextField {...params}
            placeholder="Search…"
            autoComplete='off'
          />}
      />
        
      </Search>
      <React.Fragment key={'top'}>
        {( 
          error || 
          (isLoaded && (resultAmount === 0 && keyboard.length >1))) && 
          <div style={{
            display: "flex", 
            justifyContent:"space-between",
            alignItems: "center"
            }}
          >
            <ErrorIcon color="danger"/>
          </div>
        }
        {!isLoaded && 
          <div style={{
            display: "flex", 
            justifyContent:"space-between",
            alignItems: "center"
            }}
          >
            <CircularProgress color="warning"/>
          </div>
        }
        {isLoaded && resultAmount > 0 && <>
        <ThemeProvider theme={buttonTheme}>
          <Button variant="contained" color="primary" onClick={toggleDrawer('top', true)}>{resultAmount}</Button>
        </ThemeProvider>
        <Drawer
          variant="temporary"
          anchor={'top'}
          open={state['top']}
        >
          <ResultList 
            results={results} 
            onClose={toggleDrawer('top', false)}
            changeCommit={(commit) => {
              if(results[commit.id][commit.field] !== commit.value)
              {
                showSc.save = true
                showSc.cancel = true
                changeValues.changed.push({
                  id:commit.id,
                  field:commit.field,
                  value:commit.value,
                })
              }
            }}
            selectionChange={(newSelection) => {
              changeValues.selected = newSelection
 
              if(newSelection.length > 0)
              {
                setShowDelete(false);
                let countA = 0;
                let countP = 0;
                newSelection.map((selection) => {
                  countA += (selection.split('-')[0] === 'a')?1:0;
                  countP += (selection.split('-')[0] === 'p')?1:0;
                });

                if((countA === 1 && countP > 0) || countP == 2)
                {
                  setShowJoin(false)
                }
                else
                {
                  setShowJoin(true)
                }
                if(countA === 1 && countP === 1)
                {
                  setShowLeave(false)
                }
                else
                {
                  setShowLeave(true)
                }
              }
              else
              {
                setShowDelete(true)
              }

              if(newSelection.length === 2)
              {
                setShowMerge(false)
              }
              else
              {
                setShowMerge(true)
              }
            }}
            showDelete={showDelete}
            onDelete={()=>{handleTableAction('delete')}}
            showJoin={showJoin}
            onJoin={()=>{handleTableAction('join')}}
            showMerge={showMerge}
            onMerge={()=>{handleTableAction('merge')}}
            showLeave={showLeave}
            onLeave={()=>{handleTableAction('leave')}}
            showCancel={false}
            onCancel={()=>{handleTableAction('cancel')}}
            showSave={false}
            onSave={()=>{handleTableAction('save')}}
          />
        </Drawer>
        </>
      }
      </React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{popTitle}</DialogTitle>
        <DialogContent>
           {popBody}
           {showForm && 
            <>
            <FormControl>
              <InputLabel htmlFor="affil-name-outlined">Name</InputLabel>
              <OutlinedInput
                id="affil-name-outlined"
                value={joinMetaName}
                onChange={handleJoinMetaName}
                label="Name"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="affil-type-select-label">Type</InputLabel>
              <Select
                labelId="affil-type-select-label"
                id="affil-type-select"
                value={joinMetaType}
                label="Type"
                onChange={(handleJoinMetaType)}
              >
                <MenuItem value={"Person"}>Person</MenuItem>
                <MenuItem value={"Family"}>Family</MenuItem>
                <MenuItem value={"Club"}>Club</MenuItem>
                <MenuItem value={"Business"}>Business</MenuItem>
                <MenuItem value={"Government"}>Government</MenuItem>
                <MenuItem value={"Association"}>Association</MenuItem>
                <MenuItem value={"Foundation"}>Foundation</MenuItem>
              </Select>
            </FormControl>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {actionLoaded && <Button onClick={commit}>{actionType}</Button>}
          {error && <Button onClick={handleClose}>Okay</Button>}
          {!actionLoaded && <CircularProgress/>}
        </DialogActions>
      </Dialog>
    </>
  ) 

  return search_bar
  
}