import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Cookies from 'universal-cookie';
import AddSettingsButton from './settings-inputs/component-add-settings-button';
import PeopleIcon from '@mui/icons-material/People';
import UserBreakdown from './settings-inputs/user-breakdown';
import UserChip from './settings-inputs/user-chip';
import PermissionChip from './settings-inputs/permission-chip';
import PermissionBreakdown from './settings-inputs/permission-breakdown';
import CsvDownload from 'react-json-to-csv'

const addSetting = (target, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:setting_package,
    setting:target
  }

  return fetch(cookies.get('apiUrl')+"/settings/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const updateSeting = (target, id, setting_package) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:target,
    package:setting_package,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/settings/update", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const removeUserPermission = (type, id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id
  }

  return fetch(cookies.get('apiUrl')+"/user/delete/"+type, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addUpdatePermission = (permission) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    permission:permission
  }

  return fetch(cookies.get('apiUrl')+"/user/add/permission", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const addUpdateUser = (target) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  let userData = target
  if(target.hasOwnProperty('password'))
  {
    const {confirm, ...rest} = target
    userData = rest    
  }  

  const ttl = {
    user: user,
    token: token,
    target:userData
  }

  return fetch(cookies.get('apiUrl')+"/user/add/user", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })  
}

const fetchUsers = (index) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
  }

  let url = cookies.get('apiUrl')+"/user/list"
  if(index !== 'users')
  {
    url = cookies.get('apiUrl')+"/user/permissions"
  }

  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function UserSettings() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState('');
  const [saveClick, setSaveClick] = React.useState(false);
  const [deleteClick, setDeleteClick] = React.useState(false);

  const [newPassword, setNewPassword] = React.useState(false);
  const [userSaveClick, setUserSaveClick] = React.useState(false);

  const [refresh, setRefresh] = React.useState(false);
  const [deleteTarget, setDeleteTarget] = React.useState({})
  const [updateTarget, setUpdateTarget] = React.useState({click: false})
  const [addTarget, setAddTarget] = React.useState({click: false})

  const [errorUsers, setErrorUsers] = React.useState(null)
  const [errorPermissions, setErrorPermissions] = React.useState(null)

  const [loadingDescriptions, setLoadingUsers] = React.useState(false)
  const [loadingPermissions, setLoadingPermissions] = React.useState(false)

  const [dataUsers, setDataUsers] = React.useState([])
  const [fileDownload, setFileDownload]  = React.useState([])
  const [dataPermissions, setDataPermissions] = React.useState([])
  const [newDataPermission, setNewDataPermission] = React.useState({})
  const [newUserData, setNewUserData] = React.useState({})

  const [targetPermission, setTargetPermission] = React.useState({})
  const [targetUser, setTargetUser] = React.useState({})
  const [disableNew, setDisableNew] = React.useState({})
  
  React.useEffect(() => {
    if(saveClick)
    {
      addUpdatePermission(targetPermission).then(res => res.json())
      .then(
        (result) => {
          setSaveClick(false)
          setRefresh(!refresh)
        })
    }
  },[targetPermission, saveClick])

  React.useEffect(() => {
    if(userSaveClick)
    {
      addUpdateUser(targetUser).then(res => res.json())
      .then(
        (result) => {
          setRefresh(!refresh)
          setSaveClick(false)
        })
    }
  },[targetUser, userSaveClick])

  React.useEffect(() => {
    if(updateTarget.click)
    {
      updateSeting(updateTarget.setting, updateTarget.id, updateTarget.package).then(res => res.json())
      .then(
        (result) => {
          setUpdateTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[updateTarget])

  React.useEffect(() => {
    if(addTarget.click)
    {
      addSetting(addTarget.setting, addTarget.package).then(res => res.json())
      .then(
        (result) => {
          setAddTarget({click:false})
          setRefresh(!refresh)
        })
    }
  },[addTarget])

  React.useEffect(() => {
    if(deleteClick)
    {
      removeUserPermission(deleteTarget.type, deleteTarget.id).then(res => res.json())
      .then(
        (result) => {
          setDeleteClick(false)
          setRefresh(!refresh)
        })
    }
  },[deleteTarget, deleteClick])

  React.useEffect(() => {
    switch(index)
    {
      case 'users':
        setLoadingUsers(true)
        break
      case 'permissions':
        setLoadingPermissions(true)
        break
      default:
        break
    }
    fetchUsers(index).then(res => res.json())
    .then(
      (result) => {
        switch(index)
        {
          case 'users':
            setDataUsers(result.response)
            let temp = {}
            let pertemp = '';
            let userList = []
            result.response.forEach((element, key) => {
              temp = {}
              temp['id'] = element['id']
              temp['username'] = element['username']
              temp['email_address'] = element['email_address']
              temp['first_name'] = element['first_name']
              temp['last_name'] = element['last_name']
              pertemp = ''
              element.permission.forEach((permission, permission_key) =>
              {
                if(permission_key > 0)
                  pertemp += '; '

                pertemp += permission['name']
              })
              temp['permission'] = pertemp
              userList.push(temp)
            })
            setFileDownload(userList)
            setLoadingUsers(false)
            setErrorUsers(null)
            break
          case 'permissions':
            setDataPermissions(result.response)
            setLoadingPermissions(false)
            setErrorPermissions(null)
            break
          default:
            break
        }
      },
      (error) => {
        switch(index)
        {
          case 'users':
            setErrorUsers(error)
            setLoadingUsers(true)
            break
          case 'permissions':
            setErrorPermissions(error)
            setLoadingPermissions(true)
            break
        }
      }
    )    
  }, [index, refresh]);


  const toggleData = (newIndex) => {
    if(index !== newIndex)
    {
      setIndex(newIndex)
    }
    else
    {
      setIndex('');
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const permissionHandleChange = (prop,value) => {
    setNewDataPermission({ ...newDataPermission, [prop]: value });
  };

  const userNewHandleChange = (prop,value) => {
    setNewUserData({ ...newUserData, [prop]: value });
  };

  return (
    <>
    <List>
      <ListItem key="user-settings" disablePadding>
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={"User Settings"} />
        </ListItemButton>
      </ListItem>
      <Dialog key="settings" open={open} onClose={handleClose} fullWidth>
        <DialogTitle>User Settings</DialogTitle>
        <Divider sx={{pb:"2%"}}/>
        <DialogContent>         
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('users')}
              >
                <Typography>User List</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingDescriptions && errorUsers && <ErrorIcon color="danger"/>}
                {loadingDescriptions && <CircularProgress color="warning"/>}
                {!loadingDescriptions && dataUsers.length > 0 && (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={10} >
                            <CsvDownload 
                              data={fileDownload}
                              filename={'UserList.csv'}  
                            />
                          </Grid>
                          <Grid item xs={2} >
                            <AddSettingsButton 
                              disableSave={disableNew}
                              handleSave={(e) => {
                                setTargetUser(newUserData)
                                setNewUserData({})
                                setUserSaveClick(true)
                                setRefresh(!refresh)
                             }}>
                              <UserBreakdown 
                                data={newUserData}
                                handleChange={(prop,value)=>{
                                  if(prop === 'password')
                                  {
                                    setNewPassword(value)
                                    setDisableNew(false)
                                  }

                                  if(prop === 'confirm' && newPassword === '')
                                  {
                                    setDisableNew(false)
                                  }

                                  if(prop === 'confirm' && value === newPassword)
                                  {
                                    setDisableNew(false)
                                  }

                                  userNewHandleChange(prop,value)}}
                              />
                            </AddSettingsButton>
                          </Grid>
                        </Grid>
                        <Divider sx={{mb:1}}/>
                        <Stack spacing={1}>
                          {
                          dataUsers.map(element => {
                            let label = `${element.username}`
                             
                            element.open = false
                            if(element.first_name)
                            {
                              label = `${element.username} - ${element.first_name} ${element.last_name}`
                            }

                            return (
                              <div key={element.id}>
                                <UserChip 
                                  label={label}
                                  data={element}
                                  onSave={(edit) =>{
                                    setTargetUser(edit)
                                    setUserSaveClick(true)
                                  }}
                                  onDelete={(id) => {
                                    setDeleteTarget({
                                      id:id,
                                      type:'user'
                                    })
                                    setDeleteClick(true)
                                  }}
                                />
                              </div>
                            )})
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => toggleData('permissions')}
              >
                <Typography>Permission Groups</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!loadingPermissions && errorPermissions && <ErrorIcon color="danger"/>}
                {loadingPermissions && <CircularProgress color="warning"/>}
                {!loadingPermissions &&
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={10} >
                            <Typography styles={{paddingTop:'2%'}}>
                              
                            </Typography>
                          </Grid>
                          <Grid item xs={2} >
                            <AddSettingsButton 
                              width={true}
                              handleSave={(e) => {
                                setTargetPermission(newDataPermission)
                                setSaveClick(true)
                              }}>
                              <PermissionBreakdown 
                                data={newDataPermission}
                                handleChange={(prop,value)=>{permissionHandleChange(prop,value)}}
                              />
                            </AddSettingsButton>
                          </Grid>
                        </Grid>
                        <Divider sx={{mb:1}}/>
                        <Stack spacing={1}>
                          {
                          dataPermissions.map(element => {
                            let label = `${element.name}`
                             
                            return (
                              <div key={element.id}>
                                <PermissionChip 
                                  label={label}
                                  data={element}
                                  onSave={(edit) =>{
                                    setTargetPermission(edit)
                                    setSaveClick(true)
                                  }}
                                  onDelete={(id) => {
                                    setDeleteTarget({
                                      id:id,
                                      type:'permission'
                                    })
                                    setDeleteClick(true)
                                  }}
                                />
                              </div>
                            )})
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                }
              </AccordionDetails>
            </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </List>
  </>
  );
}
