import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'universal-cookie';

const getTemplateList = (report) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    report:report,
  }

  return fetch(cookies.get('apiUrl')+"/report/get/templates", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ReportTemplateSelect(props) {
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  const [raw, setRaw] = React.useState([]);
  let report = props.report;
  let hasTemplates = props.hasTemplates;
  const header = props.header

  const handleChange = (event) => {
    setSelectedTemplate(event.target.value);
    Object.entries(raw).map((key) => {
      if(key[1].name === event.target.value)
      {
        props.setTemplate(key[1])
      }
    })
    
  };

  React.useEffect(() => {
    if(report !== null && hasTemplates)
    {
      getTemplateList(report).then(res => res.json())
        .then(
          (result) => {
            let list = []
            let element = {}
            Object.entries(result.response).map((key) => {
              element = {
                key: key[0],
                label: key[1].name,
                checked: true
              }
              
              list.push(element)
            })

            hasTemplates = false
            setRaw(result.response)
            setTemplates(list)
          },
          (error) => {
            
          }
        );
    }
    else
    {
      setTemplates([])
      hasTemplates = false ;
      setSelectedTemplate([])
    };
  }, [report, hasTemplates, props]);


  if(templates.length < 1 || !hasTemplates)
  {
    return <></>
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': header,
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-template-select"
          select
          label="Template"
          value={selectedTemplate}
          onChange={handleChange}
          className="component_select"
        >
          {templates.map((option) => (
            <MenuItem key={'template'+option.key} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </Box>
  );
}
