import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function EventTypeSelect(props) {
  let id = 'report-select';
  const [type, setType] = React.useState(-1);

  const handleChange = (event) => {
    setType(event.target.value);
    props.callback(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '95%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id={id}
          select
          label="Select Type"
          value={type}
          onChange={handleChange}
          className=""
        >
          <MenuItem key="tmt-section" value={-1}>
            Select Type
          </MenuItem>
          <MenuItem key={'tracking'} value={'Tracking'}>
            Tracking
          </MenuItem>
          <MenuItem key={'basic'} value={'Basic'}>
            Series
          </MenuItem>
        </TextField>
      </div>
    </Box>
  );
}
