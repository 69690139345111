import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DataGridPro,
  useGridApiContext,
  GridEvents,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  GridToolbar
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const isNavigationKey = (key) =>
  key === 'Home' ||
  key === 'End' ||
  key.indexOf('Arrow') === 0 ||
  key.indexOf('Page') === 0 ||
  key === ' ';

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent(GridEvents.cellNavigationKeyDown, props, event);
    }
  };

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            tabIndex={-1}
            size="small"
          >
            {filteredDescendantCount}
          </Button>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
};

CustomGridTreeDataGroupingCell.propTypes = {
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The node of the row that the current cell belongs to.
   */
  rowNode: PropTypes.shape({
    /**
     * The id of the row children.
     */
    children: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    /**
     * Current expansion status of the row.
     */
    childrenExpanded: PropTypes.bool,
    /**
     * 0-based depth of the row in the tree.
     */
    depth: PropTypes.number.isRequired,
    /**
     * The field used to group the children of this row.
     * Is `null` if no field has been used to group the children of this row.
     */
    groupingField: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
      
    /**
     * The key used to group the children of this row.
     */
    groupingKey: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * If `true`, this node has been automatically added to fill a gap in the tree structure.
     */
    isAutoGenerated: PropTypes.bool,
    /**
     * The row id of the parent (null if this row is a top level row).
     */
    parent: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
};

const groupingColDef = {
  headerName: '',
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} groupingField={"path"}/>,
  width:10
};

export default function DataTable(props) {
  const filter = (props.filter)?{Toolbar: GridToolbar}:{};

  var temp_edit = (values,event)=>{}
  if(props.onEditCommit !== undefined)
  {
    temp_edit=props.onEditCommit
  }

  const onEditCommit=temp_edit

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGridPro
        checkboxSelection={props.canEdit}
        disableSelectionOnClick
        onSelectionModelChange={(newSelection) => {
          props.selectionChange(newSelection)
        }} 
        components={filter}
        rows={props.data}
        columns={props.columns}
        autoPageSize
        pagination
        onCellEditCommit={(values, event) => {onEditCommit(values,event)}}
        getTreeDataPath={(row) => row.path}
        groupingColDef={groupingColDef}
        treeData
      />
    </div>
  );
}
