import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro'
import Cookies from 'universal-cookie';
import TableSelect from '../inputs/table-select';
import TableCheck from '../inputs/table-check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import TableGifter from '../inputs/table-gifter';
import FamilyTransactuibChip from '../inputs/family-transaction-chip';

const fetchTransaction = (type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    setting:type,
  }

  return fetch(cookies.get('apiUrl')+"/settings/get", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const doTableAction = (action,selected,changed,entityId) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    selected:selected,
    changed:changed,
    table:'transaction',
    target:entityId
  }

  return fetch(cookies.get('apiUrl')+"/table/"+action, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function AccountingDetails(props) {
  const [accounts, setAccounts] = React.useState([])
  const [desc, setDesc] = React.useState([])
  const [discounts, setDiscounts] = React.useState([{id:-1, name:"No Discount"}])
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [saveOpen, setSaveOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [changeQueue, setChangeQueue] = React.useState([]);
  const cookies = new Cookies();
  var permission = (cookies.get('permission') === 'null')

  const [action, setAction] = React.useState({
    click:false,
    action:'',
    selected: [],
    changed:changeQueue,
  });

  const onEditCommit = (values, event) => {
    
    if(values.field === 'receiptDate')
    {
      let ts = new Date(values.value)
      values.value = ts.getTime()
    }
    
    let append = {id:values.id, field:values.field, value:values.value}
    changeQueue.push(append)
  }

  React.useEffect(() => {
    fetchTransaction('accounts').then(res => res.json())
      .then(
        (result) => {
          const {donation, purchase} = result.response
          setAccounts(donation.concat(purchase))
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    fetchTransaction('descriptions').then(res => res.json())
      .then(
        (result) => {
          const {donation, purchase} = result.response
          let descriptions = donation.concat(purchase)
          let pass =[]
          descriptions.map((t)=>{
            t.id = t.name 

            pass.push(t)
          })
          setDesc(pass)
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    fetchTransaction('discounts').then(res => res.json())
      .then(
        (result) => {
          setDiscounts(discounts.concat(result.response))
        },
        (error) => {
        }
      );
  }, []);

  React.useEffect(() => {
    if(action.click)
    {
      doTableAction(action.action,action.selected,action.changed, props.entityId).then(res => res.json())
        .then(
          (result) => {
            action.click = false
            action.selected = []
            action.changed = []
            setSaveOpen(false)
            setConfirmOpen(false)
          },
          (error) => {
          }
        );
    }
  }, [action, props]);

  const handleRemove = () => {
    action.click = true
    action.action = 'delete'
    setAction({...action})
  };

  const handleSave = () => {
    action.changed = changeQueue
    action.click = true
    action.action = 'save'
    setAction({...action})
  };

  const columns = [
    {
      field: "receiptDate",
      headerName: "Receipt Date",
      valueGetter: (params) => new Date(params.row.receiptDate),
      width: 100,
      editable:permission,
      type:'date',
    },
    {
      headerName:'Account',
      field:'account',
      renderCell: (params) => {
        return (<TableSelect 
            id={params.id} 
            list={accounts} 
            field="account" 
            val={params.value} 
            callback={(val,e) => onEditCommit(val,e)}
            disabled={!permission}
            />)},
      width: 150,
      filterable:false,
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 80,
      editable:permission,
      valueFormatter: (params) => {
        const valueFormatted = params.value.toLocaleString();
        return `$${valueFormatted}`;
      },
    },
    {
     field: "paidBy",
     headerName: "Method",
     renderCell: (params) => {
      return (<TableSelect
        id={params.id} 
        disabled={!permission}
        field="paidBy"
        list={[
        {id:'Cash or Check', name:'Cash/Check'},
        {id:'Credit/Debit Card', name:'Credit/Debit Card'},
        {id:'PayPal', name:'PayPal'},
        {id:'In-Kind', name:'In-Kind'},
        {id:'Stripe', name:'Stripe'},
        {id:'Eventbright', name:'Eventbright'},
        {id:'Direct Deposit', name:'Direct Deposit'},
      ]} 
        val={params.value} 
        callback={(val,e) => onEditCommit(val,e)} />)
    },
     width: 150,
     editable:false
    },
    {
     field: "description",
     headerName: "Solicitation",
     width: 150,
     renderCell: (params) => {
      return (
      <TableSelect 
        id={params.id} 
        list={desc}
        field="description"
        val={params.value} 
        callback={(val,e) => onEditCommit(val,e)} 
        disabled={!permission}
        />
    )},
    },
    {
      field: "discount",
      headerName: "Discount",
      renderCell: (params) => {
        return (<TableSelect 
          id={params.id} 
          list={discounts} 
          field="discount" 
          val={params.value} 
          callback={(val,e) => onEditCommit(val,e)} 
          disabled={!permission}
          />
        )},
      width: 150,
      editable:false
    },
    {
      field: "gifter",
      headerName: "Gifter",
      width: 150,
      editable:false,
      renderCell: (params) => {
        return (
          <TableGifter
            id={params.id}
            field={"gifter"}
            val={params.value}
            callback={(val,e) => onEditCommit(val,e)} 
            disabled={!permission}
          />)}
    },
    {
      field: "anonymous",
      headerName: "Anon",
      width: 100,
      editable:false,
      renderCell: (params) => {
        return <TableCheck 
          id={params.id} 
          val={params.value} 
          callback={(val,e) => onEditCommit(val,e)} 
          disabled={!permission}
          />
      },
    },
  ]

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleSaveClose = () => {
    setSaveOpen(false);
  };

  const openSave = () =>{
    setSaveOpen(changeQueue.length > 0)
  }

  return ( <>
    <Grid container spacing={1}>
      {permission &&
        <Grid item xs={12}>
          <IconButton 
            size="large"
            color="inherit"
            onClick={() => {setConfirmOpen(true)}}
            disabled={!show}
            >
            <DeleteForeverIcon/>
          </IconButton>
          <IconButton 
            size="large"
            color="inherit"
            onClick={() => {openSave(true)}}
            >
            <SaveIcon/>
          </IconButton>
          <FamilyTransactuibChip
            columns={columns}
            id={props.family}
            accounts={accounts}
          />
        </Grid>
      }
      <Grid item xs={12}>
        <div style={{ height: 600, width: '100%' }}>
          <DataGridPro
            rows={props.data}
            checkboxSelection={permission}
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => {
              action.selected = newSelection
              setShow(newSelection.length > 0)
            }} 
            columns={columns}
            density="compact"
            onCellEditCommit={(values, event) => {onEditCommit(values,event)}}
          />
        </div>
      </Grid>
    </Grid>
    <Dialog open={confirmOpen} onClose={handleClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>This cannot be undone</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleRemove}>Yes</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={saveOpen} onClose={handleSaveClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={handleSaveClose}>No</Button>
        <Button onClick={handleSave}>Yes</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
