import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro'
import AddTmButton from '../input/add-tm-button';
import EntitySearchInput from '../../../core-components/entity-details/inputs/entity-search-input';
import Grid from '@mui/material/Grid';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Cookies from 'universal-cookie';

const columns = [
  {
    field: "maintainerType",
    headerName: "Type",
    width: 90,
   },
  {
   field: "firstName",
   headerName: "First Name",
   width: 100,
   editable:true
  },
  {
   field: "lastName",
   headerName: "Last Name",
   width: 100,
   editable:true
  },
  {
    field: "emailAddress",
    headerName: "Email Address",
    width: 150,
    editable:true
  },
  {
   field: "primaryNumber",
   headerName: "Phone Number",
   width: 150,
   editable:true
  },
  {
   field: "address",
   headerName: "Address",
   width: 150,
   editable:true
  },
  {
    field: "city",
    headerName: "City",
    width: 75,
    editable:true
   },
  {
    field: "state",
    headerName: "State",
    width: 75,
    editable:true
   },
  {
    field: "zipCode",
    headerName: "Zip Code",
    width: 75,
    editable:true
   },
]

const addVolunteer = (section,type,entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    entity:entity,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const removeVolunteer = (section, entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  
  const ttl = {
    user: user,
    token: token,
    section:section,
    entity: entity
  }

  return fetch(cookies.get('apiUrl')+"/trail/remove/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function MaintainerView(props) {
  const [height, setHeight] = React.useState('200px');
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('')
  const [selectionChange, setSelectionChange] = React.useState([])
  const [volunteerType, setVolunteerType] = React.useState('Maintainer')
  const [ newVolunteer, setNewVolunteer ] = React.useState({
    name:props.name,
    type:'Maintainer',
    click:false
  });

  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'

  React.useEffect(() => {
    if(newVolunteer.click)
    {
      addVolunteer(newVolunteer.name, newVolunteer.type,newVolunteer.entity).then(res => res.json())
      .then(
        (result) => {
          newVolunteer.click = false
          props.refresh()
        })
    }
  },[newVolunteer, props])

  const [ deleteList, setDeleteList ] = React.useState({
    list: [],
    click:false
  });

  React.useEffect(() => {
    if(deleteList.click)
    {
      deleteList.list.forEach(element => {      
        removeVolunteer(props.entity, element).then(res => res.json())
        .then(
          (result) => {
            deleteList.click = false
            props.refresh()
          })
      })
    }
  },[deleteList, props])

  if(props.type === 'Section' && height !== '200px' )
  {
    setHeight('200px')
  }
  else if(props.type === 'Region' && height !== '400px' )
  {
    setHeight('400px')
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={1}>
        {props.type === 'Section' && <Grid item xs={1}>
          <AddTmButton title="Add Volunteer" handleSave={(e) => {
            setNewVolunteer({
              name:newVolunteer.name,
              entity: search.id,
              type:volunteerType,
              click:true
            })
          }}>
            <Stack spacing={1}>
              <EntitySearchInput 
                type="person"
                label="Search"
                onChange={(value) => {setSearch(value)}}
              />
              <FormControl fullWidth>
                <InputLabel id="new-section-type-select-label">Type</InputLabel>
                <Select
                  labelId="new-section-type-select-label"
                  id="new-section-type-select"
                  value={volunteerType}
                  label="Type"
                  onChange={(e)=>{
                    setVolunteerType(e.target.value)
                  }}
                >
                  <MenuItem value="Maintainer">Maintainer</MenuItem>
                  <MenuItem value="Sponsor">Sponsor</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </AddTmButton>
        </Grid>
        }
        <Grid item xs={1}>
          {permission && 
            <IconButton 
              size="small" 
              color="inherit" 
              onClick={() => {
                if(selectionChange.length > 0)
                {
                  setOpen(true)
                }
              }}>
              <PersonRemoveIcon/>
            </IconButton>
          }
        </Grid>
      </Grid>
        <div style={{ display: 'flex', height: height }}>
          <div style={{ flexGrow: 1 }}>
            <DataGridPro
              rows={props.data}
              columns={columns}
              density="compact"
              checkboxSelection={permission}
              onSelectionModelChange={(newSelection)=>{setSelectionChange(newSelection)}}
            />
          </div>
        </div>
      <Dialog open={open} onClose={() => {setOpen(false)}}>
        <DialogTitle>Remove Maintainer(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteList({
              list:selectionChange,
              click:true
            })
            setOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>      
    </Stack>
  );
}
