import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DummyChip from './component-dummy-chip';
import MailIcon from '@mui/icons-material/Mail';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import SaveIcon from '@mui/icons-material/Save';
import BeenhereIcon from '@mui/icons-material/Beenhere';

export default function DetailDocumentation() {

  return (
      <Box p={2}>
        <Typography>
          You will often see the <AddIcon size='small'/> next to a field name. Generally, that means to add that particular attribute chip
          to the entity record.
        </Typography>
        <Typography mt={2}>
          Throughout each of the accordions, in the detail popup, there are elements called, chips. Chips in Trail Tracker are 
          points of data that can be attributed to an entity. Some are interactive some just present some information to you.
        </Typography>
        <Typography  mt={2}>
          The best way to find out if you can interact with a chip is to simply click on them. The interactive chips contain data about the
          chip attribute
        </Typography>
        <Box p={2}>
          <Stack direction={'row'} spacing={2}>
            <Chip label="I'm a Chip!"/>
            <DummyChip/>
          </Stack>
        </Box>
        <Typography  mt={2}>
          Throught the documentation you will see the word entity. In trail tracker an entity is a person or affiliation that is 
          recorded in the database.
        </Typography>
        <Typography  mt={2} mb={2}>
          Affiliations are entities that are not Person types. i.e. Business, Family, Club
        </Typography>
        <Divider/>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>General Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The General Details is where you can assign roles, change the entity type and control the mailing types that are assigned to the 
              entity. 
            </Typography >
            <Divider/>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
              >
                <Typography>Adding roles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The general details Accordion'}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={<>Click on the {<AddIcon size="small"/>} icon</>}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={'Select the role and assigned date'}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Four"
                      secondary={"Click 'Save'"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Removing roles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The general details Accordion'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={<>Click on the {<DeleteIcon size="small"/>} icon</>}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={"Click 'Yes'"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Updating roles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The general details Accordion'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={'Click on the chip body of a role'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={"Click on the date"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Four"
                      secondary={"Select the new date and click 'Ok'"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Updating mailing types</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The general details Accordion'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={'Click on the chip body of a Mailing Type'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Three (A)"
                      secondary={<>Click on the <MailIcon size='small'/> toggle to turn off email correspondence</>}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Three (B)"
                      secondary={<>Click on the <MarkunreadMailboxIcon size='small'/> toggle to turn off physical mail correspondence</>}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Four"
                      secondary={<>Click on the <CloseFullscreenIcon size="small" /> to close</>}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Changing entity types</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography mb={2} mt={2}>
                  This is something that can cause data loss. Trail Tracker will prompt you before
                  any change are made. Please use with care
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The general details Accordion'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={'Select the entity type that you would like to switch to'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={"Click 'Save' on the confirmation popup"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Membership</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Membership Accordion is where you can modify membership type and the date joined as well as view some basic membership,
              data
            </Typography >
            <Typography mt={2}>
              The Expiration date, status, and last paid dates are all derived from the last membership doantion/purchase
            </Typography >
            <Divider/>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Changing basic information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The memberhsip accordion'}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={"Change the membership type or the date joined"}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={<>Click on the <SaveIcon size="small"/> to save</>}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Four"
                      secondary={"Click 'Ok' to confirm"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography>Trail Managment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The trail management accordion is where you can change the maintainer type, the section the entity maintains, and use the 
              various form functions. Specifically when an affiliation is a Govenrment type you will be presented with differnt options
              that reflect the various specific details that a Govenrment organization may have. 
            </Typography >
            <Typography mt={2}>
              Click on the <AddIcon size="small"/> next it's data field to add a chip of that type.
            </Typography >
            <Typography mt={2}>
              You can change the maintainer type by simply changing the maintainer in the dropdown
            </Typography >
            <Divider/>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Reading the form information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Forms are presented under three catagories: Expired, Current, and Missing. If a form Chip is classified underneath a category
                  that means that the particular form is either Expired, Current, or Missing. If a form has an expiration date the date will 
                  present next to the chip as a sub chip. If a form is within three months of expiring the date will turn yellow. Current dates
                  will be shown as green and expired date, red. Non expiring forms will always be shown under current and no date will be shown.
                </Typography >
                <Typography mt={1}>
                  The trash icon will remove the form chip. Generally forms are never removed unless there is a mistake.
                </Typography >
              </AccordionDetails>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Reading the section and region data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The section data is color coded to match the region it belongs too. Clicking on the section will also display the region
                  that it belongs to. Clicking the trash icon will remove the section from the entity.
                </Typography >
                <Typography pt={1}>
                  Clicking on the region will display the region's trail coordinator.
                </Typography >
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Membership</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Membership Accordion is where you can modify membership type and the date joined as well as view some basic membership,
              data
            </Typography >
            <Typography mt={2}>
              The Expiration date, status, and last paid dates are all derived from the last membership doantion/purchase
            </Typography >
            <Divider/>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Changing basic information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Step One"
                      secondary={'Expand The memberhsip accordion'}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Two"
                      secondary={"Change the membership type or the date joined"}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Three"
                      secondary={<>Click on the <SaveIcon size="small"/> to save</>}
                    />
                  </ListItem>
                  <Divider/>
                  <ListItem>
                    <ListItemText
                      primary="Step Four"
                      secondary={"Click 'Ok' to confirm"}
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </Box>
  );
}
