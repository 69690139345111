import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {
  DataGridPro,
  GridToolbar
} from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ActivityButton from '../../core-components/entity-details/header/component-entity-activity';
import MaintainerButton from './input/parcel-maintainers';
import NotesButton from '../../core-components/entity-details/header/component-entity-notes';
import EditParcel from './input/edit-parcel';
import Cookies from 'universal-cookie';
import PublicLand from './input/public-land';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

const cookies = new Cookies();
const permission = cookies.get('permission') === 'null'

const deleteParcel = (parcels) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    selected:parcels,
    table:'land'
  }

  return fetch(cookies.get('apiUrl')+"/table/delete", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function ParcelTable(props) {
  const filter = (props.filter)?{Toolbar: GridToolbar}:{};
  const [open, setOpen] = React.useState(false)
  const cookies = new Cookies();
  const permission = cookies.get('permission') === 'null'
  const [selectionList,setSelectionList] = React.useState([])
  const [deleteClick, setDeleteClick] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  var temp_edit = (values,event)=>{}
  if(props.onEditCommit !== undefined)
  {
    temp_edit=props.onEditCommit
  }

  const onEditCommit=temp_edit

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if(deleteClick)
    {
      deleteParcel(selectionList)
      .then(res => res.json())
      .then(
        (result) => {
          setDeleteClick(false)
          props.refresh()
        })
    }
  },[deleteClick,selectionList, props])

  const columns = [
    {
      headerName:'Actions',
      field:'target',
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <EditParcel data={params.row} refresh={()=>props.refresh()}/>
            <MaintainerButton data={params.row}/>
            <NotesButton type="land" focusId={params.id}/>
            <ActivityButton type="land" focusId={params.id} />
            {params.row.public === 'Public' &&
              <PublicLand data={params.row}/>
            }
          </Stack>
        )
      },
      width: 200,
      filterable:false,
      hide:!permission
    },
    {
      headerName:'Parcel Tax Id',
      field:'taxId',
      width: 150,
    },
    {
      field: "properName",
      headerName: "Landowner",
      width: 100,
     },
    {
     field: "parcelCounty",
     headerName: "Parcel County",
     width: 100,
    },
    {
      field: "parcelTown",
      headerName: "Parcel Town",
      width: 80,
    },
    {
      field: "parcelAddress",
      headerName: "Parcel Address",
      width: 150,
    },
    {
      field: "address",
      headerName: "Landowner Address",
      width: 150,
     },
     {
      field: "city",
      headerName: "Landowner City",
      width: 80,
     },
     {
      field: "state",
      headerName: "Landowner State",
      width: 80,
     },
     {
      field: "zipCode",
      headerName: "Landowner Zip Code",
      width: 80,
     },
    {
      field: "emailAddress",
      headerName: "Landowner Email",
      width: 150,
     },
     {
      field: "primaryNumber",
      headerName: "Landowner Phone Number",
      width: 150,
     },
     {
      field: "public",
      headerName: "Public",
      width: 100,
     },
     {
      field: "agreement",
      headerName: "Agreement",
      width: 100,
     },
     {
      field: "section",
      headerName: "Section",
      width: 100,
     },
     {
      field: "map",
      headerName: "Map",
      width: 100,
     },
     {
      field: "region",
      headerName: "Region",
      width: 100,
     },
     {
      field: "rtc",
      headerName: "RTC",
      width: 100,
     },
  ]

  return (
    <div>
      {props.error && <ErrorIcon color="danger"/>}
      {!props.loading && <CircularProgress color="warning"/>} 
      {props.loading && props.data.length > 0 && (
        <Button variant="Contained" onClick={handleClick}>Search: {props.data.length} parcels</Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h5" component="h4" >Parcels</Typography>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2}>
            <IconButton 
              size="large"
              color="inherit"
              onClick={() => {setConfirmOpen(true)}}
              disabled={!(selectionList.length > 0)}
              >
              <DeleteForeverIcon/>
            </IconButton> 
          </Grid>
        </Grid>
        <div style={{ display: 'flex', height: '600px' }}>
          <div style={{ flexGrow: 1 }}> 
            <DataGridPro
              checkboxSelection={permission}
              disableSelectionOnClick
              onSelectionModelChange={(newSelection)=>{
                setSelectionList(newSelection)
              }}
              components={filter}
              rows={props.data}
              columns={columns}
              autoPageSize
              pagination
              onCellEditCommit={(values, event) => {onEditCommit(values,event)}}
            />
          </div>
        </div>
        <DialogActions>
          <Button type="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}}>
        <DialogTitle>Remove Parcel(s)?</DialogTitle>
        <DialogActions>
          <Button type="contained" onClick={() => {setConfirmOpen(false)}}>No</Button>
          <Button type="contained" onClick={()=>{
            setDeleteClick(true)
            setConfirmOpen(false)
          }}>Yes</Button>
        </DialogActions>
      </Dialog>      
    </div>
  );
}
