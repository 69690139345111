import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import EntitySearchInput from '../../../core-components/entity-details/inputs/entity-search-input';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import MixedSearchInput from '../../../core-components/entity-details/inputs/mixed-search-input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

const editParcel = (values,id) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    package:values,
    landId:id
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/parcel", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function EditParcel(props) {
  const id = props.data.id
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(true);
  const [open, setOpen] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [removeLandowner, setRemoveLandowner] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [ swis, setSwis ] = React.useState(props.data.parcelSwis)
  const [ address, setAddress ] = React.useState(props.data.parcelAddress)
  const [ county, setCounty ] = React.useState(props.data.parcelCounty)
  const [ town, setTown ] = React.useState(props.data.parcelTown)
  const [ taxId, setTaxId ] = React.useState(props.data.taxId)
  const [ landowner, setLandowner ] = React.useState(props.data.raw)
  const  oldLandonwer  = props.data.raw
  const [ section, setSection ] = React.useState(props.data.section)
  const oldSection  = props.data.section
  const [ agreement, setAgreement ] = React.useState(props.data.agreement)
  const [ request, setRequest ] = React.useState({click:false, data:{}})

  React.useEffect(() => {
    if(request.click)
    {
      editParcel(request.data, id).then(res => res.json())
        .then(
          (result) => {
            request.click = false
            setRefresh(!refresh)
            setRemoveLandowner(false)
            props.refresh()
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }}, [request, id]);

    React.useEffect(()=>{
      if(removeLandowner)
      {
        handleSave()
      }
    }, [removeLandowner])

  const handleClick = (event) => {
    setOpen(true)
  };

  const handleSave = () => {
    request.data= {
      town: town,
      address: address,
      parcelTaxId: taxId,
      swis:swis,
      county:county,
      agreement:agreement
    }

    if(removeLandowner)
    {
      request.data['entity'] = 'null'
    }
    else if(landowner !== oldLandonwer)
    {
      request.data['entity'] = landowner.id
    }

    if(oldSection !== section)
    {
      request.data['section'] = section
    }

    request.click= true
    setRequest({...request})
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-label="Volunteers"
        color="inherit"
        onClick={handleClick}
      >
        <Badge color="primary">
          <EditIcon/>
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Edit Parcel 
        </DialogTitle>
        <DialogContent>
         <Box>
          <Stack spacing={1}>
            <MixedSearchInput
              label={'Section'}
              type={'section'}
              value={section}
              onChange={(value) => {setSection(value)}}
            />
            <EntitySearchInput 
              label="Landowner"
              value={landowner}
              searchValue={landowner.firstName + ' ' + landowner.lastName}
              onChange={(value) => {
                if(value === null)
                {
                  value = {firstName:"", lastName:""}
                }
                setLandowner(value)
              
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="new-section-type-select-label">Agreement</InputLabel>
              <Select
                labelId="new-section-type-select-label"
                id="new-section-type-select"
                value={agreement}
                label="Agreement"
                onChange={(e)=>{
                  setAgreement(e.target.value)
                }}
              >
                <MenuItem value="FLTC Owns">FLTC Owns</MenuItem>
                <MenuItem value="Easement">Easement</MenuItem>
                <MenuItem value="Written Contract">Written Contract</MenuItem>
                <MenuItem value="Verbal Agreement">Verbal Agreement</MenuItem>
                <MenuItem value="Off Trail">Off Trail</MenuItem>
              </Select>
            </FormControl>
            <TextField 
              id="taxId" 
              label="Tax Id" 
              variant="outlined" 
              value={taxId}
              onChange={(e) => {setTaxId(e.target.value)}}
            />
            <TextField 
              id="swiss" 
              label="SWIS" 
              variant="outlined" 
              value={swis}
              onChange={(e) => {setSwis(e.target.value)}}
            />
            <TextField 
              id="address"
              label="Address" 
              variant="outlined"
              value={address}
              onChange={(e) => {setAddress(e.target.value)}}
            />
            <TextField 
              id="county-search-add" 
              label="County" 
              variant="outlined" 
              value={county}
              onChange={(e) => {setCounty(e.target.value)}}
            />
            <TextField 
              id="town-search-add"
              label="Town" 
              variant="outlined"
              value={town}
              onChange={(e) => setTown(e.target.value)}
            />
          </Stack>
        </Box>
      </DialogContent> 
      <DialogActions>
        <Button type="contained" onClick={()=>{
          setConfirmOpen(true)
        }}>Unlink Landowner</Button>
        <Button type="contained" onClick={handleClose}>Close</Button>
        <Button type="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={confirmOpen} onClose={handleClose}>
      <DialogTitle>
        Remove Landowner?
      </DialogTitle>
      <DialogActions>
        <Button type="contained" onClick={()=>setConfirmOpen(false)}>No</Button>
        <Button type="contained" onClick={()=>{
          setRemoveLandowner(true)
          setConfirmOpen(false)
        }}>Yes</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
