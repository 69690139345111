import * as React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles, styled } from '@material-ui/core/styles';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Cookies from 'universal-cookie';

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 0.8125}rem`,
    height: (props) => `${props.size * 32}px`,
    borderRadius: "9999px",
  },
  avatar: {
    "&&": {
      height: (props) => `${props.size * 24}px`,
      width: (props) => `${props.size * 24}px`,
      fontSize: (props) => `${props.size * 0.25}rem`
    }
  },
  deleteIcon: {
    height: (props) => `${props.size * 22}px`,
    width: (props) => `${props.size * 22}px`,
    color: "primary"
  }
}));

const removeSection = (id, entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    mpId:id,
    entity: entity
  }

  return fetch(cookies.get('apiUrl')+"/trail/remove/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

export default function SectionChip(props) {
  const [open, setOpen] = React.useState(false);
  const [chipTitle, setChipTitle] = React.useState(props.data.name)
  const [size, setSize] = React.useState(1)
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [click, setClick] = React.useState(false);

  const cookies = new Cookies();
  var permissiveIcon = <></>
  if(cookies.get('permission') === 'null')
  {
    permissiveIcon = <DeleteIcon/>;
  }
  const [icon, setIcon] = React.useState(permissiveIcon)


  const handleClick = (event) => {
    if(!open)
    {
      setIcon(<CloseFullscreenIcon />)
      setOpen(!open)
      setSize(1.5)
      setChipTitle((props.data.region))
    }
  };

  React.useEffect(() => {
    if(click)
    {
      removeSection(props.data.id, props.entityId).then(res => res.json())
      .then(
        (result) => {
          setClick(false)
          props.refresh()
        })
    }
  },[props, click])
  
  const handleDelete = (event) => {
    if(open)
    {
      setSize(1)
      setChipTitle(props.data.name)
      setOpen(!open)
      setIcon(permissiveIcon)
    }
    else
    {
      setConfirmOpen(true)
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleRemove = () => {
    setConfirmOpen(false)
    setClick(true)
  };
  var theme = null

  if(props.data.color !== 'primary')
  {
    var theme = createTheme({
      palette: {
        primary: {
          main: props.data.color,
        },
      },
    });
  }

  return (
    <div>
      {theme !== null && 
      <ThemeProvider theme={theme}>
        <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
            xs={{bgcolor:props.data.color}}
            color="primary"
        />
      </ThemeProvider>}
      {theme === null && 
        <CustomChip
            size={size}
            label={chipTitle}
            variant="filled"
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={icon}
            xs={{bgcolor:props.data.color}}
            color="primary"
        />}
      <Dialog open={confirmOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleRemove}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
