import * as React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Cookies from 'universal-cookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Badge from '@mui/material/Badge';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import {MobileDatePicker,LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import MixedSearchInput from '../../core-components/entity-details/inputs/mixed-search-input';
import AddDetailButton from '../../core-components/entity-details/inputs/component-add-detail-button';
import EntitySearchInput from '../../core-components/entity-details/inputs/entity-search-input';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import NewMembershipButton from './component-add-membership-button';
import AddDonationButton from './component-add-donation-button';
import AddPurchaseButton from './component-add-purchase-button';
import AccountingDetails from './component-accounting-details';
import CircularProgress from '@mui/material/CircularProgress';

const addMaintainer = (section,type,entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    section:section,
    entity:entity,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/volunteer", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const linkEntity = (left, right) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    left: left,
    right: right
  }

  return fetch(cookies.get('apiUrl')+"/detail/link/entity", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const addLinkLand = (values, entity) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    entity:entity,
    package:values
  }

  return fetch(cookies.get('apiUrl')+"/trail/add/parcel", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}


const recordMembership = (id,data,type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  let target = (data.target !== false) ? data.target:id

  let anon = (data.anonymous)? 'Yes': 'No'
  const ttl = {
    user: user,
    token: token,
    entity:id,
    type:'membership',
    membership:type,
    membershipTarget: target,
    transaction:{
      anonymous:anon,
      payment:data.payment,
      paid_by:data.paidBy,
      description:data.description,
      account_id:1,
      receipt_date:data.receiptDate.toISOString().split('T')[0],
      memo:data.memo,
      gifter:null,
    }
  }


  return fetch(cookies.get('apiUrl')+"/transaction/record", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const recordTransaction = (id,data,gift = null) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';
  let anon = (data.anonymous)? 'Yes': 'No'
  const ttl = {
    user: user,
    token: token,
    entity:id,
    type:data.type,
    transaction:{
      anonymous:anon,
      payment:data.payment,
      paid_by:data.paidBy,
      description:data.description,
      account_id:data.account,
      receipt_date:data.receiptDate.toISOString().split('T')[0],
      memo:data.memo,
      recognition:data.recognition,
      gifter:data.gift,
    }
  }

  return fetch(cookies.get('apiUrl')+"/transaction/record", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const saveGeneral = (entityPackage) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    data: entityPackage,
  }

  return fetch(cookies.get('apiUrl')+"/data/add/entity", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const registerForEvent = (id,reg) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    attribute: reg.date_joined.toISOString().split('T')[0],
    name:reg.name
  }

  return fetch(cookies.get('apiUrl')+"/events/register", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const saveAttribute = (id,name, type) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    id:id,
    attribute: name,
    type:type
  }

  return fetch(cookies.get('apiUrl')+"/detail/attribute/add", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchSearch = (values) => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    search: values.search,
    county: values.county,
    town: values.town,
    map: '',
    passback: Date.now()
  }

  return fetch(cookies.get('apiUrl')+"/search/parcels", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
   
}

const fetchActivity = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const ttl = {
    user: user,
    token: token,
    type:'donation',
  }

  return fetch(cookies.get('apiUrl')+"/data/package/transaction", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(ttl)
  })
}

const deleteComponent = () => {
  const cookies = new Cookies();
  let user = (cookies.get('user') !== undefined)? cookies.get('user'):''; 
  let token = (cookies.get('credToken') !== undefined)? cookies.get('credToken'):'';

  const request = { 
    user: user, 
    token: token,
    component_name: 'add-entity'
   }

  return fetch(cookies.get('apiUrl')+"/user/remove/component", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(request)
  })
}

export default function AddNewEntityComponent(props) {
  const [ saveTitle, setSaveTitle] = React.useState('Confirm Parcel');
  const [ saveContent, setSaveContent] = React.useState(`Don't forget to double check your work. If you make a mistake, please see documentation to correct the mistake`);
  const [ pastSave, setPastSave] = React.useState(false);
  const [saveButtonTitle, setSaveButtonTitle] = React.useState('Save')
  const [saveLoading, setSaveLoading ] = React.useState(false)
  const [saveError, setSaveError ] = React.useState(false)
  const [userError, setUserError ] = React.useState(false)
  const [ showSave, setShowSave] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true)

  const [trackOpen, setTrackOpen] = React.useState({
    generalDetails: false,
    awardsEvents: false,
    trailManagment: false,
    parcel: false,
    membership: false,
    contactInformation: false,
    accounting: false,
    affiliatesAffiliation: false,
    typeSelect: true
  })

  const [newEntity, setNewEntity] = React.useState({
    click: false,
    general:{},
    awards: [],
    roles: [],
    events: [],
    forms: [],
    sections: [],
    parcels: [],
    transactions:[],
    relationships: [],
    membershipTransaction: [],
    membership: {
      status:'No membership',
      expDate: '',
      dateJoined: '',
      type: 'Click Add membership to set membership',
      lastPaid: '',
      target:false
    }
  })

  const handleSave = () => {
    if(saveButtonTitle === 'Okay')
    {
      window.location.reload()
    }
    else if(pastSave)
    {
      setConfirmOpen(false)
      setShowSave(false)
    }
    else
    {
      //check errors
      let hasErrors = false
      let errorMessage = []

      if(!newEntity.general.hasOwnProperty('type'))
      {
        errorMessage.push(<div>Please enter the entity type</div>)
        hasErrors = true
      }

      if(!newEntity.general.hasOwnProperty('first_name'))
      {
        errorMessage.push(<div>Please enter a first name</div>)
        hasErrors = true
      }

      if(!newEntity.general.hasOwnProperty('last_name'))
      {
        errorMessage.push(<div>Please enter a last name</div>)
        hasErrors = true
      }

      if(!newEntity.general.hasOwnProperty('email_address') && !newEntity.general.hasOwnProperty('address'))
      {
        errorMessage.push(<div>Please enter an email address or a mailing address</div>)
        hasErrors = true
      }

      if(newEntity.general.hasOwnProperty('address') && !newEntity.general.hasOwnProperty('state'))
      {
        errorMessage.push(<div>Please enter the mailing state</div>)
        hasErrors = true
      }

      if(newEntity.general.hasOwnProperty('address') && !newEntity.general.hasOwnProperty('city'))
      {
        errorMessage.push(<div>Please enter the mailing city</div>)
        hasErrors = true
      }

      if(newEntity.general.hasOwnProperty('address') && !newEntity.general.hasOwnProperty('zip_code'))
      {
        errorMessage.push(<div>Please enter the mailing zip code</div>)
        hasErrors = true
      }

      setUserError(hasErrors)
      setPastSave(!hasErrors)
      if(!hasErrors)
      {
        newEntity.click = true
        setNewEntity({...newEntity})
      }
      else
      {
        setSaveTitle('There are some input errors')
        setSaveContent(errorMessage)
      }
    }
  }

  React.useEffect(() => {
    if(newEntity.click)
    {
      setPastSave(true)
      setSaveButtonTitle(<CircularProgress/>)
      setSaveLoading(true)
      setSaveTitle('Saving General Details')
      let entityPackage = {}
      //build data package
      if(newEntity.general.type !== 'Person')
      {
        const 
        {
          first_name, middle_name, last_name, ...dataPackage
        } = newEntity.general

        dataPackage['name'] = ''
        if(first_name)
          dataPackage['name'] += first_name + ' '
        if(middle_name)  
          dataPackage['name'] += middle_name + ' '
        if(last_name)
          dataPackage['name'] += last_name 
        entityPackage = dataPackage
      }

      //This is done here so that the fields are consistent except for the name of the entity
      let messageStack = []
      var contactInformation = []
      var contactTitles = ''
      var splitData = []
      var index = 0;
      for(const [key,value] of Object.entries(newEntity.general))
      {
        contactTitles = ''
        splitData = key.split('_')
        splitData.map(item => {
          contactTitles += item.charAt(0).toUpperCase() + item.slice(1) + ' '  
        })
        contactTitles += '- ' + value
        contactInformation.push((<Typography>{contactTitles}</Typography>))
      }

      messageStack.push(<Stack spacing={1}>{contactInformation}</Stack>)
      messageStack.push(<Divider/>)
      var message = (<>{messageStack}</>)
      setSaveContent(message)
      
      if(newEntity.general.type === 'Person')
      {
        const 
        {
          primary_phone_number, secondary_phone_number, ...dataPackage
        } = newEntity.general
        dataPackage['primary_number'] = primary_phone_number
        dataPackage['secondary_number'] = secondary_phone_number
        entityPackage = dataPackage
      }
    
      saveGeneral(entityPackage).then(res => res.json())
        .then(
          (result) => {
            
            if(newEntity.roles.length > 0 || newEntity.awards.length > 0) 
            {
            setSaveTitle('Success! Now saving attributes...')
            }
            let id = result.response.id

            let attributeStack = []
            newEntity.roles.map(element => {
              saveAttribute(id,element, 'role').then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Role - {element}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add role - {element}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            attributeStack = []
            newEntity.awards.map(element => {
              saveAttribute(id,element, 'awards').then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Award - {element}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add award - {element}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            if(newEntity.events.length > 0) 
            {
              setSaveTitle('Now registering events...')
            }
            attributeStack = []
            newEntity.events.map(element => {
              registerForEvent(id,element).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Event - {element.name}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to register to event - {element.name}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            if(newEntity.membershipTransaction.length > 0)
            {
              setSaveTitle('Now saving membership...')
            }
            attributeStack = []
            newEntity.membershipTransaction.map(element => {
              recordMembership(id,element,newEntity.membership.type).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Membership - {newEntity.membership.type}</Typography></>)
                  messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                  messageStack.push(<Divider/>)
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add membership - {newEntity.membership.type}</Typography></>)
                  messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                  messageStack.push(<Divider/>)
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })
            
            if(newEntity.transactions.length > 0)
            {
              setSaveTitle('Now saving accounting transactions...')
            }
            attributeStack = []
            newEntity.transactions.map(element => {
              recordTransaction(id,element).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Transaction - {element.description}, ${element.payment}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add transaction - {element.description}, ${element.payment}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                }
              );
              
            })

            if(newEntity.sections.length > 0) 
            {
              setSaveTitle('Adding trail managment records...')
            }
            attributeStack = []
            newEntity.sections.map(element => {
              addMaintainer(element.name, element.type, id).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Section {element.type} - {element.name} </Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add to section - {element.name}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            if(newEntity.parcels.length > 0) 
            {
              setSaveTitle('Adding parcels...')
            }
            attributeStack = []
            newEntity.parcels.map(element => {
              addLinkLand(element, id).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>Parcel - {element.parcel_tax_id} </Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to add to parcel - {element.parcel_tax_id}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            if(newEntity.relationships.length > 0) 
            {
              setSaveTitle('Linking entities...')
            }
            attributeStack = []
            newEntity.relationships.map(element => {
              linkEntity(id, element.id).then(res => res.json())
              .then(
                (result) => {
                  attributeStack.push(<><Typography>{result.response} </Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{result.response}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                },
                (error) => {
                  attributeStack.push(<><Typography>Failed to link entities - {element.name}</Typography></>)
                  if(attributeStack.length > 1)
                  {
                    messageStack[index] = <Stack spacing={1}>{attributeStack}</Stack>
                    messageStack[index+1] = <Divider/>
                  }
                  else
                  {
                    messageStack.push(<Stack spacing={1}>{attributeStack}</Stack>)
                    index = messageStack.length -1
                    messageStack.push(<Divider/>)
                    
                  }
                  message = (<>{messageStack}</>)
                  setSaveContent(message)
                  setSaveError(error);
                }
              );
            })

            setSaveTitle('Please review and note any errors to correct!')
            setSaveLoading(false);
            setSaveButtonTitle('Okay')
          },
          (error) => {
            setSaveLoading(false);
            setSaveContent('There was an error processing your request, please try again after clicking okay!')
            newEntity.click = false
            setNewEntity({...newEntity})
            setSaveButtonTitle('Okay')
            setSaveError(error);
          }
        );
      }
    }, [newEntity]);
  
  
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);  
  const disabled = false
  const [ results, setResults ] = React.useState([])
  const [ searchError, setSearchError ] = React.useState(null)
  const [ searchLoading, setSearchLoading ] = React.useState('')
  const [ isLoaded, setIsLoaded] = React.useState(true);
  const [ type, setType] = React.useState('') 

  const [ confirmOpen, setConfirmOpen] = React.useState(false);
  const [ searchType, setSearchType] = React.useState('');
  const [ newAward, setNewAward] = React.useState('');
  const [ newRole, setNewRole] = React.useState('');
  const [ newEvent, setNewEvent] = React.useState('');
  const [ swis, setSwis ] = React.useState('')
  const [ address, setAddress ] = React.useState('')
  const [ county, setCounty ] = React.useState('')
  const [ town, setTown ] = React.useState('')
  const [ agreement, setAgreement ] = React.useState('')
  const [ section, setSection ] = React.useState()
  const [ requestClick, setRequestClick ] = React.useState(false)
  const [newMember, setNewMember ] = React.useState({})
  const [tempLink, setTempLink ] = React.useState(null)
  const [componentDelete, setComponentDelete ] = React.useState(false)


  const [tPackage, setTPackage] = React.useState({
    contributors:[],
    accounts:[],
    apply: false,
    descriptions: [],
    memberTypes:[],
    discounts:[]
  });

  const [ pass, setPass ] = React.useState(
    {
      search:'',
      town:'',
      county:''
    }
  )
  const [ search, setSearch ] = React.useState('')
  const [addSection, setAddSection] = React.useState(
    {
      name: '',
      type: 'Maintainer'
    }
  );

  const [ landRequest, setLandRequest ] = React.useState(
    {
      parcel_tax_id:'',
      town:'',
      county:'',
      swis:'',
      address:''
    }
  )
  const [options,setOptions ] = React.useState([])
  const [protect,setProtect ] = React.useState(false)
  const menuId = 'add-new-entity-menu-id';
  const [pending, setPending] = React.useState(false)
  const [linkTitle, setLinkTitle] = React.useState('Affiliates/Affiliation')
  const [eventDate, setEventDate] = React.useState(new Date())
  const [error, setError] = React.useState(false)
  const [timestamp, setTimestamp] = React.useState({
    ts:0
  })
  

  React.useEffect(() => {
    setIsLoaded(false);
    fetchActivity().then(res => res.json())
      .then(
        (result) => {
          setTPackage(result.response)
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  React.useEffect(() => {
    let pass = false
    for(var manditory in trackOpen)
    {
      if(trackOpen[manditory])
      {
        pass = true
      }
    }
    setDisableSave(pass)
  }, [trackOpen]);

  React.useEffect(() => {
    setIsLoaded(false);
    if(componentDelete === true)
    {
      deleteComponent()
      .then(res => res.json())
      .then((result) => {
        setPending(true);
        window.location.reload();
      },
      (error) => {
        setPending(true);
        setError(error);
      });
    }
  }, [componentDelete]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    setPending(true);
    setComponentDelete(!componentDelete)
  }

  React.useEffect(() => {
    let values = {
      town:pass.town,
      county:pass.county,
      search:pass.search
    }
    setSearchLoading(false);
    setProtect(true)
    fetchSearch(values).then(res => res.json())
      .then(
        (result) => {
          if(timestamp.ts < result.passback)
          {
            setOptions(result.response.options);
            setResults(result.response.table)
            if(result.response.table.length===1 )
            {
              if(result.response.table[0].taxId === search)
              {
                setAddress(result.response.table[0].parcelAddress)
                setSwis(result.response.table[0].parcelSwis)
                setTown(result.response.table[0].parcelTown)
                setCounty(result.response.table[0].parcelCounty)
                setAgreement(result.response.table[0].agreement)
                setSaveTitle('Change and Link')
              }
            }
            setSearchLoading(true);
            setSearchError(null)
            timestamp.ts = result.passback
            setProtect(false)
          }
        },
        (error) => {
          setSearchLoading(true);
          setSearchError(error);
          setProtect(false)
        }
      );
  }, [pass]);

  const handleReportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const handleChangeGeneral = (prop) => (event) => {
    newEntity.general[prop] = event.target.value;
  };

  const handleChangeType = (prop) => (event) => {
    newEntity.general[prop] = event.target.value;
    setNewEntity({...newEntity})
    trackOpen['typeSelect'] = false
    setTrackOpen({...trackOpen})

    if(newEntity.general.type === 'Person')
    {
      setLinkTitle('Affiliations')
      setSearchType('affiliation')
    }
    else if(newEntity.general.hasOwnProperty('type'))
    {
      setLinkTitle('Affiliates')
      setSearchType('person')
    }
  };

  const handleCountyChange = (event) => {
    if(!protect)
    {
      setCounty(event.target.value)
    }
  }

  const handleTownChange = (event) => {
    if(!protect)
    {
      setTown(event.target.value)
    }
  }


  const handleAddressChange = (event) => {
    if(!protect)
    {
      setAddress(event.target.value)
    }
  }

  const handleSwisChange = (event) => {
    if(!protect)
    {
      setSwis(event.target.value)
    }
  }

  const handleSearch = (value) => {
    pass.search = value;
    pass.town=town
    pass.county=county
    setSearch(value)
    setPass({...pass})
  }

  const handleDateJoined = (event) => {
    let lock = newMember;
    lock['dateJoined'] = event
    setNewMember({...lock});
  };

  const showConfirmSave = () =>{
    setUserError(false)
    setSaveTitle('Confirm new entity')
    setPastSave(false)
    setSaveContent(`Don't forget to double check your work. If you make a mistake, please see documentation to correct the mistake`)
    setShowSave(true)
  }



  return (
      <Paper className={props.className} direction="columns" style={{ height: '100%'}}>
        <Grid container spacing={0}>
        <Grid item xs={1} md={4}>
            <IconButton
                size="large"
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleReportMenuOpen}
                color="inherit"
            >
              <Badge>
                <MenuIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={6} md={4} justifyContent="center">
            <div className="component_header">
              <Typography variant="h5" component="h2" pt={1}>Add</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="Contained" onClick={(e)=>{setConfirmOpen(true)}}>Entity</Button>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDeleteClick}>Remove Component</MenuItem>
        </Menu>
        <Dialog open={confirmOpen}>
          <DialogTitle>New Entity</DialogTitle>
          <DialogContent>
            <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                trackOpen['contactInformation'] = !trackOpen['contactInformation']
                setTrackOpen({...trackOpen})
              }}
              >
                <Typography>Contact Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        onChange={handleChangeType('type')}
                      >
                        <MenuItem value={"Person"}>Person</MenuItem>
                        <MenuItem value={"Family"}>Family</MenuItem>
                        <MenuItem value={"Club"}>Club</MenuItem>
                        <MenuItem value={"Business"}>Business</MenuItem>
                        <MenuItem value={"Government"}>Government</MenuItem>
                        <MenuItem value={"Association"}>Association</MenuItem>
                        <MenuItem value={"Foundation"}>Foundation</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="first-name" 
                        label="First Name" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('first_name')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="middle-name" 
                        label="Middle Name" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('middle_name')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="last-name" 
                        label="Last Name" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('last_name')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="email-address"
                        label="Email address"
                        variant="outlined"
                        onChange={handleChangeGeneral('email_address')}  
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="alt-email-address"
                        label="Alt Email address"
                        variant="outlined"
                        onChange={handleChangeGeneral('alt_email_address')}  
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="phone-number"
                        label="Phone number"
                        variant="outlined"
                        onChange={handleChangeGeneral('primary_phone_number')}  
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="secondary-phone-number" 
                        label="Alt Phone number" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('secondary_phone_number')}
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="address" 
                        label="Address" 
                        variant="outlined"
                        onChange={handleChangeGeneral('address')}  
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="city" 
                        label="City" 
                        variant="outlined"
                        onChange={handleChangeGeneral('city')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="state" 
                        label="State" 
                        variant="outlined"
                        onChange={handleChangeGeneral('state')} 
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="zipCode" 
                        label="Zip Code" 
                        variant="outlined"
                        onChange={handleChangeGeneral('zip_code')}  
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="county" 
                        label="County" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('county')}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <FormControl fullWidth>
                      <TextField 
                        id="inCareOf" 
                        label="In care of" 
                        variant="outlined" 
                        onChange={handleChangeGeneral('in_care_of')}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['generalDetails'] = !trackOpen['generalDetails']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>General Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={8} >
                        <Typography styles={{paddingTop:'2%'}}>
                          Roles
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <AddDetailButton 
                          title="Add Role"
                          handleSave={(e) => {
                            if (newEntity.roles.indexOf(newRole) === -1) {
                              newEntity.roles.push(newRole)
                              setNewEntity({...newEntity})
                            }
                          }}>
                          <Stack spacing={1}>
                            <MixedSearchInput
                              label={'Search'}
                              type={'role'}
                              onChange={(value) => {setNewRole(value)}}
                            />
                          </Stack>
                        </AddDetailButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider/>
                  <Stack spacing={1}>
                    {newEntity.roles.map(element => (
                      <Chip 
                        label={element} 
                        onDelete={() => {
                          let index = newEntity.roles.indexOf(element)
                          if ( index > -1) {
                            newEntity.roles.splice(index, 1)
                            setNewEntity({...newEntity})
                          }
                        }}
                        deleteIcon={(<DeleteIcon/>)}
                      ></Chip>
                      ))}
                  </Stack>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['membership'] = !trackOpen['membership']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>Membership</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={10} >
                   <NewMembershipButton
                      onSave={(transaction,membership) => 
                      {
                        newEntity.membership = membership
                        newEntity.membershipTransaction = [transaction] 
                        setNewEntity({...newEntity})
                      }}
                      tPackage={tPackage}
                    />
                  </Grid>
                </Grid>
                <Divider/>
                <Box sx={{ minWidth: 120, pt:1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                          labelId="type-select-label"
                          id="type-select"
                          value={newEntity.membership.type}
                          label="Member Type"
                          disabled
                        >
                          {tPackage.memberTypes.map((t)=> <MenuItem key={"cccss"+t.id} value={t.id}>{t.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="Date Joined"
                            inputFormat="MM/dd/yyyy"
                            value={newEntity.membership.dateJoined}
                            onChange={()=>{}}
                            renderInput={(params) => <TextField {...params} />}
                            disabled
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          id="status-id"
                          label="Status"
                          value={newEntity.membership.status}
                          disabled
                        >
                        </TextField>
                      </FormControl>  
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="Expiration Date"
                            inputFormat="MM/dd/yyyy"
                            value={newEntity.membership.expDate}
                            onChange={()=>{}}
                            disabled
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="Last Paid"
                            inputFormat="MM/dd/yyyy"
                            disabled
                            value={newEntity.membership.lastPaid}
                            onChange={()=>{}}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['accounting'] = !trackOpen['accounting']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>Accounting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={5} >
                   <AddDonationButton
                      onSave={(transaction) => 
                      {
                        newEntity.transactions.push(transaction) 
                        setNewEntity({...newEntity})
                      }}
                      tPackage={tPackage}
                      type={type}
                    />
                  </Grid>
                  <Grid item xs={5} >
                   <AddPurchaseButton
                      onSave={(transaction) => 
                      {
                        newEntity.transactions.push(transaction)
                        setNewEntity({...newEntity})
                      }}
                      tPackage={tPackage}
                      type={type}
                    />
                  </Grid>
                </Grid>
                <Divider/>
                <AccountingDetails 
                  data={[...newEntity.membershipTransaction, ...newEntity.transactions]} 
                  onDelete={(selected)=>{
                    selected.map(element=> {
                      let id = element.id
                      if(newEntity.membershipTransaction.length === 1)
                      {
                        if(newEntity.membershipTransaction[0].id === id)
                        {
                          newEntity.membership = {
                            status:'No membership',
                            expDate: '',
                            dateJoined: '',
                            type: 'Click Add membership to set membership',
                            lastPaid: '',
                            target:false
                          }
                          newEntity.membershipTransaction = []

                          setNewEntity({...newEntity})
                        }
                      }
                      else
                      {
                        let i = 0
                        let delIndex = false
                        newEntity.transactions.map(element => {
                          i++
                          if(element.id === id)
                          {
                            delIndex = i
                          }
                        })

                        newEntity.transactions.splice(delIndex,1)
                        setNewEntity({...newEntity})
                      }
                    }
                  )}}
                  onSave={(selected)=>{
                    let i = 0
                    let updateIndex = false
                    newEntity.transactions.map(element => {
                      i++
                      if(element.id === selected.id)
                      {
                        updateIndex = i
                      }
                    })

                    let update = newEntity.transactions[updateIndex]
                    update[selected.field] = selected.value
                    newEntity.transactions[updateIndex] = update
                    setNewEntity({...newEntity})
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['affiliatesAffiliation'] = !trackOpen['affiliatesAffiliation']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>{linkTitle}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container >
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={8} >
                        <Typography sx={{paddingTop:'2%'}}>
                          Relations
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <AddDetailButton 
                          title={"Add " + linkTitle}
                          handleSave={(e) => {
                            newEntity.relationships.push({...tempLink})
                            setNewEntity({...newEntity})
                            setTempLink(null)

                          }}>
                          <EntitySearchInput
                            label={'Search'}
                            type={searchType}
                            ignore={true}
                            onChange={(value) => {setTempLink(value)}}
                          />
                        </AddDetailButton>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Stack spacing={1}>
                      {newEntity.relationships.map(element => (
                        <Chip 
                        label={<>{element.type} - {element.properName}</>} 
                        onDelete={() => {
                          let deleteT = false
                          let i = 0
                          newEntity.relationships.map(obj => {
                            if(element.properName === obj.properName)
                            {
                              deleteT = i
                            }
                            i++
                          })

                          if(deleteT !== false)
                          {
                            newEntity.relationships.splice(deleteT,1)
                            setNewEntity({...newEntity})
                          }
                        }}
                        deleteIcon={(<DeleteIcon/>)}
                      ></Chip>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['eventsAwards'] = !trackOpen['eventsAwards']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>Events and Awards</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={8} >
                        <Typography styles={{paddingTop:'2%'}}>
                          Events
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <AddDetailButton 
                          title="Register for event"
                          handleSave={(e) => {
                            let obj = {
                              name: newEvent,
                              date_joined: eventDate
                            }
                            let add = true

                            newEntity.events.map(element => {
                              if(element.name === obj.name)
                              {
                                add = false
                              }
                            })

                            if(add)
                            {
                              newEntity.events.push(obj)
                              setNewEntity({...newEntity})
                            }
                          }}>
                          <Stack spacing={1}>
                            <MixedSearchInput
                              label={'Search'}
                              type={'event'}
                              onChange={(value) => {setNewEvent(value)}}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                label="Date Registered"
                                inputFormat="MM/dd/yyyy"
                                value={eventDate}
                                onChange={(handleDateJoined)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </AddDetailButton>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Stack spacing={1}>
                      {newEntity.events.map(element => (
                        <Chip 
                          label={element.name} 
                          onDelete={() => {
                            let deleteT = false
                            let i = 0
                            newEntity.events.map(obj => {
                              if(element.name === obj.name)
                              {
                                deleteT = i
                              }
                              i++
                            })

                            if(deleteT !== false)
                            {
                              newEntity.events.splice(deleteT,1)
                              setNewEntity({...newEntity})
                            }
                          }}
                          deleteIcon={(<DeleteIcon/>)}
                        ></Chip>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={8} >
                        <Typography sx={{paddingTop:'2%'}}>
                          Awards
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <AddDetailButton 
                          title="Add award"
                          handleSave={(e) => {
                            if (newEntity.awards.indexOf(newAward) === -1) {
                              newEntity.awards.push(newAward)
                              setNewEntity({...newEntity})
                            }
                          }}>
                          <MixedSearchInput
                            label={'Search'}
                            type={'award'}
                            onChange={(value) => {
                              setNewAward(value)
                            }}
                          />
                        </AddDetailButton>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Stack spacing={1}>
                      {newEntity.awards.map(element => (
                        <Chip 
                          label={element} 
                          onDelete={() => {
                            let index = newEntity.awards.indexOf(element)
                            if ( index > -1) {
                              newEntity.awards.splice(index, 1)
                              setNewEntity({...newEntity})
                            }
                          }}
                          deleteIcon={(<DeleteIcon/>)}
                        ></Chip>
                        ))}
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['trailManagement'] = !trackOpen['trailManagement']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>Trail Management</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={10} >
                    <Typography sx={{paddingTop:'2%'}}>
                      Section
                    </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <AddDetailButton handleSave={(e) => {
                      let add = true
                      let focus = addSection

                      setAddSection({
                        name: '', 
                        type: 'Maintainer'
                      })

                      newEntity.sections.map(element => {
                        if(element.name === focus.name)
                        {
                          add = false
                        }
                      })
                      
                      if(add)
                      {
                        newEntity.sections.push(focus)
                        setNewEntity({...newEntity})
                      }
                    }} title="Add Section">
                      <Stack spacing={1}>
                        <MixedSearchInput
                          label={'Search'}
                          type={'section'}
                          onChange={(value) => {
                            addSection.name = value
                            setAddSection({...addSection})
                          }}
                        />
                        <FormControl fullWidth>
                          <InputLabel id="new-section-type-select-label">Type</InputLabel>
                          <Select
                            labelId="new-section-type-select-label"
                            id="new-section-type-select"
                            value={addSection.type}
                            label="Type"
                            onChange={(e)=>{
                              addSection.type = e.target.value
                              setAddSection({...addSection})
                            }}
                          >
                            <MenuItem value="Maintainer">Maintainer</MenuItem>
                            <MenuItem value="Sponsor">Sponsor</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </AddDetailButton>
                  </Grid>
                </Grid>
                <Divider/>
                <Stack spacing={1}>
                  {newEntity.sections.map(element => (<Chip 
                    label={element.name} 
                    onDelete={() => {
                      let deleteT = false
                      let i = 0
                      newEntity.sections.map(obj => {
                        if(element.name === obj.name)
                        {
                          deleteT = i
                        }
                        i++
                      })

                      if(deleteT !== false)
                      {
                        newEntity.sections.splice(deleteT,1)
                        setNewEntity({...newEntity})
                      }
                    }}
                    deleteIcon={(<DeleteIcon/>)}
                  ></Chip>))}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  trackOpen['parcels'] = !trackOpen['parcels']
                  setTrackOpen({...trackOpen})
                }}
              >
                <Typography>Parcels</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={10} >
                    <Typography sx={{paddingTop:'2%'}}>
                      Parcels
                    </Typography>
                  </Grid>
                  <Grid item xs={2} >
                  <AddDetailButton 
                    title="Change/Add and link parcel" 
                    saveTitle={saveTitle}
                    handleSave={(e) => {
                      landRequest.address = address
                      landRequest.swis = swis
                      landRequest.parcel_tax_id = search
                      landRequest.town = town
                      landRequest.county = county
                      landRequest.section = section
                      landRequest.agreement = agreement
                      newEntity.parcels.push(landRequest)
                      setNewEntity({...newEntity})

                    }}>
                    <Stack spacing={1}>
                      <Autocomplete
                        clearOnBlur={false}
                        id="combo-box-demo"
                        options={options}
                        onInputChange={(e, value) => {
                          handleSearch(value)
                        }}
                        renderInput={(params) => <TextField {...params} label="Tax Id" />}
                      />
                      <MixedSearchInput
                        label={'Section'}
                        type={'section'}
                        value={section}
                        onChange={(value) => {setSection(value)}}
                      />
                      <FormControl fullWidth>
                        <InputLabel id="new-section-type-select-label">Agreement</InputLabel>
                        <Select
                          labelId="new-section-type-select-label"
                          id="new-section-type-select"
                          value={agreement}
                          label="Agreement"
                          onChange={(e)=>{
                            setAgreement(e.target.value)
                          }}
                        >
                          <MenuItem value="FLTC Owns">FLTC Owns</MenuItem>
                          <MenuItem value="Easement">Easement</MenuItem>
                          <MenuItem value="Written Contract">Written Contract</MenuItem>
                          <MenuItem value="Verbal Agreement">Verbal Agreement</MenuItem>
                          <MenuItem value="Off Trail">Off Trail</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField 
                        id="swiss" 
                        label="SWIS" 
                        variant="outlined" 
                        value={swis}
                        onChange={handleSwisChange}
                      />
                      <TextField 
                        id="address"
                        label="Address" 
                        variant="outlined"
                        value={address}
                        onChange={handleAddressChange}
                      />
                      <TextField 
                        id="county-search-add" 
                        label="County" 
                        variant="outlined" 
                        value={county}
                        onChange={handleCountyChange}
                      />
                      <TextField 
                        id="town-search-add"
                        label="Town" 
                        variant="outlined"
                        value={town}
                        onChange={handleTownChange}
                      />
                    </Stack>
                  </AddDetailButton>
                  </Grid>
                </Grid>
                <Divider/>
                <Stack spacing={1}>
                  {newEntity.parcels.map(element => (<Chip 
                    label={element.parcel_tax_id} 
                    onDelete={() => {
                      let deleteT = false
                      let i = 0
                      newEntity.parcels.map(obj => {
                        if(element.parcel_tax_id === obj.parcel_tax_id)
                        {
                          deleteT = i
                        }
                        i++
                      })

                      if(deleteT !== false)
                      {
                        newEntity.parcels.splice(deleteT,1)
                        setNewEntity({...newEntity})
                      }
                    }}
                    deleteIcon={(<DeleteIcon/>)}
                  ></Chip>))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button type="contained" onClick={handleClose}>Cancel</Button>
            <Button type="contained" disabled={disableSave} onClick={showConfirmSave}>Save</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showSave}>
          <DialogTitle>{saveTitle}</DialogTitle>
          <DialogContent>
            <Stack>
              {saveContent}
            </Stack>
          </DialogContent>
          <DialogActions>
            {!pastSave && <Button type="contained" onClick={()=>{setShowSave(false)}}>Close</Button>}
            {!userError && <Button type="contained" onClick={() => {handleSave()}} disabled={saveLoading}>{saveButtonTitle}</Button>}
          </DialogActions>
        </Dialog>
      </Paper>
  );
}